import { Box, IconClear } from 'braid-design-system';

import { truncateText } from 'src/utils/truncateText/truncateText';

import * as styles from '../Pills.css';

export const getPillText = ({
  text,
  isActive,
  isMobile,
}: {
  text: string;
  isActive: boolean;
  isMobile: boolean;
}) => {
  const pillText = isMobile ? truncateText({ text, length: 35 }) : text;

  if (isActive) {
    return (
      <>
        {pillText}
        <Box
          background={isActive ? 'customLight' : 'customDark'}
          className={styles.clearIcon}
          component="span"
        >
          <IconClear />
        </Box>
      </>
    );
  }

  return pillText;
};
