/** This file was generated by `pnpm generate-salaryranges` **/
import type { AllSalaryRanges } from 'src/config/types';

export const salaryRanges: Record<string, AllSalaryRanges> = {
  'anz-1': {
    en: {
      defaultCurrency: { code: 'AUD' },
      searchSalaryRanges: [
        {
          frequency: { label: 'Annually', type: 'annual', isDefault: true },
          range: [
            { shortLabel: '0', longLabel: '$0', value: '0' },
            { shortLabel: '30K', longLabel: '$30K', value: '30000' },
            { shortLabel: '40K', longLabel: '$40K', value: '40000' },
            { shortLabel: '50K', longLabel: '$50K', value: '50000' },
            { shortLabel: '60K', longLabel: '$60K', value: '60000' },
            { shortLabel: '70K', longLabel: '$70K', value: '70000' },
            { shortLabel: '80K', longLabel: '$80K', value: '80000' },
            { shortLabel: '100K', longLabel: '$100K', value: '100000' },
            { shortLabel: '120K', longLabel: '$120K', value: '120000' },
            { shortLabel: '150K', longLabel: '$150K', value: '150000' },
            { shortLabel: '200K', longLabel: '$200K', value: '200000' },
            { shortLabel: '250K', longLabel: '$250K', value: '250000' },
            { shortLabel: '350K', longLabel: '$350K', value: '350000' },
            { shortLabel: '350K+', longLabel: '$350K+', value: '' },
          ],
        },
        {
          frequency: { label: 'Monthly', type: 'monthly', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: '$0', value: '0' },
            { shortLabel: '2K', longLabel: '$2K', value: '2000' },
            { shortLabel: '3K', longLabel: '$3K', value: '3000' },
            { shortLabel: '4K', longLabel: '$4K', value: '4000' },
            { shortLabel: '5K', longLabel: '$5K', value: '5000' },
            { shortLabel: '6K', longLabel: '$6K', value: '6000' },
            { shortLabel: '7K', longLabel: '$7K', value: '7000' },
            { shortLabel: '8K', longLabel: '$8K', value: '8000' },
            { shortLabel: '10K', longLabel: '$10K', value: '10000' },
            { shortLabel: '12K', longLabel: '$12K', value: '12000' },
            { shortLabel: '17K', longLabel: '$17K', value: '17000' },
            { shortLabel: '20K', longLabel: '$20K', value: '20000' },
            { shortLabel: '30K', longLabel: '$30K', value: '30000' },
            { shortLabel: '30K+', longLabel: '$30K+', value: '' },
          ],
        },
        {
          frequency: { label: 'Hourly', type: 'hourly', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: '$0', value: '0' },
            { shortLabel: '15', longLabel: '$15', value: '15' },
            { shortLabel: '20', longLabel: '$20', value: '20' },
            { shortLabel: '25', longLabel: '$25', value: '25' },
            { shortLabel: '30', longLabel: '$30', value: '30' },
            { shortLabel: '35', longLabel: '$35', value: '35' },
            { shortLabel: '40', longLabel: '$40', value: '40' },
            { shortLabel: '50', longLabel: '$50', value: '50' },
            { shortLabel: '60', longLabel: '$60', value: '60' },
            { shortLabel: '75', longLabel: '$75', value: '75' },
            { shortLabel: '100', longLabel: '$100', value: '100' },
            { shortLabel: '125', longLabel: '$125', value: '125' },
            { shortLabel: '175', longLabel: '$175', value: '175' },
            { shortLabel: '175+', longLabel: '$175+', value: '' },
          ],
        },
      ],
    },
  },
  'anz-2': {
    en: {
      defaultCurrency: { code: 'NZD' },
      searchSalaryRanges: [
        {
          frequency: { label: 'Annually', type: 'annual', isDefault: true },
          range: [
            { shortLabel: '0', longLabel: '$0', value: '0' },
            { shortLabel: '30K', longLabel: '$30K', value: '30000' },
            { shortLabel: '40K', longLabel: '$40K', value: '40000' },
            { shortLabel: '50K', longLabel: '$50K', value: '50000' },
            { shortLabel: '60K', longLabel: '$60K', value: '60000' },
            { shortLabel: '70K', longLabel: '$70K', value: '70000' },
            { shortLabel: '80K', longLabel: '$80K', value: '80000' },
            { shortLabel: '100K', longLabel: '$100K', value: '100000' },
            { shortLabel: '120K', longLabel: '$120K', value: '120000' },
            { shortLabel: '150K', longLabel: '$150K', value: '150000' },
            { shortLabel: '200K', longLabel: '$200K', value: '200000' },
            { shortLabel: '250K', longLabel: '$250K', value: '250000' },
            { shortLabel: '350K', longLabel: '$350K', value: '350000' },
            { shortLabel: '350K+', longLabel: '$350K+', value: '' },
          ],
        },
        {
          frequency: { label: 'Monthly', type: 'monthly', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: '$0', value: '0' },
            { shortLabel: '2K', longLabel: '$2K', value: '2000' },
            { shortLabel: '3K', longLabel: '$3K', value: '3000' },
            { shortLabel: '4K', longLabel: '$4K', value: '4000' },
            { shortLabel: '5K', longLabel: '$5K', value: '5000' },
            { shortLabel: '6K', longLabel: '$6K', value: '6000' },
            { shortLabel: '7K', longLabel: '$7K', value: '7000' },
            { shortLabel: '8K', longLabel: '$8K', value: '8000' },
            { shortLabel: '10K', longLabel: '$10K', value: '10000' },
            { shortLabel: '12K', longLabel: '$12K', value: '12000' },
            { shortLabel: '17K', longLabel: '$17K', value: '17000' },
            { shortLabel: '20K', longLabel: '$20K', value: '20000' },
            { shortLabel: '30K', longLabel: '$30K', value: '30000' },
            { shortLabel: '30K+', longLabel: '$30K+', value: '' },
          ],
        },
        {
          frequency: { label: 'Hourly', type: 'hourly', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: '$0', value: '0' },
            { shortLabel: '15', longLabel: '$15', value: '15' },
            { shortLabel: '20', longLabel: '$20', value: '20' },
            { shortLabel: '25', longLabel: '$25', value: '25' },
            { shortLabel: '30', longLabel: '$30', value: '30' },
            { shortLabel: '35', longLabel: '$35', value: '35' },
            { shortLabel: '40', longLabel: '$40', value: '40' },
            { shortLabel: '50', longLabel: '$50', value: '50' },
            { shortLabel: '60', longLabel: '$60', value: '60' },
            { shortLabel: '75', longLabel: '$75', value: '75' },
            { shortLabel: '100', longLabel: '$100', value: '100' },
            { shortLabel: '125', longLabel: '$125', value: '125' },
            { shortLabel: '175', longLabel: '$175', value: '175' },
            { shortLabel: '175+', longLabel: '$175+', value: '' },
          ],
        },
      ],
    },
  },
  'asia-1': {
    en: {
      defaultCurrency: { code: 'HKD' },
      searchSalaryRanges: [
        {
          frequency: { label: 'Annually', type: 'annual', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: '$0', value: '0' },
            { shortLabel: '120K', longLabel: '$120K', value: '120000' },
            { shortLabel: '150K', longLabel: '$150K', value: '150000' },
            { shortLabel: '200K', longLabel: '$200K', value: '200000' },
            { shortLabel: '250K', longLabel: '$250K', value: '250000' },
            { shortLabel: '300K', longLabel: '$300K', value: '300000' },
            { shortLabel: '350K', longLabel: '$350K', value: '350000' },
            { shortLabel: '400K', longLabel: '$400K', value: '400000' },
            { shortLabel: '500K', longLabel: '$500K', value: '500000' },
            { shortLabel: '600K', longLabel: '$600K', value: '600000' },
            { shortLabel: '720K', longLabel: '$720K', value: '720000' },
            { shortLabel: '960K', longLabel: '$960K', value: '960000' },
            { shortLabel: '1.5M', longLabel: '$1.5M', value: '1500000' },
            { shortLabel: '1.5M+', longLabel: '$1.5M+', value: '' },
          ],
        },
        {
          frequency: { label: 'Monthly', type: 'monthly', isDefault: true },
          range: [
            { shortLabel: '0', longLabel: '$0', value: '0' },
            { shortLabel: '11K', longLabel: '$11K', value: '11000' },
            { shortLabel: '14K', longLabel: '$14K', value: '14000' },
            { shortLabel: '17K', longLabel: '$17K', value: '17000' },
            { shortLabel: '20K', longLabel: '$20K', value: '20000' },
            { shortLabel: '25K', longLabel: '$25K', value: '25000' },
            { shortLabel: '30K', longLabel: '$30K', value: '30000' },
            { shortLabel: '35K', longLabel: '$35K', value: '35000' },
            { shortLabel: '40K', longLabel: '$40K', value: '40000' },
            { shortLabel: '50K', longLabel: '$50K', value: '50000' },
            { shortLabel: '60K', longLabel: '$60K', value: '60000' },
            { shortLabel: '80K', longLabel: '$80K', value: '80000' },
            { shortLabel: '120K', longLabel: '$120K', value: '120000' },
            { shortLabel: '120K+', longLabel: '$120K+', value: '' },
          ],
        },
        {
          frequency: { label: 'Hourly', type: 'hourly', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: '$0', value: '0' },
            { shortLabel: '40', longLabel: '$40', value: '40' },
            { shortLabel: '60', longLabel: '$60', value: '60' },
            { shortLabel: '80', longLabel: '$80', value: '80' },
            { shortLabel: '100', longLabel: '$100', value: '100' },
            { shortLabel: '125', longLabel: '$125', value: '125' },
            { shortLabel: '150', longLabel: '$150', value: '150' },
            { shortLabel: '175', longLabel: '$175', value: '175' },
            { shortLabel: '200', longLabel: '$200', value: '200' },
            { shortLabel: '250', longLabel: '$250', value: '250' },
            { shortLabel: '350', longLabel: '$350', value: '350' },
            { shortLabel: '450', longLabel: '$450', value: '450' },
            { shortLabel: '600', longLabel: '$600', value: '600' },
            { shortLabel: '600+', longLabel: '$600+', value: '' },
          ],
        },
      ],
    },
  },
  'asia-3': {
    en: {
      defaultCurrency: { code: 'THB' },
      searchSalaryRanges: [
        {
          frequency: { label: 'Annually', type: 'annual', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: '฿0', value: '0' },
            { shortLabel: '200K', longLabel: '฿200K', value: '200000' },
            { shortLabel: '250K', longLabel: '฿250K', value: '250000' },
            { shortLabel: '300K', longLabel: '฿300K', value: '300000' },
            { shortLabel: '400K', longLabel: '฿400K', value: '400000' },
            { shortLabel: '500K', longLabel: '฿500K', value: '500000' },
            { shortLabel: '600K', longLabel: '฿600K', value: '600000' },
            { shortLabel: '700K', longLabel: '฿700K', value: '700000' },
            { shortLabel: '800K', longLabel: '฿800K', value: '800000' },
            { shortLabel: '1M', longLabel: '฿1M', value: '1000000' },
            { shortLabel: '1.2M', longLabel: '฿1.2M', value: '1200000' },
            { shortLabel: '1.8M', longLabel: '฿1.8M', value: '1800000' },
            { shortLabel: '2.4M', longLabel: '฿2.4M', value: '2400000' },
            { shortLabel: '2.4M+', longLabel: '฿2.4M+', value: '' },
          ],
        },
        {
          frequency: { label: 'Monthly', type: 'monthly', isDefault: true },
          range: [
            { shortLabel: '0', longLabel: '฿0', value: '0' },
            { shortLabel: '15K', longLabel: '฿15K', value: '15000' },
            { shortLabel: '20K', longLabel: '฿20K', value: '20000' },
            { shortLabel: '25K', longLabel: '฿25K', value: '25000' },
            { shortLabel: '30K', longLabel: '฿30K', value: '30000' },
            { shortLabel: '40K', longLabel: '฿40K', value: '40000' },
            { shortLabel: '50K', longLabel: '฿50K', value: '50000' },
            { shortLabel: '60K', longLabel: '฿60K', value: '60000' },
            { shortLabel: '70K', longLabel: '฿70K', value: '70000' },
            { shortLabel: '80K', longLabel: '฿80K', value: '80000' },
            { shortLabel: '100K', longLabel: '฿100K', value: '100000' },
            { shortLabel: '150K', longLabel: '฿150K', value: '150000' },
            { shortLabel: '200K', longLabel: '฿200K', value: '200000' },
            { shortLabel: '200K+', longLabel: '฿200K+', value: '' },
          ],
        },
        {
          frequency: { label: 'Hourly', type: 'hourly', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: '฿0', value: '0' },
            { shortLabel: '100', longLabel: '฿100', value: '100' },
            { shortLabel: '125', longLabel: '฿125', value: '125' },
            { shortLabel: '150', longLabel: '฿150', value: '150' },
            { shortLabel: '200', longLabel: '฿200', value: '200' },
            { shortLabel: '250', longLabel: '฿250', value: '250' },
            { shortLabel: '300', longLabel: '฿300', value: '300' },
            { shortLabel: '350', longLabel: '฿350', value: '350' },
            { shortLabel: '400', longLabel: '฿400', value: '400' },
            { shortLabel: '500', longLabel: '฿500', value: '500' },
            { shortLabel: '600', longLabel: '฿600', value: '600' },
            { shortLabel: '900', longLabel: '฿900', value: '900' },
            { shortLabel: '1.2K', longLabel: '฿1.2K', value: '1200' },
            { shortLabel: '1.2K+', longLabel: '฿1.2K+', value: '' },
          ],
        },
      ],
    },
    th: {
      defaultCurrency: { code: 'THB' },
      searchSalaryRanges: [
        {
          frequency: { label: 'รายปี', type: 'annual', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: '฿0', value: '0' },
            { shortLabel: '200K', longLabel: '฿200K', value: '200000' },
            { shortLabel: '250K', longLabel: '฿250K', value: '250000' },
            { shortLabel: '300K', longLabel: '฿300K', value: '300000' },
            { shortLabel: '400K', longLabel: '฿400K', value: '400000' },
            { shortLabel: '500K', longLabel: '฿500K', value: '500000' },
            { shortLabel: '600K', longLabel: '฿600K', value: '600000' },
            { shortLabel: '700K', longLabel: '฿700K', value: '700000' },
            { shortLabel: '800K', longLabel: '฿800K', value: '800000' },
            { shortLabel: '1M', longLabel: '฿1M', value: '1000000' },
            { shortLabel: '1.2M', longLabel: '฿1.2M', value: '1200000' },
            { shortLabel: '1.8M', longLabel: '฿1.8M', value: '1800000' },
            { shortLabel: '2.4M', longLabel: '฿2.4M', value: '2400000' },
            { shortLabel: '2.4M+', longLabel: '฿2.4M+', value: '' },
          ],
        },
        {
          frequency: { label: 'รายเดือน', type: 'monthly', isDefault: true },
          range: [
            { shortLabel: '0', longLabel: '฿0', value: '0' },
            { shortLabel: '15K', longLabel: '฿15K', value: '15000' },
            { shortLabel: '20K', longLabel: '฿20K', value: '20000' },
            { shortLabel: '25K', longLabel: '฿25K', value: '25000' },
            { shortLabel: '30K', longLabel: '฿30K', value: '30000' },
            { shortLabel: '40K', longLabel: '฿40K', value: '40000' },
            { shortLabel: '50K', longLabel: '฿50K', value: '50000' },
            { shortLabel: '60K', longLabel: '฿60K', value: '60000' },
            { shortLabel: '70K', longLabel: '฿70K', value: '70000' },
            { shortLabel: '80K', longLabel: '฿80K', value: '80000' },
            { shortLabel: '100K', longLabel: '฿100K', value: '100000' },
            { shortLabel: '150K', longLabel: '฿150K', value: '150000' },
            { shortLabel: '200K', longLabel: '฿200K', value: '200000' },
            { shortLabel: '200K+', longLabel: '฿200K+', value: '' },
          ],
        },
        {
          frequency: { label: 'รายชั่วโมง', type: 'hourly', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: '฿0', value: '0' },
            { shortLabel: '100', longLabel: '฿100', value: '100' },
            { shortLabel: '125', longLabel: '฿125', value: '125' },
            { shortLabel: '150', longLabel: '฿150', value: '150' },
            { shortLabel: '200', longLabel: '฿200', value: '200' },
            { shortLabel: '250', longLabel: '฿250', value: '250' },
            { shortLabel: '300', longLabel: '฿300', value: '300' },
            { shortLabel: '350', longLabel: '฿350', value: '350' },
            { shortLabel: '400', longLabel: '฿400', value: '400' },
            { shortLabel: '500', longLabel: '฿500', value: '500' },
            { shortLabel: '600', longLabel: '฿600', value: '600' },
            { shortLabel: '900', longLabel: '฿900', value: '900' },
            { shortLabel: '1.2K', longLabel: '฿1.2K', value: '1200' },
            { shortLabel: '1.2K+', longLabel: '฿1.2K+', value: '' },
          ],
        },
      ],
    },
  },
  'asia-4': {
    en: {
      defaultCurrency: { code: 'IDR' },
      searchSalaryRanges: [
        {
          frequency: { label: 'Annually', type: 'annual', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: 'Rp 0', value: '0' },
            { shortLabel: '25M', longLabel: 'Rp 25M', value: '25000000' },
            { shortLabel: '50M', longLabel: 'Rp 50M', value: '50000000' },
            { shortLabel: '75M', longLabel: 'Rp 75M', value: '75000000' },
            { shortLabel: '100M', longLabel: 'Rp 100M', value: '100000000' },
            { shortLabel: '150M', longLabel: 'Rp 150M', value: '150000000' },
            { shortLabel: '200M', longLabel: 'Rp 200M', value: '200000000' },
            { shortLabel: '250M', longLabel: 'Rp 250M', value: '250000000' },
            { shortLabel: '300M', longLabel: 'Rp 300M', value: '300000000' },
            { shortLabel: '500M', longLabel: 'Rp 500M', value: '500000000' },
            { shortLabel: '750M', longLabel: 'Rp 750M', value: '750000000' },
            { shortLabel: '1B', longLabel: 'Rp 1B', value: '1000000000' },
            { shortLabel: '1.3B', longLabel: 'Rp 1.3B', value: '1300000000' },
            { shortLabel: '1.3B+', longLabel: 'Rp 1.3B+', value: '' },
          ],
        },
        {
          frequency: { label: 'Monthly', type: 'monthly', isDefault: true },
          range: [
            { shortLabel: '0', longLabel: 'Rp 0', value: '0' },
            { shortLabel: '2M', longLabel: 'Rp 2M', value: '2000000' },
            { shortLabel: '4M', longLabel: 'Rp 4M', value: '4000000' },
            { shortLabel: '6M', longLabel: 'Rp 6M', value: '6000000' },
            { shortLabel: '8M', longLabel: 'Rp 8M', value: '8000000' },
            { shortLabel: '10M', longLabel: 'Rp 10M', value: '10000000' },
            { shortLabel: '15M', longLabel: 'Rp 15M', value: '15000000' },
            { shortLabel: '20M', longLabel: 'Rp 20M', value: '20000000' },
            { shortLabel: '25M', longLabel: 'Rp 25M', value: '25000000' },
            { shortLabel: '40M', longLabel: 'Rp 40M', value: '40000000' },
            { shortLabel: '60M', longLabel: 'Rp 60M', value: '60000000' },
            { shortLabel: '80M', longLabel: 'Rp 80M', value: '80000000' },
            { shortLabel: '100M', longLabel: 'Rp 100M', value: '100000000' },
            { shortLabel: '100M+', longLabel: 'Rp 100M+', value: '' },
          ],
        },
        {
          frequency: { label: 'Hourly', type: 'hourly', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: 'Rp 0', value: '0' },
            { shortLabel: '10K', longLabel: 'Rp 10K', value: '10000' },
            { shortLabel: '20K', longLabel: 'Rp 20K', value: '20000' },
            { shortLabel: '35K', longLabel: 'Rp 35K', value: '35000' },
            { shortLabel: '50K', longLabel: 'Rp 50K', value: '50000' },
            { shortLabel: '75K', longLabel: 'Rp 75K', value: '75000' },
            { shortLabel: '100K', longLabel: 'Rp 100K', value: '100000' },
            { shortLabel: '125K', longLabel: 'Rp 125K', value: '125000' },
            { shortLabel: '150K', longLabel: 'Rp 150K', value: '150000' },
            { shortLabel: '250K', longLabel: 'Rp 250K', value: '250000' },
            { shortLabel: '350K', longLabel: 'Rp 350K', value: '350000' },
            { shortLabel: '500K', longLabel: 'Rp 500K', value: '500000' },
            { shortLabel: '650K', longLabel: 'Rp 650K', value: '650000' },
            { shortLabel: '650K+', longLabel: 'Rp 650K+', value: '' },
          ],
        },
      ],
    },
    id: {
      defaultCurrency: { code: 'IDR' },
      searchSalaryRanges: [
        {
          frequency: { label: 'Tahunan', type: 'annual', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: 'Rp 0', value: '0' },
            { shortLabel: '25 jt', longLabel: 'Rp 25 jt', value: '25000000' },
            { shortLabel: '50 jt', longLabel: 'Rp 50 jt', value: '50000000' },
            { shortLabel: '75 jt', longLabel: 'Rp 75 jt', value: '75000000' },
            {
              shortLabel: '100 jt',
              longLabel: 'Rp 100 jt',
              value: '100000000',
            },
            {
              shortLabel: '150 jt',
              longLabel: 'Rp 150 jt',
              value: '150000000',
            },
            {
              shortLabel: '200 jt',
              longLabel: 'Rp 200 jt',
              value: '200000000',
            },
            {
              shortLabel: '250 jt',
              longLabel: 'Rp 250 jt',
              value: '250000000',
            },
            {
              shortLabel: '300 jt',
              longLabel: 'Rp 300 jt',
              value: '300000000',
            },
            {
              shortLabel: '500 jt',
              longLabel: 'Rp 500 jt',
              value: '500000000',
            },
            {
              shortLabel: '750 jt',
              longLabel: 'Rp 750 jt',
              value: '750000000',
            },
            { shortLabel: '1 M', longLabel: 'Rp 1 M', value: '1000000000' },
            { shortLabel: '1,3 M', longLabel: 'Rp 1,3 M', value: '1300000000' },
            { shortLabel: '1,3 M+', longLabel: 'Rp 1,3 M+', value: '' },
          ],
        },
        {
          frequency: { label: 'Bulanan', type: 'monthly', isDefault: true },
          range: [
            { shortLabel: '0', longLabel: 'Rp 0', value: '0' },
            { shortLabel: '2 jt', longLabel: 'Rp 2 jt', value: '2000000' },
            { shortLabel: '4 jt', longLabel: 'Rp 4 jt', value: '4000000' },
            { shortLabel: '6 jt', longLabel: 'Rp 6 jt', value: '6000000' },
            { shortLabel: '8 jt', longLabel: 'Rp 8 jt', value: '8000000' },
            { shortLabel: '10 jt', longLabel: 'Rp 10 jt', value: '10000000' },
            { shortLabel: '15 jt', longLabel: 'Rp 15 jt', value: '15000000' },
            { shortLabel: '20 jt', longLabel: 'Rp 20 jt', value: '20000000' },
            { shortLabel: '25 jt', longLabel: 'Rp 25 jt', value: '25000000' },
            { shortLabel: '40 jt', longLabel: 'Rp 40 jt', value: '40000000' },
            { shortLabel: '60 jt', longLabel: 'Rp 60 jt', value: '60000000' },
            { shortLabel: '80 jt', longLabel: 'Rp 80 jt', value: '80000000' },
            {
              shortLabel: '100 jt',
              longLabel: 'Rp 100 jt',
              value: '100000000',
            },
            { shortLabel: '100 jt+', longLabel: 'Rp 100 jt+', value: '' },
          ],
        },
        {
          frequency: { label: 'Per Jam', type: 'hourly', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: 'Rp 0', value: '0' },
            { shortLabel: '10 rb', longLabel: 'Rp 10 rb', value: '10000' },
            { shortLabel: '20 rb', longLabel: 'Rp 20 rb', value: '20000' },
            { shortLabel: '35 rb', longLabel: 'Rp 35 rb', value: '35000' },
            { shortLabel: '50 rb', longLabel: 'Rp 50 rb', value: '50000' },
            { shortLabel: '75 rb', longLabel: 'Rp 75 rb', value: '75000' },
            { shortLabel: '100 rb', longLabel: 'Rp 100 rb', value: '100000' },
            { shortLabel: '125 rb', longLabel: 'Rp 125 rb', value: '125000' },
            { shortLabel: '150 rb', longLabel: 'Rp 150 rb', value: '150000' },
            { shortLabel: '250 rb', longLabel: 'Rp 250 rb', value: '250000' },
            { shortLabel: '350 rb', longLabel: 'Rp 350 rb', value: '350000' },
            { shortLabel: '500 rb', longLabel: 'Rp 500 rb', value: '500000' },
            { shortLabel: '650 rb', longLabel: 'Rp 650 rb', value: '650000' },
            { shortLabel: '650 rb+', longLabel: 'Rp 650 rb+', value: '' },
          ],
        },
      ],
    },
  },
  'asia-5': {
    en: {
      defaultCurrency: { code: 'MYR' },
      searchSalaryRanges: [
        {
          frequency: { label: 'Annually', type: 'annual', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: 'RM 0', value: '0' },
            { shortLabel: '20K', longLabel: 'RM 20K', value: '20000' },
            { shortLabel: '30K', longLabel: 'RM 30K', value: '30000' },
            { shortLabel: '40K', longLabel: 'RM 40K', value: '40000' },
            { shortLabel: '50K', longLabel: 'RM 50K', value: '50000' },
            { shortLabel: '60K', longLabel: 'RM 60K', value: '60000' },
            { shortLabel: '90K', longLabel: 'RM 90K', value: '90000' },
            { shortLabel: '130K', longLabel: 'RM 130K', value: '130000' },
            { shortLabel: '180K', longLabel: 'RM 180K', value: '180000' },
            { shortLabel: '240K', longLabel: 'RM 240K', value: '240000' },
            { shortLabel: '360K', longLabel: 'RM 360K', value: '360000' },
            { shortLabel: '480K', longLabel: 'RM 480K', value: '480000' },
            { shortLabel: '600K', longLabel: 'RM 600K', value: '600000' },
            { shortLabel: '600K+', longLabel: 'RM 600K+', value: '' },
          ],
        },
        {
          frequency: { label: 'Monthly', type: 'monthly', isDefault: true },
          range: [
            { shortLabel: '0', longLabel: 'RM 0', value: '0' },
            { shortLabel: '1K', longLabel: 'RM 1K', value: '1000' },
            { shortLabel: '2K', longLabel: 'RM 2K', value: '2000' },
            { shortLabel: '3K', longLabel: 'RM 3K', value: '3000' },
            { shortLabel: '4K', longLabel: 'RM 4K', value: '4000' },
            { shortLabel: '5K', longLabel: 'RM 5K', value: '5000' },
            { shortLabel: '8K', longLabel: 'RM 8K', value: '8000' },
            { shortLabel: '11K', longLabel: 'RM 11K', value: '11000' },
            { shortLabel: '15K', longLabel: 'RM 15K', value: '15000' },
            { shortLabel: '20K', longLabel: 'RM 20K', value: '20000' },
            { shortLabel: '30K', longLabel: 'RM 30K', value: '30000' },
            { shortLabel: '40K', longLabel: 'RM 40K', value: '40000' },
            { shortLabel: '50K', longLabel: 'RM 50K', value: '50000' },
            { shortLabel: '50K+', longLabel: 'RM 50K+', value: '' },
          ],
        },
        {
          frequency: { label: 'Hourly', type: 'hourly', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: 'RM 0', value: '0' },
            { shortLabel: '5', longLabel: 'RM 5', value: '5' },
            { shortLabel: '10', longLabel: 'RM 10', value: '10' },
            { shortLabel: '15', longLabel: 'RM 15', value: '15' },
            { shortLabel: '20', longLabel: 'RM 20', value: '20' },
            { shortLabel: '30', longLabel: 'RM 30', value: '30' },
            { shortLabel: '50', longLabel: 'RM 50', value: '50' },
            { shortLabel: '70', longLabel: 'RM 70', value: '70' },
            { shortLabel: '90', longLabel: 'RM 90', value: '90' },
            { shortLabel: '120', longLabel: 'RM 120', value: '120' },
            { shortLabel: '180', longLabel: 'RM 180', value: '180' },
            { shortLabel: '240', longLabel: 'RM 240', value: '240' },
            { shortLabel: '300', longLabel: 'RM 300', value: '300' },
            { shortLabel: '300+', longLabel: 'RM 300+', value: '' },
          ],
        },
      ],
    },
  },
  'asia-6': {
    en: {
      defaultCurrency: { code: 'PHP' },
      searchSalaryRanges: [
        {
          frequency: { label: 'Annually', type: 'annual', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: '₱0', value: '0' },
            { shortLabel: '150K', longLabel: '₱150K', value: '150000' },
            { shortLabel: '200K', longLabel: '₱200K', value: '200000' },
            { shortLabel: '300K', longLabel: '₱300K', value: '300000' },
            { shortLabel: '400K', longLabel: '₱400K', value: '400000' },
            { shortLabel: '500K', longLabel: '₱500K', value: '500000' },
            { shortLabel: '600K', longLabel: '₱600K', value: '600000' },
            { shortLabel: '750K', longLabel: '₱750K', value: '750000' },
            { shortLabel: '900K', longLabel: '₱900K', value: '900000' },
            { shortLabel: '1.1M', longLabel: '₱1.1M', value: '1100000' },
            { shortLabel: '1.3M', longLabel: '₱1.3M', value: '1300000' },
            { shortLabel: '1.5M', longLabel: '₱1.5M', value: '1500000' },
            { shortLabel: '2M', longLabel: '₱2M', value: '2000000' },
            { shortLabel: '2M+', longLabel: '₱2M+', value: '' },
          ],
        },
        {
          frequency: { label: 'Monthly', type: 'monthly', isDefault: true },
          range: [
            { shortLabel: '0', longLabel: '₱0', value: '0' },
            { shortLabel: '10K', longLabel: '₱10K', value: '10000' },
            { shortLabel: '15K', longLabel: '₱15K', value: '15000' },
            { shortLabel: '20K', longLabel: '₱20K', value: '20000' },
            { shortLabel: '30K', longLabel: '₱30K', value: '30000' },
            { shortLabel: '40K', longLabel: '₱40K', value: '40000' },
            { shortLabel: '50K', longLabel: '₱50K', value: '50000' },
            { shortLabel: '60K', longLabel: '₱60K', value: '60000' },
            { shortLabel: '70K', longLabel: '₱70K', value: '70000' },
            { shortLabel: '80K', longLabel: '₱80K', value: '80000' },
            { shortLabel: '100K', longLabel: '₱100K', value: '100000' },
            { shortLabel: '120K', longLabel: '₱120K', value: '120000' },
            { shortLabel: '150K', longLabel: '₱150K', value: '150000' },
            { shortLabel: '150K+', longLabel: '₱150K+', value: '' },
          ],
        },
        {
          frequency: { label: 'Hourly', type: 'hourly', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: '₱0', value: '0' },
            { shortLabel: '50', longLabel: '₱50', value: '50' },
            { shortLabel: '100', longLabel: '₱100', value: '100' },
            { shortLabel: '150', longLabel: '₱150', value: '150' },
            { shortLabel: '200', longLabel: '₱200', value: '200' },
            { shortLabel: '250', longLabel: '₱250', value: '250' },
            { shortLabel: '300', longLabel: '₱300', value: '300' },
            { shortLabel: '350', longLabel: '₱350', value: '350' },
            { shortLabel: '400', longLabel: '₱400', value: '400' },
            { shortLabel: '450', longLabel: '₱450', value: '450' },
            { shortLabel: '500', longLabel: '₱500', value: '500' },
            { shortLabel: '700', longLabel: '₱700', value: '700' },
            { shortLabel: '900', longLabel: '₱900', value: '900' },
            { shortLabel: '900+', longLabel: '₱900+', value: '' },
          ],
        },
      ],
    },
  },
  'asia-7': {
    en: {
      defaultCurrency: { code: 'SGD' },
      searchSalaryRanges: [
        {
          frequency: { label: 'Annually', type: 'annual', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: '$0', value: '0' },
            { shortLabel: '30K', longLabel: '$30K', value: '30000' },
            { shortLabel: '40K', longLabel: '$40K', value: '40000' },
            { shortLabel: '50K', longLabel: '$50K', value: '50000' },
            { shortLabel: '60K', longLabel: '$60K', value: '60000' },
            { shortLabel: '70K', longLabel: '$70K', value: '70000' },
            { shortLabel: '80K', longLabel: '$80K', value: '80000' },
            { shortLabel: '100K', longLabel: '$100K', value: '100000' },
            { shortLabel: '120K', longLabel: '$120K', value: '120000' },
            { shortLabel: '180K', longLabel: '$180K', value: '180000' },
            { shortLabel: '240K', longLabel: '$240K', value: '240000' },
            { shortLabel: '300K', longLabel: '$300K', value: '300000' },
            { shortLabel: '360K', longLabel: '$360K', value: '360000' },
            { shortLabel: '360K+', longLabel: '$360K+', value: '' },
          ],
        },
        {
          frequency: { label: 'Monthly', type: 'monthly', isDefault: true },
          range: [
            { shortLabel: '0', longLabel: '$0', value: '0' },
            { shortLabel: '2K', longLabel: '$2K', value: '2000' },
            { shortLabel: '3K', longLabel: '$3K', value: '3000' },
            { shortLabel: '4K', longLabel: '$4K', value: '4000' },
            { shortLabel: '5K', longLabel: '$5K', value: '5000' },
            { shortLabel: '6K', longLabel: '$6K', value: '6000' },
            { shortLabel: '7K', longLabel: '$7K', value: '7000' },
            { shortLabel: '8K', longLabel: '$8K', value: '8000' },
            { shortLabel: '10K', longLabel: '$10K', value: '10000' },
            { shortLabel: '15K', longLabel: '$15K', value: '15000' },
            { shortLabel: '20K', longLabel: '$20K', value: '20000' },
            { shortLabel: '25K', longLabel: '$25K', value: '25000' },
            { shortLabel: '30K', longLabel: '$30K', value: '30000' },
            { shortLabel: '30K+', longLabel: '$30K+', value: '' },
          ],
        },
        {
          frequency: { label: 'Hourly', type: 'hourly', isDefault: false },
          range: [
            { shortLabel: '0', longLabel: '$0', value: '0' },
            { shortLabel: '5', longLabel: '$5', value: '5' },
            { shortLabel: '10', longLabel: '$10', value: '10' },
            { shortLabel: '15', longLabel: '$15', value: '15' },
            { shortLabel: '20', longLabel: '$20', value: '20' },
            { shortLabel: '30', longLabel: '$30', value: '30' },
            { shortLabel: '40', longLabel: '$40', value: '40' },
            { shortLabel: '50', longLabel: '$50', value: '50' },
            { shortLabel: '60', longLabel: '$60', value: '60' },
            { shortLabel: '90', longLabel: '$90', value: '90' },
            { shortLabel: '120', longLabel: '$120', value: '120' },
            { shortLabel: '150', longLabel: '$150', value: '150' },
            { shortLabel: '180', longLabel: '$180', value: '180' },
            { shortLabel: '180+', longLabel: '$180+', value: '' },
          ],
        },
      ],
    },
  },
};
