import {
  ApacCandidateFooter,
  ApacCandidateHeaderFooterLinkProvider,
} from '@seek/apac-candidate-header-footer';

import { useHeaderFooterLinkProvider } from 'src/hooks';
import { useFooterProps } from 'src/hooks/useHeaderFooterProps';

const Footer = () => {
  const footerLink = useHeaderFooterLinkProvider();
  const { authenticationStatus, widthMode } = useFooterProps();

  return (
    <ApacCandidateHeaderFooterLinkProvider value={footerLink}>
      <ApacCandidateFooter
        authenticationStatus={authenticationStatus}
        isExpandedVersion={true}
        widthMode={widthMode}
      />
    </ApacCandidateHeaderFooterLinkProvider>
  );
};

export default Footer;
