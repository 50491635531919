import {
  get as getLocalStorage,
  set as setLocalStorage,
  on as onLocalStorage,
  off as offLocalStorage,
} from 'local-storage';
import { useCallback, useEffect, useMemo, useState } from 'react';

const VIEWED_JOBS_STORAGE_KEY = 'VIEWED_JOBS';
const MAX_VIEWED_JOBS = 500;
export const VIEWED_JOBS_TIME_LIMIT = 1000 * 60 * 60 * 24 * 30; // 30 days

interface ViewedJob {
  jobId: string;
  timestamp: number;
}
const isFreshJob = (job: ViewedJob) =>
  job.timestamp > Date.now() - VIEWED_JOBS_TIME_LIMIT;

export const mergeViewedJobs = ({
  jobId,
  jobs,
  limit,
}: {
  jobId: string;
  jobs: ViewedJob[];
  limit: number;
}) => {
  const newViewedJobs =
    jobs?.filter(
      (job) =>
        // Remove current job from the list, to be re-added at the top with ..
        // .. the latest timestamp
        job.jobId !== jobId &&
        // Remove stale jobs from the list
        isFreshJob(job),
    ) || [];

  newViewedJobs.unshift({ jobId, timestamp: Date.now() });

  return newViewedJobs.slice(0, limit);
};

const parseViewedJobsStorage = () => {
  const storageViewedJobs = getLocalStorage<ViewedJob[]>(
    VIEWED_JOBS_STORAGE_KEY,
  );
  return Array.isArray(storageViewedJobs) ? storageViewedJobs : [];
};

const noop = () => {};
export const useIsJobViewed = (jobId: string) => {
  const [viewedJobs, setViewedJobs] = useState(parseViewedJobsStorage());

  useEffect(() => {
    onLocalStorage<ViewedJob[]>(
      VIEWED_JOBS_STORAGE_KEY,
      (updatedViewedJobs) => {
        if (Array.isArray(updatedViewedJobs)) {
          setViewedJobs(updatedViewedJobs);
        }
      },
    );

    return () => {
      offLocalStorage<ViewedJob[]>(VIEWED_JOBS_STORAGE_KEY, noop);
    };
  }, []);

  const isJobViewed = useMemo(
    () =>
      viewedJobs?.some(
        (viewedJob) => viewedJob.jobId === jobId && isFreshJob(viewedJob),
      ),
    [viewedJobs, jobId],
  );

  const viewJob = useCallback(() => {
    const newViewedJobs = mergeViewedJobs({
      jobId,
      jobs: parseViewedJobsStorage(),
      limit: MAX_VIEWED_JOBS,
    });
    setLocalStorage(VIEWED_JOBS_STORAGE_KEY, newViewedJobs);
    setViewedJobs(newViewedJobs);
  }, [jobId]);

  return {
    isJobViewed,
    viewJob,
  };
};

export const useViewJob = (jobId?: string) =>
  useCallback(() => {
    if (!jobId) {
      return;
    }
    const newViewedJobs = mergeViewedJobs({
      jobId,
      jobs: parseViewedJobsStorage(),
      limit: MAX_VIEWED_JOBS,
    });
    setLocalStorage(VIEWED_JOBS_STORAGE_KEY, newViewedJobs);
  }, [jobId]);
