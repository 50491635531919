const SPACE_BAR = 32;

const validRoleRegex = /^(checkbox|radio|button)$/;

const handleKeyDown = (event) => {
  if (event.keyCode !== SPACE_BAR) {
    return;
  }

  const role = event.target.getAttribute('role');

  if (!role || !validRoleRegex.test(role)) {
    return;
  }

  event.preventDefault();
  event.target.click();
};

const attach = (target) => {
  target.addEventListener('keydown', handleKeyDown);
};

export default attach;
