
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiSm9iIENsYXNzaWZpY2F0aW9ucyIsInNob3cgY2xhc3NpZmljYXRpb24gbGlzdCI6InNob3cgY2xhc3NpZmljYXRpb24gbGlzdCIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkNsZWFyIGNsYXNzaWZpY2F0aW9ucyIsIkRvbmUiOiJEb25lIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiQW55IENsYXNzaWZpY2F0aW9uIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJIaWRlIGNsYXNzaWZpY2F0aW9ucyIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiSm9iIENsYXNzaWZpY2F0aW9ucyIsInNob3cgY2xhc3NpZmljYXRpb24gbGlzdCI6InNob3cgY2xhc3NpZmljYXRpb24gbGlzdCIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkNsZWFyIGNsYXNzaWZpY2F0aW9ucyIsIkRvbmUiOiJEb25lIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiQW55IENsYXNzaWZpY2F0aW9uIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJIaWRlIGNsYXNzaWZpY2F0aW9ucyIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiSm9iIENsYXNzaWZpY2F0aW9ucyIsInNob3cgY2xhc3NpZmljYXRpb24gbGlzdCI6InNob3cgY2xhc3NpZmljYXRpb24gbGlzdCIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkNsZWFyIGNsYXNzaWZpY2F0aW9ucyIsIkRvbmUiOiJEb25lIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiQW55IENsYXNzaWZpY2F0aW9uIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJIaWRlIGNsYXNzaWZpY2F0aW9ucyIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiSm9iIENsYXNzaWZpY2F0aW9ucyIsInNob3cgY2xhc3NpZmljYXRpb24gbGlzdCI6InNob3cgY2xhc3NpZmljYXRpb24gbGlzdCIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkNsZWFyIGNsYXNzaWZpY2F0aW9ucyIsIkRvbmUiOiJEb25lIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiQW55IENsYXNzaWZpY2F0aW9uIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJIaWRlIGNsYXNzaWZpY2F0aW9ucyIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiSm9iIENsYXNzaWZpY2F0aW9ucyIsInNob3cgY2xhc3NpZmljYXRpb24gbGlzdCI6InNob3cgY2xhc3NpZmljYXRpb24gbGlzdCIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkNsZWFyIGNsYXNzaWZpY2F0aW9ucyIsIkRvbmUiOiJEb25lIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiQW55IENsYXNzaWZpY2F0aW9uIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJIaWRlIGNsYXNzaWZpY2F0aW9ucyIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiSm9iIENsYXNzaWZpY2F0aW9ucyIsInNob3cgY2xhc3NpZmljYXRpb24gbGlzdCI6InNob3cgY2xhc3NpZmljYXRpb24gbGlzdCIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkNsZWFyIGNsYXNzaWZpY2F0aW9ucyIsIkRvbmUiOiJEb25lIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiQW55IENsYXNzaWZpY2F0aW9uIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJIaWRlIGNsYXNzaWZpY2F0aW9ucyIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiSm9iIENsYXNzaWZpY2F0aW9ucyIsInNob3cgY2xhc3NpZmljYXRpb24gbGlzdCI6InNob3cgY2xhc3NpZmljYXRpb24gbGlzdCIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkNsZWFyIGNsYXNzaWZpY2F0aW9ucyIsIkRvbmUiOiJEb25lIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiQW55IENsYXNzaWZpY2F0aW9uIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJIaWRlIGNsYXNzaWZpY2F0aW9ucyIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiSm9iIENsYXNzaWZpY2F0aW9ucyIsInNob3cgY2xhc3NpZmljYXRpb24gbGlzdCI6InNob3cgY2xhc3NpZmljYXRpb24gbGlzdCIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkNsZWFyIGNsYXNzaWZpY2F0aW9ucyIsIkRvbmUiOiJEb25lIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiQW55IENsYXNzaWZpY2F0aW9uIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJIaWRlIGNsYXNzaWZpY2F0aW9ucyIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiSm9iIENsYXNzaWZpY2F0aW9ucyIsInNob3cgY2xhc3NpZmljYXRpb24gbGlzdCI6InNob3cgY2xhc3NpZmljYXRpb24gbGlzdCIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkNsZWFyIGNsYXNzaWZpY2F0aW9ucyIsIkRvbmUiOiJEb25lIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiQW55IENsYXNzaWZpY2F0aW9uIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJIaWRlIGNsYXNzaWZpY2F0aW9ucyIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiSm9iIENsYXNzaWZpY2F0aW9ucyIsInNob3cgY2xhc3NpZmljYXRpb24gbGlzdCI6InNob3cgY2xhc3NpZmljYXRpb24gbGlzdCIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkNsZWFyIGNsYXNzaWZpY2F0aW9ucyIsIkRvbmUiOiJEb25lIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiQW55IENsYXNzaWZpY2F0aW9uIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJIaWRlIGNsYXNzaWZpY2F0aW9ucyIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiSm9iIENsYXNzaWZpY2F0aW9ucyIsInNob3cgY2xhc3NpZmljYXRpb24gbGlzdCI6InNob3cgY2xhc3NpZmljYXRpb24gbGlzdCIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkNsZWFyIGNsYXNzaWZpY2F0aW9ucyIsIkRvbmUiOiJEb25lIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiQW55IENsYXNzaWZpY2F0aW9uIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJIaWRlIGNsYXNzaWZpY2F0aW9ucyIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiSm9iIENsYXNzaWZpY2F0aW9ucyIsInNob3cgY2xhc3NpZmljYXRpb24gbGlzdCI6InNob3cgY2xhc3NpZmljYXRpb24gbGlzdCIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkNsZWFyIGNsYXNzaWZpY2F0aW9ucyIsIkRvbmUiOiJEb25lIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiQW55IENsYXNzaWZpY2F0aW9uIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJIaWRlIGNsYXNzaWZpY2F0aW9ucyIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiSm9iIENsYXNzaWZpY2F0aW9ucyIsInNob3cgY2xhc3NpZmljYXRpb24gbGlzdCI6InNob3cgY2xhc3NpZmljYXRpb24gbGlzdCIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkNsZWFyIGNsYXNzaWZpY2F0aW9ucyIsIkRvbmUiOiJEb25lIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiQW55IENsYXNzaWZpY2F0aW9uIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJIaWRlIGNsYXNzaWZpY2F0aW9ucyIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiSm9iIENsYXNzaWZpY2F0aW9ucyIsInNob3cgY2xhc3NpZmljYXRpb24gbGlzdCI6InNob3cgY2xhc3NpZmljYXRpb24gbGlzdCIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkNsZWFyIGNsYXNzaWZpY2F0aW9ucyIsIkRvbmUiOiJEb25lIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiQW55IENsYXNzaWZpY2F0aW9uIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJIaWRlIGNsYXNzaWZpY2F0aW9ucyIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiSm9iIENsYXNzaWZpY2F0aW9ucyIsInNob3cgY2xhc3NpZmljYXRpb24gbGlzdCI6InNob3cgY2xhc3NpZmljYXRpb24gbGlzdCIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkNsZWFyIGNsYXNzaWZpY2F0aW9ucyIsIkRvbmUiOiJEb25lIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiQW55IENsYXNzaWZpY2F0aW9uIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJIaWRlIGNsYXNzaWZpY2F0aW9ucyIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiSm9iIENsYXNzaWZpY2F0aW9ucyIsInNob3cgY2xhc3NpZmljYXRpb24gbGlzdCI6InNob3cgY2xhc3NpZmljYXRpb24gbGlzdCIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkNsZWFyIGNsYXNzaWZpY2F0aW9ucyIsIkRvbmUiOiJEb25lIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiQW55IENsYXNzaWZpY2F0aW9uIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJIaWRlIGNsYXNzaWZpY2F0aW9ucyIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiSm9iIENsYXNzaWZpY2F0aW9ucyIsInNob3cgY2xhc3NpZmljYXRpb24gbGlzdCI6InNob3cgY2xhc3NpZmljYXRpb24gbGlzdCIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkNsZWFyIGNsYXNzaWZpY2F0aW9ucyIsIkRvbmUiOiJEb25lIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiQW55IENsYXNzaWZpY2F0aW9uIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJIaWRlIGNsYXNzaWZpY2F0aW9ucyIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiSm9iIENsYXNzaWZpY2F0aW9ucyIsInNob3cgY2xhc3NpZmljYXRpb24gbGlzdCI6InNob3cgY2xhc3NpZmljYXRpb24gbGlzdCIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkNsZWFyIGNsYXNzaWZpY2F0aW9ucyIsIkRvbmUiOiJEb25lIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiQW55IENsYXNzaWZpY2F0aW9uIiwiQ2xhc3NpZmljYXRpb25zIjoiQ2xhc3NpZmljYXRpb25zIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJIaWRlIGNsYXNzaWZpY2F0aW9ucyIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiS2xhc2lmaWthc2kgUGVrZXJqYWFuIiwic2hvdyBjbGFzc2lmaWNhdGlvbiBsaXN0IjoidGFtcGlsa2FuIGRhZnRhciBrbGFzaWZpa2FzaSIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkhhcHVzIGtsYXNpZmlrYXNpIiwiRG9uZSI6IlNlbGVzYWkiLCJBbnkgQ2xhc3NpZmljYXRpb24iOiJTZW11YSBLbGFzaWZpa2FzaSIsIkNsYXNzaWZpY2F0aW9ucyI6IktsYXNpZmlrYXNpIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJTZW1idW55aWthbiBrbGFzaWZpa2FzaSIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJUYW1waWxrYW4gZGFmdGFyIGtsYXNpZmlrYXNpLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiS2xhc2lmaWthc2kgUGVrZXJqYWFuIiwic2hvdyBjbGFzc2lmaWNhdGlvbiBsaXN0IjoidGFtcGlsa2FuIGRhZnRhciBrbGFzaWZpa2FzaSIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkhhcHVzIGtsYXNpZmlrYXNpIiwiRG9uZSI6IlNlbGVzYWkiLCJBbnkgQ2xhc3NpZmljYXRpb24iOiJTZW11YSBLbGFzaWZpa2FzaSIsIkNsYXNzaWZpY2F0aW9ucyI6IktsYXNpZmlrYXNpIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJTZW1idW55aWthbiBrbGFzaWZpa2FzaSIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJUYW1waWxrYW4gZGFmdGFyIGtsYXNpZmlrYXNpLiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoi4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIiwic2hvdyBjbGFzc2lmaWNhdGlvbiBsaXN0Ijoi4LmB4Liq4LiU4LiH4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIiwiQ2xlYXIgY2xhc3NpZmljYXRpb25zIjoi4Lii4LiB4LmA4Lil4Li04LiB4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIiwiRG9uZSI6IuC5gOC4o%2BC4teC4ouC4muC4o%2BC5ieC4reC4ouC5geC4peC5ieC4pyIsIkFueSBDbGFzc2lmaWNhdGlvbiI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4meC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIkNsYXNzaWZpY2F0aW9ucyI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSIsIkhpZGUgY2xhc3NpZmljYXRpb25zIjoi4LiL4LmI4Lit4LiZ4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIiwiU2hvdyBjbGFzc2lmaWNhdGlvbiBsaXN0LiI6IuC5geC4quC4lOC4h%2BC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoi4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIiwic2hvdyBjbGFzc2lmaWNhdGlvbiBsaXN0Ijoi4LmB4Liq4LiU4LiH4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIiwiQ2xlYXIgY2xhc3NpZmljYXRpb25zIjoi4Lii4LiB4LmA4Lil4Li04LiB4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIiwiRG9uZSI6IuC5gOC4o%2BC4teC4ouC4muC4o%2BC5ieC4reC4ouC5geC4peC5ieC4pyIsIkFueSBDbGFzc2lmaWNhdGlvbiI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4meC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIkNsYXNzaWZpY2F0aW9ucyI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSIsIkhpZGUgY2xhc3NpZmljYXRpb25zIjoi4LiL4LmI4Lit4LiZ4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIiwiU2hvdyBjbGFzc2lmaWNhdGlvbiBsaXN0LiI6IuC5geC4quC4lOC4h%2BC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiS2xhc2lmaWthc2kgUGVrZXJqYWFuIiwic2hvdyBjbGFzc2lmaWNhdGlvbiBsaXN0IjoidGFtcGlsa2FuIGRhZnRhciBrbGFzaWZpa2FzaSIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkhhcHVzIGtsYXNpZmlrYXNpIiwiRG9uZSI6IlNlbGVzYWkiLCJBbnkgQ2xhc3NpZmljYXRpb24iOiJTZW11YSBLbGFzaWZpa2FzaSIsIkNsYXNzaWZpY2F0aW9ucyI6IktsYXNpZmlrYXNpIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJTZW1idW55aWthbiBrbGFzaWZpa2FzaSIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJUYW1waWxrYW4gZGFmdGFyIGtsYXNpZmlrYXNpLiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiS2xhc2lmaWthc2kgUGVrZXJqYWFuIiwic2hvdyBjbGFzc2lmaWNhdGlvbiBsaXN0IjoidGFtcGlsa2FuIGRhZnRhciBrbGFzaWZpa2FzaSIsIkNsZWFyIGNsYXNzaWZpY2F0aW9ucyI6IkhhcHVzIGtsYXNpZmlrYXNpIiwiRG9uZSI6IlNlbGVzYWkiLCJBbnkgQ2xhc3NpZmljYXRpb24iOiJTZW11YSBLbGFzaWZpa2FzaSIsIkNsYXNzaWZpY2F0aW9ucyI6IktsYXNpZmlrYXNpIiwiSGlkZSBjbGFzc2lmaWNhdGlvbnMiOiJTZW1idW55aWthbiBrbGFzaWZpa2FzaSIsIlNob3cgY2xhc3NpZmljYXRpb24gbGlzdC4iOiJUYW1waWxrYW4gZGFmdGFyIGtsYXNpZmlrYXNpLiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoi4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIiwic2hvdyBjbGFzc2lmaWNhdGlvbiBsaXN0Ijoi4LmB4Liq4LiU4LiH4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIiwiQ2xlYXIgY2xhc3NpZmljYXRpb25zIjoi4Lii4LiB4LmA4Lil4Li04LiB4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIiwiRG9uZSI6IuC5gOC4o%2BC4teC4ouC4muC4o%2BC5ieC4reC4ouC5geC4peC5ieC4pyIsIkFueSBDbGFzc2lmaWNhdGlvbiI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4meC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIkNsYXNzaWZpY2F0aW9ucyI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSIsIkhpZGUgY2xhc3NpZmljYXRpb25zIjoi4LiL4LmI4Lit4LiZ4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIiwiU2hvdyBjbGFzc2lmaWNhdGlvbiBsaXN0LiI6IuC5geC4quC4lOC4h%2BC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoi4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIiwic2hvdyBjbGFzc2lmaWNhdGlvbiBsaXN0Ijoi4LmB4Liq4LiU4LiH4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIiwiQ2xlYXIgY2xhc3NpZmljYXRpb25zIjoi4Lii4LiB4LmA4Lil4Li04LiB4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIiwiRG9uZSI6IuC5gOC4o%2BC4teC4ouC4muC4o%2BC5ieC4reC4ouC5geC4peC5ieC4pyIsIkFueSBDbGFzc2lmaWNhdGlvbiI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4meC4l%2BC4seC5ieC4h%2BC4q%2BC4oeC4lCIsIkNsYXNzaWZpY2F0aW9ucyI6IuC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSIsIkhpZGUgY2xhc3NpZmljYXRpb25zIjoi4LiL4LmI4Lit4LiZ4Lib4Lij4Liw4LmA4Lig4LiX4LiH4Liy4LiZIiwiU2hvdyBjbGFzc2lmaWNhdGlvbiBsaXN0LiI6IuC5geC4quC4lOC4h%2BC4m%2BC4o%2BC4sOC5gOC4oOC4l%2BC4h%2BC4suC4mSJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiW8S0w7bDtsO2w58gw4fGmsSDxIPEg8WhxaHDrMOsw6zGksOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaFdIiwic2hvdyBjbGFzc2lmaWNhdGlvbiBsaXN0IjoiW8Wh4bipw7bDtsO2xbUgw6fGmsSDxIPEg8WhxaHDrMOsw6zGksOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIMaaw6zDrMOsxaHhua9dIiwiQ2xlYXIgY2xhc3NpZmljYXRpb25zIjoiW8OHxprhur3hur3hur3Eg8SDxIPFmSDDp8aaxIPEg8SDxaHFocOsw6zDrMaSw6zDrMOsw6fEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYnFoV0iLCJEb25lIjoiW%2BG4isO2w7bguIHguLXguYnhur3hur1dIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiW%2BG6rOC4geC4teC5icO9w73DvSDDh8aaxIPEg8SDxaHFocOsw6zDrMaSw6zDrMOsw6fEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYldIiwiQ2xhc3NpZmljYXRpb25zIjoiW8OHxprEg8SDxaHFocOsw6zGksOsw6zDp8SDxIPhua%2FDrMOsw7bDtuC4geC4teC5icWhXSIsIkhpZGUgY2xhc3NpZmljYXRpb25zIjoiW%2BG4qMOsw6zGjOG6veG6vSDDp8aaxIPEg8WhxaHDrMOsxpLDrMOsw6fEg8SD4bmvw6zDrMO2w7bguIHguLXguYnFoV0iLCJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIjoiW8Wg4bipw7bDtsO2xbUgw6fGmsSDxIPEg8WhxaHDrMOsw6zGksOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIMaaw6zDrMOsxaHhua8uXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJKb2IgQ2xhc3NpZmljYXRpb25zIjoiW8S0w7bDtsO2w58gw4fGmsSDxIPEg8WhxaHDrMOsw6zGksOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJxaFdIiwic2hvdyBjbGFzc2lmaWNhdGlvbiBsaXN0IjoiW8Wh4bipw7bDtsO2xbUgw6fGmsSDxIPEg8WhxaHDrMOsw6zGksOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIMaaw6zDrMOsxaHhua9dIiwiQ2xlYXIgY2xhc3NpZmljYXRpb25zIjoiW8OHxprhur3hur3hur3Eg8SDxIPFmSDDp8aaxIPEg8SDxaHFocOsw6zDrMaSw6zDrMOsw6fEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYnFoV0iLCJEb25lIjoiW%2BG4isO2w7bguIHguLXguYnhur3hur1dIiwiQW55IENsYXNzaWZpY2F0aW9uIjoiW%2BG6rOC4geC4teC5icO9w73DvSDDh8aaxIPEg8SDxaHFocOsw6zDrMaSw6zDrMOsw6fEg8SDxIPhua%2FDrMOsw6zDtsO2w7bguIHguLXguYldIiwiQ2xhc3NpZmljYXRpb25zIjoiW8OHxprEg8SDxaHFocOsw6zGksOsw6zDp8SDxIPhua%2FDrMOsw7bDtuC4geC4teC5icWhXSIsIkhpZGUgY2xhc3NpZmljYXRpb25zIjoiW%2BG4qMOsw6zGjOG6veG6vSDDp8aaxIPEg8WhxaHDrMOsxpLDrMOsw6fEg8SD4bmvw6zDrMO2w7bguIHguLXguYnFoV0iLCJTaG93IGNsYXNzaWZpY2F0aW9uIGxpc3QuIjoiW8Wg4bipw7bDtsO2xbUgw6fGmsSDxIPEg8WhxaHDrMOsw6zGksOsw6zDrMOnxIPEg8SD4bmvw6zDrMOsw7bDtsO24LiB4Li14LmJIMaaw6zDrMOsxaHhua8uXSJ9!"
        )
      )
      });
  
      export { translations as default };
    