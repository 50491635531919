import { useTranslations } from '@vocab/react';
import {
  Box,
  Heading,
  List,
  Stack,
  Text,
  PageBlock,
} from 'braid-design-system';
import { memo } from 'react';

import translations from './.vocab';

const PageNotFound = () => {
  const { t } = useTranslations(translations);
  return (
    <PageBlock
      data={{
        automation: 'pageNotFound',
      }}
    >
      <Box paddingY="xxxlarge">
        <Stack space="large">
          <Heading level="3">
            {t('Sorry, there doesn’t seem to be anything here...')}
          </Heading>
          <List>
            <Text>{t('Check the URL, there may be a typo.')}</Text>
            <Text>{t('If all else fails, try going back.')}</Text>
          </List>
        </Stack>
      </Box>
    </PageBlock>
  );
};

export default memo(PageNotFound);
