import { useSelector } from 'src/store/react';
import { selectTitleTagTestTitle } from 'src/store/results/selectors';

import { generateMetaDescription, generateMetaTitle } from '../utils';

import { useSearchResultsHeadDescription } from './description';
import { useSearchResultsHeadTitle } from './title';

export function useSearchResultsHead() {
  const titleTagTestTitle = useSelector(selectTitleTagTestTitle);
  const srpHeadTitle = useSearchResultsHeadTitle();

  const title = titleTagTestTitle || srpHeadTitle;

  const pageDescription = useSearchResultsHeadDescription();

  const titleMeta = generateMetaTitle(title || '');
  const descriptionMeta = generateMetaDescription(pageDescription);

  const meta = [...titleMeta, ...descriptionMeta];

  return { title, meta };
}
