import { isProduction } from '..';
import type { AppConfig } from '../types';

export default {
  zone: 'asia-7',
  defaultLocale: 'en-SG',
  availableLocales: ['en-SG'],
  timedBanners: {
    cookieConsent: {
      displayDates: {
        startDate: '2023-10-15T00:00:00.000Z',
      },
    },
  },
  zoneFeatures: {
    NUDGE_ENABLED: false,
    HOMEPAGE_BANNER_TYPE: 'GENERIC_ASIA_BANNER',
    SEARCH_PAGE_SIZE: 32,
    SHOW_FLOATING_SAVE_SEARCH: false,
    AUTO_SELECT_SPLIT_VIEW_FIRST_JOB: false,
    SHOW_MARKETING_AD_SPLIT_VIEW: true,
    LMIS_ENABLED: true,
    BEHAVIOURAL_CUES_ENABLED: true,
    BEHAVIOURAL_CUES_FILTERS_ENABLED: false,
    LOGGED_OUT_RECS: false,
    REMOTE_SEARCH_FILTER: false,
    REMOTE_SEARCH_FILTER_NEW_LABEL: true,
    DYNAMIC_PILLS: false,
    SECONDARY_FILTERS: false,
    DYNAMIC_PILLS_V2: false,
    KEYWORD_AUTOSUGGEST_V2: true,
    NEW_JOB_CARD_DENSITY: false,
    ENABLE_VERIFIED_HIRER_BADGE: true,
    SERP_JOBCARD_INFO_DENSITY_1: false,
    MATCHED_QUALITIES: false,
    ENTRY_LEVEL_BADGE: true, 
  },
  BRANCH_IO_KEY: isProduction
    ? 'key_live_keVW4JKlS0jBJLPfSRNUJnhhtElmK541'
    : 'key_test_igQ77GMmQ1itGVIiMGXzojmavAcg9pio',
  GPT_ACCOUNT_ID: '23209468642',
} as AppConfig;
