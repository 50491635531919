import { metrics } from '@seek/metrics-js';
import { createContext, useContext, useRef } from 'react';

export const MetricsContext = createContext<{
  startTimer: (key: string) => void;
  stopTimer: (key: string, additionalTags?: string[]) => void;
  cancelTimer: (key: string) => void;
}>({
  startTimer: () => {},
  stopTimer: () => {},
  cancelTimer: () => {},
});

export const MetricsContextProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const timersRef = useRef<Record<string, number>>({});

  const startTimer = (key: string) => {
    timersRef.current[key] = Date.now();
  };

  const stopTimer = (key: string, additionalTags?: string[]) => {
    const startTime = timersRef.current[key];
    if (startTime) {
      const duration = Date.now() - startTime;

      metrics.timing('metrics.timeToEffect', duration, [
        `action:${key}`,
        ...(additionalTags ?? []),
      ]);

      delete timersRef.current[key];
    }
  };

  const cancelTimer = (key: string) => {
    const startTime = timersRef.current[key];
    if (startTime) delete timersRef.current[key];
  };

  return (
    <MetricsContext.Provider value={{ startTimer, stopTimer, cancelTimer }}>
      {children}
    </MetricsContext.Provider>
  );
};

export const useMetricsTimerContext = () => useContext(MetricsContext);
