import {
  type ReactNode,
  createContext,
  useReducer,
  useContext,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';

import useAuthenticated from 'src/hooks/useAuthenticated';
import { selectSearchTag } from 'src/store/featureFlags/selectors';
import { selectFeatureFlag } from 'src/store/selectors';

interface State {
  showNewJobsSignIn: boolean;
  showUpToDateZeroCount: boolean;
}

type Action =
  | { type: 'SET_SHOW_NEW_JOBS_SIGNIN'; payload: boolean }
  | { type: 'SET_SHOW_SHOW_UP_TO_DATE_ZERO_COUNT'; payload: boolean };

const initialState: State = {
  showNewJobsSignIn: false,
  showUpToDateZeroCount: false,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_SHOW_NEW_JOBS_SIGNIN':
      return { ...state, showNewJobsSignIn: action.payload };
    case 'SET_SHOW_SHOW_UP_TO_DATE_ZERO_COUNT':
      return { ...state, showUpToDateZeroCount: action.payload };
    default:
      return state;
  }
}

interface BehavioralCuesFiltersContextType {
  state: State;
  dispatch: React.Dispatch<Action>;
}

const BehavioralCuesFiltersContext = createContext<
  BehavioralCuesFiltersContextType | undefined
>(undefined);

export const useBehavioralCuesFilters = () => {
  const context = useContext(BehavioralCuesFiltersContext);
  if (!context) {
    throw new Error(
      'useBehavioralCuesFilters must be used within a BehavioralCuesFiltersProvider',
    );
  }
  return context;
};

interface BehavioralCuesFiltersProviderProps {
  children: ReactNode;
}

export const BehavioralCuesFiltersProvider: React.FC<
  BehavioralCuesFiltersProviderProps
> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isBehaviouralCuesFiltersEnabled = useSelector(
    selectFeatureFlag('behaviouralCuesFilters'),
  );
  const authenticationStatus = useAuthenticated();

  const isNewToYou = useSelector(selectSearchTag) === 'new';
  useEffect(() => {
    dispatch({
      type: 'SET_SHOW_NEW_JOBS_SIGNIN',
      payload:
        isNewToYou &&
        isBehaviouralCuesFiltersEnabled &&
        authenticationStatus !== 'authenticated',
    });
  }, [isNewToYou, authenticationStatus, isBehaviouralCuesFiltersEnabled]);

  return (
    <BehavioralCuesFiltersContext.Provider value={{ state, dispatch }}>
      {children}
    </BehavioralCuesFiltersContext.Provider>
  );
};
