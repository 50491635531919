import type { Zone } from '@seek/audience-zones';
import type { SearchParams } from '@seek/chalice-types';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';

import type { Country } from 'src/types/globals';

import { shouldRequestRelatedSearches } from './convertToApiQueryHelper';

const isFalsy = (x: any) => !x;
const clean = (object: Record<PropertyKey, any>) => omitBy(object, isFalsy);

// Asia markets shouldn't have a default where value.
const defaultWhereValue: Partial<Record<Country, string>> = {
  AU: 'All Australia',
  NZ: 'All New Zealand',
};

const shouldUseDefaultWhereValue = (country: Country) =>
  Object.keys(defaultWhereValue).includes(country);

const getDefaultWhereValue = (country: Country) =>
  shouldUseDefaultWhereValue(country) ? defaultWhereValue[country] : '';

const hasLegacyLocations = ({
  nation,
  state,
  location,
  area,
  areaid,
}: Record<PropertyKey, any>) =>
  typeof nation !== 'undefined' ||
  typeof state !== 'undefined' ||
  typeof location !== 'undefined' ||
  typeof area !== 'undefined' ||
  typeof areaid !== 'undefined';

const includeSeoAndRelatedSearchesData = ({
  searchParams,
  zone,
}: {
  searchParams: SearchParams;
  zone?: Zone;
}): SearchParams => {
  const include = `${searchParams.include || ''}seodata${
    shouldRequestRelatedSearches({ searchParams, zone })
      ? ',relatedsearches'
      : ''
  },joracrosslink,gptTargeting`;

  return { ...searchParams, include };
};

const atCompany = (searchParams: SearchParams): SearchParams => {
  if (!searchParams.companysearch) {
    return searchParams;
  }
  const { keywords } = searchParams;

  return {
    ...omit(searchParams, ['keywords', 'companysearch']),
    companyname: keywords,
  };
};

export default ({
  country,
  searchQuery,
  zone,
}: {
  country: Country;
  searchQuery: SearchParams;
  zone?: Zone;
}): SearchParams => {
  const defaultParams = {
    where: !hasLegacyLocations(searchQuery)
      ? getDefaultWhereValue(country)
      : '',
    page: '1',
  };

  const expandedQuery = { ...defaultParams, ...searchQuery };

  return clean(
    includeSeoAndRelatedSearchesData({
      searchParams: atCompany(expandedQuery),
      zone,
    }),
  );
};
