import type { UrlLocation } from '@seek/chalice-types';
import type { Location } from 'history';
import {
  parse as parseQueryString,
  stringify as stringifyQueryString,
} from 'query-string';

export const routerLocationToUrlLocation = ({
  search = '',
  ...location
}: Location<UrlLocation['state']>): UrlLocation => ({
  ...location,
  query: parseQueryString(search),
});

export const urlLocationToRouterLocation = ({
  query = {},
  ...location
}: UrlLocation): Location<UrlLocation['state']> =>
  ({
    ...location,
    search: stringifyQueryString(query),
  } as Location<UrlLocation['state']>);
