import type { Zone } from '@seek/audience-zones';
import type { CountrySiteName, Language, Locale } from '@seek/melways-sites';
import { createContext, useCallback, useContext, useState } from 'react';

import { parseLocale, toLocale } from 'src/modules/locale-utils';
import { setLanguage as setLanguageStore } from 'src/store/appConfig';
import { useDispatch } from 'src/store/react';

import type { AppConfig, ZoneConfig } from './types';
import { getBrandFromZone } from './utils/getBrandFromZone';

type AppConfigContextType = AppConfig & {
  setLanguage: (lang: Language) => void;
};

const AppConfigContext = createContext<AppConfigContextType>(
  {} as AppConfigContextType,
);
export const AppConfigProvider = AppConfigContext.Provider;
export const useMakeAppConfigContext = (
  config: AppConfig,
): AppConfigContextType => {
  const {
    language: contextLanguage,
    locale: contextSelectedLocale,
    ...context
  } = config;
  const [language, setLanguageState] = useState(contextLanguage);
  const [locale, setLocale] = useState(contextSelectedLocale);
  const dispatch = useDispatch();

  const setLanguage = useCallback(
    (lang: Language) => {
      setLanguageState(lang);
      setLocale(toLocale(lang, context.country));

      dispatch(setLanguageStore(lang));
    },
    [context.country, dispatch],
  );

  return {
    ...context,
    language,
    locale,
    setLanguage,
  };
};

export const useAppConfig = () => useContext(AppConfigContext);

export const useTimedBanner = <B extends keyof AppConfig['timedBanners']>(
  banner: B,
) => useAppConfig().timedBanners[banner];

export const useZoneFeatures = () => useAppConfig().zoneFeatures;

interface GenerateConfigArgs {
  zone: Zone;
  site: CountrySiteName;
  requestedLocale: Locale;
}
export const generateConfig = ({
  zone,
  requestedLocale,
  site,
}: GenerateConfigArgs): AppConfig => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const config = require(`./zone-configs/${zone}`).default as ZoneConfig;
  const locale = determineSelectedLocale({ config, requestedLocale });

  return {
    ...config,
    brand: getBrandFromZone(zone),
    locale,
    site,
    ...parseLocale(locale), // adds language and country to the config
  };
};

const determineSelectedLocale = ({
  config,
  requestedLocale,
}: {
  config: ZoneConfig;
  requestedLocale: Locale;
}) => {
  const available = config.availableLocales.includes(requestedLocale);
  return available ? requestedLocale : config.defaultLocale;
};
