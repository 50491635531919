import base64 from 'base-64';
import get from 'lodash/get';
const removeIndexPart = (token) => {
  return token ? token.split('_')[0] : '';
};

export const getDecodedToken = (tracking) => {
  let token = '';
  try {
    const decodedTracking = tracking
      ? base64.decode(decodeURIComponent(tracking))
      : '';
    token = decodedTracking ? get(JSON.parse(decodedTracking), 'token') : '';
  } catch (e) {} //eslint-disable-line
  return token;
};

export const getDecodedTokenWithoutIndex = (tracking) => {
  return removeIndexPart(getDecodedToken(tracking));
};
