import {
  Stack,
  HiddenVisually,
  Heading,
  Tiles,
  RadioGroup,
  RadioItem,
  useResponsiveValue,
} from 'braid-design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Link } from 'src/components/NavLink/NavLink';
import { getDateRanges } from 'src/modules/refine-job-search';
import type { Option } from 'src/types/globals';

import { useSecondaryFilterContext } from '../../context/SecondaryFilterContext';

export const ListingTime = () => {
  const { zone, language, filterSelection, setFilterSelection, ROBOTS_TAG } =
    useSecondaryFilterContext();
  const dates = getDateRanges(zone, language);

  // As date listing, "Any time" label has a value of an empty string, this serves
  // as a placeholder value due to Braid's RadioItem's value property unable to
  // take an empty string or undefined value.
  const DEFAULT_LISTING_TIME = '0';

  const [activeValue, setActiveValue] = useState<string>(
    filterSelection?.daterange || DEFAULT_LISTING_TIME,
  );
  const isMobile = useResponsiveValue()({
    mobile: true,
    tablet: false,
  });

  const getOptionItem = useCallback(
    (label: string, value: string) => {
      const isActive = filterSelection?.daterange === value;
      return {
        label,
        value,
        isActive,
        ...(!isActive && {
          refineParams: {
            ...filterSelection,
            daterange: value,
          },
        }),
      };
    },
    [filterSelection],
  );

  const dateListedOptions: Option[] = useMemo(
    () =>
      dates ? dates.map(({ label, value }) => getOptionItem(label, value)) : [],
    [dates, getOptionItem],
  );

  const handleListingTimeSelection = (value: string) => {
    setFilterSelection((currentFilterSelection) => ({
      ...currentFilterSelection,
      daterange: value === DEFAULT_LISTING_TIME ? '' : value,
    }));
  };

  useEffect(() => {
    setActiveValue(filterSelection?.daterange || DEFAULT_LISTING_TIME);
  }, [filterSelection?.daterange]);

  return (
    <Stack space="medium" data={{ automation: 'listingTime' }}>
      <Heading level="4" id="listingTime">
        {'Listing time'}
      </Heading>
      <nav role="navigation" aria-labelledby="RefineDateListed">
        <HiddenVisually>
          <h1 id="RefineDateListed">{'Date Listed'}</h1>
        </HiddenVisually>
        <Tiles columns={{ mobile: 1, tablet: 2 }} space="medium">
          <RadioGroup
            id="listingTime-rg1"
            onChange={({ currentTarget: { value } }) =>
              handleListingTimeSelection(value)
            }
            value={activeValue || dateListedOptions[0].value}
            size={isMobile ? 'small' : 'standard'}
            aria-labelledby="listingTime"
          >
            {dateListedOptions
              .filter((_, index) => (isMobile ? true : index % 2 === 0))
              .map((option) => (
                <RadioItem
                  key={option.value}
                  label={
                    <Link
                      location={{
                        pathname: '/jobs',
                        query: option.refineParams,
                      }}
                      rel={ROBOTS_TAG}
                      disabledLinkNavigate
                    >
                      {option.label}
                    </Link>
                  }
                  value={option.value || DEFAULT_LISTING_TIME}
                />
              ))}
          </RadioGroup>

          {!isMobile ? (
            <RadioGroup
              id="listingTime-rg2"
              onChange={({ currentTarget: { value } }) =>
                handleListingTimeSelection(value)
              }
              value={activeValue || dateListedOptions[0].value}
              size={isMobile ? 'small' : 'standard'}
              aria-labelledby="listingTime"
            >
              {dateListedOptions
                .filter((_, index) => (isMobile ? true : index % 2 !== 0))
                .map((option) => (
                  <RadioItem
                    key={option.value}
                    label={
                      <Link
                        location={{
                          pathname: '/jobs',
                          query: option.refineParams,
                        }}
                        rel={ROBOTS_TAG}
                        disabledLinkNavigate
                      >
                        {option.label}
                      </Link>
                    }
                    value={option.value || DEFAULT_LISTING_TIME}
                  />
                ))}
            </RadioGroup>
          ) : null}
        </Tiles>
      </nav>
    </Stack>
  );
};
