/*
  This exact code implementation is derived from 
  https://github.com/seek-oss/braid-design-system/blob/1c5da13ae84ae03ca2b36d03e7ade82c4181fd27/packages/braid-design-system/src/lib/components/private/normalizeKey.ts.

  For reference:
  - Braid version: v32.21.0
*/

import type { KeyboardEvent } from 'react';

// Normalizes the 'key' property of a KeyboardEvent in IE/Edge
export function normalizeKey({ key, keyCode }: KeyboardEvent) {
  if (keyCode >= 37 && keyCode <= 40 && key.indexOf('Arrow') !== 0) {
    return `Arrow${key}`;
  }
  if (keyCode === 27) {
    return 'Escape';
  }
  return key;
}
