export default (src) =>
  new Promise((resolve, reject) => {
    const img = document.createElement('img');
    const removeImg = () => img.parentNode && img.parentNode.removeChild(img);

    img.addEventListener('load', () => {
      resolve();
      removeImg();
    });

    img.addEventListener('error', () => {
      reject();
      removeImg();
    });

    img.setAttribute('src', src);
    img.setAttribute('style', 'position:absolute;width:0;height:0;opacity:0');

    document.body.appendChild(img);
  });
