import type { SearchParams } from '@seek/chalice-types';
import type taxonomy from '@seek/taxonomy-seekanz';
// @ts-expect-error non-ts file
import copy from 'shallow-copy';

type SortModes = (typeof taxonomy)['sortModes'];

const getCurrentSortMode = (
  hasKeywords: boolean,
  requestedSortMode: SearchParams['sortmode'],
) => {
  if (requestedSortMode === 'ListedDate') {
    return 'ListedDate';
  }
  return hasKeywords ? 'KeywordRelevance' : 'ListedDate';
};

export default (sortModes: SortModes, params: SearchParams) => {
  const hasKeywords =
    typeof params.keywords === 'string' && params.keywords.trim() !== '';
  const currentSortMode = getCurrentSortMode(hasKeywords, params.sortmode);

  return {
    sortmode: sortModes
      .filter(
        (sortMode) => sortMode.value !== 'KeywordRelevance' || hasKeywords,
      )
      .map((sortMode) => {
        const newSortMode = copy(sortMode);

        newSortMode.isActive = currentSortMode === newSortMode.value;

        if (!newSortMode.isActive) {
          newSortMode.refineParams = copy(params);
          newSortMode.refineParams.sortmode = newSortMode.value;
        }

        return newSortMode;
      }),
  };
};
