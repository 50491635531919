import { useTranslations } from '@vocab/react';
import {
  Box,
  ButtonLink,
  Heading,
  Actions,
  Stack,
  Text,
  PageBlock,
} from 'braid-design-system';
import { memo } from 'react';

import { useBrandName } from 'src/hooks/useBrandName';

import translations from './.vocab';

const ExpiredJob = () => {
  const { t } = useTranslations(translations);
  const brandName = useBrandName();

  return (
    <PageBlock data={{ automation: 'expiredJobPage' }}>
      <Box paddingY="xxxlarge">
        <Stack space="large">
          <Heading level="2">{t('This job is no longer advertised')}</Heading>
          <Text size="large">
            {t(
              'Jobs remain on {brand} for 30 days, unless the advertiser removes them sooner.',
              { brand: brandName },
            )}
          </Text>
          <Actions>
            <ButtonLink role="link" tone="brandAccent" href="/">
              {t('Search for another job')}
            </ButtonLink>
          </Actions>
        </Stack>
      </Box>
    </PageBlock>
  );
};

export default memo(ExpiredJob);
