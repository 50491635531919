import {
  Box,
  Text,
  ButtonIcon,
  IconChevron,
  useResponsiveValue,
} from 'braid-design-system';
import { useState, useRef, useEffect } from 'react';

import * as styles from './LoadingPills.css.ts';

export const LoadingPills = () => {
  const loadingPills = new Array(8).fill('Loading............');
  const ref = useRef<HTMLDivElement>(null);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const checkIfPillsFit = () => {
    const carouselDiv = ref.current;
    if (!carouselDiv) return;

    const maxScrollLeft = carouselDiv.scrollWidth - carouselDiv.clientWidth;

    setIsAtEnd(carouselDiv.scrollLeft >= maxScrollLeft - 1);
  };

  useEffect(() => {
    const carouselDiv = ref.current;
    if (carouselDiv) {
      checkIfPillsFit();
      carouselDiv.addEventListener('scroll', checkIfPillsFit);
      return () => {
        carouselDiv.removeEventListener('scroll', checkIfPillsFit);
      };
    }
  }, []);

  const isMobile =
    useResponsiveValue()({
      mobile: true,
      tablet: false,
    }) || false;

  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      width="full"
      paddingLeft={{ mobile: 'small', tablet: 'none' }}
    >
      <Box display="flex" overflow="hidden" ref={ref}>
        {loadingPills.map((keyword, index) => (
          <Box key={index} paddingRight="small">
            <Box
              key={index}
              className={styles.loadingPills}
              overflow="hidden"
              display="flex"
              alignItems="center"
              borderRadius="full"
              paddingY="xxsmall"
              paddingX="small"
            >
              <Box opacity={0}>
                <Text
                  baseline={false}
                  maxLines={1}
                  size={isMobile ? 'small' : 'standard'}
                >
                  {keyword}
                </Text>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        display={!isAtEnd && !isMobile ? 'flex' : 'none'}
        position="absolute"
        top={0}
        bottom={0}
        right={0}
        className={styles.fadeInRight}
        pointerEvents="none"
      />
      <Box
        display={!isAtEnd && !isMobile ? 'flex' : 'none'}
        position="relative"
        right={0}
        zIndex={1}
      >
        <ButtonIcon
          variant="soft"
          id="load-scroll-right"
          label="scroll-right"
          icon={<IconChevron direction="right" tone="secondary" />}
          size="standard"
          bleed={false}
        />
      </Box>
    </Box>
  );
};
