import type { UrlLocation } from '@seek/chalice-types';
import { useMelwaysInfo, useMelwaysLink } from '@seek/melways-react';
import {
  HOMEPAGE_LANGUAGE_OVERRIDE_PATH,
  getSiteNameFromHostname,
  getZoneFromSite,
  isMelwaysCountrySiteName,
  type Country,
  type CountrySiteName,
  type Language,
  type MelwaysZone,
  type createUrlResolver,
} from '@seek/melways-sites';
import {
  hrefFromLocation,
  isAbsoluteUrl,
  isHomepagePath,
  isLocalisedPath,
} from '@seek/seek-jobs-seo';
import { useMemo } from 'react';

const devDomainsSiteNamesMap: Record<string, CountrySiteName> = {
  'dev.seek.com.au': 'candidate-seek-au',
  'dev.seek.co.nz': 'candidate-seek-nz',
  'dev.hk.jobsdb.com': 'candidate-jobsdb-hk',
  'dev.th.jobsdb.com': 'candidate-jobsdb-th',
  'dev.jobstreet.co.id': 'candidate-jobstreet-id',
  'dev.jobstreet.com.my': 'candidate-jobstreet-my',
  'dev.jobstreet.com.ph': 'candidate-jobstreet-ph',
  'dev.jobstreet.com.sg': 'candidate-jobstreet-sg',
  'dev.id.jobstreet.com': 'candidate-jobstreet-id',
  'dev.my.jobstreet.com': 'candidate-jobstreet-my',
  'dev.ph.jobstreet.com': 'candidate-jobstreet-ph',
  'dev.sg.jobstreet.com': 'candidate-jobstreet-sg',
};

const GANTRY_PREVIEW_DOMAINS_INFIX_REGEX = /^\d{1,2}-chalice-dev\./;

export const getSiteFromHostname = (
  hostname: string,
): {
  site: CountrySiteName;
  shouldAllowOverride?: boolean;
  consolidated?: boolean;
} => {
  const consolidated = isConsolidatedDomain(hostname);
  try {
    return {
      site: getSiteNameFromHostname(hostname) as CountrySiteName,
      // It's safe to only check for `staging` here since the invocation ..
      // .. to `getSiteNameFromHostname` will throw if the hostname is invalid
      shouldAllowOverride: hostname.includes('staging'),
      consolidated,
    };
  } catch (_) {
    const devSite = devDomainsSiteNamesMap[hostname];
    if (devSite) {
      return {
        site: devSite,
        consolidated,
      };
    }

    const matchedGantryDevDomains =
      GANTRY_PREVIEW_DOMAINS_INFIX_REGEX.test(hostname);

    if (matchedGantryDevDomains) {
      return {
        site:
          // We're defaulting Gantry environments to the `au` region.
          // Zones can then be overridden using country query params.
          // We will look into a way to allow dev zon-aware domains in the future.
          'candidate-seek-au',
        shouldAllowOverride: true,
      };
    }

    return { site: 'candidate-seek-au' };
  }
};

export const useLocalisedUrlLocation = (location: UrlLocation): UrlLocation => {
  const localisedPath = useLocalisedLinks({ path: location.pathname || '' });

  return useMemo(() => {
    if (location.protocol === 'tel:') {
      return location;
    }

    if (localisedPath === location.pathname) {
      return location;
    }

    const localisedUrlLocation = {
      ...location,
      pathname: localisedPath,
    };

    return {
      ...localisedUrlLocation,
      href: hrefFromLocation(localisedUrlLocation, location.href),
    };
  }, [localisedPath, location]);
};

type UseLocalisedLinksProp = Parameters<
  ReturnType<typeof createUrlResolver>
>['0'];
type UseLocalisedLinkReturn<T> = T extends UseLocalisedLinksProp[]
  ? string[]
  : string;
export const useLocalisedLinks = <
  T extends UseLocalisedLinksProp | UseLocalisedLinksProp[],
>(
  props: T,
): UseLocalisedLinkReturn<T> => {
  const { language: currentLanguage } = useMelwaysInfo();

  // accept both single prop object and an array of props
  const linkProps = useMemo<UseLocalisedLinksProp[]>(
    () => (Array.isArray(props) ? props : [props]),
    [props],
  );

  const localisedPaths = useMelwaysLink(
    // apply melways override for homepage path
    linkProps.map(({ path, language: lang, ...rest }) => ({
      // Temporarily, we need to pass language here to ensure homepage exception rules are considered
      // If candidates land on `/` for `th-TH` or `id-ID`, melways provider will set the `language` as `en`
      // while it should be the local language.
      language: lang ?? currentLanguage,
      path: isHomepagePath(path) ? HOMEPAGE_LANGUAGE_OVERRIDE_PATH : path,
      ...rest,
    })),
  );

  return useMemo(() => {
    const paths = localisedPaths.map((localisedPath, index) => {
      const { path, language } = linkProps[index];
      const isLocalised = isLocalisedPath({
        path,
        language: (language || currentLanguage) as Language,
      });
      const isAbsolute = isAbsoluteUrl(path);
      return !isLocalised && !isAbsolute ? localisedPath : path;
    });

    return Array.isArray(props) ? paths : paths[0];
  }, [
    localisedPaths,
    props,
    linkProps,
    currentLanguage,
  ]) as UseLocalisedLinkReturn<T>;
};

const consolidatedHostNames = [
  'id.jobstreet.com',
  'my.jobstreet.com',
  'ph.jobstreet.com',
  'sg.jobstreet.com',
  'id.staging.jobstreet.com',
  'my.staging.jobstreet.com',
  'ph.staging.jobstreet.com',
  'sg.staging.jobstreet.com',
  // Dev consolidated domains
  'dev.id.jobstreet.com',
  'dev.my.jobstreet.com',
  'dev.ph.jobstreet.com',
  'dev.sg.jobstreet.com',
];

// To be cleaned up once all domains are consolidated
export const isConsolidatedDomain = (hostname: string = ''): boolean =>
  consolidatedHostNames.includes(hostname);

export const getCookieDomainFromHost = (hostname: string = ''): string => {
  if (!isConsolidatedDomain(hostname)) {
    return `.${hostname.replace(/^www\./, '')}`;
  }

  const isStagingDomain = hostname.includes('.staging.');
  const isDevDomain = hostname.startsWith('dev.');

  if (!isStagingDomain && !isDevDomain) {
    return `.${hostname.split('.').slice(-2).join('.')}`;
  }

  if (isStagingDomain) {
    return `.${hostname.split('.').slice(-3).join('.')}`;
  }

  // We're limiting the dev domains to the top level domain for now
  // Ideally, we should be sharing the domain scope across all consolidated domains
  // (Check PR Technical Change Notes).
  // Any other unknown domain will be treated as a dev domain
  return `.${hostname}`;
};

const DEFAULT_COUNTRY: Country = 'au';
const DEFAULT_ZONE: MelwaysZone = 'anz-1';

/**
 * `useMelwaysCountry()` returns the current country code from the `useMelwaysInfo()` and defaults to `"au"`. So the type of the country is `Country`, which does not contain the `null` value like `useMelwaysInfo().country`.
 */
export const useMelwaysCountry = (): Country => {
  const { country: melwaysCountry } = useMelwaysInfo();
  return melwaysCountry || DEFAULT_COUNTRY;
};

const melwaysZoneCache: Partial<Record<string, MelwaysZone>> = {};

/**
 * This helper function will help to derive the `zone` bt utilizing the `useMelwaysInfo()` and `getZoneFromSite()` from `@seek/melways-sites`.
 *
 * ℹ️ **Front-End-Practices' Recommendation**: `zone` isn't something we've encouraged, instead using the primitive values like `region` and `brand`.
 */
export const useMelwaysZone = (): MelwaysZone => {
  const { site } = useMelwaysInfo();
  const cachedZone = melwaysZoneCache[site];
  if (cachedZone) {
    return cachedZone;
  }

  if (isMelwaysCountrySiteName(site)) {
    const zone = getZoneFromSite(site);
    melwaysZoneCache[site] = zone;
    return zone;
  }

  return DEFAULT_ZONE;
};
