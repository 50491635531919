import gql from 'graphql-tag';

export const GET_LOCATION_SUGGESTIONS = gql`
  query searchLocationsSuggest(
    $query: String!
    $count: Int!
    $recentLocation: String!
    $locale: Locale
    $country: CountryCodeIso2
    $visitorId: UUID
    $isRemoteEnabled: Boolean
  ) {
    searchLocationsSuggest(
      query: $query
      count: $count
      recentLocation: $recentLocation
      locale: $locale
      country: $country
      visitorId: $visitorId
      isRemoteEnabled: $isRemoteEnabled
    ) {
      __typename
      suggestions {
        ... on LocationSuggestion {
          __typename
          text
          highlights {
            start
            end
          }
        }
        ... on LocationSuggestionGroup {
          __typename
          label
          suggestions {
            text
            highlights {
              start
              end
            }
          }
        }
      }
    }
  }
`;
