// @ts-expect-error: non-ts file
import { provideHooks } from 'redial';
import loadable from 'sku/@loadable/component';

import { getRecentSearches } from 'src/store/recentSearches';
import { expandSearchFilters } from 'src/store/search';
import type { RedialLocals } from 'src/types/RedialLocals';

import { useHomeTracking } from './useHomeTracking';

export const HomePage = loadable(
  /* #__LOADABLE__ */ () =>
    import(
      /* webpackChunkName: "HomePage" */ 'src/components/HomePage/HomePage'
    ),
);

const hooks = {
  fetch: ({ routeEnter, dispatch, cookies, country }: RedialLocals) => {
    dispatch(expandSearchFilters(false));
    return routeEnter
      ? dispatch(getRecentSearches({ cookies, country }))
      : null;
  },
};

const Home = () => {
  useHomeTracking();
  return <HomePage />;
};

export default provideHooks(hooks)(Home);
