// This module is inspired from https://stackoverflow.com/questions/52292603/is-there-a-callback-for-window-scrollto#answer-55686711
export const scrollTo = (
  options?: ScrollToOptions & { tolerance?: number },
  callback?: () => void,
) => {
  if (typeof window === 'undefined') {
    return;
  }

  const { top = 0, behavior = 'smooth', left = 0, tolerance } = options ?? {};

  if (callback) {
    // This range is to ensure the callback will be called if the scroll hits a close enough point to the offset
    const TOLERANCE = tolerance ?? 0;
    const offset = Number(top.toFixed());

    const onScroll = () => {
      const scrollY = Number(window.scrollY.toFixed());
      if (offset - scrollY <= TOLERANCE) {
        window.removeEventListener('scroll', onScroll);
        callback();
      }
    };

    window.addEventListener('scroll', onScroll);
    onScroll();
  }

  window.scrollTo({
    top,
    left,
    behavior,
  });
};
