import { useTranslations } from '@vocab/react';
import { HiddenVisually } from 'braid-design-system';
import type { ComponentProps } from 'react';

import RefineRadio from '../RefineRadio/RefineRadio';

import translations from './.vocab';

interface WorkTypesProps {
  handleSelection: (value: string) => any;
  workTypes: ComponentProps<typeof RefineRadio>['options'];
}

const WorkTypes = (props: WorkTypesProps) => {
  const { t } = useTranslations(translations);
  const { handleSelection, workTypes } = props;
  return (
    <nav role="navigation" aria-labelledby="RefineWorkType">
      <HiddenVisually>
        <h1 id="RefineWorkType">{t('Refine by Work Type')}</h1>
      </HiddenVisually>
      <RefineRadio
        multiSelect={true}
        handleSelection={handleSelection}
        labelledBy="RefineWorkType"
        options={workTypes}
        path="/jobs"
        data-automation="refineWorkType"
      />
    </nav>
  );
};

export default WorkTypes;
