import {
  type Brand,
  brandAndCountryFromZone,
  type Zone,
} from '@seek/audience-zones';

import type { Country } from 'src/types/globals';

export default (zone: Zone = 'anz-1'): Country => {
  const [, country] = brandAndCountryFromZone(zone) as [Brand, Country];

  return country;
};
