import { useTranslations } from '@vocab/react';
import { Box, ContentBlock, Heading, Stack, Text } from 'braid-design-system';

import { useSelector } from 'src/store/react';
import { selectKeywordField } from 'src/store/selectors';

import { ScreenReaderSkipTarget } from '../ScreenReaderSkipTarget/ScreenReaderSkipTarget';

import translations from './.vocab';
import AllCountries from './AllCountries';
import CareerArticles from './CareerArticles';
import PopularCountries from './PopularCountries';

const PoeaCountryPicker = () => {
  const { t } = useTranslations(translations);
  const keywordsField = useSelector(selectKeywordField);
  const filteredKeyword = keywordsField?.replace(/jobs?/gi, '').trim();
  return (
    <Box data={{ automation: 'poea-country-picker' }}>
      <Box background="brand" paddingX="xxlarge" paddingY="xlarge">
        <ContentBlock width="large">
          <ScreenReaderSkipTarget name="start-of-content" />
          <Stack space="large">
            <Heading level="1">
              {t('Find world-wide')} {filteredKeyword || 'POEA'} {t('jobs')}
            </Heading>
            <Text size="large">
              {t(
                'Find jobs verified by WorkAbroad as DMW (formerly POEA)-approved overseas opportunities',
              )}
            </Text>
          </Stack>
        </ContentBlock>
      </Box>
      <PopularCountries />
      <CareerArticles />
      <AllCountries />
    </Box>
  );
};

export default PoeaCountryPicker;
