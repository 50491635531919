import type { DateRanges } from './types';

// TODO: Temporarily hardcoded dateRanges values as these will be migrate to the candidate graph later on.
export const dateRanges: Record<string, DateRanges> = {
  'anz-1': {
    en: [
      {
        label: 'Any time',
        value: '',
      },
      {
        label: 'Today',
        value: '1',
      },
      {
        label: 'Last 3 days',
        value: '3',
      },
      {
        label: 'Last 7 days',
        value: '7',
      },
      {
        label: 'Last 14 days',
        value: '14',
      },
      {
        label: 'Last 30 days',
        value: '31',
      },
    ],
  },
  'anz-2': {
    en: [
      {
        label: 'Any time',
        value: '',
      },
      {
        label: 'Today',
        value: '1',
      },
      {
        label: 'Last 3 days',
        value: '3',
      },
      {
        label: 'Last 7 days',
        value: '7',
      },
      {
        label: 'Last 14 days',
        value: '14',
      },
      {
        label: 'Last 30 days',
        value: '31',
      },
    ],
  },
  'asia-1': {
    en: [
      {
        label: 'Any time',
        value: '',
      },
      {
        label: 'Today',
        value: '1',
      },
      {
        label: 'Last 3 days',
        value: '3',
      },
      {
        label: 'Last 7 days',
        value: '7',
      },
      {
        label: 'Last 14 days',
        value: '14',
      },
      {
        label: 'Last 30 days',
        value: '31',
      },
    ],
  },
  'asia-3': {
    en: [
      {
        label: 'Any time',
        value: '',
      },
      {
        label: 'Today',
        value: '1',
      },
      {
        label: 'Last 3 days',
        value: '3',
      },
      {
        label: 'Last 7 days',
        value: '7',
      },
      {
        label: 'Last 14 days',
        value: '14',
      },
      {
        label: 'Last 30 days',
        value: '31',
      },
    ],
    th: [
      {
        label: 'ทุกเวลา',
        value: '',
      },
      {
        label: 'วันนี้',
        value: '1',
      },
      {
        label: '3 วันที่ผ่านมา',
        value: '3',
      },
      {
        label: '7 วันที่ผ่านมา',
        value: '7',
      },
      {
        label: '14 วันที่ผ่านมา',
        value: '14',
      },
      {
        label: '30 วันที่ผ่านมา',
        value: '31',
      },
    ],
  },
  'asia-4': {
    en: [
      {
        label: 'Any time',
        value: '',
      },
      {
        label: 'Today',
        value: '1',
      },
      {
        label: 'Last 3 days',
        value: '3',
      },
      {
        label: 'Last 7 days',
        value: '7',
      },
      {
        label: 'Last 14 days',
        value: '14',
      },
      {
        label: 'Last 30 days',
        value: '31',
      },
    ],
    id: [
      {
        label: 'Kapan saja',
        value: '',
      },
      {
        label: 'Hari Ini',
        value: '1',
      },
      {
        label: '3 hari terakhir',
        value: '3',
      },
      {
        label: '7 hari terakhir',
        value: '7',
      },
      {
        label: '14 hari terakhir',
        value: '14',
      },
      {
        label: '30 hari terakhir',
        value: '31',
      },
    ],
  },
  'asia-5': {
    en: [
      {
        label: 'Any time',
        value: '',
      },
      {
        label: 'Today',
        value: '1',
      },
      {
        label: 'Last 3 days',
        value: '3',
      },
      {
        label: 'Last 7 days',
        value: '7',
      },
      {
        label: 'Last 14 days',
        value: '14',
      },
      {
        label: 'Last 30 days',
        value: '31',
      },
    ],
  },
  'asia-6': {
    en: [
      {
        label: 'Any time',
        value: '',
      },
      {
        label: 'Today',
        value: '1',
      },
      {
        label: 'Last 3 days',
        value: '3',
      },
      {
        label: 'Last 7 days',
        value: '7',
      },
      {
        label: 'Last 14 days',
        value: '14',
      },
      {
        label: 'Last 30 days',
        value: '31',
      },
    ],
  },
  'asia-7': {
    en: [
      {
        label: 'Any time',
        value: '',
      },
      {
        label: 'Today',
        value: '1',
      },
      {
        label: 'Last 3 days',
        value: '3',
      },
      {
        label: 'Last 7 days',
        value: '7',
      },
      {
        label: 'Last 14 days',
        value: '14',
      },
      {
        label: 'Last 30 days',
        value: '31',
      },
    ],
  },
};
