import findKey from 'lodash/findKey';

import {
  jobDetailsPageRegex,
  searchResultsPageRegex,
  homePageRegex,
  expiredJobPageRegex,
} from 'src/modules/routes-regexp';

const pageNameRegexps = {
  'job-details': jobDetailsPageRegex,
  'search-results': searchResultsPageRegex,
  home: homePageRegex,
  'expired-job': expiredJobPageRegex,
};

export default (pathname: string) =>
  findKey(pageNameRegexps, (regex) => regex.test(pathname)) || '';
