export const mockMarqueeCompanies = [
  {
    id: '432421',
    title: 'AECOM',
    organizationId: '2790',
    logo: 'https://image-service-cdn.seek.com.au/16fef24e13c2c238026648fc2ffec2374061b817/f3c5292cec0e05e4272d9bf9146f390d366481d0',
    reviews: {
      rating: 3.5,
      count: 168,
    },
    companyNameSlug: 'australian-broadcasting-corporation-1',
    jobCount: 175,
  },
  {
    id: '432389',
    title: 'BP',
    organizationId: '5442',
    logo: 'https://image-service-cdn.seek.com.au/1122710e7591045ef87bf65bebb6405cf9e2db3a/f3c5292cec0e05e4272d9bf9146f390d366481d0',
    reviews: {
      rating: 3.5,
      count: 243,
    },
    companyNameSlug: 'australian-broadcasting-corporation-1',
    jobCount: 154,
  },
  {
    id: '432295',
    title: 'Woolworths Supermarkets',
    organizationId: '981',
    logo: 'https://image-service-cdn.seek.com.au/8ed288e231c6ed03637ecfb2bd4a33bcc4ef76ae/f3c5292cec0e05e4272d9bf9146f390d366481d0',
    reviews: {
      rating: 3.3,
      count: 2737,
    },
    companyNameSlug: 'australian-broadcasting-corporation-1',
    jobCount: 845,
  },
  {
    id: '797000',
    title: 'DXC Technology',
    organizationId: '7330',
    logo: 'https://image-service-cdn.seek.com.au/5ca9bed0636fe0463673b3414a41a92053f30575/f3c5292cec0e05e4272d9bf9146f390d366481d0',
    reviews: {
      rating: 2.7,
      count: 222,
    },
    companyNameSlug: 'australian-broadcasting-corporation-1',
    jobCount: 221,
  },
  {
    id: '432554',
    title: 'Computershare',
    organizationId: '5050',
    logo: 'https://image-service-cdn.seek.com.au/47e8a8dab97ef46ab24f883467b040ce2226cff0/f3c5292cec0e05e4272d9bf9146f390d366481d0',
    reviews: {
      rating: 3.2,
      count: 135,
    },
    companyNameSlug: 'australian-broadcasting-corporation-1',
    jobCount: 41,
  },
  {
    id: '432306',
    title: 'Commonwealth Bank of Australia',
    organizationId: '2034',
    logo: 'https://image-service-cdn.seek.com.au/1ce3fdd0073d3abf463145e24abc4603a9c5648f/f3c5292cec0e05e4272d9bf9146f390d366481d0',
    reviews: {
      rating: 3.5,
      count: 1370,
    },
    companyNameSlug: 'australian-broadcasting-corporation-1',
    jobCount: 392,
  },
  {
    id: '434023',
    title: 'Mindtree',
    organizationId: '2911',
    logo: 'https://image-service-cdn.seek.com.au/e4f6ac2a553f47f9be3b838c6c614d3e9e52ce31/f3c5292cec0e05e4272d9bf9146f390d366481d0',
    reviews: {
      rating: 4,
      count: 8,
    },
    companyNameSlug: 'australian-broadcasting-corporation-1',
    jobCount: 0,
  },
  {
    id: '432325',
    title: 'Downer',
    organizationId: '2425',
    logo: 'https://image-service-cdn.seek.com.au/14991be0b4fff87f76ade70578f6310f59063bf6',
    reviews: {
      rating: 3.5,
      count: 1198,
    },
    companyNameSlug: 'australian-broadcasting-corporation-1',
    jobCount: 736,
  },
  {
    id: '432378',
    title: 'Deloitte',
    organizationId: '1408',
    logo: 'https://image-service-cdn.seek.com.au/54dcbbdd427dc8ef715f160108029b74b0a85960',
    reviews: {
      rating: 3.3,
      count: 237,
    },
    companyNameSlug: 'australian-broadcasting-corporation-1',
    jobCount: 406,
  },
  {
    id: '437989',
    title: 'Gilbert + Tobin Lawyers',
    organizationId: '2455',
    logo: 'https://image-service-cdn.seek.com.au/ae504a82a574e085a93367610ab0875fd7a333df/f3c5292cec0e05e4272d9bf9146f390d366481d0',
    reviews: {
      rating: 4.3,
      count: 4,
    },
    companyNameSlug: 'australian-broadcasting-corporation-1',
    jobCount: 17,
  },
  {
    id: '753299',
    title: 'Domain Group',
    organizationId: '6933',
    logo: 'https://image-service-cdn.seek.com.au/5622c5d4dfdbc2c8f59dd394f09cb43eb249a6a1/f3c5292cec0e05e4272d9bf9146f390d366481d0',
    reviews: {
      rating: 3.2,
      count: 22,
    },
    companyNameSlug: 'australian-broadcasting-corporation-1',
    jobCount: 60,
  },
  {
    id: '432321',
    title: 'Accenture',
    organizationId: '1369',
    logo: 'https://image-service-cdn.seek.com.au/8cf3e7a12583cce3f9afe1f1081619045776d449/f3c5292cec0e05e4272d9bf9146f390d366481d0',
    reviews: {
      rating: 3.7,
      count: 312,
    },
    companyNameSlug: 'australian-broadcasting-corporation-1',
    jobCount: 87,
  },
  {
    id: '432679',
    title: 'Toll Group',
    organizationId: '3003',
    logo: 'https://cpp-prod-seek-company-image-uploads.s3.ap-southeast-2.amazonaws.com/432679/logo/986cc7f1-bc21-11ea-994a-21a90d206834.jpg',
    reviews: {
      rating: 3,
      count: 983,
    },
    companyNameSlug: 'australian-broadcasting-corporation-1',
    jobCount: 77,
  },
  {
    id: '814812',
    title: 'BGL Corporate Solutions',
    organizationId: '19630',
    logo: 'https://image-service-cdn.seek.com.au/f51e4d028ea7c2398daeed5f2aff09adad4cb53a/f3c5292cec0e05e4272d9bf9146f390d366481d0',
    reviews: {
      rating: 4.1,
      count: 21,
    },
    companyNameSlug: 'australian-broadcasting-corporation-1',
    jobCount: 7,
  },
];
