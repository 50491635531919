import type { DeviceType } from '@seek/chalice-types';

import { isGooglebotMobile } from './googlebot';
import { getUserAgent } from './isomorphic-useragent';

const isMobile = (ua: string) =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(ua);

// Mobile device detection
export const isMobileUserAgent = (): boolean => {
  const userAgent = getUserAgent();
  if (isGooglebotMobile(userAgent)) {
    return true;
  }

  return isMobile(userAgent);
};

export const getCurrentDevice = (): DeviceType => {
  const ua = getUserAgent() || '';

  if (ua.match(/iphone/i)) {
    return 'iOS';
  }

  if (ua.match(/Android/i)) {
    return 'Android';
  }

  // We would like to detect if the user is using a Desktop device, but we could only detect if the user is using a mobile device or not.
  // For the rest of the Non-Mobile devices, we will return 'Desktop' as the device type. Cause originally we only need to know if the user is using a iOS or Android device.
  if (!isMobile(ua)) {
    return 'Desktop';
  }

  return 'Other';
};
