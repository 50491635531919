import type { SearchResultLocationV5 } from '@seek/chalice-types';

const LocationTypes = {
  SUBURB: 'Suburb',
};

const isSuburb = (location: SearchResultLocationV5 | undefined): boolean => {
  if (location === undefined) {
    return false;
  }
  return location.type === LocationTypes.SUBURB;
};

export default isSuburb;
