import type { Language, Locale } from '@seek/melways-sites';

import type { Country } from 'src/types/globals';

export const toLocale = (language: Language, country: Country): Locale =>
  `${language}-${country}` as Locale;

export const parseLocale = (
  locale: Locale,
): { country: Country; language: Language } => {
  const [language, country] = locale.split('-');
  return { country: country as Country, language: language as Language };
};
