import { Box, Strong, Text } from 'braid-design-system';
import { useEffect, useState } from 'react';

import { environment } from 'src/config';

export const PreviewBranchName = () => {
  const [branchName, setBranchName] = useState('');

  useEffect(() => {
    setBranchName(window.SEEK_CONFIG?.SEEK_BRANCH_NAME);
  }, []);

  const shouldShowBanner =
    branchName &&
    branchName !== 'master' &&
    ['dev', 'stag'].includes(environment);

  if (!shouldShowBanner) {
    return null;
  }

  return (
    <Box background="brandAccent" paddingY="small" textAlign="center">
      <Text size="small">
        You are currently previewing <Strong>{branchName}</Strong> branch.
      </Text>
    </Box>
  );
};
