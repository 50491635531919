import gql from 'graphql-tag';

export const TRACK_JOB_DETAILED_VIEWED = gql`
  mutation TrackJobDetailsViewed(
    $input: TrackJobDetailsViewedInput!
    $locale: Locale = "en-AU"
  ) {
    trackJobDetailsViewed(input: $input) {
      __typename
      ... on TrackJobDetailsViewedSuccess {
        __typename
        eventId
      }
      ... on TrackJobDetailsViewedFailure {
        error {
          __typename
          message(locale: $locale)
        }
      }
    }
  }
`;
