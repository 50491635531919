import { useTranslations } from '@vocab/react';
import { Box, HiddenVisually, Stack, Text } from 'braid-design-system';
import type { ComponentProps } from 'react';

import { useSearchSalaryRanges } from 'src/hooks/useSearchSalaryRanges';

import RefineRadio from '../RefineRadio/RefineRadio';
import SalaryType from '../SalaryTypeGraph/SalaryTypeGraph';

import translations from './.vocab';

interface SalaryProps {
  handleSelection: () => any;
  salaries: ComponentProps<typeof RefineRadio>['options'];
  salaryTypes: ComponentProps<typeof SalaryType>['salaryTypes'];
  label: string;
}

const Salary = (props: SalaryProps) => {
  const { t } = useTranslations(translations);
  const { defaultCurrency } = useSearchSalaryRanges();
  const { handleSelection, salaries, salaryTypes, label } = props;
  const id = `refineSalary${label}`;

  return (
    <Box component="nav" role="navigation" aria-labelledby={id}>
      <HiddenVisually>
        <h1 id={id}>{t('Salary', { label })}</h1>
      </HiddenVisually>

      <Stack space="large">
        <SalaryType
          data-automation={`${id}SalaryType`}
          salaryTypes={salaryTypes}
        />

        <Text size="small" tone="secondary">
          {t('Salary (currencyCode)', { currencyCode: defaultCurrency.code })}
        </Text>

        <RefineRadio
          handleSelection={handleSelection}
          labelledBy={id}
          options={salaries}
          path="/jobs"
          data-automation={id}
        />
      </Stack>
    </Box>
  );
};

export default Salary;
