export const truncateText = ({
  text,
  length,
  suffix = '...',
}: {
  text: string;
  length: number;
  suffix?: string;
}) => {
  if (text.length >= length) {
    return text.substring(0, length - suffix.length).concat(suffix);
  }

  return text;
};
