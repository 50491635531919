import type { UrlLocation } from '@seek/chalice-types';
import {
  enrichLocation,
  isAbsoluteUrl,
  locationFromHref,
} from '@seek/seek-jobs-seo';
import { useMemo } from 'react';

import { useSelector } from 'src/store/react';
import { selectUrl } from 'src/store/selectors';
import { useLocalisedUrlLocation } from 'src/utils/melwaysHelper';

function getEnrichedLocation(
  location: string | UrlLocation,
  baseLocation: string,
) {
  // If location is an href string then coerce to a location object
  return typeof location === 'string'
    ? locationFromHref(location, baseLocation)
    : enrichLocation(location, baseLocation);
}

interface Props {
  location?: UrlLocation | string;
  shouldUseUrlResolver?: boolean;
}

const shouldLocaliseLocation = (location: Props['location']) => {
  if (typeof location === 'undefined') {
    return false;
  }
  if (typeof location === 'string') {
    return !isAbsoluteUrl(location);
  }
  return !location.hostname;
};

export function useEnrichedLocation({
  location,
  shouldUseUrlResolver = true,
}: Props): UrlLocation | undefined {
  const url = useSelector(selectUrl);

  // If location is undefined pass it through as undefined
  const { enrichedLocation, shouldLocalise } = useMemo(
    () => ({
      enrichedLocation:
        typeof location !== 'undefined'
          ? getEnrichedLocation(location, url || '')
          : location,
      shouldLocalise: shouldLocaliseLocation(location),
    }),
    [location, url],
  );

  const localisedLocation = useLocalisedUrlLocation(enrichedLocation || {});
  return shouldUseUrlResolver && shouldLocalise
    ? localisedLocation
    : enrichedLocation;
}

export function useJobCardEnrichedLocation() {
  const url = useSelector(selectUrl);

  return useMemo(
    () => (location: string | UrlLocation) =>
      getEnrichedLocation(location, url || ''),
    [url],
  );
}
