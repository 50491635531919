import type { Distances } from './types';

export const distances: Record<string, Distances> = {
  'anz-1': {
    en: [
      {
        label: 'Exact location only',
        value: '0',
      },
      {
        label: '2 km',
        value: '2',
      },
      {
        label: '5 km',
        value: '5',
      },
      {
        label: '10 km',
        value: '10',
      },
      {
        label: '25 km',
        value: '25',
      },
      {
        label: '50 km',
        value: '50',
      },
      {
        label: '100 km',
        value: '100',
      },
    ],
  },
  'anz-2': {
    en: [
      {
        label: 'Exact location only',
        value: '0',
      },
      {
        label: '2 km',
        value: '2',
      },
      {
        label: '5 km',
        value: '5',
      },
      {
        label: '10 km',
        value: '10',
      },
      {
        label: '25 km',
        value: '25',
      },
      {
        label: '50 km',
        value: '50',
      },
      {
        label: '100 km',
        value: '100',
      },
    ],
  },
  'asia-1': {
    en: [
      {
        label: 'Exact location only',
        value: '0',
      },
      {
        label: '2 km',
        value: '2',
      },
      {
        label: '5 km',
        value: '5',
      },
      {
        label: '10 km',
        value: '10',
      },
      {
        label: '25 km',
        value: '25',
      },
      {
        label: '50 km',
        value: '50',
      },
      {
        label: '100 km',
        value: '100',
      },
    ],
  },
  'asia-3': {
    en: [
      {
        label: 'Exact location only',
        value: '0',
      },
      {
        label: '2 km',
        value: '2',
      },
      {
        label: '5 km',
        value: '5',
      },
      {
        label: '10 km',
        value: '10',
      },
      {
        label: '25 km',
        value: '25',
      },
      {
        label: '50 km',
        value: '50',
      },
      {
        label: '100 km',
        value: '100',
      },
    ],
    th: [
      {
        label: 'สถานที่ที่แน่นอนเท่านั้น',
        value: '0',
      },
      {
        label: '2 กม.',
        value: '2',
      },
      {
        label: '5 กม.',
        value: '5',
      },
      {
        label: '10 กม.',
        value: '10',
      },
      {
        label: '25 กม.',
        value: '25',
      },
      {
        label: '50 กม.',
        value: '50',
      },
      {
        label: '100 กม.',
        value: '100',
      },
    ],
  },
  'asia-4': {
    en: [
      {
        label: 'Exact location only',
        value: '0',
      },
      {
        label: '2 km',
        value: '2',
      },
      {
        label: '5 km',
        value: '5',
      },
      {
        label: '10 km',
        value: '10',
      },
      {
        label: '25 km',
        value: '25',
      },
      {
        label: '50 km',
        value: '50',
      },
      {
        label: '100 km',
        value: '100',
      },
    ],
    id: [
      {
        label: 'Hanya lokasi tertentu',
        value: '0',
      },
      {
        label: '2 km',
        value: '2',
      },
      {
        label: '5 km',
        value: '5',
      },
      {
        label: '10 km',
        value: '10',
      },
      {
        label: '25 km',
        value: '25',
      },
      {
        label: '50 km',
        value: '50',
      },
      {
        label: '100 km',
        value: '100',
      },
    ],
  },
  'asia-5': {
    en: [
      {
        label: 'Exact location only',
        value: '0',
      },
      {
        label: '2 km',
        value: '2',
      },
      {
        label: '5 km',
        value: '5',
      },
      {
        label: '10 km',
        value: '10',
      },
      {
        label: '25 km',
        value: '25',
      },
      {
        label: '50 km',
        value: '50',
      },
      {
        label: '100 km',
        value: '100',
      },
    ],
  },
  'asia-6': {
    en: [
      {
        label: 'Exact location only',
        value: '0',
      },
      {
        label: '2 km',
        value: '2',
      },
      {
        label: '5 km',
        value: '5',
      },
      {
        label: '10 km',
        value: '10',
      },
      {
        label: '25 km',
        value: '25',
      },
      {
        label: '50 km',
        value: '50',
      },
      {
        label: '100 km',
        value: '100',
      },
    ],
  },
  'asia-7': {
    en: [
      {
        label: 'Exact location only',
        value: '0',
      },
      {
        label: '2 km',
        value: '2',
      },
      {
        label: '5 km',
        value: '5',
      },
      {
        label: '10 km',
        value: '10',
      },
      {
        label: '25 km',
        value: '25',
      },
      {
        label: '50 km',
        value: '50',
      },
      {
        label: '100 km',
        value: '100',
      },
    ],
  },
};
