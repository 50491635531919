import type { SearchParams } from '@seek/chalice-types';
import { Stack, TextLink, Divider, Text } from 'braid-design-system';
import { useSelector } from 'react-redux';

import { poeaTerms } from 'src/config/utils/constants';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { selectPathname, selectSearchQuery } from 'src/store/selectors';

export const isPoeaPathWithoutLocation = (path: string) => {
  const includesPoeaTerms = poeaTerms.some((term) => path.includes(term));
  const excludesLocation = !/\/in-[^/]+/.test(path);
  return includesPoeaTerms && excludesLocation;
};

export const generateColumnData = ({
  items,
  columns,
}: {
  items: string[];
  columns: number;
}) => {
  const itemsPerColumn = Math.ceil(items.length / columns);
  const columnData = [];

  for (let columnIndex = 0; columnIndex < columns; columnIndex++) {
    const start = columnIndex * itemsPerColumn;
    const end = (columnIndex + 1) * itemsPerColumn;
    const itemsInColumn = items.slice(start, end);

    columnData.push({
      key: columnIndex,
      start,
      items: itemsInColumn,
    });
  }

  return columnData;
};

interface CountryLinkProps {
  country: string;
  withDivider?: boolean;
}

export const CountryLink = ({ country, withDivider }: CountryLinkProps) => {
  const currentPath = useSelector(selectPathname);
  const analyticsFacade = useAnalyticsFacade();

  const searchQuery = useSelector(selectSearchQuery);
  const countryLinkPath = getCountryLink(currentPath, country, searchQuery);

  return (
    <Stack space="medium">
      <Text>
        <TextLink
          href={countryLinkPath}
          onClick={() => {
            analyticsFacade.poeaLinksPressed({
              currentPage: 'country picker',
              linkText: country,
              linkAction: 'country selection',
              linkPosition: 'body',
            });
          }}
          data={{ automation: `poea-country-picker-country-${country}` }}
        >
          {country}
        </TextLink>
      </Text>
      {withDivider && <Divider />}
    </Stack>
  );
};

export const getCountryLink = (
  currentPath: string,
  country: string,
  searchQuery?: SearchParams,
): string => {
  const subClassification = searchQuery?.subclassification;

  const workType = searchQuery?.worktype;

  if (subClassification && !workType) {
    return `${currentPath}/in-${country}`;
  }

  if (subClassification && workType) {
    return addCountryToPath(currentPath, country, 3);
  }

  return addCountryToPath(currentPath, country, 2);
};

const countryToValidCountryNameMap: Record<string, string> = {
  'New Zealand': 'All-New-Zealand',
  Australia: 'All-Australia',
};

const addCountryToPath = (path: string, country: string, position: number) => {
  const validCountryName = getValidCountryName(country);
  const pathParts = path.split('/');
  const updatedPathParts = [
    ...pathParts.slice(0, position),
    `in-${validCountryName}`,
    ...pathParts.slice(position),
  ];
  return updatedPathParts.join('/');
};

const getValidCountryName = (country: string): string =>
  countryToValidCountryNameMap[country] || country;
