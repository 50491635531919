import { useTranslations } from '@vocab/react';
import {
  Box,
  Columns,
  ContentBlock,
  Heading,
  Stack,
  IconFlag,
  List,
  Column,
} from 'braid-design-system';

import translations from './.vocab';
import { CountryLink, generateColumnData } from './utils';

const countries = [
  'Albania',
  'Algeria',
  'Angola',
  'Austria',
  'Bahrain',
  'Belgium',
  'Botswana',
  'British Indian Ocean Territory',
  'Brunei',
  'Canada',
  'Cayman Islands',
  'China',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Djibouti',
  'Fiji',
  'Ghana',
  'Greece',
  'Guam',
  'Hungary',
  'Indonesia',
  'Ireland',
  'Italy',
  'Japan',
  'Jordan',
  'Kyrgyzstan',
  'Lithuania',
  'Maldives',
  'Malta',
  'Micronesia',
  'Mongolia',
  'Montenegro',
  'Mozambique',
  'Nauru',
  'New Zealand',
  'Nigeria',
  'Northern Mariana Islands',
  'Oman',
  'Palau',
  'Papua New Guinea',
  'Poland',
  'Republic of the Congo',
  'Romania',
  'Saint Vincent and the Grenadines',
  'Serbia',
  'Seychelles',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'South Korea',
  'Taiwan',
  'Tanzania',
  'Thailand',
  'Turks and Caicos Islands',
  'United Kingdom',
  'United States of America',
  'Vanuatu',
  'Venezuela',
  'Zambia',
];

const AllCountries = () => {
  const { t } = useTranslations(translations);
  const columnData = generateColumnData({
    items: countries,
    columns: 4,
  });

  return (
    <Box paddingX="xlarge" paddingY="small">
      <ContentBlock width="large">
        <Box paddingTop="medium">
          <Heading level="3">{t('All other countries')}</Heading>
          <Box paddingTop="xlarge">
            <Columns space="small" collapseBelow="tablet">
              {columnData.map(({ key, start, items }) => (
                <Column key={key}>
                  <Stack space="large">
                    <List
                      type="icon"
                      icon={<IconFlag />}
                      start={start + 1}
                      space="large"
                    >
                      {items.map((country, index) => (
                        <CountryLink
                          key={index}
                          country={country}
                          withDivider={false}
                        />
                      ))}
                    </List>
                  </Stack>
                </Column>
              ))}
            </Columns>
          </Box>
        </Box>
      </ContentBlock>
    </Box>
  );
};

export default AllCountries;
