import { linkContextDestructor as headerFooterLinkContextDestructor } from '@seek/apac-candidate-header-footer';
import type { UrlLocation } from '@seek/chalice-types';
import {
  ButtonLink as BraidButtonLink,
  Link as BraidLink,
  makeLinkComponent,
  MenuItemLink as BraidMenuItemLink,
  Pagination as BraidPagination,
  TextLink as BraidTextLink,
} from 'braid-design-system';
import omit from 'lodash/omit';
import {
  forwardRef,
  memo,
  useCallback,
  useMemo,
  type ComponentProps,
  type CSSProperties,
  type ElementType,
  type MouseEvent as ReactMouseEvent,
} from 'react';

import {
  type mapLinkContextToTrackingContext,
  useHeaderFooterLinkContextMapper,
} from 'src/hooks';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useEnrichedLocation } from 'src/modules/enriched-location';
// @ts-ignore
import isNewTabEvent from 'src/modules/is-new-tab-event';
import { getDefaultLinkTargetFromLocation } from 'src/modules/navlink-rules';
import { useQualifiedLinkParams } from 'src/modules/qualified-location';
import { linkNavigate } from 'src/store/location';
import { useDispatch } from 'src/store/react';
import type { ButtonColor } from 'src/types/globals';

import * as styles from './NavLink.css';

interface Base {
  style?: CSSProperties;
  color?: ButtonColor;
  tabIndex?: number;
  ghost?: boolean;
  chevron?: 'right';
}

interface UseLinkPropsParams {
  enrichedLocation?: UrlLocation;
  givenTarget?: string;
  getQualifiedLinkParams: (enrichedLocation: UrlLocation) => {
    href: string;
    rel?: string;
  };
  rel?: string;
}

export const useLinkProps = ({
  givenTarget,
  getQualifiedLinkParams,
  rel,
}: UseLinkPropsParams) =>
  useMemo(
    () => (enrichedLocation?: UrlLocation) => {
      if (enrichedLocation) {
        if (enrichedLocation.protocol === 'tel:') {
          return {
            href: enrichedLocation.href,
            rel,
            target: getTarget(givenTarget, enrichedLocation),
          };
        }
        return {
          ...getQualifiedLinkParams(enrichedLocation),
          target: getTarget(givenTarget, enrichedLocation),
        };
      }
      return { rel };
    },
    [givenTarget, getQualifiedLinkParams, rel],
  );

export interface NavLinkProps extends Base {
  className?: string;
  title?: string;
  rel?: string;
  location?: UrlLocation | string;
  target?: string;
  fullWidth?: boolean;
  analytics?: string;
  linkContext?: ReturnType<typeof mapLinkContextToTrackingContext>;
  onClick?: (event: ReactMouseEvent<HTMLAnchorElement>) => void;
  runOnClickOnly?: boolean;
  preNavigationHooks?: Array<() => void>;
  component?: string | ElementType;
  allowPropagation?: boolean;
  role?: string;
  disabledLinkNavigate?: boolean;
  [key: string]: any;
}

const isRightClickPressed = ({ nativeEvent }: ReactMouseEvent) =>
  nativeEvent.type === 'contextmenu' || nativeEvent.which === 3;

const getTarget = (target: string | undefined, enrichedLocation: UrlLocation) =>
  target || getDefaultLinkTargetFromLocation(enrichedLocation);

const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  (originalProps, ref) => {
    const headerFooterLinkContextMapper = useHeaderFooterLinkContextMapper();

    const [passedProps, headerProps] = headerFooterLinkContextDestructor(
      originalProps,
      headerFooterLinkContextMapper,
    );
    const isHeaderFooterLink = Boolean(headerProps);

    const {
      analytics,
      onClick,
      preNavigationHooks,
      allowPropagation,
      target: givenTarget,
      component: LinkComponent = 'a',
      linkContext,
      runOnClickOnly,
      className,
      disabledLinkNavigate,
      ...restProps
    } = { ...passedProps, ...headerProps } as NavLinkProps;

    const analyticsFacade = useAnalyticsFacade();
    const dispatch = useDispatch();
    const enrichedLocation = useEnrichedLocation({
      location: passedProps.location,
      shouldUseUrlResolver: !isHeaderFooterLink,
    });
    const getQualifiedLinkParams = useQualifiedLinkParams({
      rel: passedProps.rel,
      shouldUseUrlResolver: !isHeaderFooterLink,
    });

    if (typeof className !== 'string' && typeof className !== 'undefined') {
      throw new Error(
        "NavLink's `className` prop needs to be of type string. Consider using Braid's Link component if you need a more complex className!",
      );
    }

    const props = omit(restProps, [
      'allowPropagation',
      'dispatch',
      'dispatchNavigate',
      'analytics',
      'linkContext',
      'href',
      'component',
      'location',
      'onClick', // don't allow onClick override
      'preNavigationHooks',
      'rel',
      'fullWidth',
    ]);

    const { href, rel, target } = useLinkProps({
      givenTarget,
      getQualifiedLinkParams,
      rel: restProps.rel,
    })(enrichedLocation);

    const handleClick = useCallback(
      (event: ReactMouseEvent<HTMLAnchorElement>) => {
        // event.currentTarget.dataset is for the JobCardLink on Metropolis

        if (
          !allowPropagation ||
          event.currentTarget.dataset.allowPropagation === 'false'
        ) {
          event.stopPropagation();
        }

        // Cancel navigation if trying to access context menu
        if (isRightClickPressed(event)) {
          event.preventDefault();
          return;
        }

        if (typeof onClick === 'function') {
          onClick(event);
          // This is for Split View's JobCard liked implementation
          if (
            runOnClickOnly ||
            event.currentTarget.dataset.runClickOnly === 'true'
          ) {
            return;
          }
        }

        if (!enrichedLocation) {
          event.preventDefault();
          return;
        }

        if (disabledLinkNavigate) {
          event.preventDefault();
          return;
        }

        // if this is a new tab event, target is obviously _blank
        const linkTarget = isNewTabEvent(event)
          ? '_blank'
          : getTarget(givenTarget, enrichedLocation);

        if ((analytics || linkContext) && href) {
          analyticsFacade.linkClicked({
            linkContext,
            linkName: linkContext?.eventName || analytics || '',
            href,
          });
        }

        dispatch(
          linkNavigate({
            event,
            target: linkTarget,
            location: enrichedLocation,
            preNavigationHooks,
          }),
        );

        event.preventDefault();
      },
      [
        allowPropagation,
        analytics,
        analyticsFacade,
        disabledLinkNavigate,
        dispatch,
        enrichedLocation,
        givenTarget,
        href,
        linkContext,
        onClick,
        preNavigationHooks,
        runOnClickOnly,
      ],
    );

    return (
      <LinkComponent
        ref={ref}
        onClick={handleClick}
        href={href}
        rel={rel}
        className={`${styles.reset}${className ? ` ${className}` : ''}`}
        {...props}
        target={target}
      />
    );
  },
);

NavLink.displayName = 'NavLink';

const sanitiseColor: (color?: string) => ButtonColor | undefined = (color) =>
  ((color === 'pink' ||
    color === 'gray' ||
    color === 'blue' ||
    color === 'white') &&
    (color as ButtonColor)) ||
  undefined;

export const CustomLinkForBraid = makeLinkComponent(
  ({ href, color, ...restProps }, ref) => (
    <NavLink
      location={href}
      ref={ref}
      color={sanitiseColor(color)}
      {...restProps}
    />
  ),
);

export const MenuItemLink = BraidMenuItemLink as React.FunctionComponent<
  Pick<ComponentProps<typeof BraidMenuItemLink>, 'children'> &
    Pick<
      NavLinkProps,
      | 'title'
      | 'rel'
      | 'target'
      | 'analytics'
      | 'onClick'
      | 'preNavigationHooks'
      | 'allowPropagation'
      | 'role'
      | 'data'
    > & {
      href: UrlLocation | string;
    }
>;

// @ts-expect-error
export const TextLink = BraidTextLink as React.FunctionComponent<
  Pick<
    ComponentProps<typeof BraidTextLink>,
    | 'weight'
    | 'hitArea'
    | 'showVisited'
    | 'data'
    | 'tabIndex'
    | 'children'
    | 'icon'
    | 'id'
    | 'iconPosition'
  > &
    Pick<
      NavLinkProps,
      | 'title'
      | 'rel'
      | 'target'
      | 'analytics'
      | 'linkContext'
      | 'onClick'
      | 'preNavigationHooks'
      | 'allowPropagation'
      | 'role'
      | 'runOnClickOnly'
      | 'disabledLinkNavigate'
    > & {
      location: UrlLocation | string;
    }
>;

// @ts-expect-error
export const ButtonLink = BraidButtonLink as React.FunctionComponent<
  Pick<
    ComponentProps<typeof BraidButtonLink>,
    | 'size'
    | 'bleed'
    | 'tone'
    | 'data'
    | 'variant'
    | 'loading'
    | 'tabIndex'
    | 'children'
    | 'icon'
    | 'iconPosition'
  > &
    Pick<
      NavLinkProps,
      | 'title'
      | 'rel'
      | 'target'
      | 'analytics'
      | 'linkContext'
      | 'onClick'
      | 'preNavigationHooks'
      | 'allowPropagation'
      | 'role'
    > & {
      location: UrlLocation | string;
    }
>;

// @ts-expect-error
export const Link = BraidLink as React.FunctionComponent<
  Pick<
    ComponentProps<typeof BraidLink>,
    'children' | 'className' | 'tabIndex' | 'id'
  > &
    Pick<
      NavLinkProps,
      | 'title'
      | 'rel'
      | 'target'
      | 'analytics'
      | 'onClick'
      | 'preNavigationHooks'
      | 'allowPropagation'
      | 'role'
      | 'location'
      | 'runOnClickOnly'
      | 'disabledLinkNavigate'
    >
>;

// @ts-expect-error
export const Pagination = BraidPagination as React.FunctionComponent<
  Pick<
    ComponentProps<typeof BraidPagination>,
    | 'page'
    | 'total'
    | 'label'
    | 'pageLimit'
    | 'pageLabel'
    | 'nextLabel'
    | 'previousLabel'
    | 'data'
    | 'pageLimit'
  > & {
    linkProps: ({
      page,
      type,
    }: {
      page: number;
      type: 'next' | 'previous' | 'pageNumber';
    }) => Pick<NavLinkProps, 'location' | 'preNavigationHooks'> & {
      'data-automation': string;
    };
  }
>;

export default memo(NavLink);
