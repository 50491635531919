import type { InputProps } from '.';

const sensitiveFields = ['email', 'name', 'phone'];

export const scrubSensitiveData = (obj: InputProps) =>
  Object.entries(obj).reduce((acc: InputProps, [key, value]) => {
    if (!sensitiveFields.some((x) => key.toLowerCase().includes(x))) {
      acc[key] =
        value !== null && typeof value === 'object'
          ? scrubSensitiveData(value)
          : value;
    }
    return acc;
  }, {});
