import type { Zone } from '@seek/audience-zones';
import { Md5 } from 'md5-typescript';

import type { TFeatureFlagNames } from '../featureFlags/types';
import type { ChaliceStore } from '../types';

import type {
  TExperiment,
  TExperimentKeys,
  TFeatureExperiments,
  TSeekExperiments,
  VariationType,
} from './types';

const HEX_SIZE = 16;
const CHAR_SIZE = 6;
const NUMBER_OF_POSSIBLE_GROUP = Math.pow(HEX_SIZE, CHAR_SIZE);

interface IsUserInExperimentProps {
  userId: string;
  experiment: TExperiment;
  zone: Zone;
  shouldExcludeFromExperiment: boolean;
}
const isUserInExperiment = ({
  userId,
  experiment: { name, percentage, groupSalt, targetZones },
  zone,
  shouldExcludeFromExperiment,
}: IsUserInExperimentProps): VariationType | undefined => {
  if (!targetZones.has(zone)) {
    return undefined;
  }

  if (!userId || percentage <= 0 || shouldExcludeFromExperiment) {
    return {
      name: 'outsider',
      index: '2',
    };
  }

  const md = Md5.init(`${userId}.${groupSalt || name}`);

  // 0.00 - 0.99, it will never hit 1.00
  const groupBucket =
    parseInt(md.slice(-CHAR_SIZE), HEX_SIZE) / NUMBER_OF_POSSIBLE_GROUP;

  // from outsiderMark to 100%
  const outsiderMark = Math.min((percentage * 2) / 100, 1);
  // from controlMark to outsiderMark
  const controlMark = percentage / 100;

  if (groupBucket >= outsiderMark) {
    return {
      name: 'outsider',
      index: '2',
    };
  }

  if (groupBucket >= controlMark) {
    return {
      name: 'control',
      index: '0',
    };
  }

  return {
    name: 'variant',
    index: '1',
  };
};
export const isUserInExperimentForTest = isUserInExperiment;

export const toggleExperiment = ({
  experimentId,
  experiments,
  cookieValue,
}: {
  experimentId: TFeatureFlagNames;
  experiments: TSeekExperiments;
  cookieValue?: string;
}): boolean => {
  if (
    Boolean(cookieValue) ||
    experiments?.[experimentId]?.variation.name === 'variant'
  ) {
    return true;
  }

  return false;
};

export const mapToSeekExperiments = ({
  userId,
  featureExperiments,
  shouldExcludeFromExperiment,
  zone,
}: {
  featureExperiments: TFeatureExperiments;
  userId: string;
  shouldExcludeFromExperiment: boolean;
  zone: Zone;
}): TSeekExperiments =>
  Object.entries(featureExperiments).reduce<TSeekExperiments>(
    (acc, [experimentId, experiment]) => {
      if (
        experiment.percentage > 0 ||
        experiment.enableTrackingForZeroPercent
      ) {
        const variation = isUserInExperiment({
          userId,
          experiment,
          shouldExcludeFromExperiment,
          zone,
        });

        if (variation) {
          acc[experimentId as TExperimentKeys] = {
            name: experiment.name,
            num: experiment.num,
            variation,
          };
        }
      }
      return acc;
    },
    {},
  );

export const selectExperimentsAsSolTags = (
  state: Pick<ChaliceStore, 'experiments'>,
): Record<string, string> =>
  Object.values(state.experiments).reduce(
    (memo, value) => ({
      ...memo,
      [`seek:chalice:experiments:${value.name.replace(/ /g, '')}`]:
        value.variation.index.toString(),
    }),
    {},
  );
