import type { ZoneFeatures } from 'src/config/types';

import type { ResultsState } from './types';

export const getRoleIdFromResults = (results: ResultsState) => {
  const roleIds = results?.results?.jobs
    ?.map((job) => !job.isFeatured && job.roleId)
    .filter((id) => id);

  return roleIds?.[0] || undefined;
};

export const getPageSize = (zoneFeatures: ZoneFeatures) =>
  zoneFeatures?.SEARCH_PAGE_SIZE || 22;
