import {
  type TypedUseSelectorHook,
  useSelector as reduxUseSelector,
  useDispatch as reduxUseDispatch,
  useStore as reduxUseStore,
} from 'react-redux';
import type { ThunkDispatch } from 'redux-thunk';

import type createStore from './createStore';
import type { TypedAction, ChaliceStore } from './types';

export type ReduxDispatch = ThunkDispatch<
  ReturnType<typeof createStore>,
  any,
  TypedAction
>;
export function useReduxDispatch(): ReduxDispatch {
  return reduxUseDispatch<ReduxDispatch>();
}

export const useSelector: TypedUseSelectorHook<ChaliceStore> = reduxUseSelector;
export const useDispatch: () => ThunkDispatch<ChaliceStore, null, TypedAction> =
  reduxUseDispatch;
export const useStore: () => ReturnType<typeof createStore> = reduxUseStore;
