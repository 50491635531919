import gql from 'graphql-tag';

export const GET_CANDIDATE_DETAILS = gql`
  query GetCandidateDetails {
    viewer {
      id
      personalDetails {
        firstName
      }
      emailAddress
      trackingId
      identity {
        actor {
          id
        }
      }
    }
  }
`;
