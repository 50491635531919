import { Box, Stack } from 'braid-design-system';

import type { RefinementType } from 'src/types/globals';

import RefineRadioItem from './RefineRadioItem/RefineRadioItem';

import * as styles from './RefineRadio.css';

interface RefineRadioProps {
  options: RefinementType[];
  multiSelect?: boolean;
  path: string;
  labelledBy: string;
  handleSelection: (arg0: any) => any;
}

const RefineRadio = (props: RefineRadioProps) => {
  const {
    options = [],
    multiSelect = false,
    path,
    labelledBy,
    handleSelection,
    ...restProps
  } = props;

  const id = `${labelledBy}__radiogroup`;
  const ariaRole = multiSelect ? {} : { role: 'radiogroup' };

  return (
    <Box
      {...restProps}
      id={id}
      aria-labelledby={labelledBy}
      {...ariaRole}
      className={styles.maxHeight}
    >
      <Stack space="large" component="ul">
        {options.map((option, index) => {
          const itemProps = {
            multiSelect,
            path,
            handleSelection,
            labelledBy: id,
          };

          return (
            <RefineRadioItem
              {...option}
              {...itemProps}
              id={`${id}-${index + 1}`}
              key={option.value}
            />
          );
        })}
      </Stack>
    </Box>
  );
};

export default RefineRadio;
