import type { ChaliceStore } from '../types';

export const selectPageLoadedCount = (state: ChaliceStore) =>
  state.jobdetails.pageLoadedCount;

export const selectJobDetailsResult = (state: ChaliceStore) =>
  state.jobdetails.result;

export const selectJobDetailsPersonalised = (state: ChaliceStore) =>
  state.jobdetails.personalised;

export const selectIsJobDetailsPending = (state: ChaliceStore) =>
  state.jobdetails.jobPending;

export const selectIsJobDetailsFetchFailure = (state: ChaliceStore) =>
  Boolean(state.jobdetails.error);

export const selectJobDetailsViewedCorrelationId = (state: ChaliceStore) =>
  state.jobdetails.jobDetailsViewedCorrelationId;

export const selectJobDetailsIsExpired = (state: ChaliceStore) =>
  Boolean(state.jobdetails.result?.job.isExpired);

export const selectXRealIp = (state: ChaliceStore) => state.jobdetails.xRealIp;
