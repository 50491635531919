import { useLazyQuery } from '@apollo/client';
import { useHubble } from '@seek/hubble';
import { get as localStorageGet } from 'local-storage';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import type {
  searchLocationsSuggest as searchLocationsSuggestType,
  searchLocationsSuggestVariables,
  searchLocationsSuggest_searchLocationsSuggest_suggestions,
  searchLocationsSuggest_searchLocationsSuggest_suggestions_LocationSuggestionGroup,
  searchLocationsSuggest_searchLocationsSuggest_suggestions_LocationSuggestionGroup_suggestions,
} from 'src/modules/graphql/queries/types/searchLocationsSuggest';
import { useSelector } from 'src/store/react';
import { selectFeatureFlag, selectRecentSearches } from 'src/store/selectors';
import { useMelwaysCountry } from 'src/utils/melwaysHelper';

import { GET_LOCATION_SUGGESTIONS } from '../graphql/queries/locations';

export interface FormattedPersonalisedSuggestion {
  label: string;
  suggestions: Array<
    searchLocationsSuggest_searchLocationsSuggest_suggestions_LocationSuggestionGroup_suggestions & {
      value: {
        analyticsType: string;
      };
    }
  >;
}

export const DEBOUNCED_TIMEOUT = 100;

const formatSuggestionsFromQuery = (
  locationSuggestions: searchLocationsSuggest_searchLocationsSuggest_suggestions_LocationSuggestionGroup[],
): FormattedPersonalisedSuggestion[] => {
  const withSuggestions = locationSuggestions.filter(
    (ls) => ls.suggestions.length > 0,
  );
  return withSuggestions.map((locationSuggestion) => ({
    label: locationSuggestion.label,
    suggestions: locationSuggestion.suggestions.map(
      (
        suggestion: searchLocationsSuggest_searchLocationsSuggest_suggestions_LocationSuggestionGroup_suggestions,
      ) => ({
        ...suggestion,
        value: {
          analyticsType: locationSuggestion.label.toLowerCase(),
        },
      }),
    ),
  }));
};

const isPersonalisedSuggestionGroup = (
  suggestionVal: searchLocationsSuggest_searchLocationsSuggest_suggestions,
  locationQuery: string,
): suggestionVal is searchLocationsSuggest_searchLocationsSuggest_suggestions_LocationSuggestionGroup =>
  suggestionVal?.__typename === 'LocationSuggestionGroup' &&
  locationQuery.trim() === '';

const getLastSearch = () => localStorageGet<string>('lastSearchWhere');

const useLocationSuggestions = () => {
  const { locale } = useAppConfig();
  const country = useMelwaysCountry();
  const searches = useSelector(selectRecentSearches);
  const hubble = useHubble();
  const visitorId = hubble.visitorId();
  const remoteSearchFilterEnabled = useSelector(
    selectFeatureFlag('remoteSearchFilter'),
  );

  const [location, setLocation] = useState({ current: '', recent: '' });
  const [suggestions, setSuggestions] = useState<
    | FormattedPersonalisedSuggestion[]
    | searchLocationsSuggest_searchLocationsSuggest_suggestions[]
  >([]);
  const [personalisedSuggestionsCache, setPersonalisedSuggestionsCache] =
    useState<Record<string, FormattedPersonalisedSuggestion[]>>({});
  const [suggestionsCache, setSuggestionsCache] = useState<
    Record<string, searchLocationsSuggest_searchLocationsSuggest_suggestions[]>
  >({});

  const [loadSuggestions] = useLazyQuery<
    searchLocationsSuggestType,
    searchLocationsSuggestVariables
  >(GET_LOCATION_SUGGESTIONS, {
    fetchPolicy: 'no-cache',
    ssr: false,
    onCompleted: ({ searchLocationsSuggest }) => {
      const locationSuggestions = searchLocationsSuggest?.suggestions ?? [];
      const isPersonalised = isPersonalisedSuggestionGroup(
        locationSuggestions[0],
        location.current,
      );

      if (isPersonalised) {
        const formattedSuggestions = formatSuggestionsFromQuery(
          locationSuggestions as searchLocationsSuggest_searchLocationsSuggest_suggestions_LocationSuggestionGroup[],
        );
        setSuggestions(formattedSuggestions);

        setPersonalisedSuggestionsCache(
          (previousPersonalisedSuggestionsCache) => ({
            ...previousPersonalisedSuggestionsCache,
            [location.recent]: formattedSuggestions,
          }),
        );
      } else {
        setSuggestionsCache((previousSuggestionsCache) => ({
          ...previousSuggestionsCache,
          [location.current]: locationSuggestions,
        }));
        setSuggestions(locationSuggestions);
      }
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLoadWhereSuggestions = useCallback(
    debounce((args) => {
      loadSuggestions(args);
    }, DEBOUNCED_TIMEOUT),
    [loadSuggestions],
  );

  useEffect(
    () => () => {
      debouncedLoadWhereSuggestions.cancel();
    },
    [debouncedLoadWhereSuggestions],
  );

  const fetchWhereSuggestions = ({ query = '', recentSearchLocation = '' }) => {
    debouncedLoadWhereSuggestions({
      variables: {
        query,
        recentLocation: recentSearchLocation,
        count: 16,
        locale,
        country,
        visitorId,
        isRemoteEnabled: remoteSearchFilterEnabled,
      },
    });
  };

  const getRecentSearchWhere = useCallback(() => {
    const latestRecentSearch =
      searches && searches.length > 0 ? searches[0] : undefined;
    const recentWhereLocation = latestRecentSearch?.query?.where || '';

    return recentWhereLocation;
  }, [searches]);

  const getSuggestions = ({ text = '' }) => {
    const query = text.trim();
    const recentSearchLocation = getLastSearch() || getRecentSearchWhere();
    setLocation({ current: query, recent: recentSearchLocation });

    if (query === '') {
      if (!personalisedSuggestionsCache[recentSearchLocation]) {
        fetchWhereSuggestions({
          recentSearchLocation,
        });
      } else {
        setSuggestions(
          () => personalisedSuggestionsCache[recentSearchLocation],
        );
      }
    } else if (suggestionsCache[query]) {
      setSuggestions(suggestionsCache[query]);
    } else {
      fetchWhereSuggestions({
        query,
      });
    }
  };

  return {
    getSuggestions,
    suggestions,
  };
};

export default useLocationSuggestions;
