import type { SalaryType, SearchParams } from '@seek/chalice-types';
import { useMemo, useState } from 'react';

import { getRefineSearchQuery } from 'src/components/Search/utils/utils';
import { useSearchSalaryRanges } from 'src/hooks/useSearchSalaryRanges';
import { useSelector } from 'src/store/react';
import { selectSearchQuery } from 'src/store/selectors';

import { useSecondaryFilterContext } from '../../context/SecondaryFilterContext';

export interface SalaryFields {
  salaryFromValue?: string;
  salaryToValue?: string;
}

interface SalaryRangeFields {
  label: string;
  value: string;
  isActive: boolean;
  refineParams?: SearchParams;
}

export interface SalaryRange {
  id: string;
  label: string;
  ranges: SalaryRangeFields[];
  labelSelection?: string;
  frequency: SalaryType;
}

export const useSalaryRange = () => {
  const { filterSelection } = useSecondaryFilterContext();
  const { searchSalaryRanges: data, defaultCurrency } = useSearchSalaryRanges();

  const searchQuery = getRefineSearchQuery(useSelector(selectSearchQuery));
  const { salaryrange, salarytype, ...searchQueryWithoutSalary } = searchQuery;

  const defaultFrequency = (data.find((item) => item.frequency.isDefault)
    ?.frequency.type ?? 'annual') as SalaryType;

  const frequency = filterSelection?.salarytype ?? defaultFrequency;

  const selectedSalaryFrequencyRanges = data.find(
    ({ frequency: { type } }) => type === frequency,
  )?.range;

  const defaultSalaryRangeFrom = selectedSalaryFrequencyRanges?.[0];
  const defaultSalaryRangeTo =
    selectedSalaryFrequencyRanges?.[selectedSalaryFrequencyRanges.length - 1];

  const [salarySelected, setSalarySelected] = useState<SalaryFields>({
    salaryFromValue: defaultSalaryRangeFrom?.value,
    salaryToValue: defaultSalaryRangeTo?.value,
  });

  const selectedSalaryRangeFromValue = salarySelected.salaryFromValue;
  const selectedSalaryRangeFromIndex =
    selectedSalaryFrequencyRanges?.findIndex(
      ({ value }) => value === selectedSalaryRangeFromValue,
    ) ?? -1;

  const activeSalaryRangeFromValue =
    selectedSalaryRangeFromValue && selectedSalaryRangeFromValue > '0'
      ? selectedSalaryRangeFromValue
      : defaultSalaryRangeFrom?.value;

  const selectedSalaryRangeToValue = salarySelected.salaryToValue;
  const selectedSalaryRangeToIndex =
    selectedSalaryFrequencyRanges?.findIndex(
      ({ value }) => value === selectedSalaryRangeToValue,
    ) ?? -1;

  const activeSalaryRangeToValue =
    selectedSalaryRangeToValue && selectedSalaryRangeToValue > '0'
      ? selectedSalaryRangeToValue
      : defaultSalaryRangeTo?.value;

  const salariesFrom: SalaryRangeFields[] = useMemo(
    () =>
      data
        .find(({ frequency: { type } }) => type === frequency)
        ?.range.slice(0, -1)
        .map(({ longLabel, value }, index) => {
          const isActive = value === activeSalaryRangeFromValue;

          return {
            label: longLabel,
            value,
            isActive,
            refineParams:
              index < selectedSalaryRangeToIndex ||
              selectedSalaryRangeToIndex === -1
                ? {
                    ...searchQueryWithoutSalary,
                    salarytype: frequency,
                    salaryrange: `${value}-${selectedSalaryRangeToValue ?? ''}`,
                  }
                : undefined,
          };
        }) || [],
    [
      data,
      frequency,
      activeSalaryRangeFromValue,
      selectedSalaryRangeToIndex,
      searchQueryWithoutSalary,
      selectedSalaryRangeToValue,
    ],
  );

  const salariesTo: SalaryRangeFields[] = useMemo(
    () =>
      data
        .find(({ frequency: { type } }) => type === frequency)
        ?.range.slice(1)
        .map(({ longLabel, value }, index) => {
          const isActive = value === activeSalaryRangeToValue;

          return {
            label: longLabel,
            value,
            isActive,
            refineParams:
              index >= selectedSalaryRangeFromIndex
                ? {
                    ...searchQueryWithoutSalary,
                    salarytype: frequency,
                    salaryrange: `${
                      selectedSalaryRangeFromValue ??
                      defaultSalaryRangeFrom?.value
                    }-${value}`,
                  }
                : undefined,
          };
        }) || [],
    [
      data,
      frequency,
      activeSalaryRangeToValue,
      selectedSalaryRangeFromIndex,
      searchQueryWithoutSalary,
      selectedSalaryRangeFromValue,
      defaultSalaryRangeFrom?.value,
    ],
  );

  const fromLabel =
    selectedSalaryFrequencyRanges?.[selectedSalaryRangeFromIndex]?.longLabel ??
    defaultSalaryRangeFrom?.longLabel;
  const toLabel =
    selectedSalaryFrequencyRanges?.[selectedSalaryRangeToIndex]?.longLabel ??
    defaultSalaryRangeTo?.longLabel;

  const salaryRanges = [
    {
      id: 'salaryFrom',
      label: 'From',
      ranges: salariesFrom,
      labelSelection: fromLabel,
      frequency,
      selectedSalaryLabel:
        selectedSalaryFrequencyRanges?.[selectedSalaryRangeFromIndex]
          ?.longLabel || defaultSalaryRangeFrom?.longLabel,
    },
    {
      id: 'salaryTo',
      label: 'To',
      ranges: salariesTo,
      labelSelection: toLabel,
      frequency,
      selectedSalaryLabel:
        selectedSalaryFrequencyRanges?.[selectedSalaryRangeToIndex]
          ?.longLabel || defaultSalaryRangeTo?.longLabel,
    },
  ];

  return {
    salaryRanges,
    salarySelected,
    setSalarySelected,
    defaultSalaryRangeFrom,
    defaultSalaryRangeTo,
    frequency,
    searchQueryWithoutSalary,
    defaultFrequency,
    defaultCurrency,
    data,
  };
};
