import { gql, useQuery } from '@apollo/client';
import type { JobTag } from '@seek/chalice-types';
import { useHubble } from '@seek/hubble';
import { type ComponentProps, useMemo } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import {
  type GetHomeRecommendationsQuery,
  type RecommendedGlobalJobFragmentFragment,
  GetHomeRecommendationsDocument,
} from 'src/graphql/graphql';
import { useTimezone } from 'src/hooks/useTimezone';
import { useSelector } from 'src/store/react';
import { selectSmarterSearchCluster } from 'src/store/selectors';
import type { ApolloCacheType } from 'src/types/apollo';

import type JobCard from '../SharedComponent/JobCard/JobCard';

export const TOP_SECTION_LIMIT = 3;
export const RECOMMENDATIONS_LIMIT = 50;

export type RecommendedJobs = NonNullable<
  NonNullable<
    NonNullable<GetHomeRecommendationsQuery['viewer']>['recommendations']
  >['recommendedGlobalJobs']
>;

const mapRecommendedJobs = (
  jobs?: RecommendedJobs['globalJobs'],
): Array<ComponentProps<typeof JobCard>['jobInfo']> =>
  jobs?.map((job) => {
    const {
      job: { advertiser, id, listedAt, location, products, salary, title },
      solMetadata,
      tags,
      isSaved,
    } = job;

    return {
      advertiserName: advertiser.name,
      id,
      isSaved,
      listingDateLabel: listedAt?.label,
      locationLabel: location.label,
      salaryCurrencyLabel: salary?.currencyLabel ?? undefined,
      salaryLabel: salary?.label,
      solMetadata,
      srcLogo: products?.branding?.logo?.url,
      tags: (tags as JobTag[]) ?? undefined,
      title,
    };
  }) || [];

export const useHomeRecommendations = () => {
  const { zone, locale } = useAppConfig();
  const hubble = useHubble();
  const visitorId = hubble.visitorId();
  const smarterSearchCluster = useSelector(selectSmarterSearchCluster);
  const timezone = useTimezone();

  const { data, loading, error } = useQuery(GetHomeRecommendationsDocument, {
    variables: {
      zone,
      visitorId,
      limit: RECOMMENDATIONS_LIMIT,
      timezone,
      cluster: smarterSearchCluster,
      locale,
    },
    ssr: false,
  });

  const recommendedJobs = data?.viewer?.recommendations?.recommendedGlobalJobs;
  const jobs = useMemo(
    () => mapRecommendedJobs(recommendedJobs?.globalJobs),
    [recommendedJobs?.globalJobs],
  );
  const topJobs = useMemo(() => jobs?.slice(0, TOP_SECTION_LIMIT), [jobs]);
  const moreJobs = useMemo(() => jobs?.slice(TOP_SECTION_LIMIT), [jobs]);

  return {
    rawRecommendedJobs: recommendedJobs,
    topJobs,
    moreJobs,
    loading,
    solMetadata: recommendedJobs?.solMetadata,
    error,
  };
};

export const useUpdateRecommendedGlobalJobCache = (jobId: string) => {
  const update = (isSaved: boolean) => (cache: ApolloCacheType) => {
    const targetJob = cache.readFragment<RecommendedGlobalJobFragmentFragment>({
      id: `RecommendedGlobalJob:${jobId}`,
      fragment: gql`
        fragment RecommendedGlobalJobFragment on RecommendedGlobalJob {
          id
          isSaved
        }
      `,
    });

    if (!targetJob || targetJob.isSaved === isSaved) {
      return;
    }

    cache.modify({
      id: cache.identify(targetJob),
      fields: {
        isSaved(_originalValue: boolean) {
          return isSaved;
        },
      },
    });
  };

  return { setSaved: update(true), setUnsave: update(false) };
};
