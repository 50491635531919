import { ResizeObserver } from '@juggle/resize-observer';
import { useTranslations } from '@vocab/react';
import {
  Box,
  HiddenVisually,
  IconChevron,
  IconClear,
  Text,
} from 'braid-design-system';
import { useCallback, useEffect, useRef, useState } from 'react';
// @ts-expect-error no type defs
import IsolatedScroll from 'react-isolated-scroll';

import NavLink from 'src/components/NavLink/NavLink';
import type { RefinementClassification } from 'src/modules/refine-job-search/lib/types';
import { isClientMobileWidth } from 'src/modules/responsive-helpers';
import { scrollTo } from 'src/modules/scroll-with-callback';
import useIsomorphicLayoutEffect from 'src/modules/useIsomorphicLayoutEffect';
import { useSelector } from 'src/store/react';
import type { RefinementType } from 'src/types/globals';

import translations from './.vocab';
import ClassificationList from './ClassificationList/ClassificationList';

import * as styles from './ClassificationsField.css';

const shouldShowCloseButton = (isOpen: boolean, isMobileWidth: boolean) =>
  !(isOpen && isMobileWidth);

export const correctBackdropPosition = (ref: null | HTMLElement) => {
  if (ref) {
    ref.style.left = '0px';
    ref.style.top = '0px';

    const { left, top } = ref.getBoundingClientRect();
    ref.style.left = `${left >= 0 ? '-' : ''}${left}px`;
    ref.style.top = `${top >= 0 ? '-' : ''}${top}px`;
  }
};

interface ClassificationsFieldProps {
  collapsed: boolean;
  classifications: Array<RefinementClassification | RefinementType>;
  selectedClassifications: Array<RefinementClassification | RefinementType>;
  isSearchInView?: boolean;
}

const ClassificationsField = (props: ClassificationsFieldProps) => {
  const {
    collapsed,
    classifications,
    selectedClassifications,
    isSearchInView,
  } = props;
  const { t } = useTranslations(translations);
  const backdropEl = useRef<HTMLDivElement | null>(null);
  const classificationsToggleEl = useRef<HTMLInputElement | null>(null);

  const searchQuery = useSelector((state) => state.search.query);
  const clearClassification = useSelector(
    (state) => state.search.refinements.clear?.classification,
  );

  const [classificationListOpen, setClassificationListOpen] = useState(false);

  useIsomorphicLayoutEffect(() => {
    correctBackdropPosition(backdropEl.current);
  }, [backdropEl]);

  const openClassificationList = useCallback(() => {
    setClassificationListOpen(true);
    if (isClientMobileWidth() && classificationsToggleEl.current !== null) {
      const yScroll =
        classificationsToggleEl.current.getBoundingClientRect().top +
        window.scrollY;

      scrollTo({ top: yScroll - 10 });
    }
  }, [classificationsToggleEl]);

  const toggleClassificationList = useCallback(() => {
    if (classificationListOpen) {
      setClassificationListOpen(false);
    } else {
      openClassificationList();
    }
  }, [classificationListOpen, openClassificationList]);

  const onClearClassificationClick = useCallback((event: MouseEvent) => {
    event.stopPropagation();
    if (classificationsToggleEl.current) {
      classificationsToggleEl.current.focus();
    }
  }, []);

  const onClassificationListKeyUp = useCallback(() => {
    if (classificationsToggleEl.current) {
      classificationsToggleEl.current.click();
      classificationsToggleEl.current.focus();
    }
  }, [classificationsToggleEl]);

  const refinePath = collapsed ? '/jobs' : '/';

  const onResize = useCallback(() => {
    correctBackdropPosition(backdropEl.current);
  }, [backdropEl]);

  const observerCallback: ResizeObserverCallback = useCallback(
    (entries: ResizeObserverEntry[]) => {
      window.requestAnimationFrame((): void | undefined => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        onResize();
      });
    },
    [onResize],
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver(observerCallback);
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.unobserve(document.body);
    };
  }, [observerCallback]);

  useEffect(() => {
    if (isSearchInView === false) {
      setClassificationListOpen(false);
    }
  }, [isSearchInView]);

  return (
    <Box
      role="region"
      aria-label={t('Job Classifications')}
      data-automation="job-classifications"
      position="relative"
      background="surface"
      borderRadius="standard"
      className={styles.fieldBackgroundOverride}
    >
      <Box
        component="input"
        id="ClassificationsField__Classifications"
        className={styles.toggle}
        type="checkbox"
        checked={classificationListOpen}
        aria-expanded={classificationListOpen}
        aria-label={t('show classification list')}
        onChange={toggleClassificationList}
        ref={classificationsToggleEl}
      />
      <Box
        ref={backdropEl}
        component="label"
        data-automation="classificationDropDownListBackdrop"
        htmlFor="ClassificationsField__Classifications"
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        className={styles.backdrop}
      >
        <HiddenVisually>{t('Hide classifications')}</HiddenVisually>
      </Box>

      <Box
        component="label"
        data-automation="classificationDropDownList"
        htmlFor="ClassificationsField__Classifications"
        background="surface"
        className={[styles.field, styles.fieldBackgroundOverride]}
      >
        <HiddenVisually>{t('Show classification list.')}</HiddenVisually>

        <Text
          baseline={false}
          tone={selectedClassifications.length === 0 ? 'secondary' : undefined}
        >
          <Box
            component="span"
            className={[
              styles.text,
              clearClassification ? styles.shortenForClearLink : undefined,
            ]}
          >
            {{
              0: t('Any Classification'),
              1: selectedClassifications[0]
                ? selectedClassifications[0].label
                : '',
            }[selectedClassifications.length] ||
              `${selectedClassifications.length} ${t('Classifications')}`}
          </Box>
        </Text>

        <Box
          display="flex"
          alignItems="center"
          transition="fast"
          position="absolute"
          right={0}
          top={0}
          pointerEvents={clearClassification ? undefined : 'none'}
          className={
            clearClassification &&
            shouldShowCloseButton(classificationListOpen, isClientMobileWidth())
              ? undefined
              : styles.hideClearLink
          }
        >
          <Box
            display={{
              mobile: classificationListOpen ? 'block' : 'none',
              tablet: 'none',
            }}
            pointerEvents="none"
            transition="fast"
            opacity={!classificationListOpen ? 0 : undefined}
            className={styles.done}
          >
            <Text tone="formAccent" weight="medium" baseline={false}>
              {t('Done')}
            </Text>
          </Box>

          <Box
            component="span"
            display={{
              mobile: classificationListOpen ? 'none' : 'flex',
              tablet: 'flex',
            }}
            justifyContent="center"
            alignItems="center"
            height="touchable"
            paddingLeft="small"
            paddingRight={
              selectedClassifications.length === 0 ? 'small' : undefined
            }
          >
            <Text tone="secondary" baseline={false}>
              <IconChevron
                direction={classificationListOpen ? 'up' : 'down'}
                alignY="lowercase"
              />
            </Text>
          </Box>

          <NavLink
            title={t('Clear classifications')}
            className={styles.clearLink}
            location={{
              pathname: refinePath,
              query: clearClassification || searchQuery,
            }}
            aria-hidden={!clearClassification}
            aria-label={t('Clear classifications')}
            tabIndex={
              clearClassification &&
              shouldShowCloseButton(
                classificationListOpen,
                isClientMobileWidth(),
              )
                ? 0
                : -1
            }
            data-automation="clearAllClassifications"
            rel="nofollow"
            onClick={onClearClassificationClick}
          >
            <Text tone="secondary">
              <IconClear />
            </Text>
          </NavLink>
        </Box>
      </Box>

      <IsolatedScroll
        id="classificationsPanel"
        className={styles.classificationsPanel}
      >
        <ClassificationList
          handleClose={onClassificationListKeyUp}
          path={refinePath}
          classifications={classifications}
          open={classificationListOpen}
        />
      </IsolatedScroll>

      <Box
        component="span"
        boxShadow="borderField"
        className={styles.borderOverlay}
      />
      <Box
        component="span"
        boxShadow="outlineFocus"
        className={styles.focusOverlay}
      />
      <Box
        component="span"
        boxShadow="borderFormAccent"
        className={styles.hoverOverlay}
      />
    </Box>
  );
};

export default ClassificationsField;
