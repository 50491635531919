export const timeoutPromise = (
  promise: Promise<any>,
  ms: number,
): Promise<any> => {
  // Create a promise that rejects in <ms> milliseconds
  const timeout = new Promise((_, reject) => {
    const id = setTimeout(() => {
      reject({ isTimedOut: true });
    }, ms);
    promise.then(() => clearTimeout(id));
  });
  // Returns a race between our timeout and the passed in promise
  return Promise.race([promise, timeout]);
};

export const tryPromise = (func: () => any) =>
  new Promise((resolve, reject) => {
    try {
      const val = func();
      resolve(val);
    } catch (error) {
      reject(error);
    }
  });
