export const generateMetaDescription = (content: string) => [
  { name: 'description', content },
  { property: 'og:description', content },
  { property: 'twitter:description', content },
];

export const generateMetaTitle = (title: string) => [
  { property: 'og:title', content: title },
  { property: 'twitter:title', content: title },
];
