/**
 * Wraps react-helmet with some security features.
 */
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import { Helmet } from 'react-helmet-async';
import striptags from 'striptags';

interface Meta {
  name?: string;
  content: string;
  property?: string;
}
export interface SafetyHelmetProps {
  title?: string;
  link?: Array<{ rel: string; href: string; hreflang?: string }>;
  meta?: Meta[];
  script?: Array<{ type: string; innerHTML: string }>;
}

const stripObject = (o: any) => mapValues(o, (v) => striptags(v));
const stripArray = (a: any[]) => map(a, stripObject);
const omitEmpties = (o: any) => omitBy(o, isEmpty);

export const stripProps = (props: SafetyHelmetProps) => {
  if (isNil(props)) {
    return {};
  }
  const title = props.title ? striptags(props.title) : null;
  const link = props.link ? stripArray(props.link) : null;
  const meta = props.meta ? stripArray(props.meta) : null;
  const script = props.script ? stripArray(props.script) : null;
  return omitEmpties({ title, link, meta, script });
};

export const SafetyHelmet = (props: SafetyHelmetProps) => (
  <Helmet {...stripProps(props)} />
);
