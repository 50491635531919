import { useTranslations } from '@vocab/react';
import { Box, Divider, HiddenVisually, Text } from 'braid-design-system';
import { useSelector } from 'react-redux';

import { Link } from 'src/components/NavLink/NavLink';
import { selectSearchQuery } from 'src/store/selectors';
import type { Option } from 'src/types/globals';

import { getRefineSearchQuery } from '../../utils/utils';

import translations from './.vocab';

import * as styles from './SalaryTypeGraph.css';

interface SalaryTypeProps {
  salaryTypes: Option[];
}

const SalaryType = (props: SalaryTypeProps) => {
  const { t } = useTranslations(translations);
  const { salaryTypes, ...restProps } = props;
  const searchQuery = getRefineSearchQuery(useSelector(selectSearchQuery));

  return (
    <Box component="section" {...restProps}>
      <HiddenVisually>
        <h1>{t('Salary type')}</h1>
      </HiddenVisually>

      <Box
        component="ul"
        role="radiogroup"
        display="flex"
        className={styles.scrollable}
      >
        {salaryTypes?.map(({ isActive, value, label, refineParams }) => (
          // We always provide a link here, even for the selected item
          // so screen readers maintain radio button selection context
          <Box
            component="li"
            className={{
              [styles.item]: true,
              [styles.active]: isActive,
            }}
            key={value}
            role="presentation"
          >
            <Text tone={isActive ? 'formAccent' : 'secondary'}>
              <Link
                role="radio"
                aria-label={t('Salary type, label', { label })}
                aria-checked={isActive}
                location={
                  isActive
                    ? {
                        pathname: '/jobs',
                        query: searchQuery,
                        hash: '#',
                      }
                    : {
                        pathname: '/jobs',
                        query: refineParams,
                      }
                }
                data-automation={isActive ? 'refineActiveSalaryType' : ''}
              >
                {label}
              </Link>
            </Text>
          </Box>
        ))}
      </Box>
      <Box className={styles.dividerContainer}>
        <Divider />
      </Box>
    </Box>
  );
};

export default SalaryType;
