import {
  Stack,
  Columns,
  Column,
  Box,
  Text,
  ContentBlock,
  Heading,
  Link,
  useResponsiveValue,
} from 'braid-design-system';

import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';

import * as styles from './CareerArticles.css';

interface ArticleCardProps {
  image: string;
  title: string;
  url: string;
  description?: string;
  index?: number;
}

const minorCareerAdviceArticles = [
  {
    title: 'Our Comprehensive Guide On How To Work Abroad',
    image:
      'https://media.graphassets.com/resize=fit:crop,width:302,height:160/OFySV2uOTX6AK8Jky1qX',
    url: 'https://ph.jobstreet.com/career-advice/article/8-steps-on-how-to-work-overseas',
  },
  {
    title: '10 Best Countries To Work Abroad, For Global And Filing',
    image:
      'https://media.graphassets.com/resize=fit:crop,width:302,height:160/3BHLHLrlToHECufwuyj0',
    url: 'https://ph.jobstreet.com/career-advice/article/finding-jobs-abroad-top-10-most-attractive-countries-for-the-global-workforce-infographic',
  },
  {
    title: 'Looking To Work Overseas? In Demand Jobs Abroad',
    image:
      'https://media.graphassets.com/resize=fit:crop,width:302,height:160/k7yZO6oSAasm5L8AO7gu',
    url: 'https://ph.jobstreet.com/career-advice/article/workabroad-ph-sea-based-engineers-healthcare-professionals-are-highly-paid-overseas',
  },
  {
    title: "Pinoy Working Abroad: What It's Like And How To Prepare",
    image:
      'https://media.graphassets.com/resize=fit:crop,width:302,height:160/IQhiVXSyTB221GUCs2KD',
    url: 'https://ph.jobstreet.com/career-advice/article/everything-you-need-to-know-about-working-abroad',
  },
];

const leadCareerAdviceArticle = {
  title:
    'POEA answers top 10 questions to help ensure safe, real overseas jobs',
  description:
    'Top 10 FAQs from the POEA to help ensure safe, real overseas jobs.',
  image:
    'https://media.graphassets.com/resize=fit:crop,width:1280,height:660/XI85oYkKTpK2G07b68Vb',
  url: 'https://ph.jobstreet.com/career-advice/article/poea-answers-top-10-questions-to-help-ensure-safe-real-overseas-jobs',
};

const ArticleCard = ({ image, title, url, index }: ArticleCardProps) => {
  const analyticsFacade = useAnalyticsFacade();

  return (
    <Link
      href={url}
      onClick={() => {
        analyticsFacade.poeaLinksPressed({
          currentPage: 'country picker',
          linkText: title,
          linkAction: 'career advice article',
          linkPosition: 'body',
        });
      }}
      data={{ automation: `poea-country-picker-career-article-${index}` }}
    >
      <Box
        background="body"
        boxShadow="borderNeutralLight"
        borderRadius="large"
        overflow="hidden"
      >
        <Stack space="xxsmall">
          <Box component="img" width="full" height="full" src={image} />
          <Box padding="medium" overflow="hidden">
            <Text weight="strong" maxLines={2}>
              {title}
            </Text>
          </Box>
        </Stack>
      </Box>
    </Link>
  );
};

const WideArticleCard = ({
  image,
  title,
  url,
  description,
}: ArticleCardProps) => {
  const analyticsFacade = useAnalyticsFacade();

  return (
    <Link
      href={url}
      onClick={() => {
        analyticsFacade.poeaLinksPressed({
          currentPage: 'country picker',
          linkText: title,
          linkAction: 'career advice article',
          linkPosition: 'body',
        });
      }}
    >
      <Box
        background="body"
        boxShadow="borderNeutralLight"
        borderRadius="large"
        overflow="hidden"
      >
        <Columns space="small">
          <Column width="3/5">
            <Box component="img" src={image} width="full" height="full" />
          </Column>
          <Column>
            <Box padding="medium">
              <Stack space="large">
                <Heading level="4">{title}</Heading>
                <Text>{description}</Text>
              </Stack>
            </Box>
          </Column>
        </Columns>
      </Box>
    </Link>
  );
};

const CareerArticles = () => {
  const responsiveValue = useResponsiveValue();
  const isMobile = responsiveValue({
    mobile: true,
    tablet: false,
    desktop: false,
  });
  return (
    <Box paddingX="xxlarge" paddingY="small">
      <ContentBlock width="large">
        <Box paddingBottom="large">
          <Heading level="3">Articles to help you prepare</Heading>
        </Box>
        <Stack space="large">
          {!isMobile && <WideArticleCard {...leadCareerAdviceArticle} />}
          <Box overflow="auto" className={styles.cardGroup}>
            <Box style={{ width: '1280px' }}>
              <Columns space="small">
                {(isMobile
                  ? [leadCareerAdviceArticle, ...minorCareerAdviceArticles]
                  : minorCareerAdviceArticles
                ).map(({ image, title, url }, index) => (
                  <Column width="1/4" key={index}>
                    <Box style={{ scrollSnapAlign: 'start' }}>
                      <ArticleCard
                        image={image}
                        title={title}
                        url={url}
                        index={index}
                      />
                    </Box>
                  </Column>
                ))}
              </Columns>
            </Box>
          </Box>
        </Stack>
      </ContentBlock>
    </Box>
  );
};

export default CareerArticles;
