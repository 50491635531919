import { Box, HiddenVisually } from 'braid-design-system';

import { SCREEN_READER_ANNOUNCER_ID } from 'src/utils/a11y/announce';

export const ScreenReaderAnnouncer = () => (
  <HiddenVisually>
    <Box
      aria-live="polite"
      component="span"
      data-automation={SCREEN_READER_ANNOUNCER_ID}
      id={SCREEN_READER_ANNOUNCER_ID}
      outline="none"
      role="status"
    />
  </HiddenVisually>
);
