import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import type {
  Banner,
  BannerPlacement,
  GetBannerQuery,
  GetBannerQueryVariables,
} from 'src/graphql/graphql';
import { GET_BANNER } from 'src/modules/graphql/queries/banner';
import { logger } from 'src/modules/logger';
import { isUuid } from 'src/utils/uuid';

import { getRoleIdFromResults } from '../results/resultsHelpers';
import type { TypedAction, TypedThunkAction } from '../types';

import {
  GET_BANNER_ERROR,
  GET_BANNER_SUCCESS,
  type BannerState,
} from './types';

const initialState: BannerState = { template: { items: [] }, error: null };

interface GetBannerArgs {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  placement: BannerPlacement;
  visitorId?: string;
}

export const getBanner =
  ({ apolloClient, placement, visitorId }: GetBannerArgs): TypedThunkAction =>
  async (dispatch, getState) => {
    const {
      appConfig: { country, locale, zone },
      search,
      user: { authenticated, seekerId },
      results,
    } = getState();

    const roleId = getRoleIdFromResults(results);
    try {
      const response = await apolloClient.query<
        GetBannerQuery,
        GetBannerQueryVariables
      >({
        query: GET_BANNER,
        variables: {
          placement,
          country,
          locale,
          zone,
          roleId,
          visitorId: isUuid(visitorId) ? visitorId : undefined,
          candidateId: seekerId?.toString(),
          loggedIn: authenticated?.toString(),
          ...search.query,
        },
        errorPolicy: 'all',
      });

      const banner = response?.data?.banner as Banner;
      const errors = response?.errors;

      if (errors && errors.length) {
        for (const error of errors) {
          if (!banner) {
            logger.error(
              { err: error, input: { placement, country } },
              'Banner GraphQL Error',
            );
          }

          logger.error(
            { err: error, input: { placement, country } },
            'Banner GraphQL Partial Failure',
          );
        }
      }

      dispatch({
        type: GET_BANNER_SUCCESS,
        payload: banner,
      });
    } catch (error) {
      dispatch({
        type: GET_BANNER_ERROR,
        payload: {
          error: error instanceof Error ? error.message : String(error),
        },
      });
    }
  };

export default function reducer(
  state: BannerState = initialState,
  action: TypedAction,
): BannerState {
  switch (action.type) {
    case GET_BANNER_SUCCESS: {
      const { payload } = action;
      return {
        ...payload,
        error: null,
      };
    }

    case GET_BANNER_ERROR: {
      const { error } = action.payload;
      return {
        ...initialState,
        error,
      };
    }

    default:
      return state;
  }
}
