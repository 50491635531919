import createTrackingClient, {
  createEmptyClient,
} from '@seek/seek-impression-tracking';
import { parse as parseCookies } from 'cookie';

import { jobTrackingEnabled, jobTrackingUrl } from 'src/config';
import { logger } from 'src/modules/logger';

const sessionId = ENV.CLIENT
  ? parseCookies(document.cookie).JobseekerSessionId
  : null;

const client =
  ENV.CLIENT && jobTrackingEnabled
    ? createTrackingClient(jobTrackingUrl, {
        params: sessionId ? { sessionId } : undefined,
        errorLogger: logger.error.bind(logger, {}, 'Impression tracking: '),
      })
    : createEmptyClient();

export default client;
