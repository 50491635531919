import { logger } from 'src/modules/logger';

export const getSearchParams = (source?: string | Record<string, string>) => {
  const searchParams =
    typeof window === 'undefined' ? '' : window.location?.search;
  return new URLSearchParams(source || searchParams);
};

export const getParam = (param: string | string[], url?: string) => {
  const params = getSearchParams(url);
  if (Array.isArray(param)) {
    return params.get(param.find((p) => params.get(p)) || '') || '';
  }
  return params.get(param) || '';
};

/**
 * @param paramsPair an object of key value pairs to push to the url.
 *
 * @description this function will push the given params to the url without changing the existing
 * params. It doesn't update the browser history stack as it replaces the state with the new params.
 */
export const pushParams = (paramsPair: Record<string, string>) => {
  if (typeof window === 'undefined' || Object.keys(paramsPair).length === 0) {
    return;
  }

  const searchParams = getSearchParams();

  Object.entries(paramsPair).forEach(([key, value]) => {
    searchParams.set(key, value);
  });

  navigateWithParams(searchParams);
};

/**
 * @param paramsKeys an array of keys to remove from the url.
 *
 * @description this function will remove the given params from the url without changing the existing
 * params. It doesn't update the browser history stack as it replaces the state without the removed params.
 */
export const removeParams = (paramsKeys: string[]) => {
  if (typeof window === 'undefined' || paramsKeys.length === 0) {
    return;
  }

  const searchParams = getSearchParams();
  paramsKeys.forEach((param) => {
    searchParams.delete(param);
  });

  navigateWithParams(searchParams);
};

const navigateWithParams = (params: URLSearchParams) => {
  if (typeof window === 'undefined') {
    return;
  }

  try {
    const url = new URL(window.location.href);
    url.search = params.toString();

    window.history.replaceState({}, '', url.toString());
  } catch (error) {
    logger.error(error as Error, 'Failed to update URL params');
  }
};
