import type { SearchParams } from '@seek/chalice-types';
import omit from 'lodash/omit';

export default (searchParams: SearchParams) => {
  if (searchParams.companyid || searchParams.companyname) {
    return {
      company: omit(searchParams, ['companyid', 'companyname']),
    };
  }

  // Signal that it isn't possible to clear a company right now
  return {};
};
