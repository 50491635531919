import { AppConfigProvider, useMakeAppConfigContext } from './appConfig';
import type { AppConfig } from './types';

export const AppConfigProviderWrapper = ({
  children,
  config,
}: {
  children: React.ReactNode;
  config: AppConfig;
}) => (
  <AppConfigProvider value={useMakeAppConfigContext(config)}>
    {children}
  </AppConfigProvider>
);
