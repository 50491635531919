const BLOCKING_TIMEOUT = 9000;
import { isProduction } from 'src/config';
import { logger } from 'src/modules/logger';

import { START_TRANSACTION, END_TRANSACTION } from './actions';

// data for transaction and timeout information
let timeout = null;
let currentTransaction = null;

// Utility functions
const contains = (array, item) => array.indexOf(item) > -1;

// redux-devtools wraps actions in PERFORM_ACTION actions
function unwrapDevtoolsAction(action) {
  return (action.type === 'PERFORM_ACTION' && action.action) || action;
}

function getAllowedActions() {
  const { allowedActions } = currentTransaction;
  return allowedActions.concat([START_TRANSACTION, END_TRANSACTION]);
}

function isAllowedAction(type) {
  return contains(getAllowedActions(), type);
}

export function getTransactionInfo(action) {
  const { meta, type } = unwrapDevtoolsAction(action);
  if (!meta) {
    return { type };
  }
  const { transaction } = meta;
  return {
    transaction,
    type,
  };
}

export function endBlocking() {
  currentTransaction = null;
  clearTimeout(timeout);
}

export function startBlocking(newTransaction) {
  currentTransaction = newTransaction;
  if (timeout) {
    clearTimeout(timeout);
  }
  timeout = setTimeout(() => {
    endBlocking();
  }, BLOCKING_TIMEOUT);
}

export function warnBecauseNotAllowedInCurrentTransaction(type) {
  if (!currentTransaction || isAllowedAction(type)) {
    return false;
  }

  if (!isProduction) {
    logger.warn(
      {},
      `Transaction '${currentTransaction.name}' was interrupted prematurely by unexpected action of type: '${type}'. Please ensure this action type is included within the allowedActions field of the transaction definition.`,
    );
  }

  return true;
}

export function shouldNotify() {
  return !currentTransaction;
}
