export const GET_LMIS_SUCCESS = 'GET_LMIS_SUCCESS';
export const GET_LMIS_ERROR = 'GET_LMIS_ERROR';
export const RESET_JDP_LMIS = 'RESET_JDP_LMIS';

export enum pageKeys {
  SRP = 'SRP',
  JDP = 'JDP',
}

// Fix me
export type LmisType = 'contextual' | 'generic' | 'fallback';
export interface Lmis {
  content?: string;
  lmisResultType?: LmisType;
  lmisEntity?: string;
  lmisSnippet?: Record<string, string>;
}

export interface LmisState {
  SRP: Lmis;
  JDP: Lmis;
}

interface LmisSuccessAction {
  type: typeof GET_LMIS_SUCCESS;
  payload: {
    content?: string;
    lmisSnippet?: Record<string, string> | null;
    key: string;
  };
}

export type LmisAction =
  | LmisSuccessAction
  | {
      type: typeof RESET_JDP_LMIS;
    }
  | {
      type: typeof GET_LMIS_ERROR;
      payload: { key: string };
    };
