import { useEffect } from 'react';

import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';

export const useHomeTracking = () => {
  const analyticsFacade = useAnalyticsFacade();

  useEffect(() => {
    analyticsFacade.homePageLoaded({
      flightId: undefined,
    });
  }, [analyticsFacade]);
};
