import gql from 'graphql-tag';

export const GET_SALARY_NUDGE = gql`
  query GetSalaryNudge($zone: String!) {
    viewer {
      salaryNudge(zone: $zone) {
        callToActions {
          displayText
          operation
        }
        firstName
        id
        imageUrl
        prompt
        text
      }
    }
  }
`;

export const ADD_SALARY_NUDGE_FEEDBACK = gql`
  mutation AddSalaryNudgeFeedback(
    $nudgeId: ID!
    $nudgeOption: String!
    $zone: String!
  ) {
    addSalaryNudgeFeedback(
      nudgeId: $nudgeId
      nudgeOption: $nudgeOption
      zone: $zone
    ) {
      nudgeId
    }
  }
`;
