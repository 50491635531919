export const isGooglebotMobile = (ua: string) =>
  /Mobile.*Googlebot.*http:\/\/www.google.com\/bot.html/.test(ua);

export const isGooglebotDesktop = (ua: string) =>
  !isGooglebotMobile(ua) &&
  /Googlebot.*http:\/\/www.google.com\/bot.html/.test(ua);

export const isGooglebotOther = (ua: string) =>
  /Google-InspectionTool/.test(ua);

export const isGooglebot = (ua: string): boolean =>
  isGooglebotMobile(ua) || isGooglebotDesktop(ua) || isGooglebotOther(ua);
