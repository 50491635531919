import type createStore from '../createStore';

/**
 * Resolves either the login id or undefined if not logged in
 * For sync checking, use selectLoginId(state)
 * @param store Redux store
 */
export function getLoginIdFromStore(
  store: ReturnType<typeof createStore>,
): Promise<string | undefined> {
  const state = store.getState();
  // optimistic logging for pending state is set to true
  return state.user.authenticated === true
    ? new Promise((resolve) => {
        // User is authenticated but no id exists. Subscribe for changes and wait for either not authenticated or id exists
        const unsubscribe = store.subscribe(() => {
          const newState = store.getState();
          if (newState.user.authenticated === false) {
            // User is not authenticated
            resolve(undefined);
            unsubscribe();
          } else if (newState.user.seekerId) {
            // Id found
            resolve(newState.user.seekerId.toString());
            unsubscribe();
          }
        });
      })
    : Promise.resolve(undefined);
}
