
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiQ2hvb3NlIGEgcmVtb3RlIG9wdGlvbiIsIndvcmsgYXJyYW5nZW1lbnQiOiJ3b3JrIGFycmFuZ2VtZW50IiwiTmV3IjoiTmV3In0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiQ2hvb3NlIGEgcmVtb3RlIG9wdGlvbiIsIndvcmsgYXJyYW5nZW1lbnQiOiJ3b3JrIGFycmFuZ2VtZW50IiwiTmV3IjoiTmV3In0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiQ2hvb3NlIGEgcmVtb3RlIG9wdGlvbiIsIndvcmsgYXJyYW5nZW1lbnQiOiJ3b3JrIGFycmFuZ2VtZW50IiwiTmV3IjoiTmV3In0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiQ2hvb3NlIGEgcmVtb3RlIG9wdGlvbiIsIndvcmsgYXJyYW5nZW1lbnQiOiJ3b3JrIGFycmFuZ2VtZW50IiwiTmV3IjoiTmV3In0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiQ2hvb3NlIGEgcmVtb3RlIG9wdGlvbiIsIndvcmsgYXJyYW5nZW1lbnQiOiJ3b3JrIGFycmFuZ2VtZW50IiwiTmV3IjoiTmV3In0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiQ2hvb3NlIGEgcmVtb3RlIG9wdGlvbiIsIndvcmsgYXJyYW5nZW1lbnQiOiJ3b3JrIGFycmFuZ2VtZW50IiwiTmV3IjoiTmV3In0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiQ2hvb3NlIGEgcmVtb3RlIG9wdGlvbiIsIndvcmsgYXJyYW5nZW1lbnQiOiJ3b3JrIGFycmFuZ2VtZW50IiwiTmV3IjoiTmV3In0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiQ2hvb3NlIGEgcmVtb3RlIG9wdGlvbiIsIndvcmsgYXJyYW5nZW1lbnQiOiJ3b3JrIGFycmFuZ2VtZW50IiwiTmV3IjoiTmV3In0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiQ2hvb3NlIGEgcmVtb3RlIG9wdGlvbiIsIndvcmsgYXJyYW5nZW1lbnQiOiJ3b3JrIGFycmFuZ2VtZW50IiwiTmV3IjoiTmV3In0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiQ2hvb3NlIGEgcmVtb3RlIG9wdGlvbiIsIndvcmsgYXJyYW5nZW1lbnQiOiJ3b3JrIGFycmFuZ2VtZW50IiwiTmV3IjoiTmV3In0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiQ2hvb3NlIGEgcmVtb3RlIG9wdGlvbiIsIndvcmsgYXJyYW5nZW1lbnQiOiJ3b3JrIGFycmFuZ2VtZW50IiwiTmV3IjoiTmV3In0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiQ2hvb3NlIGEgcmVtb3RlIG9wdGlvbiIsIndvcmsgYXJyYW5nZW1lbnQiOiJ3b3JrIGFycmFuZ2VtZW50IiwiTmV3IjoiTmV3In0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiQ2hvb3NlIGEgcmVtb3RlIG9wdGlvbiIsIndvcmsgYXJyYW5nZW1lbnQiOiJ3b3JrIGFycmFuZ2VtZW50IiwiTmV3IjoiTmV3In0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiQ2hvb3NlIGEgcmVtb3RlIG9wdGlvbiIsIndvcmsgYXJyYW5nZW1lbnQiOiJ3b3JrIGFycmFuZ2VtZW50IiwiTmV3IjoiTmV3In0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiQ2hvb3NlIGEgcmVtb3RlIG9wdGlvbiIsIndvcmsgYXJyYW5nZW1lbnQiOiJ3b3JrIGFycmFuZ2VtZW50IiwiTmV3IjoiTmV3In0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiQ2hvb3NlIGEgcmVtb3RlIG9wdGlvbiIsIndvcmsgYXJyYW5nZW1lbnQiOiJ3b3JrIGFycmFuZ2VtZW50IiwiTmV3IjoiTmV3In0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiQ2hvb3NlIGEgcmVtb3RlIG9wdGlvbiIsIndvcmsgYXJyYW5nZW1lbnQiOiJ3b3JrIGFycmFuZ2VtZW50IiwiTmV3IjoiTmV3In0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiQ2hvb3NlIGEgcmVtb3RlIG9wdGlvbiIsIndvcmsgYXJyYW5nZW1lbnQiOiJ3b3JrIGFycmFuZ2VtZW50IiwiTmV3IjoiTmV3In0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiUGlsaWggb3BzaSBqYXJhayBqYXVoIiwid29yayBhcnJhbmdlbWVudCI6IndvcmsgYXJyYW5nZW1lbnQiLCJOZXciOiJCYXJ1In0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiUGlsaWggb3BzaSBqYXJhayBqYXVoIiwid29yayBhcnJhbmdlbWVudCI6IndvcmsgYXJyYW5nZW1lbnQiLCJOZXciOiJCYXJ1In0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoi4LmA4Lil4Li34Lit4LiB4LiV4Lix4Lin4LmA4Lil4Li34Lit4LiB4LiH4Liy4LiZ4Lij4Liw4Lii4Liw4LmE4LiB4LilIiwid29yayBhcnJhbmdlbWVudCI6IndvcmsgYXJyYW5nZW1lbnQiLCJOZXciOiLguYPguKvguKHguYgifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoi4LmA4Lil4Li34Lit4LiB4LiV4Lix4Lin4LmA4Lil4Li34Lit4LiB4LiH4Liy4LiZ4Lij4Liw4Lii4Liw4LmE4LiB4LilIiwid29yayBhcnJhbmdlbWVudCI6IndvcmsgYXJyYW5nZW1lbnQiLCJOZXciOiLguYPguKvguKHguYgifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiUGlsaWggb3BzaSBqYXJhayBqYXVoIiwid29yayBhcnJhbmdlbWVudCI6IndvcmsgYXJyYW5nZW1lbnQiLCJOZXciOiJCYXJ1In0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiUGlsaWggb3BzaSBqYXJhayBqYXVoIiwid29yayBhcnJhbmdlbWVudCI6IndvcmsgYXJyYW5nZW1lbnQiLCJOZXciOiJCYXJ1In0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoi4LmA4Lil4Li34Lit4LiB4LiV4Lix4Lin4LmA4Lil4Li34Lit4LiB4LiH4Liy4LiZ4Lij4Liw4Lii4Liw4LmE4LiB4LilIiwid29yayBhcnJhbmdlbWVudCI6IndvcmsgYXJyYW5nZW1lbnQiLCJOZXciOiLguYPguKvguKHguYgifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoi4LmA4Lil4Li34Lit4LiB4LiV4Lix4Lin4LmA4Lil4Li34Lit4LiB4LiH4Liy4LiZ4Lij4Liw4Lii4Liw4LmE4LiB4LilIiwid29yayBhcnJhbmdlbWVudCI6IndvcmsgYXJyYW5nZW1lbnQiLCJOZXciOiLguYPguKvguKHguYgifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiW8OH4bipw7bDtsO2w7bFoeG6veG6vSDEg8SDIMWZ4bq94bq9bcyCw7bDtuG5r%2BG6veG6vSDDtsO2xqXhua%2FDrMOsw7bDtuC4geC4teC5iV0iLCJ3b3JrIGFycmFuZ2VtZW50IjoiW8W1w7bDtsO2xZnEtyDEg8SDxIPFmcWZxIPEg8SD4LiB4Li14LmJxKPhur3hur3hur1tzILhur3hur3hur3guIHguLXguYnhua9dIiwiTmV3IjoiW8OR4bq94bq94bq9xbVdIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJDaG9vc2UgYSByZW1vdGUgb3B0aW9uIjoiW8OH4bipw7bDtsO2w7bFoeG6veG6vSDEg8SDIMWZ4bq94bq9bcyCw7bDtuG5r%2BG6veG6vSDDtsO2xqXhua%2FDrMOsw7bDtuC4geC4teC5iV0iLCJ3b3JrIGFycmFuZ2VtZW50IjoiW8W1w7bDtsO2xZnEtyDEg8SDxIPFmcWZxIPEg8SD4LiB4Li14LmJxKPhur3hur3hur1tzILhur3hur3hur3guIHguLXguYnhua9dIiwiTmV3IjoiW8OR4bq94bq94bq9xbVdIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    