
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBTRUVLIGJyYW5kcy4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms%2BIiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5LiA8VGV4dExpbms%2BVmlldyBoZXJlPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBTRUVLIGJyYW5kcy4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms%2BIiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5LiA8VGV4dExpbms%2BVmlldyBoZXJlPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBTRUVLIGJyYW5kcy4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms%2BIiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5LiA8VGV4dExpbms%2BVmlldyBoZXJlPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBTRUVLIGJyYW5kcy4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms%2BIiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5LiA8VGV4dExpbms%2BVmlldyBoZXJlPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBTRUVLIGJyYW5kcy4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms%2BIiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5LiA8VGV4dExpbms%2BVmlldyBoZXJlPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBTRUVLIGJyYW5kcy4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms%2BIiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5LiA8VGV4dExpbms%2BVmlldyBoZXJlPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzZGIsIEpvYnN0cmVldCBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzZGIsIEpvYnN0cmVldCBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms%2BIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzZGIsIEpvYnN0cmVldCBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzZGIsIEpvYnN0cmVldCBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms%2BIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzdHJlZXQsIEpvYnNkYiBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzdHJlZXQsIEpvYnNkYiBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms%2BIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzdHJlZXQsIEpvYnNkYiBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzdHJlZXQsIEpvYnNkYiBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms%2BIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzdHJlZXQsIEpvYnNkYiBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzdHJlZXQsIEpvYnNkYiBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms%2BIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzdHJlZXQsIEpvYnNkYiBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kgYWNyb3NzIG91ciBKb2JzdHJlZXQsIEpvYnNkYiBhbmQgU0VFSyBicmFuZHMuIDxUZXh0TGluaz5WaWV3IGhlcmU8L1RleHRMaW5rPiIsIldlIGhhdmUgdXBkYXRlZCBvdXIgUHJpdmFjeSBQb2xpY3kuIjoiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4gPFRleHRMaW5rPlZpZXcgaGVyZTwvVGV4dExpbms%2BIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IkthbWkgdGVsYWggbWVtcGVyYmFydWkgS2ViaWpha2FuIFByaXZhc2kga2FtaSBkaSBzZWx1cnVoIG1lcmVrIEpvYnN0cmVldCwgSm9ic2RiLCBkYW4gU0VFSyBrYW1pLiA8VGV4dExpbms%2BTGloYXQgZGkgc2luaTwvVGV4dExpbms%2BIiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJLYW1pIHRlbGFoIG1lbXBlcmJhcnVpIEtlYmlqYWthbiBQcml2YXNpIGthbWkuIDxUZXh0TGluaz5MaWhhdCBkaSBzaW5pPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IkthbWkgdGVsYWggbWVtcGVyYmFydWkgS2ViaWpha2FuIFByaXZhc2kga2FtaSBkaSBzZWx1cnVoIG1lcmVrIEpvYnN0cmVldCwgSm9ic2RiLCBkYW4gU0VFSyBrYW1pLiA8VGV4dExpbms%2BTGloYXQgZGkgc2luaTwvVGV4dExpbms%2BIiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJLYW1pIHRlbGFoIG1lbXBlcmJhcnVpIEtlYmlqYWthbiBQcml2YXNpIGthbWkuIDxUZXh0TGluaz5MaWhhdCBkaSBzaW5pPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IuC5gOC4o%2BC4suC5hOC4lOC5ieC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC4m%2BC4o%2BC4seC4muC4m%2BC4o%2BC4uOC4h%2BC4meC5guC4meC4muC4suC4ouC4hOC4p%2BC4suC4oeC5gOC4m%2BC5h%2BC4meC4quC5iOC4p%2BC4meC4leC4seC4p%2BC4guC4reC4h%2BC5gOC4o%2BC4suC4quC4s%2BC4q%2BC4o%2BC4seC4muC5geC4muC4o%2BC4meC4lOC5jCBKb2JzZGIgSm9ic3RyZWV0IOC5geC4peC4sCBTRUVLIOC5guC4lOC4ouC4l%2BC5iOC4suC4meC4quC4suC4oeC4suC4o%2BC4luC5gOC4guC5ieC4suC4luC4tuC4hyA8VGV4dExpbms%2B4LiU4Li54LiX4Li14LmI4LiZ4Li14LmIPC9UZXh0TGluaz4iLCJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5LiI6IuC5gOC4o%2BC4suC5hOC4lOC5ieC4m%2BC4o%2BC4seC4muC4m%2BC4o%2BC4uOC4h%2BC4guC5ieC4reC4oeC4ueC4peC4meC5guC4ouC4muC4suC4ouC4hOC4p%2BC4suC4oeC5gOC4m%2BC5h%2BC4meC4quC5iOC4p%2BC4meC4leC4seC4p%2BC4guC4reC4h%2BC5gOC4o%2BC4siA8VGV4dExpbms%2B4LiU4Li54LiX4Li14LmI4LiZ4Li14LmIPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IuC5gOC4o%2BC4suC5hOC4lOC5ieC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC4m%2BC4o%2BC4seC4muC4m%2BC4o%2BC4uOC4h%2BC4meC5guC4meC4muC4suC4ouC4hOC4p%2BC4suC4oeC5gOC4m%2BC5h%2BC4meC4quC5iOC4p%2BC4meC4leC4seC4p%2BC4guC4reC4h%2BC5gOC4o%2BC4suC4quC4s%2BC4q%2BC4o%2BC4seC4muC5geC4muC4o%2BC4meC4lOC5jCBKb2JzZGIgSm9ic3RyZWV0IOC5geC4peC4sCBTRUVLIOC5guC4lOC4ouC4l%2BC5iOC4suC4meC4quC4suC4oeC4suC4o%2BC4luC5gOC4guC5ieC4suC4luC4tuC4hyA8VGV4dExpbms%2B4LiU4Li54LiX4Li14LmI4LiZ4Li14LmIPC9UZXh0TGluaz4iLCJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5LiI6IuC5gOC4o%2BC4suC5hOC4lOC5ieC4m%2BC4o%2BC4seC4muC4m%2BC4o%2BC4uOC4h%2BC4guC5ieC4reC4oeC4ueC4peC4meC5guC4ouC4muC4suC4ouC4hOC4p%2BC4suC4oeC5gOC4m%2BC5h%2BC4meC4quC5iOC4p%2BC4meC4leC4seC4p%2BC4guC4reC4h%2BC5gOC4o%2BC4siA8VGV4dExpbms%2B4LiU4Li54LiX4Li14LmI4LiZ4Li14LmIPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IkthbWkgdGVsYWggbWVtcGVyYmFydWkgS2ViaWpha2FuIFByaXZhc2kga2FtaSBkaSBzZWx1cnVoIG1lcmVrIEpvYnN0cmVldCwgSm9ic2RiLCBkYW4gU0VFSyBrYW1pLiA8VGV4dExpbms%2BTGloYXQgZGkgc2luaTwvVGV4dExpbms%2BIiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJLYW1pIHRlbGFoIG1lbXBlcmJhcnVpIEtlYmlqYWthbiBQcml2YXNpIGthbWkuIDxUZXh0TGluaz5MaWhhdCBkaSBzaW5pPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IkthbWkgdGVsYWggbWVtcGVyYmFydWkgS2ViaWpha2FuIFByaXZhc2kga2FtaSBkaSBzZWx1cnVoIG1lcmVrIEpvYnN0cmVldCwgSm9ic2RiLCBkYW4gU0VFSyBrYW1pLiA8VGV4dExpbms%2BTGloYXQgZGkgc2luaTwvVGV4dExpbms%2BIiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJLYW1pIHRlbGFoIG1lbXBlcmJhcnVpIEtlYmlqYWthbiBQcml2YXNpIGthbWkuIDxUZXh0TGluaz5MaWhhdCBkaSBzaW5pPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IuC5gOC4o%2BC4suC5hOC4lOC5ieC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC4m%2BC4o%2BC4seC4muC4m%2BC4o%2BC4uOC4h%2BC4meC5guC4meC4muC4suC4ouC4hOC4p%2BC4suC4oeC5gOC4m%2BC5h%2BC4meC4quC5iOC4p%2BC4meC4leC4seC4p%2BC4guC4reC4h%2BC5gOC4o%2BC4suC4quC4s%2BC4q%2BC4o%2BC4seC4muC5geC4muC4o%2BC4meC4lOC5jCBKb2JzZGIgSm9ic3RyZWV0IOC5geC4peC4sCBTRUVLIOC5guC4lOC4ouC4l%2BC5iOC4suC4meC4quC4suC4oeC4suC4o%2BC4luC5gOC4guC5ieC4suC4luC4tuC4hyA8VGV4dExpbms%2B4LiU4Li54LiX4Li14LmI4LiZ4Li14LmIPC9UZXh0TGluaz4iLCJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5LiI6IuC5gOC4o%2BC4suC5hOC4lOC5ieC4m%2BC4o%2BC4seC4muC4m%2BC4o%2BC4uOC4h%2BC4guC5ieC4reC4oeC4ueC4peC4meC5guC4ouC4muC4suC4ouC4hOC4p%2BC4suC4oeC5gOC4m%2BC5h%2BC4meC4quC5iOC4p%2BC4meC4leC4seC4p%2BC4guC4reC4h%2BC5gOC4o%2BC4siA8VGV4dExpbms%2B4LiU4Li54LiX4Li14LmI4LiZ4Li14LmIPC9UZXh0TGluaz4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IuC5gOC4o%2BC4suC5hOC4lOC5ieC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC4m%2BC4o%2BC4seC4muC4m%2BC4o%2BC4uOC4h%2BC4meC5guC4meC4muC4suC4ouC4hOC4p%2BC4suC4oeC5gOC4m%2BC5h%2BC4meC4quC5iOC4p%2BC4meC4leC4seC4p%2BC4guC4reC4h%2BC5gOC4o%2BC4suC4quC4s%2BC4q%2BC4o%2BC4seC4muC5geC4muC4o%2BC4meC4lOC5jCBKb2JzZGIgSm9ic3RyZWV0IOC5geC4peC4sCBTRUVLIOC5guC4lOC4ouC4l%2BC5iOC4suC4meC4quC4suC4oeC4suC4o%2BC4luC5gOC4guC5ieC4suC4luC4tuC4hyA8VGV4dExpbms%2B4LiU4Li54LiX4Li14LmI4LiZ4Li14LmIPC9UZXh0TGluaz4iLCJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5LiI6IuC5gOC4o%2BC4suC5hOC4lOC5ieC4m%2BC4o%2BC4seC4muC4m%2BC4o%2BC4uOC4h%2BC4guC5ieC4reC4oeC4ueC4peC4meC5guC4ouC4muC4suC4ouC4hOC4p%2BC4suC4oeC5gOC4m%2BC5h%2BC4meC4quC5iOC4p%2BC4meC4leC4seC4p%2BC4guC4reC4h%2BC5gOC4o%2BC4siA8VGV4dExpbms%2B4LiU4Li54LiX4Li14LmI4LiZ4Li14LmIPC9UZXh0TGluaz4ifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IlvFtOG6veG6veG6vSDhuKnEg8SDxIPhub3hur3hur3hur0gx5rHmseaxqXGjMSDxIPEg%2BG5r%2BG6veG6veG6vcaMIMO2w7bDtseax5rHmsWZIMakxZnDrMOsw6zhub3Eg8SDxIPDp8O9w73DvSDGpMO2w7bDtsaaw6zDrMOsw6fDvcO9w70gxIPEg8SDw6fFmcO2w7bDtsWhxaEgw7bDtsO2x5rHmseaxZkgxaDDi8OL4biwIMOfxZnEg8SDxIPguIHguLXguYnGjMWhLiA8VGV4dExpbms%2B4bm8w6zDrOG6veG6vcW1IOG4qeG6veG6vcWZ4bq94bq9PC9UZXh0TGluaz5dIiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJbxbThur3hur3hur0g4bipxIPEg8SD4bm94bq94bq94bq9IMeax5rHmsalxozEg8SDxIPhua%2Fhur3hur3hur3GjCDDtsO2w7bHmseax5rFmSDGpMWZw6zDrMOs4bm9xIPEg8SDw6fDvcO9w70gxqTDtsO2w7bGmsOsw6zDrMOnw73DvcO9LiA8VGV4dExpbms%2B4bm8w6zDrOG6veG6vcW1IOG4qeG6veG6vcWZ4bq94bq9PC9UZXh0TGluaz5dIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXZSBoYXZlIHVwZGF0ZWQgb3VyIFByaXZhY3kgUG9saWN5IGFjcm9zcy4uLiI6IlvFtOG6veG6veG6vSDhuKnEg8SDxIPhub3hur3hur3hur0gx5rHmseaxqXGjMSDxIPEg%2BG5r%2BG6veG6veG6vcaMIMO2w7bDtseax5rHmsWZIMakxZnDrMOsw6zhub3Eg8SDxIPDp8O9w73DvSDGpMO2w7bDtsaaw6zDrMOsw6fDvcO9w70gxIPEg8SDw6fFmcO2w7bDtsWhxaEgw7bDtsO2x5rHmseaxZkgxaDDi8OL4biwIMOfxZnEg8SDxIPguIHguLXguYnGjMWhLiA8VGV4dExpbms%2B4bm8w6zDrOG6veG6vcW1IOG4qeG6veG6vcWZ4bq94bq9PC9UZXh0TGluaz5dIiwiV2UgaGF2ZSB1cGRhdGVkIG91ciBQcml2YWN5IFBvbGljeS4iOiJbxbThur3hur3hur0g4bipxIPEg8SD4bm94bq94bq94bq9IMeax5rHmsalxozEg8SDxIPhua%2Fhur3hur3hur3GjCDDtsO2w7bHmseax5rFmSDGpMWZw6zDrMOs4bm9xIPEg8SDw6fDvcO9w70gxqTDtsO2w7bGmsOsw6zDrMOnw73DvcO9LiA8VGV4dExpbms%2B4bm8w6zDrOG6veG6vcW1IOG4qeG6veG6vcWZ4bq94bq9PC9UZXh0TGluaz5dIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    