import { selectJobDetailsResult } from '../jobdetails/selectors';
import { selectIsSrp } from '../location/selectors';
import type { ChaliceStore } from '../types';

import { pageKeys } from './types';

export const selectLmis = (state: ChaliceStore) => {
  const lmisKey =
    selectIsSrp(state) && !selectJobDetailsResult(state)
      ? pageKeys.SRP
      : pageKeys.JDP;

  return state.lmis[lmisKey];
};
