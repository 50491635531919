import type { Banner } from 'src/graphql/graphql';

export const GET_BANNER_SUCCESS = 'GET_BANNER_SUCCESS';
export const GET_BANNER_ERROR = 'GET_BANNER_ERROR';

export interface BannerItem {
  id: string;
  text: string;
  href: string;
}

export type BannerState = Banner & {
  error: string | null;
};

interface GetBannerSuccessAction {
  type: typeof GET_BANNER_SUCCESS;
  payload: Banner;
}

interface GetBannerErrorAction {
  type: typeof GET_BANNER_ERROR;
  payload: {
    error: string;
  };
}

export type BannerAction = GetBannerSuccessAction | GetBannerErrorAction;
