import type { ChaliceStore } from '../types';

export const selectSaveable = (state: ChaliceStore) =>
  state.saveSearch.saveable;
export const selectSaveSearchStatus = (state: ChaliceStore) =>
  state.saveSearch.status;
export const selectSaveSearchErrorMessage = (state: ChaliceStore) =>
  state.saveSearch.errorMessage;
export const selectSaveSearchEmailAddress = (state: ChaliceStore) =>
  state.saveSearch.emailAddress;
