import { useTranslations } from '@vocab/react';
import { HiddenVisually } from 'braid-design-system';
import type { ComponentProps } from 'react';

import RefineRadio from '../RefineRadio/RefineRadio';

import translations from './.vocab';

interface DateListedProps {
  handleSelection: (value: string) => any;
  dateRanges: ComponentProps<typeof RefineRadio>['options'];
}

const DateListed = (props: DateListedProps) => {
  const { t } = useTranslations(translations);
  const { handleSelection, dateRanges } = props;

  return (
    <nav role="navigation" aria-labelledby="RefineDateListed">
      <HiddenVisually>
        <h1 id="RefineDateListed">{t('Date Listed')}</h1>
      </HiddenVisually>
      <RefineRadio
        multiSelect={false}
        handleSelection={handleSelection}
        labelledBy="RefineDateListed"
        options={dateRanges}
        path="/jobs"
        data-automation="refineDateListed"
      />
    </nav>
  );
};

export default DateListed;
