import type {
  SearchParams,
  SearchResultJobV5,
  SearchResultLocationV5,
} from '@seek/chalice-types';
import type { Country } from '@seek/melways-sites';
import assign from 'lodash/assign';
import omit from 'lodash/omit';
// @ts-expect-error non-ts file
import { toWords } from 'spelled-number';

import { isJobExternal } from 'src/modules/qualified-location';
import type {
  JobViewModelClassification,
  JobViewModelItemPartial,
  JobViewModelV5,
} from 'src/types/JobViewModel';

const MINUTE = 60000; // 60 * 1000
const MINUTES = MINUTE;
const HOUR = 60 * MINUTES;
const HOURS = HOUR;
const DAY = 24 * HOURS;
const DAYS = DAY;

const minutes = (amount: number) => amount * MINUTES;

const hours = (amount: number) => amount * HOURS;

const days = (amount: number) => amount * DAYS;

// Picks the right mix of params from the current page (query)
// and the current job.
const pickSearchParams = (job: SearchResultJobV5, query: SearchParams) => {
  const result = omit(query, 'page'); // Always refine the to first page

  // Use the job's company name and id over the query, because
  // our follow / nofollow logic depends on these.
  if (
    query.companyname &&
    job.companyName &&
    job.companyProfileStructuredDataId
  ) {
    result.companyname = job.companyName;
    result.companyid = `${job.companyProfileStructuredDataId}`;
  }

  return result;
};

const getJobLocation = (job: SearchResultJobV5) => {
  // V5 supports an array of locations but we only need to worry about the first one for now
  if (job.locations?.length) {
    return job.locations[0];
  }
};

const getUnifiedLocation = (job: SearchResultJobV5, query: SearchParams) => {
  const jobLocation = getJobLocation(job);

  if (!jobLocation || !jobLocation.seoHierarchy) {
    return null;
  }

  // temp use before the seoHierarchy.label exist
  const getNameFromConcatenatedLabel = (index: number) => {
    const locations = jobLocation.label.split(', ');
    const seoLength = jobLocation.seoHierarchy.length;
    const isLengthMatch = locations.length === seoLength;

    if (!isLengthMatch && index === seoLength - 1) {
      return locations
        .filter((part: string, i: number) => i >= seoLength - 1)
        .join(', ');
    }
    return locations[index];
  };

  const unifiedLocationObj = jobLocation.seoHierarchy
    .map((item, index) => {
      const name = item.label ?? getNameFromConcatenatedLabel(index);
      return name
        ? {
            name,
            title: `Limit results to ${name}`,
            params: assign(pickSearchParams(job, query), {
              where: item.contextualName,
            }),
          }
        : null;
    })
    .filter((item) => item !== null);

  return unifiedLocationObj as JobViewModelItemPartial[];
};

const getUnifiedDateDescription = (job: SearchResultJobV5) => {
  const listingDateDisplay = job.listingDateDisplay;

  if (!listingDateDisplay) {
    return null;
  }

  if (listingDateDisplay === 'just now') {
    return 'Listed just now';
  }

  const timeUnit = listingDateDisplay.split(' ')[0].replace(/[0-9]/g, '');
  const number = Number(listingDateDisplay.replace(/[^0-9]/g, ''));

  if (timeUnit === 'm') {
    return `Listed ${convertToWords(number, 'minute')} ago`;
  }
  if (timeUnit === 'h') {
    return `Listed ${convertToWords(number, 'hour')} ago`;
  }
  if (timeUnit === 'd') {
    return `Listed ${convertToWords(number, 'day')} ago`;
  }
  return `Listed more than ${convertToWords(number, 'day')} ago`;
};

const getClassificationHierarchy = (job: SearchResultJobV5) => {
  // V5 supports an array of classification hierarchies but for now we only need to worry about the first one
  if (job.classifications?.length) {
    return job.classifications[0];
  }
};

const getClassification = (
  job: SearchResultJobV5,
  query: SearchParams,
  location?: SearchResultLocationV5,
): JobViewModelClassification => {
  // V5 supports an array of classification hierarchies but for now we only need to worry about the first one
  const classificationHierarchy = getClassificationHierarchy(job);
  const classification = classificationHierarchy?.classification;
  const subClassification = classificationHierarchy?.subclassification;

  const matchedWhere =
    !location ||
    location.description === 'All Australia' ||
    location.description === 'All New Zealand'
      ? ''
      : location.description;
  const result: Partial<JobViewModelClassification> = {};

  if (classification) {
    // should always exist, but it's safer to guard anyway
    result.classification = {
      name: classification.description || '',
      title: `Limit results to ${classification.description}`,
      params: assign(omit(pickSearchParams(job, query), 'subclassification'), {
        where: matchedWhere,
        classification: classification.id,
      }),
    };

    if (subClassification && subClassification.id) {
      // it is null for graduate jobs (don't ask me why)
      result.subClassification = {
        name: subClassification.description || '',
        title: `Limit results to ${subClassification.description} in ${classification.description}`,
        params: assign(pickSearchParams(job, query), {
          where: matchedWhere,
          classification: classification.id,
          subclassification: subClassification.id,
        }),
      };
    }
  }

  return result as JobViewModelClassification;
};

const getIsJobExternal = (
  job: SearchResultJobV5,
  candidateCountryCode: Country,
) => {
  const jobLocation = getJobLocation(job);

  return isJobExternal(candidateCountryCode, jobLocation?.countryCode);
};

const diffFromNow = (dateStr: string, nowStr?: string) => {
  const date = new Date(dateStr);
  const now = nowStr ? new Date(nowStr) : new Date();

  return now.getTime() - date.getTime();
};

// Converts:
//     5, 'minute'
// to:
//     'five minutes'
const convertToWords = (number: number, word: string) => {
  const numberAsWords = toWords(number);

  return `${numberAsWords} ${word}${number === 1 ? '' : 's'}`;
};

const getListingDate = (job: SearchResultJobV5, now?: string) => {
  const diff = diffFromNow(job.listingDate, now);
  let number;

  if (diff < minutes(5.5)) {
    number = 5;

    return {
      shortDescription: `${number}m ago`,
      longDescription: `Listed ${convertToWords(number, 'minute')} ago`,
    };
  }

  if (diff < minutes(59.5)) {
    number = Math.round(diff / MINUTE);

    return {
      shortDescription: `${number}m ago`,
      longDescription: `Listed ${convertToWords(number, 'minute')} ago`,
    };
  }

  if (diff < hours(23.5)) {
    number = Math.round(diff / HOUR);

    return {
      shortDescription: `${number}h ago`,
      longDescription: `Listed ${convertToWords(number, 'hour')} ago`,
    };
  }

  if (diff < days(30.5)) {
    number = Math.round(diff / DAY);

    return {
      shortDescription: `${number}d ago`,
      longDescription: `Listed ${convertToWords(number, 'day')} ago`,
    };
  }

  number = 30;

  return {
    shortDescription: `${number}d+ ago`,
    longDescription: `Listed more than ${convertToWords(number, 'day')} ago`,
  };
};

const getIsFresh = (job: SearchResultJobV5, now?: string) =>
  diffFromNow(job.listingDate, now) < hours(23.5);

const getBulletPoints = (job: SearchResultJobV5) => {
  if (job.bulletPoints && job.bulletPoints.length > 0) {
    return job.bulletPoints;
  }
};

const getWorkType = (job: SearchResultJobV5) =>
  // V5 supports an array of workTypes but for now we only need to worry about the first one
  job.workTypes?.length ? job.workTypes[0] : '';

export interface JobViewModelParams {
  job: SearchResultJobV5;
  query: SearchParams;
  location?: SearchResultLocationV5;
  now?: string;
}
const jobViewModel = (
  data: JobViewModelParams,
  candidateCountryCode: Country,
) => {
  const job = data.job || {};
  const query = data.query || {};
  const location = data.location;
  const now = data.now; // used in tests to mock the current time
  const solMetadataString = job.solMetadata
    ? JSON.stringify(job.solMetadata)
    : job.solMetadata;

  const viewModel: JobViewModelV5 = {
    advertiser: job.advertiser,
    branding: job.branding,
    bulletPoints: getBulletPoints(job),
    classification: getClassification(job, query, location),
    currencyLabel: job.currencyLabel,
    id: job.id,
    isFeatured: job.isFeatured,
    isFresh: getIsFresh(job, now),
    title: job.title,
    teaser: job.teaser,
    salary: job.salaryLabel,
    srcLogo: job.branding?.serpLogoUrl,
    solMetadataString,
    unifiedLocation: getUnifiedLocation(job, query),
    unifiedListingDate: job.listingDateDisplay,
    unifiedListingDateDescription: getUnifiedDateDescription(job),
    workType: getWorkType(job),
    isJobExternal: getIsJobExternal(job, candidateCountryCode),
  };

  if (!job.isFeatured) {
    const listingDate = getListingDate(job, now);

    viewModel.listingDate = listingDate.shortDescription;
    viewModel.listingDateDescription = listingDate.longDescription;
  }

  return viewModel;
};

export default jobViewModel;
