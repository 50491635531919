import type { PageName } from '@seek/seek-jobs-analytics';

import { useSelector } from 'src/store/react';
import { selectIsJDP, selectIsSrp } from 'src/store/selectors';

export const useCurrentPage = (): PageName => {
  const isJDP = useSelector(selectIsJDP);
  const isSrp = useSelector(selectIsSrp);

  if (isJDP) {
    return 'job details';
  }
  if (isSrp) {
    return 'search results';
  }

  return 'home';
};
