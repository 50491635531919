import { isJobDetailsLocation, isSearchResultsPath } from '@seek/seek-jobs-seo';

import type { ChaliceStore } from '../types';

export const selectLocationPageNumber = (state: ChaliceStore) =>
  state.location.pageNumber || 0;
export const selectIsSrp = (state: ChaliceStore) =>
  isSearchResultsPath(state.location.pathname);
export const selectIsJDP = (state: ChaliceStore) =>
  isJobDetailsLocation(state.location);
export const selectIsJDPSplitView = (state: ChaliceStore) =>
  isSearchResultsPath(state.location.pathname) &&
  Boolean(state.jobdetails.result);
export const selectPageTitle = (state: ChaliceStore) =>
  state.location.pageTitle;
export const selectPathname = (state: ChaliceStore) => state.location.pathname;
export const selectPrevPathname = (state: ChaliceStore) =>
  state.location.prevPathname;
export const selectProtocol = (state: ChaliceStore) => state.location.protocol;
export const selectHostname = (state: ChaliceStore) => state.location.hostname;
export const selectQuery = (state: ChaliceStore) => state.location.query;
export const selectTags = (state: ChaliceStore) => state.search.query.tags;
export const selectHash = (state: ChaliceStore) => state.location.hash;
export const selectUrl = (state: ChaliceStore) => state.location.url;
export const selectLocation = (state: ChaliceStore) => state.location;
export const selectPageNumber = (state: ChaliceStore) =>
  state.location.pageNumber;
export const selectIsHomePage = (state: ChaliceStore) =>
  state.location.isHomepage;
