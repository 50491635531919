import gql from 'graphql-tag';

export const DELETE_SAVED_JOB = gql`
  mutation DeleteSavedJob($input: DeleteSavedJobInput!, $locale: Locale) {
    deleteSavedJob2(input: $input) {
      __typename
      ... on DeleteSavedJobSuccess {
        __typename
        id
      }
      ... on DeleteSavedJobFailure {
        __typename
        errors {
          __typename
          message(locale: $locale)
        }
      }
    }
  }
`;
