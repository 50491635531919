import { initialState as appConfig } from './appConfig';
import { initialState as featureFlags } from './featureFlags';
import { initialState as jobdetails } from './jobdetails';
import { initialState as lmis } from './lmis';
import { initialState as location } from './location';
import { initialState as nudges } from './nudges';
import { initialState as recentSearches } from './recentSearches';
import { initialState as saveSearch } from './saveSearch';
import { initialState as search } from './search';
import { initialState as seo } from './seo';
import { initialState as user } from './user';

export default Object.freeze({
  appConfig,
  featureFlags,
  jobdetails,
  recentSearches,
  lmis,
  location,
  nudges,
  saveSearch,
  search,
  seo,
  user,
});
