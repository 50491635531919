import type { generateAlternateLinks } from '@seek/seek-jobs-seo';

export const SET_CANONICAL_URL = 'SET_CANONICAL_URL';
export const SET_ALTERNATE_LINKS = 'SET_ALTERNATE_LINKS';

type AlternateLinks = ReturnType<typeof generateAlternateLinks>;
export interface SeoState {
  alternateLinks?: AlternateLinks;
  canonicalUrl: string | null;
  partners: {
    canCrossLink: boolean;
  };
}

export const initialState: SeoState = {
  canonicalUrl: null,
  partners: {
    canCrossLink: false,
  },
};

export type SeoAction =
  | {
      type: typeof SET_CANONICAL_URL;
      payload: { canonical: string };
    }
  | {
      type: typeof SET_ALTERNATE_LINKS;
      payload: { alternateLinks: SeoState['alternateLinks'] };
    };
