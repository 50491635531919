import type { SalaryType } from '@seek/chalice-types';
import { useTranslations } from '@vocab/react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Salary from 'src/components/Search/RefineBar/SalaryGraph/SalaryGraph';
import { useSearchSalaryRanges } from 'src/hooks/useSearchSalaryRanges';
import { selectSearchQuery } from 'src/store/selectors';

import { getRefineSearchQuery } from '../../utils/utils';

import translations from './.vocab';

export const useSalaryTypeField = (
  handleSelection: (fieldId: string) => void,
) => {
  const { t } = useTranslations(translations);
  const searchQuery = getRefineSearchQuery(useSelector(selectSearchQuery));
  const {
    salaryrange: _salaryrange,
    salarytype: _salarytype,
    ...searchQueryWithoutSalary
  } = searchQuery;

  const { searchSalaryRanges: data } = useSearchSalaryRanges();

  const defaultFrequency = data.find(
    (item) => item.frequency.isDefault,
  )?.frequency;

  const frequency =
    searchQuery.salarytype ?? (defaultFrequency?.type as SalaryType);
  const [selectedSalaryRangeFromValue, selectedSalaryRangeToValue] =
    searchQuery.salaryrange?.split('-') ?? [];

  const selectedSalaryFrequencyRanges = data.find(
    ({ frequency: { type } }) => type === frequency,
  )?.range;

  const defaultSalaryRangeFrom = selectedSalaryFrequencyRanges?.[0];
  const defaultSalaryRangeTo =
    selectedSalaryFrequencyRanges?.[selectedSalaryFrequencyRanges.length - 1];

  const selectedSalaryRangeFromIndex =
    selectedSalaryFrequencyRanges?.findIndex(
      ({ value }) => value === selectedSalaryRangeFromValue,
    ) ?? -1;

  const selectedSalaryRangeToIndex =
    selectedSalaryFrequencyRanges?.findIndex(
      ({ value }) => value === selectedSalaryRangeToValue,
    ) ?? -1;

  const activeSalaryRangeFromValue =
    selectedSalaryRangeFromValue > '0'
      ? selectedSalaryRangeFromValue
      : defaultSalaryRangeFrom?.value;

  const activeSalaryRangeToValue =
    selectedSalaryRangeToValue > '0'
      ? selectedSalaryRangeToValue
      : defaultSalaryRangeTo?.value;

  const fromLabel =
    selectedSalaryFrequencyRanges?.[selectedSalaryRangeFromIndex]?.longLabel ??
    defaultSalaryRangeFrom?.longLabel;
  const toLabel =
    selectedSalaryFrequencyRanges?.[selectedSalaryRangeToIndex]?.longLabel ??
    defaultSalaryRangeTo?.longLabel;

  const salaryTypes = data.map(({ frequency: { type, label, isDefault } }) => {
    const isActive = type === frequency;
    return {
      label,
      value: type,
      isActive,
      refineParams:
        !isActive && !isDefault
          ? { ...searchQueryWithoutSalary, salarytype: type as SalaryType }
          : searchQueryWithoutSalary,
    };
  });

  const salariesFrom = useMemo(
    () =>
      data
        .find(({ frequency: { type } }) => type === frequency)
        ?.range.slice(0, -1)
        .map(({ shortLabel, longLabel, value }, index) => {
          const isActive = value === activeSalaryRangeFromValue;
          return {
            label: value === '0' ? longLabel : shortLabel,
            value,
            isActive,
            refineParams:
              index < selectedSalaryRangeToIndex ||
              selectedSalaryRangeToIndex === -1
                ? {
                    ...searchQueryWithoutSalary,
                    salarytype: frequency,
                    salaryrange: `${value}-${selectedSalaryRangeToValue ?? ''}`,
                  }
                : undefined,
          };
        }) || [],
    [
      data,
      frequency,
      activeSalaryRangeFromValue,
      selectedSalaryRangeToIndex,
      searchQueryWithoutSalary,
      selectedSalaryRangeToValue,
    ],
  );

  const salariesTo = useMemo(
    () =>
      data
        .find(({ frequency: { type } }) => type === frequency)
        ?.range.slice(1)
        .map(({ shortLabel, longLabel, value }, index) => {
          const isActive = value === activeSalaryRangeToValue;
          return {
            label: value === '0' ? longLabel : shortLabel,
            value,
            isActive,
            refineParams:
              index >= selectedSalaryRangeFromIndex
                ? {
                    ...searchQueryWithoutSalary,
                    salarytype: frequency,
                    salaryrange: `${
                      selectedSalaryRangeFromValue ??
                      defaultSalaryRangeFrom?.value
                    }-${value}`,
                  }
                : undefined,
          };
        }) || [],
    [
      data,
      frequency,
      activeSalaryRangeToValue,
      selectedSalaryRangeFromIndex,
      searchQueryWithoutSalary,
      selectedSalaryRangeFromValue,
      defaultSalaryRangeFrom?.value,
    ],
  );

  const isFromSelected =
    activeSalaryRangeFromValue !== defaultSalaryRangeFrom?.value;
  const isToSelected = activeSalaryRangeToValue !== defaultSalaryRangeTo?.value;

  return [
    {
      id: 'SalaryFrom',
      ariaLabel: t('minimum salary'),
      summarySentence: `${t('paying')} `, // Be wary of the trailing space
      label: fromLabel,
      filterComponent: () => (
        <Salary
          handleSelection={() => handleSelection('SalaryFrom')}
          salaries={salariesFrom ?? []}
          salaryTypes={salaryTypes ?? []}
          label={t('Minimum')}
        />
      ),
      isSelected: isFromSelected,
    },
    {
      id: 'SalaryTo',
      ariaLabel: t('maximum salary'),
      summarySentence: `${t('to')} `, // Be wary of the trailing space
      label: toLabel,
      filterComponent: () => (
        <Salary
          handleSelection={() => handleSelection('SalaryTo')}
          salaries={salariesTo ?? []}
          salaryTypes={salaryTypes ?? []}
          label={t('Maximum')}
        />
      ),
      isSelected: isToSelected,
    },
  ];
};
