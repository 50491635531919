import { logger } from 'src/modules/logger';

export const UNIX_EPOCH_DATE = new Date(0);

export const parseDate = (date = '') => {
  const dateParsed = new Date(date);
  if (!date || typeof dateParsed !== typeof new Date(0)) {
    logger.error({}, `Invalid date format, must pass in as ISO8601 format.`);
    return UNIX_EPOCH_DATE;
  }

  return dateParsed;
};
