import type { Language } from '@seek/melways-sites';

export const supportedLanguages: Language[] = ['en', 'th', 'id'];

const generateLanguageRegex = (skipLanguage: boolean = false) => {
  const ignoreGroup = skipLanguage ? '?:' : '';
  return new RegExp(
    `^(${ignoreGroup}\/(${ignoreGroup}${supportedLanguages.join('|')})?)?`,
  );
};

export const generateLanguageRegexForTest = generateLanguageRegex;

export const jobOrExpiredJobRegex = new RegExp(
  generateLanguageRegex(true).source + /\/(job|expiredjob)\/(\d+)/i.source,
);

const optionalLanguageRegex = generateLanguageRegex();
export const jobDetailsPageRegex = new RegExp(
  optionalLanguageRegex.source + /\/job(\/.*)?$/.source,
);
export const searchResultsPageRegex = new RegExp(
  optionalLanguageRegex.source +
    /(\/.*jobs.*$)|/.source +
    optionalLanguageRegex.source +
    /(\/*-jobs-jobs)/.source,
);
export const homePageRegex = new RegExp(
  optionalLanguageRegex.source + /\/?$/.source,
);
export const expiredJobPageRegex = new RegExp(
  optionalLanguageRegex.source + /\/expiredjob(\/.*)?$/.source,
);
