import type { JobTag, JobTagType } from '@seek/chalice-types';

import type { JobBadgeProps } from '../components/JobBadges';
import type { JobLabelProps } from '../components/JobLabels';

export const getRecommendationBadges = (tags: JobTag[] | undefined) => {
  const badges: JobBadgeProps[] = [];
  const labels: Record<string, JobLabelProps> = {};
  const analyticsBadges: JobTagType[] = [];
  let viewedLabel = '';

  tags
    ?.filter((tag): tag is JobTag => Boolean(tag.label))
    .forEach((tag) => {
      switch (tag.type) {
        // New and Unseen are mututally exclusive, we don't have to account for both
        // https://api.jobapi.net/docs/mordor/tags
        case 'NEW':
        case 'UNSEEN':
          badges.push({
            label: tag.label,
            tone: 'positive',
            data: {
              automation: 'newBadge',
            },
          });
          analyticsBadges.push(tag.type);
          break;
        case 'EXPIRES_SOON':
          labels[tag.type] = {
            label: tag.label,
            tone: 'critical',
            type: tag.type,
          };
          analyticsBadges.push(tag.type);

          break;
        case 'EARLY_APPLICANT':
          labels[tag.type] = {
            label: tag.label,
            tone: 'info',
            type: tag.type,
          };
          break;
        case 'VIEWED':
          viewedLabel = tag.label;
          analyticsBadges.push(tag.type);
          break;
      }
    });

  if (labels.EXPIRES_SOON) {
    delete labels.EARLY_APPLICANT;
  } else if (labels.EARLY_APPLICANT) {
    analyticsBadges.push('EARLY_APPLICANT');
  }
  return { badges, labels, viewedLabel, analyticsBadges };
};
