import { parseCookies } from 'src/modules/safe-cookies';

import type { ChaliceStore } from '../types';

export const selectAuthenticated = (state: ChaliceStore) =>
  state.user.authenticated === true;
export const selectIsAuthenticationResolved = (state: ChaliceStore) =>
  state.user.authenticated !== undefined;
export const selectEmailAddress = (state: ChaliceStore) =>
  state.user.emailAddress;
export const selectLoginId = (state: ChaliceStore) =>
  state.user.seekerId?.toString();
export const selectFirstName = (state: ChaliceStore) => state.user.firstName;
export const selectSeekerId = (state: ChaliceStore) => state.user.seekerId;
export const selectSessionId = (state: ChaliceStore) =>
  state.user?.sessionId || parseCookies()?.JobseekerSessionId;
export const selectUserClientId = (state: ChaliceStore) =>
  state.user.userClientId;
export const selectUserTestHeaders = (state: ChaliceStore) =>
  state.user.testHeaders;
export const selectEventCaptureTestTags = (state: ChaliceStore) =>
  state.user.testTags;
