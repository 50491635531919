import { Box, Text, IconChevron, FieldLabel, Stack } from 'braid-design-system';

import { useSecondaryFilterContext } from '../../../context/SecondaryFilterContext';
import type { SalaryRange } from '../useSalaryRange';

import * as styles from '../SalaryRange.css';

export const SalaryField = ({
  salary,
  selectedSalaryLabel,
}: {
  salary: SalaryRange;
  selectedSalaryLabel?: string;
}) => {
  const { activeDropdownField, setActiveDropdownField } =
    useSecondaryFilterContext();

  return (
    <Stack data={{ automation: 'salaryField' }} space="xxsmall">
      <FieldLabel
        htmlFor={`salarySelection-${salary.id}`}
        label={salary.label}
      />
      <Box
        component="input"
        id={`salarySelection-${salary.id}`}
        type="checkbox"
        role="button"
        onChange={() =>
          activeDropdownField === salary.id
            ? setActiveDropdownField(null)
            : setActiveDropdownField(salary.id)
        }
      />
      <Box component="label" htmlFor={`salarySelection-${salary.id}`}>
        <Box
          position="relative"
          className={styles.salaryRangeBar}
          borderRadius="standard"
          display="flex"
          justifyContent="spaceBetween"
          padding="small"
          width="full"
        >
          <Text>{selectedSalaryLabel}</Text>
          <Text>
            <IconChevron
              direction={activeDropdownField === salary.id ? 'up' : 'down'}
            />
          </Text>
        </Box>
      </Box>
    </Stack>
  );
};
