
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IldoZXJlIiwiRW50ZXIgYSBsb2NhdGlvbiI6IkVudGVyIGEgbG9jYXRpb24iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IkVudGVyIHN1YnVyYiwgdG93biBvciBjaXR5IiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IldoZXJlIiwiRW50ZXIgYSBsb2NhdGlvbiI6IkVudGVyIGEgbG9jYXRpb24iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IkVudGVyIHN1YnVyYiwgdG93biBvciBjaXR5IiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IldoZXJlIiwiRW50ZXIgYSBsb2NhdGlvbiI6IkVudGVyIGEgbG9jYXRpb24iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IkVudGVyIHN1YnVyYiwgdG93biBvciBjaXR5IiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IldoZXJlIiwiRW50ZXIgYSBsb2NhdGlvbiI6IkVudGVyIGEgbG9jYXRpb24iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IkVudGVyIHN1YnVyYiwgdG93biBvciBjaXR5IiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IldoZXJlIiwiRW50ZXIgYSBsb2NhdGlvbiI6IkVudGVyIGEgbG9jYXRpb24iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IkVudGVyIHN1YnVyYiwgdG93biBvciBjaXR5IiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IldoZXJlIiwiRW50ZXIgYSBsb2NhdGlvbiI6IkVudGVyIGEgbG9jYXRpb24iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IkVudGVyIHN1YnVyYiwgdG93biBvciBjaXR5IiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IldoZXJlIiwiRW50ZXIgYSBsb2NhdGlvbiI6IkVudGVyIGEgbG9jYXRpb24iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IkVudGVyIHN1YnVyYiwgdG93biBvciBjaXR5IiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IldoZXJlIiwiRW50ZXIgYSBsb2NhdGlvbiI6IkVudGVyIGEgbG9jYXRpb24iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IkVudGVyIHN1YnVyYiwgdG93biBvciBjaXR5IiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IldoZXJlIiwiRW50ZXIgYSBsb2NhdGlvbiI6IkVudGVyIGEgbG9jYXRpb24iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IkVudGVyIHN1YnVyYiwgdG93biBvciBjaXR5IiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IldoZXJlIiwiRW50ZXIgYSBsb2NhdGlvbiI6IkVudGVyIGEgbG9jYXRpb24iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IkVudGVyIHN1YnVyYiwgdG93biBvciBjaXR5IiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IldoZXJlIiwiRW50ZXIgYSBsb2NhdGlvbiI6IkVudGVyIGEgbG9jYXRpb24iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IkVudGVyIHN1YnVyYiwgdG93biBvciBjaXR5IiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IldoZXJlIiwiRW50ZXIgYSBsb2NhdGlvbiI6IkVudGVyIGEgbG9jYXRpb24iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IkVudGVyIHN1YnVyYiwgdG93biBvciBjaXR5IiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IldoZXJlIiwiRW50ZXIgYSBsb2NhdGlvbiI6IkVudGVyIGEgbG9jYXRpb24iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IkVudGVyIHN1YnVyYiwgdG93biBvciBjaXR5IiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IldoZXJlIiwiRW50ZXIgYSBsb2NhdGlvbiI6IkVudGVyIGEgbG9jYXRpb24iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IkVudGVyIHN1YnVyYiwgdG93biBvciBjaXR5IiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IldoZXJlIiwiRW50ZXIgYSBsb2NhdGlvbiI6IkVudGVyIGEgbG9jYXRpb24iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IkVudGVyIHN1YnVyYiwgdG93biBvciBjaXR5IiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IldoZXJlIiwiRW50ZXIgYSBsb2NhdGlvbiI6IkVudGVyIGEgbG9jYXRpb24iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IkVudGVyIHN1YnVyYiwgdG93biBvciBjaXR5IiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IldoZXJlIiwiRW50ZXIgYSBsb2NhdGlvbiI6IkVudGVyIGEgbG9jYXRpb24iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IkVudGVyIHN1YnVyYiwgdG93biBvciBjaXR5IiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IldoZXJlIiwiRW50ZXIgYSBsb2NhdGlvbiI6IkVudGVyIGEgbG9jYXRpb24iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IkVudGVyIHN1YnVyYiwgdG93biBvciBjaXR5IiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IkRpIG1hbmEiLCJFbnRlciBhIGxvY2F0aW9uIjoiTWFzdWtrYW4gbG9rYXNpIiwiRW50ZXIgc3VidXJiLCB0b3duIG9yIGNpdHkiOiJNYXN1a2thbiBwaW5nZ2lyYW4ga290YSwga290YSBrZWNpbCwgYXRhdSBrb3RhIiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJNYXN1a2thbiBwaW5nZ2lyYW4ga290YSwga290YSwgYXRhdSB3aWxheWFoIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IkRpIG1hbmEiLCJFbnRlciBhIGxvY2F0aW9uIjoiTWFzdWtrYW4gbG9rYXNpIiwiRW50ZXIgc3VidXJiLCB0b3duIG9yIGNpdHkiOiJNYXN1a2thbiBwaW5nZ2lyYW4ga290YSwga290YSBrZWNpbCwgYXRhdSBrb3RhIiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJNYXN1a2thbiBwaW5nZ2lyYW4ga290YSwga290YSwgYXRhdSB3aWxheWFoIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IuC4quC4luC4suC4meC4l%2BC4teC5iCIsIkVudGVyIGEgbG9jYXRpb24iOiLguKPguLDguJrguLjguKrguJbguLLguJnguJfguLXguYgiLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IuC4o%2BC4sOC4muC4uOC4leC4s%2BC4muC4pSDguYDguKHguLfguK3guIcg4Lir4Lij4Li34Lit4Lit4Liz4LmA4Lig4LitIiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiLguKPguLDguJrguLjguJXguLPguJrguKUg4Lit4Liz4LmA4Lig4LitIOC4q%2BC4o%2BC4t%2BC4reC4oOC4ueC4oeC4tOC4oOC4suC4hCJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IuC4quC4luC4suC4meC4l%2BC4teC5iCIsIkVudGVyIGEgbG9jYXRpb24iOiLguKPguLDguJrguLjguKrguJbguLLguJnguJfguLXguYgiLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IuC4o%2BC4sOC4muC4uOC4leC4s%2BC4muC4pSDguYDguKHguLfguK3guIcg4Lir4Lij4Li34Lit4Lit4Liz4LmA4Lig4LitIiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiLguKPguLDguJrguLjguJXguLPguJrguKUg4Lit4Liz4LmA4Lig4LitIOC4q%2BC4o%2BC4t%2BC4reC4oOC4ueC4oeC4tOC4oOC4suC4hCJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IkRpIG1hbmEiLCJFbnRlciBhIGxvY2F0aW9uIjoiTWFzdWtrYW4gbG9rYXNpIiwiRW50ZXIgc3VidXJiLCB0b3duIG9yIGNpdHkiOiJNYXN1a2thbiBwaW5nZ2lyYW4ga290YSwga290YSBrZWNpbCwgYXRhdSBrb3RhIiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJNYXN1a2thbiBwaW5nZ2lyYW4ga290YSwga290YSwgYXRhdSB3aWxheWFoIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IkRpIG1hbmEiLCJFbnRlciBhIGxvY2F0aW9uIjoiTWFzdWtrYW4gbG9rYXNpIiwiRW50ZXIgc3VidXJiLCB0b3duIG9yIGNpdHkiOiJNYXN1a2thbiBwaW5nZ2lyYW4ga290YSwga290YSBrZWNpbCwgYXRhdSBrb3RhIiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiJNYXN1a2thbiBwaW5nZ2lyYW4ga290YSwga290YSwgYXRhdSB3aWxheWFoIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IuC4quC4luC4suC4meC4l%2BC4teC5iCIsIkVudGVyIGEgbG9jYXRpb24iOiLguKPguLDguJrguLjguKrguJbguLLguJnguJfguLXguYgiLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IuC4o%2BC4sOC4muC4uOC4leC4s%2BC4muC4pSDguYDguKHguLfguK3guIcg4Lir4Lij4Li34Lit4Lit4Liz4LmA4Lig4LitIiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiLguKPguLDguJrguLjguJXguLPguJrguKUg4Lit4Liz4LmA4Lig4LitIOC4q%2BC4o%2BC4t%2BC4reC4oOC4ueC4oeC4tOC4oOC4suC4hCJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IuC4quC4luC4suC4meC4l%2BC4teC5iCIsIkVudGVyIGEgbG9jYXRpb24iOiLguKPguLDguJrguLjguKrguJbguLLguJnguJfguLXguYgiLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IuC4o%2BC4sOC4muC4uOC4leC4s%2BC4muC4pSDguYDguKHguLfguK3guIcg4Lir4Lij4Li34Lit4Lit4Liz4LmA4Lig4LitIiwiRW50ZXIgc3VidXJiLCBjaXR5LCBvciByZWdpb24iOiLguKPguLDguJrguLjguJXguLPguJrguKUg4Lit4Liz4LmA4Lig4LitIOC4q%2BC4o%2BC4t%2BC4reC4oOC4ueC4oeC4tOC4oOC4suC4hCJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IlvFtOG4qeG6veG6vcWZ4bq94bq9XSIsIkVudGVyIGEgbG9jYXRpb24iOiJbw4vguIHguLXguYnhua%2Fhur3hur3hur3FmSDEg8SDxIMgxprDtsO2w7bDp8SDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5iV0iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IlvDi%2BC4geC4teC5ieG5r%2BG6veG6veG6vcWZIMWhx5rHmseaw5%2FHmseax5rFmcOfLCDhua%2FDtsO2w7bFteC4geC4teC5iSDDtsO2w7bFmSDDp8Osw6zDrOG5r8O9w73DvV0iLCJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiI6IlvDi%2BC4geC4teC5ieG5r%2BG6veG6veG6vcWZIMWhx5rHmseaw5%2FHmseax5rFmcOfLCDDp8Osw6zDrOG5r8O9w73DvSwgw7bDtsO2xZkgxZnhur3hur3hur3Eo8Osw6zDrMO2w7bDtuC4geC4teC5iV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGVyZSI6IlvFtOG4qeG6veG6vcWZ4bq94bq9XSIsIkVudGVyIGEgbG9jYXRpb24iOiJbw4vguIHguLXguYnhua%2Fhur3hur3hur3FmSDEg8SDxIMgxprDtsO2w7bDp8SDxIPEg%2BG5r8Osw6zDrMO2w7bDtuC4geC4teC5iV0iLCJFbnRlciBzdWJ1cmIsIHRvd24gb3IgY2l0eSI6IlvDi%2BC4geC4teC5ieG5r%2BG6veG6veG6vcWZIMWhx5rHmseaw5%2FHmseax5rFmcOfLCDhua%2FDtsO2w7bFteC4geC4teC5iSDDtsO2w7bFmSDDp8Osw6zDrOG5r8O9w73DvV0iLCJFbnRlciBzdWJ1cmIsIGNpdHksIG9yIHJlZ2lvbiI6IlvDi%2BC4geC4teC5ieG5r%2BG6veG6veG6vcWZIMWhx5rHmseaw5%2FHmseax5rFmcOfLCDDp8Osw6zDrOG5r8O9w73DvSwgw7bDtsO2xZkgxZnhur3hur3hur3Eo8Osw6zDrMO2w7bDtuC4geC4teC5iV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    