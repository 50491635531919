import { Heading, HiddenVisually, Stack, Tiles } from 'braid-design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getWorkTypes } from 'src/modules/refine-job-search';
import type { Option } from 'src/types/globals';

import { useSecondaryFilterContext } from '../../context/SecondaryFilterContext';
import { CheckboxFilter } from '../CheckboxFilter/CheckboxFilter';

export const WorkType = () => {
  const { filterSelection, setFilterSelection, language, zone } =
    useSecondaryFilterContext();
  const workTypeList = getWorkTypes(zone, language);
  const workTypesArray = useMemo(() => {
    const workTypeSelection = filterSelection?.worktype
      ? filterSelection.worktype.split(',')
      : [];
    return workTypeSelection;
  }, [filterSelection?.worktype]);
  const [activeValue, setActiveValue] = useState<string[]>(workTypesArray);

  const getOptionItem = useCallback(
    (label: string, value: string) => {
      const isActive = activeValue.includes(value);
      const workTypeRefineParam = isActive
        ? activeValue.filter((v) => v !== value)
        : [...activeValue, value];

      return {
        label,
        value,
        isActive,
        refineParams: {
          ...filterSelection,
          worktype: workTypeRefineParam.join(','),
        },
      };
    },
    [activeValue, filterSelection],
  );

  const workTypeOptions: Option[] = useMemo(
    () =>
      workTypeList
        ? workTypeList.map(({ label, value }) => getOptionItem(label, value))
        : [],
    [workTypeList, getOptionItem],
  );

  const handleWorkTypeSelection = (value: string) => {
    setActiveValue(() => {
      if (activeValue.includes(value)) {
        return activeValue.filter((v) => v !== value);
      }

      return [...activeValue, value];
    });

    setFilterSelection((currentFilterSelection) => ({
      ...currentFilterSelection,
      worktype: (activeValue.includes(value)
        ? activeValue.filter((v) => v !== value)
        : [...activeValue, value]
      ).join(','),
    }));
  };

  useEffect(() => {
    setActiveValue(workTypesArray);
  }, [workTypesArray]);

  return (
    <Stack space="medium" data={{ automation: 'workType' }}>
      <Heading level="4">{'Work type'}</Heading>
      <nav role="navigation" aria-labelledby="RefineWorkType">
        <HiddenVisually>
          <h1 id="RefineWorkType">{'Refine by Work Type'}</h1>
        </HiddenVisually>
        <Tiles columns={[1, 2]} space="medium">
          {workTypeOptions.map((workType) => (
            <CheckboxFilter
              key={workType.label}
              path="/jobs"
              option={workType}
              handleSelection={handleWorkTypeSelection}
            />
          ))}
        </Tiles>
      </nav>
    </Stack>
  );
};
