import type { Zone } from '@seek/audience-zones';
import type { SearchParams } from '@seek/chalice-types';

import { shouldRenderPOEA } from '../seo-header/search-results-page/utils';

// Based on the logic from the deprecated getRelatedSearchTerm
// (https://github.com/SEEK-Jobs/chalice/blob/811ac8ac900208aeec7d1400259b4c267a47c4f0/src/store/results/index.ts#L501-L520)
export const shouldRequestRelatedSearches = ({
  searchParams: { keywords, classification, subclassification },
  zone,
}: {
  searchParams: SearchParams;
  zone?: Zone;
}): boolean =>
  !(zone && shouldRenderPOEA({ keywords, zone })) &&
  Boolean(keywords || classification || subclassification);
