import {
  useExperimentsEligibleProps,
  type FeatureFlagProviderProps,
} from '@seek/candidate-feature-flag';
import { useHubble } from '@seek/hubble';

import { appName, version } from 'src/config';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';

export const useFeatureFlagProviderProps = (): Omit<
  FeatureFlagProviderProps,
  'children'
> => {
  const hubble = useHubble();
  const solId = hubble.visitorId();

  return {
    featureFlags: [],
    solId,
    experimentKeysAndTrackingNumber: [],
    customContext: {
      applicationContext: {
        key: solId,
        namespace: appName,
        build: version,
        version,
      },
    },
  };
};

export const InitialiseExperiments = () => {
  const analyticsFacade = useAnalyticsFacade();
  const { tealiumEligibleProps } = useExperimentsEligibleProps();
  if (tealiumEligibleProps) {
    analyticsFacade.setExperimentEligible(tealiumEligibleProps);
  }

  return null;
};
