import { useSelector } from 'src/store/react';
import {
  selectAuthenticated,
  selectEmailAddress,
  selectFirstName,
} from 'src/store/selectors';

const AUTHENTICATED = 'authenticated' as const;
const PENDING = 'pending' as const;
const UNAUTHENTICATED = 'unauthenticated' as const;

type authenticatedType =
  | typeof AUTHENTICATED
  | typeof PENDING
  | typeof UNAUTHENTICATED;

const useAuthenticated = (): authenticatedType => {
  const userName = useSelector(selectFirstName);
  const emailAddress = useSelector(selectEmailAddress);
  const isAuthenticated = useSelector(selectAuthenticated);

  const isAuthenticatedOrPending =
    userName || emailAddress ? 'authenticated' : 'pending';
  if (isAuthenticated) {
    if (isAuthenticatedOrPending === 'authenticated') {
      return AUTHENTICATED;
    }
    return PENDING;
  }
  return UNAUTHENTICATED;
};

export default useAuthenticated;
