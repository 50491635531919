import { set as setLocalStorage, get as getLocalStorage } from 'local-storage';

const LOCAL_STORAGE_KEY = 'gNB';

export const useGlobalNotificationBannerLocalStorage = (bannerKey: string) => {
  const getAllGlobalBanners = () =>
    JSON.parse(getLocalStorage(LOCAL_STORAGE_KEY));

  const setBannerHasBeenSeen = () => {
    setLocalStorage(
      LOCAL_STORAGE_KEY,
      JSON.stringify({ ...getAllGlobalBanners(), [bannerKey]: true }),
    );
  };

  const isBannerHasBeenSeen = () => getAllGlobalBanners()?.[bannerKey] ?? false;

  return {
    isBannerHasBeenSeen,
    setBannerHasBeenSeen,
  };
};
