/** This file was generated by `pnpm generate-classifications` **/
import type { AllClassifications } from 'src/config/types';

export const classifications: Record<string, AllClassifications> = {
  'anz-1': {
    en: [
      {
        id: 1200,
        description: 'Accounting',
        subClassifications: [
          { id: 6140, description: 'Accounts Officers/Clerks' },
          { id: 1303, description: 'Accounts Payable' },
          { id: 6141, description: 'Accounts Receivable/Credit Control' },
          { id: 6143, description: 'Analysis & Reporting' },
          { id: 6142, description: 'Assistant Accountants' },
          { id: 6144, description: 'Audit - External' },
          { id: 6145, description: 'Audit - Internal' },
          { id: 6146, description: 'Bookkeeping & Small Practice Accounting' },
          { id: 6147, description: 'Business Services & Corporate Advisory' },
          { id: 6148, description: 'Company Secretaries' },
          { id: 6149, description: 'Compliance & Risk' },
          { id: 6150, description: 'Cost Accounting' },
          { id: 6151, description: 'Financial Accounting & Reporting' },
          { id: 6152, description: 'Financial Managers & Controllers' },
          { id: 6153, description: 'Forensic Accounting & Investigation' },
          { id: 6154, description: 'Insolvency & Corporate Recovery' },
          { id: 6155, description: 'Inventory & Fixed Assets' },
          { id: 6156, description: 'Management' },
          { id: 6157, description: 'Management Accounting & Budgeting' },
          { id: 1468, description: 'Payroll' },
          { id: 6158, description: 'Strategy & Planning' },
          { id: 6159, description: 'Systems Accounting & IT Audit' },
          { id: 6160, description: 'Taxation' },
          { id: 6161, description: 'Treasury' },
          { id: 6162, description: 'Other' },
        ],
      },
      {
        id: 6251,
        description: 'Administration & Office Support',
        subClassifications: [
          { id: 6252, description: 'Administrative Assistants' },
          { id: 6253, description: 'Client & Sales Administration' },
          { id: 6254, description: 'Contracts Administration' },
          { id: 6255, description: 'Data Entry & Word Processing' },
          { id: 6256, description: 'Office Management' },
          { id: 6257, description: 'PA, EA & Secretarial' },
          { id: 6258, description: 'Receptionists' },
          { id: 6259, description: 'Records Management & Document Control' },
          { id: 6260, description: 'Other' },
        ],
      },
      {
        id: 6304,
        description: 'Advertising, Arts & Media',
        subClassifications: [
          { id: 6305, description: 'Agency Account Management' },
          { id: 6306, description: 'Art Direction' },
          { id: 6307, description: 'Editing & Publishing' },
          { id: 6308, description: 'Event Management' },
          { id: 6309, description: 'Journalism & Writing' },
          { id: 6310, description: 'Management' },
          { id: 6311, description: 'Media Strategy, Planning & Buying' },
          { id: 6312, description: 'Performing Arts' },
          { id: 6313, description: 'Photography' },
          { id: 6314, description: 'Programming & Production' },
          { id: 6315, description: 'Promotions' },
          { id: 6316, description: 'Other' },
        ],
      },
      {
        id: 1203,
        description: 'Banking & Financial Services',
        subClassifications: [
          { id: 6174, description: 'Account & Relationship Management' },
          { id: 6175, description: 'Analysis & Reporting' },
          { id: 6177, description: 'Banking - Business' },
          { id: 6178, description: 'Banking - Corporate & Institutional' },
          { id: 6176, description: 'Banking - Retail/Branch' },
          { id: 1352, description: 'Client Services' },
          { id: 6179, description: 'Compliance & Risk' },
          { id: 6180, description: 'Corporate Finance & Investment Banking' },
          { id: 6181, description: 'Credit' },
          { id: 1392, description: 'Financial Planning' },
          { id: 1404, description: 'Funds Management' },
          { id: 6182, description: 'Management' },
          { id: 6183, description: 'Mortgages' },
          { id: 6184, description: 'Settlements' },
          { id: 6185, description: 'Stockbroking & Trading' },
          { id: 6186, description: 'Treasury' },
          { id: 6187, description: 'Other' },
        ],
      },
      {
        id: 1204,
        description: 'Call Centre & Customer Service',
        subClassifications: [
          { id: 6084, description: 'Collections' },
          { id: 6085, description: 'Customer Service - Call Centre' },
          { id: 6086, description: 'Customer Service - Customer Facing' },
          { id: 6087, description: 'Management & Support' },
          { id: 6088, description: 'Sales - Inbound' },
          { id: 6089, description: 'Sales - Outbound' },
          { id: 6090, description: 'Supervisors/Team Leaders' },
          { id: 6091, description: 'Other' },
        ],
      },
      {
        id: 7019,
        description: 'CEO & General Management',
        subClassifications: [
          { id: 7020, description: 'Board Appointments' },
          { id: 7021, description: 'CEO' },
          { id: 7022, description: 'COO & MD' },
          { id: 7023, description: 'General/Business Unit Manager' },
          { id: 7024, description: 'Other' },
        ],
      },
      {
        id: 6163,
        description: 'Community Services & Development',
        subClassifications: [
          { id: 6164, description: 'Aged & Disability Support' },
          { id: 6165, description: 'Child Welfare, Youth & Family Services' },
          { id: 6166, description: 'Community Development' },
          { id: 6167, description: 'Employment Services' },
          { id: 6168, description: 'Fundraising' },
          { id: 6169, description: 'Housing & Homelessness Services' },
          { id: 6170, description: 'Indigenous & Multicultural Services' },
          { id: 6171, description: 'Management' },
          { id: 6172, description: 'Volunteer Coordination & Support' },
          { id: 6173, description: 'Other' },
        ],
      },
      {
        id: 1206,
        description: 'Construction',
        subClassifications: [
          { id: 6113, description: 'Contracts Management' },
          { id: 1387, description: 'Estimating' },
          { id: 6114, description: 'Foreperson/Supervisors' },
          { id: 6115, description: 'Health, Safety & Environment' },
          { id: 6116, description: 'Management' },
          { id: 6117, description: 'Planning & Scheduling' },
          { id: 6118, description: 'Plant & Machinery Operators' },
          { id: 6119, description: 'Project Management' },
          { id: 6120, description: 'Quality Assurance & Control' },
          { id: 6121, description: 'Surveying' },
          { id: 6122, description: 'Other' },
        ],
      },
      {
        id: 6076,
        description: 'Consulting & Strategy',
        subClassifications: [
          { id: 6077, description: 'Analysts' },
          { id: 6078, description: 'Corporate Development' },
          { id: 6079, description: 'Environment & Sustainability Consulting' },
          { id: 6080, description: 'Management & Change Consulting' },
          { id: 6081, description: 'Policy' },
          { id: 6082, description: 'Strategy & Planning' },
          { id: 6083, description: 'Other' },
        ],
      },
      {
        id: 6263,
        description: 'Design & Architecture',
        subClassifications: [
          { id: 6265, description: 'Architectural Drafting' },
          { id: 6264, description: 'Architecture' },
          { id: 6267, description: 'Fashion & Textile Design' },
          { id: 6268, description: 'Graphic Design' },
          { id: 6266, description: 'Illustration & Animation' },
          { id: 6269, description: 'Industrial Design' },
          { id: 6270, description: 'Interior Design' },
          { id: 6271, description: 'Landscape Architecture' },
          { id: 6272, description: 'Urban Design & Planning' },
          { id: 6273, description: 'Web & Interaction Design' },
          { id: 6274, description: 'Other' },
        ],
      },
      {
        id: 6123,
        description: 'Education & Training',
        subClassifications: [
          { id: 6124, description: 'Childcare & Outside School Hours Care' },
          {
            id: 6125,
            description: 'Library Services & Information Management',
          },
          { id: 6126, description: 'Management - Schools' },
          { id: 6127, description: 'Management - Universities' },
          { id: 6128, description: 'Management - Vocational' },
          { id: 6129, description: 'Research & Fellowships' },
          { id: 6130, description: 'Student Services' },
          { id: 6132, description: 'Teaching - Early Childhood' },
          { id: 6133, description: 'Teaching - Primary' },
          { id: 6134, description: 'Teaching - Secondary' },
          { id: 6135, description: 'Teaching - Tertiary' },
          { id: 6136, description: 'Teaching - Vocational' },
          { id: 6131, description: 'Teaching Aides & Special Needs' },
          { id: 6137, description: 'Tutoring' },
          { id: 6138, description: 'Workplace Training & Assessment' },
          { id: 6139, description: 'Other' },
        ],
      },
      {
        id: 1209,
        description: 'Engineering',
        subClassifications: [
          { id: 6022, description: 'Aerospace Engineering' },
          { id: 6023, description: 'Automotive Engineering' },
          { id: 6024, description: 'Building Services Engineering' },
          { id: 6026, description: 'Chemical Engineering' },
          { id: 6027, description: 'Civil/Structural Engineering' },
          { id: 6028, description: 'Electrical/Electronic Engineering' },
          { id: 6025, description: 'Engineering Drafting' },
          { id: 6029, description: 'Environmental Engineering' },
          { id: 6030, description: 'Field Engineering' },
          { id: 6031, description: 'Industrial Engineering' },
          { id: 6032, description: 'Maintenance' },
          { id: 6033, description: 'Management' },
          { id: 6034, description: 'Materials Handling Engineering' },
          { id: 6035, description: 'Mechanical Engineering' },
          { id: 6036, description: 'Process Engineering' },
          { id: 6038, description: 'Project Engineering' },
          { id: 6037, description: 'Project Management' },
          { id: 6039, description: 'Supervisors' },
          { id: 6040, description: 'Systems Engineering' },
          { id: 6041, description: 'Water & Waste Engineering' },
          { id: 6042, description: 'Other' },
        ],
      },
      {
        id: 6205,
        description: 'Farming, Animals & Conservation',
        subClassifications: [
          { id: 6206, description: 'Agronomy & Farm Services' },
          { id: 6207, description: 'Conservation, Parks & Wildlife' },
          { id: 6208, description: 'Farm Labour' },
          { id: 6209, description: 'Farm Management' },
          { id: 6210, description: 'Fishing & Aquaculture' },
          { id: 6211, description: 'Horticulture' },
          { id: 6212, description: 'Veterinary Services & Animal Welfare' },
          { id: 6213, description: 'Winery & Viticulture' },
          { id: 6214, description: 'Other' },
        ],
      },
      {
        id: 1210,
        description: 'Government & Defence',
        subClassifications: [
          { id: 1314, description: 'Air Force' },
          { id: 1322, description: 'Army' },
          { id: 1378, description: 'Emergency Services' },
          { id: 1409, description: 'Government - Federal' },
          { id: 1410, description: 'Government - Local' },
          { id: 1411, description: 'Government - State' },
          { id: 1450, description: 'Navy' },
          { id: 6359, description: 'Police & Corrections' },
          { id: 6360, description: 'Policy, Planning & Regulation' },
          { id: 6361, description: 'Other' },
        ],
      },
      {
        id: 1211,
        description: 'Healthcare & Medical',
        subClassifications: [
          { id: 6329, description: 'Ambulance/Paramedics' },
          { id: 6330, description: 'Chiropractic & Osteopathic' },
          { id: 1353, description: 'Clinical/Medical Research' },
          { id: 1372, description: 'Dental' },
          { id: 6331, description: 'Dieticians' },
          { id: 1386, description: 'Environmental Services' },
          { id: 6332, description: 'General Practitioners' },
          { id: 6333, description: 'Management' },
          { id: 6334, description: 'Medical Administration' },
          { id: 6335, description: 'Medical Imaging' },
          { id: 6336, description: 'Medical Specialists' },
          { id: 6337, description: 'Natural Therapies & Alternative Medicine' },
          { id: 6338, description: 'Nursing - A&E, Critical Care & ICU' },
          { id: 6339, description: 'Nursing - Aged Care' },
          {
            id: 6340,
            description: 'Nursing - Community, Maternal & Child Health',
          },
          { id: 6341, description: 'Nursing - Educators & Facilitators' },
          { id: 6342, description: 'Nursing - General Medical & Surgical' },
          { id: 6343, description: 'Nursing - High Acuity' },
          { id: 6344, description: 'Nursing - Management' },
          {
            id: 6345,
            description: 'Nursing - Midwifery, Neo-Natal, SCN & NICU',
          },
          { id: 6346, description: 'Nursing - Paediatric & PICU' },
          {
            id: 6347,
            description: 'Nursing - Psych, Forensic & Correctional Health',
          },
          { id: 6348, description: 'Nursing - Theatre & Recovery' },
          { id: 6349, description: 'Optical' },
          { id: 1467, description: 'Pathology' },
          { id: 6350, description: 'Pharmaceuticals & Medical Devices' },
          { id: 1470, description: 'Pharmacy' },
          { id: 6352, description: 'Physiotherapy, OT & Rehabilitation' },
          { id: 6351, description: 'Psychology, Counselling & Social Work' },
          { id: 6370, description: 'Residents & Registrars' },
          { id: 6353, description: 'Sales' },
          { id: 6354, description: 'Speech Therapy' },
          { id: 6355, description: 'Other' },
        ],
      },
      {
        id: 1212,
        description: 'Hospitality & Tourism',
        subClassifications: [
          { id: 1315, description: 'Airlines' },
          { id: 1332, description: 'Bar & Beverage Staff' },
          { id: 6052, description: 'Chefs/Cooks' },
          { id: 6053, description: 'Front Office & Guest Services' },
          { id: 1405, description: 'Gaming' },
          { id: 1415, description: 'Housekeeping' },
          { id: 6054, description: 'Kitchen & Sandwich Hands' },
          { id: 6055, description: 'Management' },
          { id: 6056, description: 'Reservations' },
          { id: 1537, description: 'Tour Guides' },
          { id: 1542, description: 'Travel Agents/Consultants' },
          { id: 1549, description: 'Waiting Staff' },
          { id: 6057, description: 'Other' },
        ],
      },
      {
        id: 6317,
        description: 'Human Resources & Recruitment',
        subClassifications: [
          { id: 6318, description: 'Consulting & Generalist HR' },
          { id: 6319, description: 'Industrial & Employee Relations' },
          { id: 6320, description: 'Management - Agency' },
          { id: 6321, description: 'Management - Internal' },
          { id: 6322, description: 'Occupational Health & Safety' },
          { id: 6323, description: 'Organisational Development' },
          { id: 6324, description: 'Recruitment - Agency' },
          { id: 6325, description: 'Recruitment - Internal' },
          { id: 6326, description: 'Remuneration & Benefits' },
          { id: 6327, description: 'Training & Development' },
          { id: 6328, description: 'Other' },
        ],
      },
      {
        id: 6281,
        description: 'Information & Communication Technology',
        subClassifications: [
          { id: 6282, description: 'Architects' },
          { id: 6283, description: 'Business/Systems Analysts' },
          { id: 6284, description: 'Computer Operators' },
          { id: 6285, description: 'Consultants' },
          { id: 6286, description: 'Database Development & Administration' },
          { id: 6287, description: 'Developers/Programmers' },
          { id: 6288, description: 'Engineering - Hardware' },
          { id: 6289, description: 'Engineering - Network' },
          { id: 6290, description: 'Engineering - Software' },
          { id: 6291, description: 'Help Desk & IT Support' },
          { id: 6292, description: 'Management' },
          { id: 6293, description: 'Networks & Systems Administration' },
          { id: 6294, description: 'Product Management & Development' },
          { id: 6295, description: 'Programme & Project Management' },
          { id: 6296, description: 'Sales - Pre & Post' },
          { id: 6297, description: 'Security' },
          { id: 6298, description: 'Team Leaders' },
          { id: 6299, description: 'Technical Writing' },
          { id: 6300, description: 'Telecommunications' },
          { id: 6301, description: 'Testing & Quality Assurance' },
          { id: 6302, description: 'Web Development & Production' },
          { id: 6303, description: 'Other' },
        ],
      },
      {
        id: 1214,
        description: 'Insurance & Superannuation',
        subClassifications: [
          { id: 6275, description: 'Actuarial' },
          { id: 6276, description: 'Assessment' },
          { id: 6277, description: 'Brokerage' },
          { id: 1350, description: 'Claims' },
          { id: 6278, description: 'Fund Administration' },
          { id: 6279, description: 'Management' },
          { id: 1499, description: 'Risk Consulting' },
          { id: 1524, description: 'Superannuation' },
          { id: 1544, description: 'Underwriting' },
          { id: 1553, description: "Workers' Compensation" },
          { id: 6280, description: 'Other' },
        ],
      },
      {
        id: 1216,
        description: 'Legal',
        subClassifications: [
          { id: 6188, description: 'Banking & Finance Law' },
          { id: 6190, description: 'Construction Law' },
          { id: 6191, description: 'Corporate & Commercial Law' },
          { id: 6189, description: 'Criminal & Civil Law' },
          { id: 6192, description: 'Environment & Planning Law' },
          { id: 6193, description: 'Family Law' },
          { id: 6194, description: 'Generalists - In-house' },
          { id: 6195, description: 'Generalists - Law Firm' },
          { id: 6196, description: 'Industrial Relations & Employment Law' },
          { id: 6197, description: 'Insurance & Superannuation Law' },
          { id: 6198, description: 'Intellectual Property Law' },
          { id: 1429, description: 'Law Clerks & Paralegals' },
          { id: 6199, description: 'Legal Practice Management' },
          { id: 1431, description: 'Legal Secretaries' },
          { id: 6200, description: 'Litigation & Dispute Resolution' },
          { id: 6201, description: 'Personal Injury Law' },
          { id: 6202, description: 'Property Law' },
          { id: 6203, description: 'Tax Law' },
          { id: 6204, description: 'Other' },
        ],
      },
      {
        id: 6092,
        description: 'Manufacturing, Transport & Logistics',
        subClassifications: [
          { id: 6095, description: 'Analysis & Reporting' },
          { id: 6093, description: 'Assembly & Process Work' },
          { id: 6094, description: 'Aviation Services' },
          { id: 6096, description: 'Couriers, Drivers & Postal Services' },
          { id: 6097, description: 'Fleet Management' },
          { id: 6098, description: 'Freight/Cargo Forwarding' },
          { id: 6099, description: 'Import/Export & Customs' },
          { id: 6101, description: 'Machine Operators' },
          { id: 6102, description: 'Management' },
          { id: 6104, description: 'Pattern Makers & Garment Technicians' },
          { id: 6105, description: 'Pickers & Packers' },
          { id: 6103, description: 'Production, Planning & Scheduling' },
          { id: 6100, description: 'Public Transport & Taxi Services' },
          { id: 6109, description: 'Purchasing, Procurement & Inventory' },
          { id: 6106, description: 'Quality Assurance & Control' },
          { id: 6110, description: 'Rail & Maritime Transport' },
          { id: 6111, description: 'Road Transport' },
          { id: 6107, description: 'Team Leaders/Supervisors' },
          { id: 6108, description: 'Warehousing, Storage & Distribution' },
          { id: 6112, description: 'Other' },
        ],
      },
      {
        id: 6008,
        description: 'Marketing & Communications',
        subClassifications: [
          { id: 6009, description: 'Brand Management' },
          { id: 6010, description: 'Digital & Search Marketing' },
          { id: 6011, description: 'Direct Marketing & CRM' },
          { id: 6012, description: 'Event Management' },
          { id: 6013, description: 'Internal Communications' },
          { id: 6017, description: 'Management' },
          { id: 6014, description: 'Market Research & Analysis' },
          { id: 6015, description: 'Marketing Assistants/Coordinators' },
          { id: 6016, description: 'Marketing Communications' },
          { id: 6018, description: 'Product Management & Development' },
          { id: 6019, description: 'Public Relations & Corporate Affairs' },
          { id: 6020, description: 'Trade Marketing' },
          { id: 6021, description: 'Other' },
        ],
      },
      {
        id: 6058,
        description: 'Mining, Resources & Energy',
        subClassifications: [
          { id: 6059, description: 'Analysis & Reporting' },
          { id: 6060, description: 'Health, Safety & Environment' },
          { id: 6061, description: 'Management' },
          { id: 6062, description: 'Mining - Drill & Blast' },
          { id: 6063, description: 'Mining - Engineering & Maintenance' },
          { id: 6064, description: 'Mining - Exploration & Geoscience' },
          { id: 6066, description: 'Mining - Operations' },
          { id: 6065, description: 'Mining - Processing' },
          { id: 6073, description: 'Natural Resources & Water' },
          { id: 6067, description: 'Oil & Gas - Drilling' },
          { id: 6068, description: 'Oil & Gas - Engineering & Maintenance' },
          { id: 6069, description: 'Oil & Gas - Exploration & Geoscience' },
          { id: 6071, description: 'Oil & Gas - Operations' },
          { id: 6070, description: 'Oil & Gas - Production & Refinement' },
          { id: 6072, description: 'Power Generation & Distribution' },
          { id: 6074, description: 'Surveying' },
          { id: 6075, description: 'Other' },
        ],
      },
      {
        id: 1220,
        description: 'Real Estate & Property',
        subClassifications: [
          { id: 6371, description: 'Administration' },
          { id: 6001, description: 'Analysts' },
          { id: 6002, description: 'Body Corporate & Facilities Management' },
          {
            id: 6003,
            description: 'Commercial Sales, Leasing & Property Mgmt',
          },
          {
            id: 6004,
            description: 'Residential Leasing & Property Management',
          },
          { id: 6005, description: 'Residential Sales' },
          { id: 6006, description: 'Retail & Property Development' },
          { id: 1546, description: 'Valuation' },
          { id: 6007, description: 'Other' },
        ],
      },
      {
        id: 6043,
        description: 'Retail & Consumer Products',
        subClassifications: [
          { id: 6044, description: 'Buying' },
          { id: 6045, description: 'Management - Area/Multi-site' },
          { id: 6046, description: 'Management - Department/Assistant' },
          { id: 6047, description: 'Management - Store' },
          { id: 6048, description: 'Merchandisers' },
          { id: 6049, description: 'Planning' },
          { id: 6050, description: 'Retail Assistants' },
          { id: 6051, description: 'Other' },
        ],
      },
      {
        id: 6362,
        description: 'Sales',
        subClassifications: [
          { id: 6363, description: 'Account & Relationship Management' },
          { id: 6364, description: 'Analysis & Reporting' },
          { id: 6366, description: 'Management' },
          { id: 6365, description: 'New Business Development' },
          { id: 6367, description: 'Sales Coordinators' },
          { id: 6368, description: 'Sales Representatives/Consultants' },
          { id: 6369, description: 'Other' },
        ],
      },
      {
        id: 1223,
        description: 'Science & Technology',
        subClassifications: [
          { id: 6215, description: 'Biological & Biomedical Sciences' },
          { id: 6216, description: 'Biotechnology & Genetics' },
          { id: 6217, description: 'Chemistry & Physics' },
          { id: 6218, description: 'Environmental, Earth & Geosciences' },
          { id: 6219, description: 'Food Technology & Safety' },
          { id: 6220, description: 'Laboratory & Technical Services' },
          { id: 6221, description: 'Materials Sciences' },
          {
            id: 6222,
            description: 'Mathematics, Statistics & Information Sciences',
          },
          { id: 6223, description: 'Modelling & Simulation' },
          { id: 6224, description: 'Quality Assurance & Control' },
          { id: 6225, description: 'Other' },
        ],
      },
      {
        id: 6261,
        description: 'Self Employment',
        subClassifications: [{ id: 6262, description: 'Self Employment' }],
      },
      {
        id: 6246,
        description: 'Sport & Recreation',
        subClassifications: [
          { id: 6247, description: 'Coaching & Instruction' },
          { id: 6248, description: 'Fitness & Personal Training' },
          { id: 6249, description: 'Management' },
          { id: 6250, description: 'Other' },
        ],
      },
      {
        id: 1225,
        description: 'Trades & Services',
        subClassifications: [
          { id: 1313, description: 'Air Conditioning & Refrigeration' },
          { id: 1328, description: 'Automotive Trades' },
          { id: 6226, description: 'Bakers & Pastry Chefs' },
          { id: 6227, description: 'Building Trades' },
          { id: 6228, description: 'Butchers' },
          { id: 1345, description: 'Carpentry & Cabinet Making' },
          { id: 6229, description: 'Cleaning Services' },
          { id: 6230, description: 'Electricians' },
          { id: 6231, description: 'Fitters, Turners & Machinists' },
          { id: 6232, description: 'Floristry' },
          { id: 1406, description: 'Gardening & Landscaping' },
          { id: 6233, description: 'Hair & Beauty Services' },
          { id: 6234, description: 'Labourers' },
          { id: 6235, description: 'Locksmiths' },
          { id: 6236, description: 'Maintenance & Handyperson Services' },
          { id: 6237, description: 'Nannies & Babysitters' },
          { id: 6238, description: 'Painters & Sign Writers' },
          { id: 6240, description: 'Plumbers' },
          { id: 6239, description: 'Printing & Publishing Services' },
          { id: 6241, description: 'Security Services' },
          { id: 6242, description: 'Tailors & Dressmakers' },
          { id: 6243, description: 'Technicians' },
          { id: 6244, description: 'Welders & Boilermakers' },
          { id: 6245, description: 'Other' },
        ],
      },
    ],
  },
  'anz-2': {
    en: [
      {
        id: 1200,
        description: 'Accounting',
        subClassifications: [
          { id: 6140, description: 'Accounts Officers/Clerks' },
          { id: 1303, description: 'Accounts Payable' },
          { id: 6141, description: 'Accounts Receivable/Credit Control' },
          { id: 6143, description: 'Analysis & Reporting' },
          { id: 6142, description: 'Assistant Accountants' },
          { id: 6144, description: 'Audit - External' },
          { id: 6145, description: 'Audit - Internal' },
          { id: 6146, description: 'Bookkeeping & Small Practice Accounting' },
          { id: 6147, description: 'Business Services & Corporate Advisory' },
          { id: 6148, description: 'Company Secretaries' },
          { id: 6149, description: 'Compliance & Risk' },
          { id: 6150, description: 'Cost Accounting' },
          { id: 6151, description: 'Financial Accounting & Reporting' },
          { id: 6152, description: 'Financial Managers & Controllers' },
          { id: 6153, description: 'Forensic Accounting & Investigation' },
          { id: 6154, description: 'Insolvency & Corporate Recovery' },
          { id: 6155, description: 'Inventory & Fixed Assets' },
          { id: 6156, description: 'Management' },
          { id: 6157, description: 'Management Accounting & Budgeting' },
          { id: 1468, description: 'Payroll' },
          { id: 6158, description: 'Strategy & Planning' },
          { id: 6159, description: 'Systems Accounting & IT Audit' },
          { id: 6160, description: 'Taxation' },
          { id: 6161, description: 'Treasury' },
          { id: 6162, description: 'Other' },
        ],
      },
      {
        id: 6251,
        description: 'Administration & Office Support',
        subClassifications: [
          { id: 6252, description: 'Administrative Assistants' },
          { id: 6253, description: 'Client & Sales Administration' },
          { id: 6254, description: 'Contracts Administration' },
          { id: 6255, description: 'Data Entry & Word Processing' },
          { id: 6256, description: 'Office Management' },
          { id: 6257, description: 'PA, EA & Secretarial' },
          { id: 6258, description: 'Receptionists' },
          { id: 6259, description: 'Records Management & Document Control' },
          { id: 6260, description: 'Other' },
        ],
      },
      {
        id: 6304,
        description: 'Advertising, Arts & Media',
        subClassifications: [
          { id: 6305, description: 'Agency Account Management' },
          { id: 6306, description: 'Art Direction' },
          { id: 6307, description: 'Editing & Publishing' },
          { id: 6308, description: 'Event Management' },
          { id: 6309, description: 'Journalism & Writing' },
          { id: 6310, description: 'Management' },
          { id: 6311, description: 'Media Strategy, Planning & Buying' },
          { id: 6312, description: 'Performing Arts' },
          { id: 6313, description: 'Photography' },
          { id: 6314, description: 'Programming & Production' },
          { id: 6315, description: 'Promotions' },
          { id: 6316, description: 'Other' },
        ],
      },
      {
        id: 1203,
        description: 'Banking & Financial Services',
        subClassifications: [
          { id: 6174, description: 'Account & Relationship Management' },
          { id: 6175, description: 'Analysis & Reporting' },
          { id: 6177, description: 'Banking - Business' },
          { id: 6178, description: 'Banking - Corporate & Institutional' },
          { id: 6176, description: 'Banking - Retail/Branch' },
          { id: 1352, description: 'Client Services' },
          { id: 6179, description: 'Compliance & Risk' },
          { id: 6180, description: 'Corporate Finance & Investment Banking' },
          { id: 6181, description: 'Credit' },
          { id: 1392, description: 'Financial Planning' },
          { id: 1404, description: 'Funds Management' },
          { id: 6182, description: 'Management' },
          { id: 6183, description: 'Mortgages' },
          { id: 6184, description: 'Settlements' },
          { id: 6185, description: 'Stockbroking & Trading' },
          { id: 6186, description: 'Treasury' },
          { id: 6187, description: 'Other' },
        ],
      },
      {
        id: 1204,
        description: 'Call Centre & Customer Service',
        subClassifications: [
          { id: 6084, description: 'Collections' },
          { id: 6085, description: 'Customer Service - Call Centre' },
          { id: 6086, description: 'Customer Service - Customer Facing' },
          { id: 6087, description: 'Management & Support' },
          { id: 6088, description: 'Sales - Inbound' },
          { id: 6089, description: 'Sales - Outbound' },
          { id: 6090, description: 'Supervisors/Team Leaders' },
          { id: 6091, description: 'Other' },
        ],
      },
      {
        id: 7019,
        description: 'CEO & General Management',
        subClassifications: [
          { id: 7020, description: 'Board Appointments' },
          { id: 7021, description: 'CEO' },
          { id: 7022, description: 'COO & MD' },
          { id: 7023, description: 'General/Business Unit Manager' },
          { id: 7024, description: 'Other' },
        ],
      },
      {
        id: 6163,
        description: 'Community Services & Development',
        subClassifications: [
          { id: 6164, description: 'Aged & Disability Support' },
          { id: 6165, description: 'Child Welfare, Youth & Family Services' },
          { id: 6166, description: 'Community Development' },
          { id: 6167, description: 'Employment Services' },
          { id: 6168, description: 'Fundraising' },
          { id: 6169, description: 'Housing & Homelessness Services' },
          { id: 6170, description: 'Indigenous & Multicultural Services' },
          { id: 6171, description: 'Management' },
          { id: 6172, description: 'Volunteer Coordination & Support' },
          { id: 6173, description: 'Other' },
        ],
      },
      {
        id: 1206,
        description: 'Construction',
        subClassifications: [
          { id: 6113, description: 'Contracts Management' },
          { id: 1387, description: 'Estimating' },
          { id: 6114, description: 'Foreperson/Supervisors' },
          { id: 6115, description: 'Health, Safety & Environment' },
          { id: 6116, description: 'Management' },
          { id: 6117, description: 'Planning & Scheduling' },
          { id: 6118, description: 'Plant & Machinery Operators' },
          { id: 6119, description: 'Project Management' },
          { id: 6120, description: 'Quality Assurance & Control' },
          { id: 6121, description: 'Surveying' },
          { id: 6122, description: 'Other' },
        ],
      },
      {
        id: 6076,
        description: 'Consulting & Strategy',
        subClassifications: [
          { id: 6077, description: 'Analysts' },
          { id: 6078, description: 'Corporate Development' },
          { id: 6079, description: 'Environment & Sustainability Consulting' },
          { id: 6080, description: 'Management & Change Consulting' },
          { id: 6081, description: 'Policy' },
          { id: 6082, description: 'Strategy & Planning' },
          { id: 6083, description: 'Other' },
        ],
      },
      {
        id: 6263,
        description: 'Design & Architecture',
        subClassifications: [
          { id: 6265, description: 'Architectural Drafting' },
          { id: 6264, description: 'Architecture' },
          { id: 6267, description: 'Fashion & Textile Design' },
          { id: 6268, description: 'Graphic Design' },
          { id: 6266, description: 'Illustration & Animation' },
          { id: 6269, description: 'Industrial Design' },
          { id: 6270, description: 'Interior Design' },
          { id: 6271, description: 'Landscape Architecture' },
          { id: 6272, description: 'Urban Design & Planning' },
          { id: 6273, description: 'Web & Interaction Design' },
          { id: 6274, description: 'Other' },
        ],
      },
      {
        id: 6123,
        description: 'Education & Training',
        subClassifications: [
          { id: 6124, description: 'Childcare & Outside School Hours Care' },
          {
            id: 6125,
            description: 'Library Services & Information Management',
          },
          { id: 6126, description: 'Management - Schools' },
          { id: 6127, description: 'Management - Universities' },
          { id: 6128, description: 'Management - Vocational' },
          { id: 6129, description: 'Research & Fellowships' },
          { id: 6130, description: 'Student Services' },
          { id: 6132, description: 'Teaching - Early Childhood' },
          { id: 6133, description: 'Teaching - Primary' },
          { id: 6134, description: 'Teaching - Secondary' },
          { id: 6135, description: 'Teaching - Tertiary' },
          { id: 6136, description: 'Teaching - Vocational' },
          { id: 6131, description: 'Teaching Aides & Special Needs' },
          { id: 6137, description: 'Tutoring' },
          { id: 6138, description: 'Workplace Training & Assessment' },
          { id: 6139, description: 'Other' },
        ],
      },
      {
        id: 1209,
        description: 'Engineering',
        subClassifications: [
          { id: 6022, description: 'Aerospace Engineering' },
          { id: 6023, description: 'Automotive Engineering' },
          { id: 6024, description: 'Building Services Engineering' },
          { id: 6026, description: 'Chemical Engineering' },
          { id: 6027, description: 'Civil/Structural Engineering' },
          { id: 6028, description: 'Electrical/Electronic Engineering' },
          { id: 6025, description: 'Engineering Drafting' },
          { id: 6029, description: 'Environmental Engineering' },
          { id: 6030, description: 'Field Engineering' },
          { id: 6031, description: 'Industrial Engineering' },
          { id: 6032, description: 'Maintenance' },
          { id: 6033, description: 'Management' },
          { id: 6034, description: 'Materials Handling Engineering' },
          { id: 6035, description: 'Mechanical Engineering' },
          { id: 6036, description: 'Process Engineering' },
          { id: 6038, description: 'Project Engineering' },
          { id: 6037, description: 'Project Management' },
          { id: 6039, description: 'Supervisors' },
          { id: 6040, description: 'Systems Engineering' },
          { id: 6041, description: 'Water & Waste Engineering' },
          { id: 6042, description: 'Other' },
        ],
      },
      {
        id: 6205,
        description: 'Farming, Animals & Conservation',
        subClassifications: [
          { id: 6206, description: 'Agronomy & Farm Services' },
          { id: 6207, description: 'Conservation, Parks & Wildlife' },
          { id: 6208, description: 'Farm Labour' },
          { id: 6209, description: 'Farm Management' },
          { id: 6210, description: 'Fishing & Aquaculture' },
          { id: 6211, description: 'Horticulture' },
          { id: 6212, description: 'Veterinary Services & Animal Welfare' },
          { id: 6213, description: 'Winery & Viticulture' },
          { id: 6214, description: 'Other' },
        ],
      },
      {
        id: 1210,
        description: 'Government & Defence',
        subClassifications: [
          { id: 1314, description: 'Air Force' },
          { id: 1322, description: 'Army' },
          { id: 1378, description: 'Emergency Services' },
          { id: 6356, description: 'Government' },
          { id: 6357, description: 'Local Government' },
          { id: 1450, description: 'Navy' },
          { id: 6359, description: 'Police & Corrections' },
          { id: 6360, description: 'Policy, Planning & Regulation' },
          { id: 6358, description: 'Regional Council' },
          { id: 6361, description: 'Other' },
        ],
      },
      {
        id: 1211,
        description: 'Healthcare & Medical',
        subClassifications: [
          { id: 6329, description: 'Ambulance/Paramedics' },
          { id: 6330, description: 'Chiropractic & Osteopathic' },
          { id: 1353, description: 'Clinical/Medical Research' },
          { id: 1372, description: 'Dental' },
          { id: 6331, description: 'Dieticians' },
          { id: 1386, description: 'Environmental Services' },
          { id: 6332, description: 'General Practitioners' },
          { id: 6333, description: 'Management' },
          { id: 6334, description: 'Medical Administration' },
          { id: 6335, description: 'Medical Imaging' },
          { id: 6336, description: 'Medical Specialists' },
          { id: 6337, description: 'Natural Therapies & Alternative Medicine' },
          { id: 6338, description: 'Nursing - A&E, Critical Care & ICU' },
          { id: 6339, description: 'Nursing - Aged Care' },
          {
            id: 6340,
            description: 'Nursing - Community, Maternal & Child Health',
          },
          { id: 6341, description: 'Nursing - Educators & Facilitators' },
          { id: 6342, description: 'Nursing - General Medical & Surgical' },
          { id: 6343, description: 'Nursing - High Acuity' },
          { id: 6344, description: 'Nursing - Management' },
          {
            id: 6345,
            description: 'Nursing - Midwifery, Neo-Natal, SCN & NICU',
          },
          { id: 6346, description: 'Nursing - Paediatric & PICU' },
          {
            id: 6347,
            description: 'Nursing - Psych, Forensic & Correctional Health',
          },
          { id: 6348, description: 'Nursing - Theatre & Recovery' },
          { id: 6349, description: 'Optical' },
          { id: 1467, description: 'Pathology' },
          { id: 6350, description: 'Pharmaceuticals & Medical Devices' },
          { id: 1470, description: 'Pharmacy' },
          { id: 6352, description: 'Physiotherapy, OT & Rehabilitation' },
          { id: 6351, description: 'Psychology, Counselling & Social Work' },
          { id: 6370, description: 'Residents & Registrars' },
          { id: 6353, description: 'Sales' },
          { id: 6354, description: 'Speech Therapy' },
          { id: 6355, description: 'Other' },
        ],
      },
      {
        id: 1212,
        description: 'Hospitality & Tourism',
        subClassifications: [
          { id: 1315, description: 'Airlines' },
          { id: 1332, description: 'Bar & Beverage Staff' },
          { id: 6052, description: 'Chefs/Cooks' },
          { id: 6053, description: 'Front Office & Guest Services' },
          { id: 1405, description: 'Gaming' },
          { id: 1415, description: 'Housekeeping' },
          { id: 6054, description: 'Kitchen & Sandwich Hands' },
          { id: 6055, description: 'Management' },
          { id: 6056, description: 'Reservations' },
          { id: 1537, description: 'Tour Guides' },
          { id: 1542, description: 'Travel Agents/Consultants' },
          { id: 1549, description: 'Waiting Staff' },
          { id: 6057, description: 'Other' },
        ],
      },
      {
        id: 6317,
        description: 'Human Resources & Recruitment',
        subClassifications: [
          { id: 6318, description: 'Consulting & Generalist HR' },
          { id: 6319, description: 'Industrial & Employee Relations' },
          { id: 6320, description: 'Management - Agency' },
          { id: 6321, description: 'Management - Internal' },
          { id: 6322, description: 'Occupational Health & Safety' },
          { id: 6323, description: 'Organisational Development' },
          { id: 6324, description: 'Recruitment - Agency' },
          { id: 6325, description: 'Recruitment - Internal' },
          { id: 6326, description: 'Remuneration & Benefits' },
          { id: 6327, description: 'Training & Development' },
          { id: 6328, description: 'Other' },
        ],
      },
      {
        id: 6281,
        description: 'Information & Communication Technology',
        subClassifications: [
          { id: 6282, description: 'Architects' },
          { id: 6283, description: 'Business/Systems Analysts' },
          { id: 6284, description: 'Computer Operators' },
          { id: 6285, description: 'Consultants' },
          { id: 6286, description: 'Database Development & Administration' },
          { id: 6287, description: 'Developers/Programmers' },
          { id: 6288, description: 'Engineering - Hardware' },
          { id: 6289, description: 'Engineering - Network' },
          { id: 6290, description: 'Engineering - Software' },
          { id: 6291, description: 'Help Desk & IT Support' },
          { id: 6292, description: 'Management' },
          { id: 6293, description: 'Networks & Systems Administration' },
          { id: 6294, description: 'Product Management & Development' },
          { id: 6295, description: 'Programme & Project Management' },
          { id: 6296, description: 'Sales - Pre & Post' },
          { id: 6297, description: 'Security' },
          { id: 6298, description: 'Team Leaders' },
          { id: 6299, description: 'Technical Writing' },
          { id: 6300, description: 'Telecommunications' },
          { id: 6301, description: 'Testing & Quality Assurance' },
          { id: 6302, description: 'Web Development & Production' },
          { id: 6303, description: 'Other' },
        ],
      },
      {
        id: 1214,
        description: 'Insurance & Superannuation',
        subClassifications: [
          { id: 6275, description: 'Actuarial' },
          { id: 6276, description: 'Assessment' },
          { id: 6277, description: 'Brokerage' },
          { id: 1350, description: 'Claims' },
          { id: 6278, description: 'Fund Administration' },
          { id: 6279, description: 'Management' },
          { id: 1499, description: 'Risk Consulting' },
          { id: 1524, description: 'Superannuation' },
          { id: 1544, description: 'Underwriting' },
          { id: 1553, description: "Workers' Compensation" },
          { id: 6280, description: 'Other' },
        ],
      },
      {
        id: 1216,
        description: 'Legal',
        subClassifications: [
          { id: 6188, description: 'Banking & Finance Law' },
          { id: 6190, description: 'Construction Law' },
          { id: 6191, description: 'Corporate & Commercial Law' },
          { id: 6189, description: 'Criminal & Civil Law' },
          { id: 6192, description: 'Environment & Planning Law' },
          { id: 6193, description: 'Family Law' },
          { id: 6194, description: 'Generalists - In-house' },
          { id: 6195, description: 'Generalists - Law Firm' },
          { id: 6196, description: 'Industrial Relations & Employment Law' },
          { id: 6197, description: 'Insurance & Superannuation Law' },
          { id: 6198, description: 'Intellectual Property Law' },
          { id: 1429, description: 'Law Clerks & Paralegals' },
          { id: 6199, description: 'Legal Practice Management' },
          { id: 1431, description: 'Legal Secretaries' },
          { id: 6200, description: 'Litigation & Dispute Resolution' },
          { id: 6201, description: 'Personal Injury Law' },
          { id: 6202, description: 'Property Law' },
          { id: 6203, description: 'Tax Law' },
          { id: 6204, description: 'Other' },
        ],
      },
      {
        id: 6092,
        description: 'Manufacturing, Transport & Logistics',
        subClassifications: [
          { id: 6095, description: 'Analysis & Reporting' },
          { id: 6093, description: 'Assembly & Process Work' },
          { id: 6094, description: 'Aviation Services' },
          { id: 6096, description: 'Couriers, Drivers & Postal Services' },
          { id: 6097, description: 'Fleet Management' },
          { id: 6098, description: 'Freight/Cargo Forwarding' },
          { id: 6099, description: 'Import/Export & Customs' },
          { id: 6101, description: 'Machine Operators' },
          { id: 6102, description: 'Management' },
          { id: 6104, description: 'Pattern Makers & Garment Technicians' },
          { id: 6105, description: 'Pickers & Packers' },
          { id: 6103, description: 'Production, Planning & Scheduling' },
          { id: 6100, description: 'Public Transport & Taxi Services' },
          { id: 6109, description: 'Purchasing, Procurement & Inventory' },
          { id: 6106, description: 'Quality Assurance & Control' },
          { id: 6110, description: 'Rail & Maritime Transport' },
          { id: 6111, description: 'Road Transport' },
          { id: 6107, description: 'Team Leaders/Supervisors' },
          { id: 6108, description: 'Warehousing, Storage & Distribution' },
          { id: 6112, description: 'Other' },
        ],
      },
      {
        id: 6008,
        description: 'Marketing & Communications',
        subClassifications: [
          { id: 6009, description: 'Brand Management' },
          { id: 6010, description: 'Digital & Search Marketing' },
          { id: 6011, description: 'Direct Marketing & CRM' },
          { id: 6012, description: 'Event Management' },
          { id: 6013, description: 'Internal Communications' },
          { id: 6017, description: 'Management' },
          { id: 6014, description: 'Market Research & Analysis' },
          { id: 6015, description: 'Marketing Assistants/Coordinators' },
          { id: 6016, description: 'Marketing Communications' },
          { id: 6018, description: 'Product Management & Development' },
          { id: 6019, description: 'Public Relations & Corporate Affairs' },
          { id: 6020, description: 'Trade Marketing' },
          { id: 6021, description: 'Other' },
        ],
      },
      {
        id: 6058,
        description: 'Mining, Resources & Energy',
        subClassifications: [
          { id: 6059, description: 'Analysis & Reporting' },
          { id: 6060, description: 'Health, Safety & Environment' },
          { id: 6061, description: 'Management' },
          { id: 6062, description: 'Mining - Drill & Blast' },
          { id: 6063, description: 'Mining - Engineering & Maintenance' },
          { id: 6064, description: 'Mining - Exploration & Geoscience' },
          { id: 6066, description: 'Mining - Operations' },
          { id: 6065, description: 'Mining - Processing' },
          { id: 6073, description: 'Natural Resources & Water' },
          { id: 6067, description: 'Oil & Gas - Drilling' },
          { id: 6068, description: 'Oil & Gas - Engineering & Maintenance' },
          { id: 6069, description: 'Oil & Gas - Exploration & Geoscience' },
          { id: 6071, description: 'Oil & Gas - Operations' },
          { id: 6070, description: 'Oil & Gas - Production & Refinement' },
          { id: 6072, description: 'Power Generation & Distribution' },
          { id: 6074, description: 'Surveying' },
          { id: 6075, description: 'Other' },
        ],
      },
      {
        id: 1220,
        description: 'Real Estate & Property',
        subClassifications: [
          { id: 6371, description: 'Administration' },
          { id: 6001, description: 'Analysts' },
          { id: 6002, description: 'Body Corporate & Facilities Management' },
          {
            id: 6003,
            description: 'Commercial Sales, Leasing & Property Mgmt',
          },
          {
            id: 6004,
            description: 'Residential Leasing & Property Management',
          },
          { id: 6005, description: 'Residential Sales' },
          { id: 6006, description: 'Retail & Property Development' },
          { id: 1546, description: 'Valuation' },
          { id: 6007, description: 'Other' },
        ],
      },
      {
        id: 6043,
        description: 'Retail & Consumer Products',
        subClassifications: [
          { id: 6044, description: 'Buying' },
          { id: 6045, description: 'Management - Area/Multi-site' },
          { id: 6046, description: 'Management - Department/Assistant' },
          { id: 6047, description: 'Management - Store' },
          { id: 6048, description: 'Merchandisers' },
          { id: 6049, description: 'Planning' },
          { id: 6050, description: 'Retail Assistants' },
          { id: 6051, description: 'Other' },
        ],
      },
      {
        id: 6362,
        description: 'Sales',
        subClassifications: [
          { id: 6363, description: 'Account & Relationship Management' },
          { id: 6364, description: 'Analysis & Reporting' },
          { id: 6366, description: 'Management' },
          { id: 6365, description: 'New Business Development' },
          { id: 6367, description: 'Sales Coordinators' },
          { id: 6368, description: 'Sales Representatives/Consultants' },
          { id: 6369, description: 'Other' },
        ],
      },
      {
        id: 1223,
        description: 'Science & Technology',
        subClassifications: [
          { id: 6215, description: 'Biological & Biomedical Sciences' },
          { id: 6216, description: 'Biotechnology & Genetics' },
          { id: 6217, description: 'Chemistry & Physics' },
          { id: 6218, description: 'Environmental, Earth & Geosciences' },
          { id: 6219, description: 'Food Technology & Safety' },
          { id: 6220, description: 'Laboratory & Technical Services' },
          { id: 6221, description: 'Materials Sciences' },
          {
            id: 6222,
            description: 'Mathematics, Statistics & Information Sciences',
          },
          { id: 6223, description: 'Modelling & Simulation' },
          { id: 6224, description: 'Quality Assurance & Control' },
          { id: 6225, description: 'Other' },
        ],
      },
      {
        id: 6261,
        description: 'Self Employment',
        subClassifications: [{ id: 6262, description: 'Self Employment' }],
      },
      {
        id: 6246,
        description: 'Sport & Recreation',
        subClassifications: [
          { id: 6247, description: 'Coaching & Instruction' },
          { id: 6248, description: 'Fitness & Personal Training' },
          { id: 6249, description: 'Management' },
          { id: 6250, description: 'Other' },
        ],
      },
      {
        id: 1225,
        description: 'Trades & Services',
        subClassifications: [
          { id: 1313, description: 'Air Conditioning & Refrigeration' },
          { id: 1328, description: 'Automotive Trades' },
          { id: 6226, description: 'Bakers & Pastry Chefs' },
          { id: 6227, description: 'Building Trades' },
          { id: 6228, description: 'Butchers' },
          { id: 1345, description: 'Carpentry & Cabinet Making' },
          { id: 6229, description: 'Cleaning Services' },
          { id: 6230, description: 'Electricians' },
          { id: 6231, description: 'Fitters, Turners & Machinists' },
          { id: 6232, description: 'Floristry' },
          { id: 1406, description: 'Gardening & Landscaping' },
          { id: 6233, description: 'Hair & Beauty Services' },
          { id: 6234, description: 'Labourers' },
          { id: 6235, description: 'Locksmiths' },
          { id: 6236, description: 'Maintenance & Handyperson Services' },
          { id: 6237, description: 'Nannies & Babysitters' },
          { id: 6238, description: 'Painters & Sign Writers' },
          { id: 6240, description: 'Plumbers' },
          { id: 6239, description: 'Printing & Publishing Services' },
          { id: 6241, description: 'Security Services' },
          { id: 6242, description: 'Tailors & Dressmakers' },
          { id: 6243, description: 'Technicians' },
          { id: 6244, description: 'Welders & Boilermakers' },
          { id: 6245, description: 'Other' },
        ],
      },
    ],
  },
  'asia-1': {
    en: [
      {
        id: 1200,
        description: 'Accounting',
        subClassifications: [
          { id: 6140, description: 'Accounts Officers/Clerks' },
          { id: 1303, description: 'Accounts Payable' },
          { id: 6141, description: 'Accounts Receivable/Credit Control' },
          { id: 6143, description: 'Analysis & Reporting' },
          { id: 6142, description: 'Assistant Accountants' },
          { id: 6144, description: 'Audit - External' },
          { id: 6145, description: 'Audit - Internal' },
          { id: 6146, description: 'Bookkeeping & Small Practice Accounting' },
          { id: 6147, description: 'Business Services & Corporate Advisory' },
          { id: 6148, description: 'Company Secretaries' },
          { id: 6149, description: 'Compliance & Risk' },
          { id: 6150, description: 'Cost Accounting' },
          { id: 6151, description: 'Financial Accounting & Reporting' },
          { id: 6152, description: 'Financial Managers & Controllers' },
          { id: 6153, description: 'Forensic Accounting & Investigation' },
          { id: 6154, description: 'Insolvency & Corporate Recovery' },
          { id: 6155, description: 'Inventory & Fixed Assets' },
          { id: 6156, description: 'Management' },
          { id: 6157, description: 'Management Accounting & Budgeting' },
          { id: 1468, description: 'Payroll' },
          { id: 6158, description: 'Strategy & Planning' },
          { id: 6159, description: 'Systems Accounting & IT Audit' },
          { id: 6160, description: 'Taxation' },
          { id: 6161, description: 'Treasury' },
          { id: 6162, description: 'Other' },
        ],
      },
      {
        id: 6251,
        description: 'Administration & Office Support',
        subClassifications: [
          { id: 6252, description: 'Administrative Assistants' },
          { id: 6253, description: 'Client & Sales Administration' },
          { id: 6254, description: 'Contracts Administration' },
          { id: 6255, description: 'Data Entry & Word Processing' },
          { id: 6256, description: 'Office Management' },
          { id: 6257, description: 'PA, EA & Secretarial' },
          { id: 6258, description: 'Receptionists' },
          { id: 6259, description: 'Records Management & Document Control' },
          { id: 6260, description: 'Other' },
        ],
      },
      {
        id: 6304,
        description: 'Advertising, Arts & Media',
        subClassifications: [
          { id: 6305, description: 'Agency Account Management' },
          { id: 6306, description: 'Art Direction' },
          { id: 6307, description: 'Editing & Publishing' },
          { id: 6308, description: 'Event Management' },
          { id: 6309, description: 'Journalism & Writing' },
          { id: 6310, description: 'Management' },
          { id: 6311, description: 'Media Strategy, Planning & Buying' },
          { id: 6312, description: 'Performing Arts' },
          { id: 6313, description: 'Photography' },
          { id: 6314, description: 'Programming & Production' },
          { id: 6315, description: 'Promotions' },
          { id: 6316, description: 'Other' },
        ],
      },
      {
        id: 1203,
        description: 'Banking & Financial Services',
        subClassifications: [
          { id: 6174, description: 'Account & Relationship Management' },
          { id: 6175, description: 'Analysis & Reporting' },
          { id: 6177, description: 'Banking - Business' },
          { id: 6178, description: 'Banking - Corporate & Institutional' },
          { id: 6176, description: 'Banking - Retail/Branch' },
          { id: 1352, description: 'Client Services' },
          { id: 6179, description: 'Compliance & Risk' },
          { id: 6180, description: 'Corporate Finance & Investment Banking' },
          { id: 6181, description: 'Credit' },
          { id: 1392, description: 'Financial Planning' },
          { id: 1404, description: 'Funds Management' },
          { id: 6182, description: 'Management' },
          { id: 6183, description: 'Mortgages' },
          { id: 6184, description: 'Settlements' },
          { id: 6185, description: 'Stockbroking & Trading' },
          { id: 6186, description: 'Treasury' },
          { id: 6187, description: 'Other' },
        ],
      },
      {
        id: 1204,
        description: 'Call Centre & Customer Service',
        subClassifications: [
          { id: 6084, description: 'Collections' },
          { id: 6085, description: 'Customer Service - Call Centre' },
          { id: 6086, description: 'Customer Service - Customer Facing' },
          { id: 6087, description: 'Management & Support' },
          { id: 6088, description: 'Sales - Inbound' },
          { id: 6089, description: 'Sales - Outbound' },
          { id: 6090, description: 'Supervisors/Team Leaders' },
          { id: 6091, description: 'Other' },
        ],
      },
      {
        id: 7019,
        description: 'CEO & General Management',
        subClassifications: [
          { id: 7020, description: 'Board Appointments' },
          { id: 7021, description: 'CEO' },
          { id: 7022, description: 'COO & MD' },
          { id: 7023, description: 'General/Business Unit Manager' },
          { id: 7024, description: 'Other' },
        ],
      },
      {
        id: 6163,
        description: 'Community Services & Development',
        subClassifications: [
          { id: 6164, description: 'Aged & Disability Support' },
          { id: 6165, description: 'Child Welfare, Youth & Family Services' },
          { id: 6166, description: 'Community Development' },
          { id: 6167, description: 'Employment Services' },
          { id: 6168, description: 'Fundraising' },
          { id: 6169, description: 'Housing & Homelessness Services' },
          { id: 6170, description: 'Indigenous & Multicultural Services' },
          { id: 6171, description: 'Management' },
          { id: 6172, description: 'Volunteer Coordination & Support' },
          { id: 6173, description: 'Other' },
        ],
      },
      {
        id: 1206,
        description: 'Construction',
        subClassifications: [
          { id: 6113, description: 'Contracts Management' },
          { id: 1387, description: 'Estimating' },
          { id: 6114, description: 'Foreperson/Supervisors' },
          { id: 6115, description: 'Health, Safety & Environment' },
          { id: 6116, description: 'Management' },
          { id: 6117, description: 'Planning & Scheduling' },
          { id: 6118, description: 'Plant & Machinery Operators' },
          { id: 6119, description: 'Project Management' },
          { id: 6120, description: 'Quality Assurance & Control' },
          { id: 6121, description: 'Surveying' },
          { id: 6122, description: 'Other' },
        ],
      },
      {
        id: 6076,
        description: 'Consulting & Strategy',
        subClassifications: [
          { id: 6077, description: 'Analysts' },
          { id: 6078, description: 'Corporate Development' },
          { id: 6079, description: 'Environment & Sustainability Consulting' },
          { id: 6080, description: 'Management & Change Consulting' },
          { id: 6081, description: 'Policy' },
          { id: 6082, description: 'Strategy & Planning' },
          { id: 6083, description: 'Other' },
        ],
      },
      {
        id: 6263,
        description: 'Design & Architecture',
        subClassifications: [
          { id: 6265, description: 'Architectural Drafting' },
          { id: 6264, description: 'Architecture' },
          { id: 6267, description: 'Fashion & Textile Design' },
          { id: 6268, description: 'Graphic Design' },
          { id: 6266, description: 'Illustration & Animation' },
          { id: 6269, description: 'Industrial Design' },
          { id: 6270, description: 'Interior Design' },
          { id: 6271, description: 'Landscape Architecture' },
          { id: 6272, description: 'Urban Design & Planning' },
          { id: 6273, description: 'Web & Interaction Design' },
          { id: 6274, description: 'Other' },
        ],
      },
      {
        id: 6123,
        description: 'Education & Training',
        subClassifications: [
          { id: 6124, description: 'Childcare & Outside School Hours Care' },
          {
            id: 6125,
            description: 'Library Services & Information Management',
          },
          { id: 6126, description: 'Management - Schools' },
          { id: 6127, description: 'Management - Universities' },
          { id: 6128, description: 'Management - Vocational' },
          { id: 6129, description: 'Research & Fellowships' },
          { id: 6130, description: 'Student Services' },
          { id: 6132, description: 'Teaching - Early Childhood' },
          { id: 6133, description: 'Teaching - Primary' },
          { id: 6134, description: 'Teaching - Secondary' },
          { id: 6135, description: 'Teaching - Tertiary' },
          { id: 6136, description: 'Teaching - Vocational' },
          { id: 6131, description: 'Teaching Aides & Special Needs' },
          { id: 6137, description: 'Tutoring' },
          { id: 6138, description: 'Workplace Training & Assessment' },
          { id: 6139, description: 'Other' },
        ],
      },
      {
        id: 1209,
        description: 'Engineering',
        subClassifications: [
          { id: 6022, description: 'Aerospace Engineering' },
          { id: 6023, description: 'Automotive Engineering' },
          { id: 6024, description: 'Building Services Engineering' },
          { id: 6026, description: 'Chemical Engineering' },
          { id: 6027, description: 'Civil/Structural Engineering' },
          { id: 6028, description: 'Electrical/Electronic Engineering' },
          { id: 6025, description: 'Engineering Drafting' },
          { id: 6029, description: 'Environmental Engineering' },
          { id: 6030, description: 'Field Engineering' },
          { id: 6031, description: 'Industrial Engineering' },
          { id: 6032, description: 'Maintenance' },
          { id: 6033, description: 'Management' },
          { id: 6034, description: 'Materials Handling Engineering' },
          { id: 6035, description: 'Mechanical Engineering' },
          { id: 6036, description: 'Process Engineering' },
          { id: 6038, description: 'Project Engineering' },
          { id: 6037, description: 'Project Management' },
          { id: 6039, description: 'Supervisors' },
          { id: 6040, description: 'Systems Engineering' },
          { id: 6041, description: 'Water & Waste Engineering' },
          { id: 6042, description: 'Other' },
        ],
      },
      {
        id: 6205,
        description: 'Farming, Animals & Conservation',
        subClassifications: [
          { id: 6206, description: 'Agronomy & Farm Services' },
          { id: 6207, description: 'Conservation, Parks & Wildlife' },
          { id: 6208, description: 'Farm Labour' },
          { id: 6209, description: 'Farm Management' },
          { id: 6210, description: 'Fishing & Aquaculture' },
          { id: 6211, description: 'Horticulture' },
          { id: 6212, description: 'Veterinary Services & Animal Welfare' },
          { id: 6213, description: 'Winery & Viticulture' },
          { id: 6214, description: 'Other' },
        ],
      },
      {
        id: 1210,
        description: 'Government & Defence',
        subClassifications: [
          { id: 1314, description: 'Air Force' },
          { id: 1322, description: 'Army' },
          { id: 1378, description: 'Emergency Services' },
          { id: 1409, description: 'Government - Federal' },
          { id: 1410, description: 'Government - Local' },
          { id: 1411, description: 'Government - State' },
          { id: 1450, description: 'Navy' },
          { id: 6359, description: 'Police & Corrections' },
          { id: 6360, description: 'Policy, Planning & Regulation' },
          { id: 6361, description: 'Other' },
        ],
      },
      {
        id: 1211,
        description: 'Healthcare & Medical',
        subClassifications: [
          { id: 6329, description: 'Ambulance/Paramedics' },
          { id: 6330, description: 'Chiropractic & Osteopathic' },
          { id: 1353, description: 'Clinical/Medical Research' },
          { id: 1372, description: 'Dental' },
          { id: 6331, description: 'Dieticians' },
          { id: 1386, description: 'Environmental Services' },
          { id: 6332, description: 'General Practitioners' },
          { id: 6333, description: 'Management' },
          { id: 6334, description: 'Medical Administration' },
          { id: 6335, description: 'Medical Imaging' },
          { id: 6336, description: 'Medical Specialists' },
          { id: 6337, description: 'Natural Therapies & Alternative Medicine' },
          { id: 6338, description: 'Nursing - A&E, Critical Care & ICU' },
          { id: 6339, description: 'Nursing - Aged Care' },
          {
            id: 6340,
            description: 'Nursing - Community, Maternal & Child Health',
          },
          { id: 6341, description: 'Nursing - Educators & Facilitators' },
          { id: 6342, description: 'Nursing - General Medical & Surgical' },
          { id: 6343, description: 'Nursing - High Acuity' },
          { id: 6344, description: 'Nursing - Management' },
          {
            id: 6345,
            description: 'Nursing - Midwifery, Neo-Natal, SCN & NICU',
          },
          { id: 6346, description: 'Nursing - Paediatric & PICU' },
          {
            id: 6347,
            description: 'Nursing - Psych, Forensic & Correctional Health',
          },
          { id: 6348, description: 'Nursing - Theatre & Recovery' },
          { id: 6349, description: 'Optical' },
          { id: 1467, description: 'Pathology' },
          { id: 6350, description: 'Pharmaceuticals & Medical Devices' },
          { id: 1470, description: 'Pharmacy' },
          { id: 6352, description: 'Physiotherapy, OT & Rehabilitation' },
          { id: 6351, description: 'Psychology, Counselling & Social Work' },
          { id: 6370, description: 'Residents & Registrars' },
          { id: 6353, description: 'Sales' },
          { id: 6354, description: 'Speech Therapy' },
          { id: 6355, description: 'Other' },
        ],
      },
      {
        id: 1212,
        description: 'Hospitality & Tourism',
        subClassifications: [
          { id: 1315, description: 'Airlines' },
          { id: 1332, description: 'Bar & Beverage Staff' },
          { id: 6052, description: 'Chefs/Cooks' },
          { id: 6053, description: 'Front Office & Guest Services' },
          { id: 1405, description: 'Gaming' },
          { id: 1415, description: 'Housekeeping' },
          { id: 6054, description: 'Kitchen & Sandwich Hands' },
          { id: 6055, description: 'Management' },
          { id: 6056, description: 'Reservations' },
          { id: 1537, description: 'Tour Guides' },
          { id: 1542, description: 'Travel Agents/Consultants' },
          { id: 1549, description: 'Waiting Staff' },
          { id: 6057, description: 'Other' },
        ],
      },
      {
        id: 6317,
        description: 'Human Resources & Recruitment',
        subClassifications: [
          { id: 6318, description: 'Consulting & Generalist HR' },
          { id: 6319, description: 'Industrial & Employee Relations' },
          { id: 6320, description: 'Management - Agency' },
          { id: 6321, description: 'Management - Internal' },
          { id: 6322, description: 'Occupational Health & Safety' },
          { id: 6323, description: 'Organisational Development' },
          { id: 6324, description: 'Recruitment - Agency' },
          { id: 6325, description: 'Recruitment - Internal' },
          { id: 6326, description: 'Remuneration & Benefits' },
          { id: 6327, description: 'Training & Development' },
          { id: 6328, description: 'Other' },
        ],
      },
      {
        id: 6281,
        description: 'Information & Communication Technology',
        subClassifications: [
          { id: 6282, description: 'Architects' },
          { id: 6283, description: 'Business/Systems Analysts' },
          { id: 6284, description: 'Computer Operators' },
          { id: 6285, description: 'Consultants' },
          { id: 6286, description: 'Database Development & Administration' },
          { id: 6287, description: 'Developers/Programmers' },
          { id: 6288, description: 'Engineering - Hardware' },
          { id: 6289, description: 'Engineering - Network' },
          { id: 6290, description: 'Engineering - Software' },
          { id: 6291, description: 'Help Desk & IT Support' },
          { id: 6292, description: 'Management' },
          { id: 6293, description: 'Networks & Systems Administration' },
          { id: 6294, description: 'Product Management & Development' },
          { id: 6295, description: 'Programme & Project Management' },
          { id: 6296, description: 'Sales - Pre & Post' },
          { id: 6297, description: 'Security' },
          { id: 6298, description: 'Team Leaders' },
          { id: 6299, description: 'Technical Writing' },
          { id: 6300, description: 'Telecommunications' },
          { id: 6301, description: 'Testing & Quality Assurance' },
          { id: 6302, description: 'Web Development & Production' },
          { id: 6303, description: 'Other' },
        ],
      },
      {
        id: 1214,
        description: 'Insurance & Superannuation',
        subClassifications: [
          { id: 6275, description: 'Actuarial' },
          { id: 6276, description: 'Assessment' },
          { id: 6277, description: 'Brokerage' },
          { id: 1350, description: 'Claims' },
          { id: 6278, description: 'Fund Administration' },
          { id: 6279, description: 'Management' },
          { id: 1499, description: 'Risk Consulting' },
          { id: 1524, description: 'Superannuation' },
          { id: 1544, description: 'Underwriting' },
          { id: 1553, description: "Workers' Compensation" },
          { id: 6280, description: 'Other' },
        ],
      },
      {
        id: 1216,
        description: 'Legal',
        subClassifications: [
          { id: 6188, description: 'Banking & Finance Law' },
          { id: 6190, description: 'Construction Law' },
          { id: 6191, description: 'Corporate & Commercial Law' },
          { id: 6189, description: 'Criminal & Civil Law' },
          { id: 6192, description: 'Environment & Planning Law' },
          { id: 6193, description: 'Family Law' },
          { id: 6194, description: 'Generalists - In-house' },
          { id: 6195, description: 'Generalists - Law Firm' },
          { id: 6196, description: 'Industrial Relations & Employment Law' },
          { id: 6197, description: 'Insurance & Superannuation Law' },
          { id: 6198, description: 'Intellectual Property Law' },
          { id: 1429, description: 'Law Clerks & Paralegals' },
          { id: 6199, description: 'Legal Practice Management' },
          { id: 1431, description: 'Legal Secretaries' },
          { id: 6200, description: 'Litigation & Dispute Resolution' },
          { id: 6201, description: 'Personal Injury Law' },
          { id: 6202, description: 'Property Law' },
          { id: 6203, description: 'Tax Law' },
          { id: 6204, description: 'Other' },
        ],
      },
      {
        id: 6092,
        description: 'Manufacturing, Transport & Logistics',
        subClassifications: [
          { id: 6095, description: 'Analysis & Reporting' },
          { id: 6093, description: 'Assembly & Process Work' },
          { id: 6094, description: 'Aviation Services' },
          { id: 6096, description: 'Couriers, Drivers & Postal Services' },
          { id: 6097, description: 'Fleet Management' },
          { id: 6098, description: 'Freight/Cargo Forwarding' },
          { id: 6099, description: 'Import/Export & Customs' },
          { id: 6101, description: 'Machine Operators' },
          { id: 6102, description: 'Management' },
          { id: 6104, description: 'Pattern Makers & Garment Technicians' },
          { id: 6105, description: 'Pickers & Packers' },
          { id: 6103, description: 'Production, Planning & Scheduling' },
          { id: 6100, description: 'Public Transport & Taxi Services' },
          { id: 6109, description: 'Purchasing, Procurement & Inventory' },
          { id: 6106, description: 'Quality Assurance & Control' },
          { id: 6110, description: 'Rail & Maritime Transport' },
          { id: 6111, description: 'Road Transport' },
          { id: 6107, description: 'Team Leaders/Supervisors' },
          { id: 6108, description: 'Warehousing, Storage & Distribution' },
          { id: 6112, description: 'Other' },
        ],
      },
      {
        id: 6008,
        description: 'Marketing & Communications',
        subClassifications: [
          { id: 6009, description: 'Brand Management' },
          { id: 6010, description: 'Digital & Search Marketing' },
          { id: 6011, description: 'Direct Marketing & CRM' },
          { id: 6012, description: 'Event Management' },
          { id: 6013, description: 'Internal Communications' },
          { id: 6017, description: 'Management' },
          { id: 6014, description: 'Market Research & Analysis' },
          { id: 6015, description: 'Marketing Assistants/Coordinators' },
          { id: 6016, description: 'Marketing Communications' },
          { id: 6018, description: 'Product Management & Development' },
          { id: 6019, description: 'Public Relations & Corporate Affairs' },
          { id: 6020, description: 'Trade Marketing' },
          { id: 6021, description: 'Other' },
        ],
      },
      {
        id: 6058,
        description: 'Mining, Resources & Energy',
        subClassifications: [
          { id: 6059, description: 'Analysis & Reporting' },
          { id: 6060, description: 'Health, Safety & Environment' },
          { id: 6061, description: 'Management' },
          { id: 6062, description: 'Mining - Drill & Blast' },
          { id: 6063, description: 'Mining - Engineering & Maintenance' },
          { id: 6064, description: 'Mining - Exploration & Geoscience' },
          { id: 6066, description: 'Mining - Operations' },
          { id: 6065, description: 'Mining - Processing' },
          { id: 6073, description: 'Natural Resources & Water' },
          { id: 6067, description: 'Oil & Gas - Drilling' },
          { id: 6068, description: 'Oil & Gas - Engineering & Maintenance' },
          { id: 6069, description: 'Oil & Gas - Exploration & Geoscience' },
          { id: 6071, description: 'Oil & Gas - Operations' },
          { id: 6070, description: 'Oil & Gas - Production & Refinement' },
          { id: 6072, description: 'Power Generation & Distribution' },
          { id: 6074, description: 'Surveying' },
          { id: 6075, description: 'Other' },
        ],
      },
      {
        id: 1220,
        description: 'Real Estate & Property',
        subClassifications: [
          { id: 6371, description: 'Administration' },
          { id: 6001, description: 'Analysts' },
          { id: 6002, description: 'Body Corporate & Facilities Management' },
          {
            id: 6003,
            description: 'Commercial Sales, Leasing & Property Mgmt',
          },
          {
            id: 6004,
            description: 'Residential Leasing & Property Management',
          },
          { id: 6005, description: 'Residential Sales' },
          { id: 6006, description: 'Retail & Property Development' },
          { id: 1546, description: 'Valuation' },
          { id: 6007, description: 'Other' },
        ],
      },
      {
        id: 6043,
        description: 'Retail & Consumer Products',
        subClassifications: [
          { id: 6044, description: 'Buying' },
          { id: 6045, description: 'Management - Area/Multi-site' },
          { id: 6046, description: 'Management - Department/Assistant' },
          { id: 6047, description: 'Management - Store' },
          { id: 6048, description: 'Merchandisers' },
          { id: 6049, description: 'Planning' },
          { id: 6050, description: 'Retail Assistants' },
          { id: 6051, description: 'Other' },
        ],
      },
      {
        id: 6362,
        description: 'Sales',
        subClassifications: [
          { id: 6363, description: 'Account & Relationship Management' },
          { id: 6364, description: 'Analysis & Reporting' },
          { id: 6366, description: 'Management' },
          { id: 6365, description: 'New Business Development' },
          { id: 6367, description: 'Sales Coordinators' },
          { id: 6368, description: 'Sales Representatives/Consultants' },
          { id: 6369, description: 'Other' },
        ],
      },
      {
        id: 1223,
        description: 'Science & Technology',
        subClassifications: [
          { id: 6215, description: 'Biological & Biomedical Sciences' },
          { id: 6216, description: 'Biotechnology & Genetics' },
          { id: 6217, description: 'Chemistry & Physics' },
          { id: 6218, description: 'Environmental, Earth & Geosciences' },
          { id: 6219, description: 'Food Technology & Safety' },
          { id: 6220, description: 'Laboratory & Technical Services' },
          { id: 6221, description: 'Materials Sciences' },
          {
            id: 6222,
            description: 'Mathematics, Statistics & Information Sciences',
          },
          { id: 6223, description: 'Modelling & Simulation' },
          { id: 6224, description: 'Quality Assurance & Control' },
          { id: 6225, description: 'Other' },
        ],
      },
      {
        id: 6261,
        description: 'Self Employment',
        subClassifications: [{ id: 6262, description: 'Self Employment' }],
      },
      {
        id: 6246,
        description: 'Sport & Recreation',
        subClassifications: [
          { id: 6247, description: 'Coaching & Instruction' },
          { id: 6248, description: 'Fitness & Personal Training' },
          { id: 6249, description: 'Management' },
          { id: 6250, description: 'Other' },
        ],
      },
      {
        id: 1225,
        description: 'Trades & Services',
        subClassifications: [
          { id: 1313, description: 'Air Conditioning & Refrigeration' },
          { id: 1328, description: 'Automotive Trades' },
          { id: 6226, description: 'Bakers & Pastry Chefs' },
          { id: 6227, description: 'Building Trades' },
          { id: 6228, description: 'Butchers' },
          { id: 1345, description: 'Carpentry & Cabinet Making' },
          { id: 6229, description: 'Cleaning Services' },
          { id: 6230, description: 'Electricians' },
          { id: 6231, description: 'Fitters, Turners & Machinists' },
          { id: 6232, description: 'Floristry' },
          { id: 1406, description: 'Gardening & Landscaping' },
          { id: 6233, description: 'Hair & Beauty Services' },
          { id: 6234, description: 'Labourers' },
          { id: 6235, description: 'Locksmiths' },
          { id: 6236, description: 'Maintenance & Handyperson Services' },
          { id: 6237, description: 'Nannies & Babysitters' },
          { id: 6238, description: 'Painters & Sign Writers' },
          { id: 6240, description: 'Plumbers' },
          { id: 6239, description: 'Printing & Publishing Services' },
          { id: 6241, description: 'Security Services' },
          { id: 6242, description: 'Tailors & Dressmakers' },
          { id: 6243, description: 'Technicians' },
          { id: 6244, description: 'Welders & Boilermakers' },
          { id: 6245, description: 'Other' },
        ],
      },
    ],
  },
  'asia-3': {
    en: [
      {
        id: 1200,
        description: 'Accounting',
        subClassifications: [
          { id: 6140, description: 'Accounts Officers/Clerks' },
          { id: 1303, description: 'Accounts Payable' },
          { id: 6141, description: 'Accounts Receivable/Credit Control' },
          { id: 6143, description: 'Analysis & Reporting' },
          { id: 6142, description: 'Assistant Accountants' },
          { id: 6144, description: 'Audit - External' },
          { id: 6145, description: 'Audit - Internal' },
          { id: 6146, description: 'Bookkeeping & Small Practice Accounting' },
          { id: 6147, description: 'Business Services & Corporate Advisory' },
          { id: 6148, description: 'Company Secretaries' },
          { id: 6149, description: 'Compliance & Risk' },
          { id: 6150, description: 'Cost Accounting' },
          { id: 6151, description: 'Financial Accounting & Reporting' },
          { id: 6152, description: 'Financial Managers & Controllers' },
          { id: 6153, description: 'Forensic Accounting & Investigation' },
          { id: 6154, description: 'Insolvency & Corporate Recovery' },
          { id: 6155, description: 'Inventory & Fixed Assets' },
          { id: 6156, description: 'Management' },
          { id: 6157, description: 'Management Accounting & Budgeting' },
          { id: 1468, description: 'Payroll' },
          { id: 6158, description: 'Strategy & Planning' },
          { id: 6159, description: 'Systems Accounting & IT Audit' },
          { id: 6160, description: 'Taxation' },
          { id: 6161, description: 'Treasury' },
          { id: 6162, description: 'Other' },
        ],
      },
      {
        id: 6251,
        description: 'Administration & Office Support',
        subClassifications: [
          { id: 6252, description: 'Administrative Assistants' },
          { id: 6253, description: 'Client & Sales Administration' },
          { id: 6254, description: 'Contracts Administration' },
          { id: 6255, description: 'Data Entry & Word Processing' },
          { id: 6256, description: 'Office Management' },
          { id: 6257, description: 'PA, EA & Secretarial' },
          { id: 6258, description: 'Receptionists' },
          { id: 6259, description: 'Records Management & Document Control' },
          { id: 6260, description: 'Other' },
        ],
      },
      {
        id: 6304,
        description: 'Advertising, Arts & Media',
        subClassifications: [
          { id: 6305, description: 'Agency Account Management' },
          { id: 6306, description: 'Art Direction' },
          { id: 6307, description: 'Editing & Publishing' },
          { id: 6308, description: 'Event Management' },
          { id: 6309, description: 'Journalism & Writing' },
          { id: 6310, description: 'Management' },
          { id: 6311, description: 'Media Strategy, Planning & Buying' },
          { id: 6312, description: 'Performing Arts' },
          { id: 6313, description: 'Photography' },
          { id: 6314, description: 'Programming & Production' },
          { id: 6315, description: 'Promotions' },
          { id: 6316, description: 'Other' },
        ],
      },
      {
        id: 1203,
        description: 'Banking & Financial Services',
        subClassifications: [
          { id: 6174, description: 'Account & Relationship Management' },
          { id: 6175, description: 'Analysis & Reporting' },
          { id: 6177, description: 'Banking - Business' },
          { id: 6178, description: 'Banking - Corporate & Institutional' },
          { id: 6176, description: 'Banking - Retail/Branch' },
          { id: 1352, description: 'Client Services' },
          { id: 6179, description: 'Compliance & Risk' },
          { id: 6180, description: 'Corporate Finance & Investment Banking' },
          { id: 6181, description: 'Credit' },
          { id: 1392, description: 'Financial Planning' },
          { id: 1404, description: 'Funds Management' },
          { id: 6182, description: 'Management' },
          { id: 6183, description: 'Mortgages' },
          { id: 6184, description: 'Settlements' },
          { id: 6185, description: 'Stockbroking & Trading' },
          { id: 6186, description: 'Treasury' },
          { id: 6187, description: 'Other' },
        ],
      },
      {
        id: 1204,
        description: 'Call Centre & Customer Service',
        subClassifications: [
          { id: 6084, description: 'Collections' },
          { id: 6085, description: 'Customer Service - Call Centre' },
          { id: 6086, description: 'Customer Service - Customer Facing' },
          { id: 6087, description: 'Management & Support' },
          { id: 6088, description: 'Sales - Inbound' },
          { id: 6089, description: 'Sales - Outbound' },
          { id: 6090, description: 'Supervisors/Team Leaders' },
          { id: 6091, description: 'Other' },
        ],
      },
      {
        id: 7019,
        description: 'CEO & General Management',
        subClassifications: [
          { id: 7020, description: 'Board Appointments' },
          { id: 7021, description: 'CEO' },
          { id: 7022, description: 'COO & MD' },
          { id: 7023, description: 'General/Business Unit Manager' },
          { id: 7024, description: 'Other' },
        ],
      },
      {
        id: 6163,
        description: 'Community Services & Development',
        subClassifications: [
          { id: 6164, description: 'Aged & Disability Support' },
          { id: 6165, description: 'Child Welfare, Youth & Family Services' },
          { id: 6166, description: 'Community Development' },
          { id: 6167, description: 'Employment Services' },
          { id: 6168, description: 'Fundraising' },
          { id: 6169, description: 'Housing & Homelessness Services' },
          { id: 6170, description: 'Indigenous & Multicultural Services' },
          { id: 6171, description: 'Management' },
          { id: 6172, description: 'Volunteer Coordination & Support' },
          { id: 6173, description: 'Other' },
        ],
      },
      {
        id: 1206,
        description: 'Construction',
        subClassifications: [
          { id: 6113, description: 'Contracts Management' },
          { id: 1387, description: 'Estimating' },
          { id: 6114, description: 'Foreperson/Supervisors' },
          { id: 6115, description: 'Health, Safety & Environment' },
          { id: 6116, description: 'Management' },
          { id: 6117, description: 'Planning & Scheduling' },
          { id: 6118, description: 'Plant & Machinery Operators' },
          { id: 6119, description: 'Project Management' },
          { id: 6120, description: 'Quality Assurance & Control' },
          { id: 6121, description: 'Surveying' },
          { id: 6122, description: 'Other' },
        ],
      },
      {
        id: 6076,
        description: 'Consulting & Strategy',
        subClassifications: [
          { id: 6077, description: 'Analysts' },
          { id: 6078, description: 'Corporate Development' },
          { id: 6079, description: 'Environment & Sustainability Consulting' },
          { id: 6080, description: 'Management & Change Consulting' },
          { id: 6081, description: 'Policy' },
          { id: 6082, description: 'Strategy & Planning' },
          { id: 6083, description: 'Other' },
        ],
      },
      {
        id: 6263,
        description: 'Design & Architecture',
        subClassifications: [
          { id: 6265, description: 'Architectural Drafting' },
          { id: 6264, description: 'Architecture' },
          { id: 6267, description: 'Fashion & Textile Design' },
          { id: 6268, description: 'Graphic Design' },
          { id: 6266, description: 'Illustration & Animation' },
          { id: 6269, description: 'Industrial Design' },
          { id: 6270, description: 'Interior Design' },
          { id: 6271, description: 'Landscape Architecture' },
          { id: 6272, description: 'Urban Design & Planning' },
          { id: 6273, description: 'Web & Interaction Design' },
          { id: 6274, description: 'Other' },
        ],
      },
      {
        id: 6123,
        description: 'Education & Training',
        subClassifications: [
          { id: 6124, description: 'Childcare & Outside School Hours Care' },
          {
            id: 6125,
            description: 'Library Services & Information Management',
          },
          { id: 6126, description: 'Management - Schools' },
          { id: 6127, description: 'Management - Universities' },
          { id: 6128, description: 'Management - Vocational' },
          { id: 6129, description: 'Research & Fellowships' },
          { id: 6130, description: 'Student Services' },
          { id: 6132, description: 'Teaching - Early Childhood' },
          { id: 6133, description: 'Teaching - Primary' },
          { id: 6134, description: 'Teaching - Secondary' },
          { id: 6135, description: 'Teaching - Tertiary' },
          { id: 6136, description: 'Teaching - Vocational' },
          { id: 6131, description: 'Teaching Aides & Special Needs' },
          { id: 6137, description: 'Tutoring' },
          { id: 6138, description: 'Workplace Training & Assessment' },
          { id: 6139, description: 'Other' },
        ],
      },
      {
        id: 1209,
        description: 'Engineering',
        subClassifications: [
          { id: 6022, description: 'Aerospace Engineering' },
          { id: 6023, description: 'Automotive Engineering' },
          { id: 6024, description: 'Building Services Engineering' },
          { id: 6026, description: 'Chemical Engineering' },
          { id: 6027, description: 'Civil/Structural Engineering' },
          { id: 6028, description: 'Electrical/Electronic Engineering' },
          { id: 6025, description: 'Engineering Drafting' },
          { id: 6029, description: 'Environmental Engineering' },
          { id: 6030, description: 'Field Engineering' },
          { id: 6031, description: 'Industrial Engineering' },
          { id: 6032, description: 'Maintenance' },
          { id: 6033, description: 'Management' },
          { id: 6034, description: 'Materials Handling Engineering' },
          { id: 6035, description: 'Mechanical Engineering' },
          { id: 6036, description: 'Process Engineering' },
          { id: 6038, description: 'Project Engineering' },
          { id: 6037, description: 'Project Management' },
          { id: 6039, description: 'Supervisors' },
          { id: 6040, description: 'Systems Engineering' },
          { id: 6041, description: 'Water & Waste Engineering' },
          { id: 6042, description: 'Other' },
        ],
      },
      {
        id: 6205,
        description: 'Farming, Animals & Conservation',
        subClassifications: [
          { id: 6206, description: 'Agronomy & Farm Services' },
          { id: 6207, description: 'Conservation, Parks & Wildlife' },
          { id: 6208, description: 'Farm Labour' },
          { id: 6209, description: 'Farm Management' },
          { id: 6210, description: 'Fishing & Aquaculture' },
          { id: 6211, description: 'Horticulture' },
          { id: 6212, description: 'Veterinary Services & Animal Welfare' },
          { id: 6213, description: 'Winery & Viticulture' },
          { id: 6214, description: 'Other' },
        ],
      },
      {
        id: 1210,
        description: 'Government & Defence',
        subClassifications: [
          { id: 1314, description: 'Air Force' },
          { id: 1322, description: 'Army' },
          { id: 1378, description: 'Emergency Services' },
          { id: 1409, description: 'Government - Federal' },
          { id: 1410, description: 'Government - Local' },
          { id: 1411, description: 'Government - State' },
          { id: 1450, description: 'Navy' },
          { id: 6359, description: 'Police & Corrections' },
          { id: 6360, description: 'Policy, Planning & Regulation' },
          { id: 6361, description: 'Other' },
        ],
      },
      {
        id: 1211,
        description: 'Healthcare & Medical',
        subClassifications: [
          { id: 6329, description: 'Ambulance/Paramedics' },
          { id: 6330, description: 'Chiropractic & Osteopathic' },
          { id: 1353, description: 'Clinical/Medical Research' },
          { id: 1372, description: 'Dental' },
          { id: 6331, description: 'Dieticians' },
          { id: 1386, description: 'Environmental Services' },
          { id: 6332, description: 'General Practitioners' },
          { id: 6333, description: 'Management' },
          { id: 6334, description: 'Medical Administration' },
          { id: 6335, description: 'Medical Imaging' },
          { id: 6336, description: 'Medical Specialists' },
          { id: 6337, description: 'Natural Therapies & Alternative Medicine' },
          { id: 6338, description: 'Nursing - A&E, Critical Care & ICU' },
          { id: 6339, description: 'Nursing - Aged Care' },
          {
            id: 6340,
            description: 'Nursing - Community, Maternal & Child Health',
          },
          { id: 6341, description: 'Nursing - Educators & Facilitators' },
          { id: 6342, description: 'Nursing - General Medical & Surgical' },
          { id: 6343, description: 'Nursing - High Acuity' },
          { id: 6344, description: 'Nursing - Management' },
          {
            id: 6345,
            description: 'Nursing - Midwifery, Neo-Natal, SCN & NICU',
          },
          { id: 6346, description: 'Nursing - Paediatric & PICU' },
          {
            id: 6347,
            description: 'Nursing - Psych, Forensic & Correctional Health',
          },
          { id: 6348, description: 'Nursing - Theatre & Recovery' },
          { id: 6349, description: 'Optical' },
          { id: 1467, description: 'Pathology' },
          { id: 6350, description: 'Pharmaceuticals & Medical Devices' },
          { id: 1470, description: 'Pharmacy' },
          { id: 6352, description: 'Physiotherapy, OT & Rehabilitation' },
          { id: 6351, description: 'Psychology, Counselling & Social Work' },
          { id: 6370, description: 'Residents & Registrars' },
          { id: 6353, description: 'Sales' },
          { id: 6354, description: 'Speech Therapy' },
          { id: 6355, description: 'Other' },
        ],
      },
      {
        id: 1212,
        description: 'Hospitality & Tourism',
        subClassifications: [
          { id: 1315, description: 'Airlines' },
          { id: 1332, description: 'Bar & Beverage Staff' },
          { id: 6052, description: 'Chefs/Cooks' },
          { id: 6053, description: 'Front Office & Guest Services' },
          { id: 1405, description: 'Gaming' },
          { id: 1415, description: 'Housekeeping' },
          { id: 6054, description: 'Kitchen & Sandwich Hands' },
          { id: 6055, description: 'Management' },
          { id: 6056, description: 'Reservations' },
          { id: 1537, description: 'Tour Guides' },
          { id: 1542, description: 'Travel Agents/Consultants' },
          { id: 1549, description: 'Waiting Staff' },
          { id: 6057, description: 'Other' },
        ],
      },
      {
        id: 6317,
        description: 'Human Resources & Recruitment',
        subClassifications: [
          { id: 6318, description: 'Consulting & Generalist HR' },
          { id: 6319, description: 'Industrial & Employee Relations' },
          { id: 6320, description: 'Management - Agency' },
          { id: 6321, description: 'Management - Internal' },
          { id: 6322, description: 'Occupational Health & Safety' },
          { id: 6323, description: 'Organisational Development' },
          { id: 6324, description: 'Recruitment - Agency' },
          { id: 6325, description: 'Recruitment - Internal' },
          { id: 6326, description: 'Remuneration & Benefits' },
          { id: 6327, description: 'Training & Development' },
          { id: 6328, description: 'Other' },
        ],
      },
      {
        id: 6281,
        description: 'Information & Communication Technology',
        subClassifications: [
          { id: 6282, description: 'Architects' },
          { id: 6283, description: 'Business/Systems Analysts' },
          { id: 6284, description: 'Computer Operators' },
          { id: 6285, description: 'Consultants' },
          { id: 6286, description: 'Database Development & Administration' },
          { id: 6287, description: 'Developers/Programmers' },
          { id: 6288, description: 'Engineering - Hardware' },
          { id: 6289, description: 'Engineering - Network' },
          { id: 6290, description: 'Engineering - Software' },
          { id: 6291, description: 'Help Desk & IT Support' },
          { id: 6292, description: 'Management' },
          { id: 6293, description: 'Networks & Systems Administration' },
          { id: 6294, description: 'Product Management & Development' },
          { id: 6295, description: 'Programme & Project Management' },
          { id: 6296, description: 'Sales - Pre & Post' },
          { id: 6297, description: 'Security' },
          { id: 6298, description: 'Team Leaders' },
          { id: 6299, description: 'Technical Writing' },
          { id: 6300, description: 'Telecommunications' },
          { id: 6301, description: 'Testing & Quality Assurance' },
          { id: 6302, description: 'Web Development & Production' },
          { id: 6303, description: 'Other' },
        ],
      },
      {
        id: 1214,
        description: 'Insurance & Superannuation',
        subClassifications: [
          { id: 6275, description: 'Actuarial' },
          { id: 6276, description: 'Assessment' },
          { id: 6277, description: 'Brokerage' },
          { id: 1350, description: 'Claims' },
          { id: 6278, description: 'Fund Administration' },
          { id: 6279, description: 'Management' },
          { id: 1499, description: 'Risk Consulting' },
          { id: 1524, description: 'Superannuation' },
          { id: 1544, description: 'Underwriting' },
          { id: 1553, description: "Workers' Compensation" },
          { id: 6280, description: 'Other' },
        ],
      },
      {
        id: 1216,
        description: 'Legal',
        subClassifications: [
          { id: 6188, description: 'Banking & Finance Law' },
          { id: 6190, description: 'Construction Law' },
          { id: 6191, description: 'Corporate & Commercial Law' },
          { id: 6189, description: 'Criminal & Civil Law' },
          { id: 6192, description: 'Environment & Planning Law' },
          { id: 6193, description: 'Family Law' },
          { id: 6194, description: 'Generalists - In-house' },
          { id: 6195, description: 'Generalists - Law Firm' },
          { id: 6196, description: 'Industrial Relations & Employment Law' },
          { id: 6197, description: 'Insurance & Superannuation Law' },
          { id: 6198, description: 'Intellectual Property Law' },
          { id: 1429, description: 'Law Clerks & Paralegals' },
          { id: 6199, description: 'Legal Practice Management' },
          { id: 1431, description: 'Legal Secretaries' },
          { id: 6200, description: 'Litigation & Dispute Resolution' },
          { id: 6201, description: 'Personal Injury Law' },
          { id: 6202, description: 'Property Law' },
          { id: 6203, description: 'Tax Law' },
          { id: 6204, description: 'Other' },
        ],
      },
      {
        id: 6092,
        description: 'Manufacturing, Transport & Logistics',
        subClassifications: [
          { id: 6095, description: 'Analysis & Reporting' },
          { id: 6093, description: 'Assembly & Process Work' },
          { id: 6094, description: 'Aviation Services' },
          { id: 6096, description: 'Couriers, Drivers & Postal Services' },
          { id: 6097, description: 'Fleet Management' },
          { id: 6098, description: 'Freight/Cargo Forwarding' },
          { id: 6099, description: 'Import/Export & Customs' },
          { id: 6101, description: 'Machine Operators' },
          { id: 6102, description: 'Management' },
          { id: 6104, description: 'Pattern Makers & Garment Technicians' },
          { id: 6105, description: 'Pickers & Packers' },
          { id: 6103, description: 'Production, Planning & Scheduling' },
          { id: 6100, description: 'Public Transport & Taxi Services' },
          { id: 6109, description: 'Purchasing, Procurement & Inventory' },
          { id: 6106, description: 'Quality Assurance & Control' },
          { id: 6110, description: 'Rail & Maritime Transport' },
          { id: 6111, description: 'Road Transport' },
          { id: 6107, description: 'Team Leaders/Supervisors' },
          { id: 6108, description: 'Warehousing, Storage & Distribution' },
          { id: 6112, description: 'Other' },
        ],
      },
      {
        id: 6008,
        description: 'Marketing & Communications',
        subClassifications: [
          { id: 6009, description: 'Brand Management' },
          { id: 6010, description: 'Digital & Search Marketing' },
          { id: 6011, description: 'Direct Marketing & CRM' },
          { id: 6012, description: 'Event Management' },
          { id: 6013, description: 'Internal Communications' },
          { id: 6017, description: 'Management' },
          { id: 6014, description: 'Market Research & Analysis' },
          { id: 6015, description: 'Marketing Assistants/Coordinators' },
          { id: 6016, description: 'Marketing Communications' },
          { id: 6018, description: 'Product Management & Development' },
          { id: 6019, description: 'Public Relations & Corporate Affairs' },
          { id: 6020, description: 'Trade Marketing' },
          { id: 6021, description: 'Other' },
        ],
      },
      {
        id: 6058,
        description: 'Mining, Resources & Energy',
        subClassifications: [
          { id: 6059, description: 'Analysis & Reporting' },
          { id: 6060, description: 'Health, Safety & Environment' },
          { id: 6061, description: 'Management' },
          { id: 6062, description: 'Mining - Drill & Blast' },
          { id: 6063, description: 'Mining - Engineering & Maintenance' },
          { id: 6064, description: 'Mining - Exploration & Geoscience' },
          { id: 6066, description: 'Mining - Operations' },
          { id: 6065, description: 'Mining - Processing' },
          { id: 6073, description: 'Natural Resources & Water' },
          { id: 6067, description: 'Oil & Gas - Drilling' },
          { id: 6068, description: 'Oil & Gas - Engineering & Maintenance' },
          { id: 6069, description: 'Oil & Gas - Exploration & Geoscience' },
          { id: 6071, description: 'Oil & Gas - Operations' },
          { id: 6070, description: 'Oil & Gas - Production & Refinement' },
          { id: 6072, description: 'Power Generation & Distribution' },
          { id: 6074, description: 'Surveying' },
          { id: 6075, description: 'Other' },
        ],
      },
      {
        id: 1220,
        description: 'Real Estate & Property',
        subClassifications: [
          { id: 6371, description: 'Administration' },
          { id: 6001, description: 'Analysts' },
          { id: 6002, description: 'Body Corporate & Facilities Management' },
          {
            id: 6003,
            description: 'Commercial Sales, Leasing & Property Mgmt',
          },
          {
            id: 6004,
            description: 'Residential Leasing & Property Management',
          },
          { id: 6005, description: 'Residential Sales' },
          { id: 6006, description: 'Retail & Property Development' },
          { id: 1546, description: 'Valuation' },
          { id: 6007, description: 'Other' },
        ],
      },
      {
        id: 6043,
        description: 'Retail & Consumer Products',
        subClassifications: [
          { id: 6044, description: 'Buying' },
          { id: 6045, description: 'Management - Area/Multi-site' },
          { id: 6046, description: 'Management - Department/Assistant' },
          { id: 6047, description: 'Management - Store' },
          { id: 6048, description: 'Merchandisers' },
          { id: 6049, description: 'Planning' },
          { id: 6050, description: 'Retail Assistants' },
          { id: 6051, description: 'Other' },
        ],
      },
      {
        id: 6362,
        description: 'Sales',
        subClassifications: [
          { id: 6363, description: 'Account & Relationship Management' },
          { id: 6364, description: 'Analysis & Reporting' },
          { id: 6366, description: 'Management' },
          { id: 6365, description: 'New Business Development' },
          { id: 6367, description: 'Sales Coordinators' },
          { id: 6368, description: 'Sales Representatives/Consultants' },
          { id: 6369, description: 'Other' },
        ],
      },
      {
        id: 1223,
        description: 'Science & Technology',
        subClassifications: [
          { id: 6215, description: 'Biological & Biomedical Sciences' },
          { id: 6216, description: 'Biotechnology & Genetics' },
          { id: 6217, description: 'Chemistry & Physics' },
          { id: 6218, description: 'Environmental, Earth & Geosciences' },
          { id: 6219, description: 'Food Technology & Safety' },
          { id: 6220, description: 'Laboratory & Technical Services' },
          { id: 6221, description: 'Materials Sciences' },
          {
            id: 6222,
            description: 'Mathematics, Statistics & Information Sciences',
          },
          { id: 6223, description: 'Modelling & Simulation' },
          { id: 6224, description: 'Quality Assurance & Control' },
          { id: 6225, description: 'Other' },
        ],
      },
      {
        id: 6261,
        description: 'Self Employment',
        subClassifications: [{ id: 6262, description: 'Self Employment' }],
      },
      {
        id: 6246,
        description: 'Sport & Recreation',
        subClassifications: [
          { id: 6247, description: 'Coaching & Instruction' },
          { id: 6248, description: 'Fitness & Personal Training' },
          { id: 6249, description: 'Management' },
          { id: 6250, description: 'Other' },
        ],
      },
      {
        id: 1225,
        description: 'Trades & Services',
        subClassifications: [
          { id: 1313, description: 'Air Conditioning & Refrigeration' },
          { id: 1328, description: 'Automotive Trades' },
          { id: 6226, description: 'Bakers & Pastry Chefs' },
          { id: 6227, description: 'Building Trades' },
          { id: 6228, description: 'Butchers' },
          { id: 1345, description: 'Carpentry & Cabinet Making' },
          { id: 6229, description: 'Cleaning Services' },
          { id: 6230, description: 'Electricians' },
          { id: 6231, description: 'Fitters, Turners & Machinists' },
          { id: 6232, description: 'Floristry' },
          { id: 1406, description: 'Gardening & Landscaping' },
          { id: 6233, description: 'Hair & Beauty Services' },
          { id: 6234, description: 'Labourers' },
          { id: 6235, description: 'Locksmiths' },
          { id: 6236, description: 'Maintenance & Handyperson Services' },
          { id: 6237, description: 'Nannies & Babysitters' },
          { id: 6238, description: 'Painters & Sign Writers' },
          { id: 6240, description: 'Plumbers' },
          { id: 6239, description: 'Printing & Publishing Services' },
          { id: 6241, description: 'Security Services' },
          { id: 6242, description: 'Tailors & Dressmakers' },
          { id: 6243, description: 'Technicians' },
          { id: 6244, description: 'Welders & Boilermakers' },
          { id: 6245, description: 'Other' },
        ],
      },
    ],
    th: [
      {
        id: 1200,
        description: 'งานบัญชี',
        subClassifications: [
          {
            id: 6140,
            description: 'งานเจ้าหน้าที่บัญชี/งานเจ้าหน้าที่ธุรการบัญชี',
          },
          { id: 1303, description: 'งานบัญชีเจ้าหนี้' },
          { id: 6141, description: 'งานบัญชีลูกหนี้/งานสินเชื่อ' },
          { id: 6143, description: 'งานวิเคราะห์และจัดทำรายงาน' },
          { id: 6142, description: 'งานผู้ช่วยผู้ทำบัญชี' },
          { id: 6144, description: 'งานตรวจสอบบัญชี - ภายนอกองค์กร' },
          { id: 6145, description: 'งานตรวจสอบบัญชี - ภายในองค์กร' },
          { id: 6146, description: 'งานธุรการบัญชี' },
          { id: 6147, description: 'งานบริการธุรกิจและที่ปรึกษาองค์กร' },
          { id: 6148, description: 'งานเลขานุการบริษัท' },
          { id: 6149, description: 'งานกำกับดูแลและบริหารความเสี่ยง' },
          { id: 6150, description: 'งานบัญชีต้นทุน' },
          { id: 6151, description: 'งานบัญชีการเงินและงบการเงิน' },
          { id: 6152, description: 'งานผู้จัดการการเงิน/งานผู้ควบคุมการเงิน' },
          {
            id: 6153,
            description: 'งานบัญชีนิติวิทยาศาสตร์และการสืบสวนการทุจริต ',
          },
          {
            id: 6154,
            description: 'งานการฟื้นฟูกิจการและปรับปรุงโครงสร้างหนี้',
          },
          { id: 6155, description: 'งานบัญชีสินค้าคงคลังและสินทรัพย์ถาวร' },
          { id: 6156, description: 'งานบริหาร' },
          { id: 6157, description: 'งานบริหารจัดการบัญชีและงบประมาณ' },
          { id: 1468, description: 'งานจัดทำเงินเดือนและค่าจ้าง' },
          { id: 6158, description: 'งานกลยุทธ์และวางแผน' },
          { id: 6159, description: 'งานระบบบัญชี งานตรวจสอบระบบ' },
          { id: 6160, description: 'งานภาษี' },
          { id: 6161, description: 'งาน Treasury' },
          { id: 6162, description: 'งานบัญชีอื่น ๆ' },
        ],
      },
      {
        id: 6251,
        description: 'งานธุรการ ',
        subClassifications: [
          { id: 6252, description: 'งานธุรการ' },
          { id: 6253, description: 'งาน Sales Admin' },
          { id: 6254, description: 'งานบริหารจัดการสัญญา' },
          { id: 6255, description: 'งานคีย์ข้อมูล' },
          { id: 6256, description: 'งานจัดการสำนักงาน' },
          { id: 6257, description: 'งานผู้ช่วยส่วนตัว งานเลขานุการ' },
          { id: 6258, description: 'งานพนักงานต้อนรับ' },
          { id: 6259, description: 'งานบันทึกและควบคุมเอกสาร' },
          { id: 6260, description: 'งานธุรการอื่น ๆ' },
        ],
      },
      {
        id: 6304,
        description: 'งานโฆษณา งานศิลปะ งานสื่อ',
        subClassifications: [
          { id: 6305, description: 'งานดูแลลูกค้า Agency' },
          { id: 6306, description: 'งาน Art Direction' },
          { id: 6307, description: 'งานตัดต่อ งานพิมพ์' },
          { id: 6308, description: 'งานบริหารจัดการอีเวนต์' },
          { id: 6309, description: 'งานข่าว งานเขียน' },
          { id: 6310, description: 'งานบริหาร' },
          { id: 6311, description: 'งานกลยุทธ์สื่อ งานวางแผนและจัดซื้อ' },
          { id: 6312, description: 'งานศิลปะการแสดง' },
          { id: 6313, description: 'งานช่างภาพ' },
          { id: 6314, description: 'งานเขียนโปรแกรม งานผลิตสื่อ' },
          { id: 6315, description: 'งานส่งเสริมการขาย' },
          {
            id: 6316,
            description: 'งานโฆษณาอื่น ๆ งานศิลปะอื่น ๆ งานสื่ออื่น ๆ',
          },
        ],
      },
      {
        id: 1203,
        description: 'งานธนาคาร งานการเงิน',
        subClassifications: [
          { id: 6174, description: 'งานบริหารจัดการ Account' },
          { id: 6175, description: 'งานวิเคราะห์และจัดทำรายงาน' },
          { id: 6177, description: 'งานธนาคาร - ลูกค้าองค์กรขนาดเล็กและกลาง' },
          {
            id: 6178,
            description: 'งานธนาคาร - ลูกค้าองค์กรขนาดใหญ่และสถาบัน',
          },
          { id: 6176, description: 'งานธนาคาร - สาขา' },
          { id: 1352, description: 'งานบริการลูกค้าทางการเงิน' },
          { id: 6179, description: 'งานดูแลการปฏิบัติงานและบริหารความเสี่ยง' },
          { id: 6180, description: 'งานที่ปรึกษาการเงินและการลงทุน' },
          { id: 6181, description: 'งานสินเชื่อ' },
          { id: 1392, description: 'งานวางแผนทางการเงิน' },
          { id: 1404, description: 'งานบริหารจัดการกองทุน' },
          { id: 6182, description: 'งานบริหาร' },
          { id: 6183, description: 'งานสินเชื่อเพื่อที่อยู่อาศัย' },
          { id: 6184, description: 'งาน Settlement' },
          { id: 6185, description: 'งานแนะนำการลงทุน งานตัวแทนแนะนำการลงทุน' },
          { id: 6186, description: 'งาน Treasury' },
          { id: 6187, description: 'งานธนาคารอื่น ๆ งานการเงินอื่น ๆ' },
        ],
      },
      {
        id: 1204,
        description: 'งาน Call Center งานบริการลูกค้า',
        subClassifications: [
          { id: 6084, description: 'งานเร่งรัดหนี้สิน' },
          { id: 6085, description: 'งานบริการลูกค้า - งาน Call Center' },
          { id: 6086, description: 'งานบริการลูกค้า - ลูกค้าสัมพันธ์' },
          { id: 6087, description: 'งานบริหาร' },
          { id: 6088, description: 'งานขายทางโทรศัพท์ - Inbound  ' },
          { id: 6089, description: 'งานขายทางโทรศัพท์ - Outbound  ' },
          { id: 6090, description: 'งานหัวหน้างาน/งานหัวหน้าทีม' },
          {
            id: 6091,
            description: 'งาน Call Center อื่น ๆ งานบริการลูกค้าอื่น ๆ',
          },
        ],
      },
      {
        id: 7019,
        description: 'งานประธานกรรมการบริหาร งานบริหารทั่วไป',
        subClassifications: [
          { id: 7020, description: 'งานคณะกรรมการผู้บริหาร' },
          { id: 7021, description: 'งานประธานกรรมการบริหาร' },
          {
            id: 7022,
            description:
              'งานประธานเจ้าหน้าที่ฝ่ายปฏิบัติการ และกรรมการผู้จัดการ',
          },
          { id: 7023, description: 'งานบริหารทั่วไป/ผู้จัดการแผนก' },
          {
            id: 7024,
            description: 'งานประธานกรรมการบริหาร งานบริหารทั่วไปอื่น ๆ',
          },
        ],
      },
      {
        id: 6163,
        description: 'งานบริการชุมชน งานพัฒนาชุมชน',
        subClassifications: [
          { id: 6164, description: 'งานช่วยเหลือผู้สูงวัยและผู้ทุพพลภาพ' },
          {
            id: 6165,
            description: 'งานสวัสดิการเด็ก เยาวชน และบริการครอบครัว',
          },
          { id: 6166, description: 'งานพัฒนาชุมชน' },
          { id: 6167, description: 'งานบริการจัดหางาน' },
          { id: 6168, description: 'งานระดมทุน' },
          { id: 6169, description: 'งานบริการด้านที่อยู่อาศัยและคนไร้บ้าน' },
          {
            id: 6170,
            description: 'งานบริการชนพื้นเมืองและความหลากหลายทางวัฒนธรรม',
          },
          { id: 6171, description: 'งานบริหาร' },
          {
            id: 6172,
            description: 'งานประสานงานอาสาสมัคร งานอาสาสมัครช่วยเหลือ',
          },
          { id: 6173, description: 'งานบริการชุมชนอื่น ๆ งานพัฒนาชุมชนอื่น ๆ' },
        ],
      },
      {
        id: 1206,
        description: 'งานก่อสร้าง',
        subClassifications: [
          { id: 6113, description: 'งานบริหารจัดการสัญญา' },
          { id: 1387, description: 'งานประเมินราคา' },
          { id: 6114, description: 'งานโฟร์แมน/งานหัวหน้างาน' },
          { id: 6115, description: 'งานวิศวกรสิ่งแวดล้อม งาน จป' },
          { id: 6116, description: 'งานบริหาร' },
          { id: 6117, description: 'งานวางแผนและการจัดกำหนดการโครงการ' },
          { id: 6118, description: 'งานควบคุมเครื่องจักร' },
          { id: 6119, description: 'งานบริหารโครงการ' },
          { id: 6120, description: 'งานประกันและควบคุมคุณภาพ' },
          { id: 6121, description: 'งานสำรวจ' },
          { id: 6122, description: 'งานก่อสร้างอื่น ๆ' },
        ],
      },
      {
        id: 6076,
        description: 'งานที่ปรึกษา งานวางแผนกลยุทธ์',
        subClassifications: [
          { id: 6077, description: 'งานนักวิเคราะห์' },
          { id: 6078, description: 'งานพัฒนาองค์กร' },
          {
            id: 6079,
            description: 'งานที่ปรึกษาด้านสิ่งแวดล้อมและความยั่งยืน',
          },
          {
            id: 6080,
            description: 'งานที่ปรึกษาด้านการจัดการและการเปลี่ยนแปลง',
          },
          { id: 6081, description: 'งานนโยบาย' },
          { id: 6082, description: 'งานวางแผนกลยุทธ์' },
          {
            id: 6083,
            description: 'งานที่ปรึกษาอื่น ๆ งานวางแผนกลยุทธ์อื่น ๆ',
          },
        ],
      },
      {
        id: 6263,
        description: 'งานออกแบบ งานสถาปัตยกรรม',
        subClassifications: [
          { id: 6265, description: 'งานเขียนแบบสถาปัตยกรรม' },
          { id: 6264, description: 'งานสถาปัตยกรรม' },
          { id: 6267, description: 'งานออกแบบแฟชั่นและสิ่งทอ' },
          { id: 6268, description: 'งานออกแบบกราฟฟิค' },
          { id: 6266, description: 'งานออกแบบภาพประกอบและแอนิเมชัน' },
          { id: 6269, description: 'งานออกแบบอุตสาหกรรม' },
          { id: 6270, description: 'งานออกแบบภายใน' },
          { id: 6271, description: 'งานภูมิสถาปัตยกรรม' },
          { id: 6272, description: 'งานออกแบบและวางผังเมือง' },
          { id: 6273, description: 'งานออกแบบเว็บไซต์' },
          { id: 6274, description: 'งานออกแบบอื่น ๆ งานสถาปัตยกรรมอื่น ๆ' },
        ],
      },
      {
        id: 6123,
        description: 'งานการศึกษา งานฝึกอบรม',
        subClassifications: [
          { id: 6124, description: 'งานดูแลเด็ก' },
          { id: 6125, description: 'งานบริการห้องสมุดและการจัดการข้อมูล' },
          { id: 6126, description: 'งานบริหาร - โรงเรียน' },
          { id: 6127, description: 'งานบริหาร - มหาวิทยาลัย' },
          { id: 6128, description: 'งานบริหาร - อาชีวศึกษา' },
          { id: 6129, description: 'งานวิจัยและทุน' },
          { id: 6130, description: 'งานบริการการศึกษา' },
          { id: 6132, description: 'งานสอน - การศึกษาปฐมวัย ' },
          { id: 6133, description: 'งานสอน - ประถมศึกษา' },
          { id: 6134, description: 'งานสอน - มัธยมศึกษา' },
          { id: 6135, description: 'งานสอน - ระดับอุดมศึกษา' },
          { id: 6136, description: 'งานสอน - อาชีวศึกษา' },
          { id: 6131, description: 'งานผู้ช่วยสอน' },
          { id: 6137, description: 'งานกวดวิชา' },
          { id: 6138, description: 'งานฝึกอบรมและประเมินสถานที่ทำงาน' },
          { id: 6139, description: 'งานการศึกษาอื่น ๆ งานฝึกอบรมอื่น ๆ' },
        ],
      },
      {
        id: 1209,
        description: 'งานวิศวกรรม',
        subClassifications: [
          { id: 6022, description: 'งานวิศวกรการบินและอวกาศ' },
          { id: 6023, description: 'งานวิศวกรยานยนต์' },
          { id: 6024, description: 'งานวิศวกรอาคาร' },
          { id: 6026, description: 'งานวิศวกรเคมี' },
          { id: 6027, description: 'งานวิศวกรโยธา/งานวิศวกรโครงสร้าง' },
          { id: 6028, description: 'งานวิศวกรไฟฟ้า/งานวิศวกรอิเล็กทรอนิกส์' },
          { id: 6025, description: 'งานวิศวกรเขียนแบบ ถอดแบบ' },
          { id: 6029, description: 'งานวิศวกรสิ่งแวดล้อม' },
          { id: 6030, description: 'งานวิศวกรสนาม' },
          { id: 6031, description: 'งานวิศวกรอุตสาหการ' },
          { id: 6032, description: 'งานวิศวกรซ่อมบำรุง' },
          { id: 6033, description: 'งานบริหาร' },
          { id: 6034, description: 'งานวิศวกรจัดการวัสดุ' },
          { id: 6035, description: 'งานวิศวกรเครื่องกล' },
          { id: 6036, description: 'งานวิศวกรกระบวนการผลิต' },
          { id: 6038, description: 'งานวิศวกรโครงการ' },
          { id: 6037, description: 'งานวิศวกรบริหารโครงการ' },
          { id: 6039, description: 'งานหัวหน้างาน' },
          { id: 6040, description: 'งานวิศวกรระบบ' },
          { id: 6041, description: 'งานวิศวกรบำบัดน้ำและของเสีย' },
          { id: 6042, description: 'งานวิศวะอื่น ๆ' },
        ],
      },
      {
        id: 6205,
        description: 'งานฟาร์ม งานสัตวบาล งานอนุรักษ์',
        subClassifications: [
          { id: 6206, description: 'งานบริการด้านพืชไร่และฟาร์ม' },
          { id: 6207, description: 'งานอนุรักษ์ งานสวนสาธารณะและสัตว์ป่า' },
          { id: 6208, description: 'งานเจ้าหน้าที่ฟาร์ม' },
          { id: 6209, description: 'งานบริหารจัดการฟาร์ม' },
          { id: 6210, description: 'งานประมงและเพาะพันธุ์สัตว์น้ำ' },
          { id: 6211, description: 'งานเกษตรพืชสวน' },
          { id: 6212, description: 'งานสัตวแพทย์และสวัสดิภาพสัตว์' },
          { id: 6213, description: 'งานผลิตไวน์และเพาะพันธุ์องุ่น' },
          {
            id: 6214,
            description: 'งานฟาร์มอื่น ๆ งานสัตวบาลอื่น ๆ งานอนุรักษ์อื่น ๆ',
          },
        ],
      },
      {
        id: 1210,
        description: 'งานราชการ ',
        subClassifications: [
          { id: 1314, description: 'งานกองทัพอากาศ' },
          { id: 1322, description: 'งานกองทัพบก' },
          { id: 1378, description: 'งานบริการฉุกเฉิน' },
          { id: 1409, description: 'งานราชการ - ส่วนกลาง' },
          { id: 1410, description: 'งานราชการ - ส่วนท้องถิ่น' },
          { id: 1411, description: 'งานรัฐบาล - ส่วนจังหวัด' },
          { id: 1450, description: 'งานกองทัพเรือ' },
          { id: 6359, description: 'งานตำรวจและราชทัณฑ์' },
          { id: 6360, description: 'งานนโยบาย งานวางแผนและระเบียบข้อบังคับ' },
          { id: 6361, description: 'งานราชการอื่น ๆ' },
        ],
      },
      {
        id: 1211,
        description: 'งานดูแลสุขภาพและการแพทย์',
        subClassifications: [
          {
            id: 6329,
            description: 'งานรถพยาบาล งานปฏิบัติการฉุกเฉินการแพทย์ ',
          },
          { id: 6330, description: 'งานไคโรแพรคติก งานนวดจัดกระดูก ' },
          { id: 1353, description: 'งานวิจัยทางการแพทย์' },
          { id: 1372, description: 'งานทันตกรรม' },
          { id: 6331, description: 'งานนักโภชนาการ' },
          { id: 1386, description: 'งานบริการด้านสิ่งแวดล้อม' },
          { id: 6332, description: 'งานแพทย์ทั่วไป' },
          { id: 6333, description: 'งานบริหาร' },
          { id: 6334, description: 'งานธุรการทางการแพทย์' },
          { id: 6335, description: 'งานแผนกรังสีวิทยา' },
          { id: 6336, description: 'งานแพทย์เฉพาะทาง' },
          { id: 6337, description: 'งานธรรมชาติบำบัดและการแพทย์ทางเลือก' },
          {
            id: 6338,
            description:
              'งานพยาบาล - แผนกอุบัติเหตุและฉุกเฉิน แผนกผู้ป่วยวิกฤติ',
          },
          { id: 6339, description: 'งานพยาบาล - แผนกดูแลผู้สูงอายุ' },
          { id: 6340, description: 'งานพยาบาล - แผนกสุขภาพชุมชน แม่และเด็ก' },
          {
            id: 6341,
            description:
              'งานพยาบาล - อาจารย์พยาบาล ผู้จัดการศึกษา การฝึกอบรมสำหรับพยาบาล',
          },
          { id: 6342, description: 'งานพยาบาล - แผนกอายุรกรรมและศัลยกรรม' },
          {
            id: 6343,
            description: 'งานพยาบาล - แผนกผู้ป่วยที่มีความเสี่ยงสูง',
          },
          { id: 6344, description: 'งานพยาบาล - งานบริหาร' },
          { id: 6345, description: 'งานพยาบาล - แผนกผดุงครรภ์' },
          { id: 6346, description: 'งานพยาบาล - แผนกกุมารเวชและไอซียูเด็ก' },
          {
            id: 6347,
            description:
              'งานพยาบาล - แผนกจิตเวช, นิติวิทยาศาสตร์และสุขภาพทัณฑสถาน',
          },
          { id: 6348, description: 'งานพยาบาล - แผนกดูแลผู้ป่วยพักฟื้น' },
          { id: 6349, description: 'งานจักษุวิทยา' },
          { id: 1467, description: 'งานพยาธิวิทยา' },
          { id: 6350, description: 'งานยา เวชภัณฑ์ และอุปกรณ์ทางการแพทย์' },
          { id: 1470, description: 'งานเภสัชกร งานร้านขายยา' },
          { id: 6352, description: 'งานกายภาพบำบัด งานฟื้นฟูสมรรถภาพ' },
          {
            id: 6351,
            description: 'งานจิตวิทยา งานให้คำปรึกษา งานสังคมสงเคราะห์',
          },
          { id: 6370, description: 'งานแพทย์ฝึกหัด งานแพทย์ประจำบ้าน' },
          { id: 6353, description: 'งานขายทางการแพทย์' },
          { id: 6354, description: 'งานบำบัดด้วยการพูด ' },
          { id: 6355, description: 'งานดูแลสุขภาพและการแพทย์อื่น ๆ' },
        ],
      },
      {
        id: 1212,
        description: 'งานบริการ งานท่องเที่ยว',
        subClassifications: [
          { id: 1315, description: 'งานสายการบิน' },
          { id: 1332, description: 'งานพนักงานบาร์และเครื่องดื่ม' },
          { id: 6052, description: 'งานเชฟ งานพ่อครัว งานแม่ครัว' },
          { id: 6053, description: 'งานแผนกต้อนรับ งานบริการลูกค้า' },
          { id: 1405, description: 'งาน Gaming' },
          { id: 1415, description: 'งานทำความสะอาด' },
          { id: 6054, description: 'งานครัว' },
          { id: 6055, description: 'งานบริหาร' },
          { id: 6056, description: 'งานสำรองห้องพัก' },
          { id: 1537, description: 'งานมัคคุเทศก์' },
          { id: 1542, description: 'งานตัวแทนท่องเที่ยว ' },
          { id: 1549, description: 'งานพนักงานเสริฟ' },
          { id: 6057, description: 'งานบริการอื่น ๆ งานท่องเที่ยวอื่น ๆ' },
        ],
      },
      {
        id: 6317,
        description: 'งานทรัพยากรบุคคล งานสรรหาบุคลากร',
        subClassifications: [
          { id: 6318, description: 'งานทรัพยากรบุคคลทั่วไป' },
          { id: 6319, description: 'งานแรงงานสัมพันธ์' },
          { id: 6320, description: 'งานบริหาร - บริษัทจัดหางาน' },
          { id: 6321, description: 'งานบริหาร - ภายในองค์กร' },
          { id: 6322, description: 'งานอาชีวอนามัยและความปลอดภัย' },
          { id: 6323, description: 'งานพัฒนาองค์กร' },
          { id: 6324, description: 'งานสรรหาบุคลากร - บริษัทจัดหางาน' },
          { id: 6325, description: 'งานสรรหาบุคลากร - ภายในองค์กร' },
          { id: 6326, description: 'งานบริหารค่าจ้างและผลตอบแทน' },
          { id: 6327, description: 'งานฝึกอบรม' },
          {
            id: 6328,
            description: 'งานทรัพยากรบุคคลอื่น ๆ งานสรรหาบุคลากรอื่น ๆ',
          },
        ],
      },
      {
        id: 6281,
        description: 'งานไอที งานเทคโนโลยีสื่อสาร',
        subClassifications: [
          { id: 6282, description: 'งานสถาปนิก' },
          {
            id: 6283,
            description: 'งานนักวิเคราะห์ธุรกิจ/งานนักวิเคราะห์ระบบ',
          },
          { id: 6284, description: 'งานช่างเทคนิคคอมพิวเตอร์' },
          { id: 6285, description: 'งานที่ปรึกษา' },
          { id: 6286, description: 'งานพัฒนาและบริหารจัดการฐานข้อมูล' },
          { id: 6287, description: 'งานโปรแกรมเมอร์' },
          { id: 6288, description: 'งานวิศวกร - ฮาร์ดแวร์' },
          { id: 6289, description: 'งานวิศวกร - เครือข่าย' },
          { id: 6290, description: 'งานวิศวกร - ซอฟต์แวร์' },
          { id: 6291, description: 'งาน Help Desk งาน IT Support' },
          { id: 6292, description: 'งานบริหาร' },
          { id: 6293, description: 'งานผู้ดูแลระบบเครือข่าย' },
          { id: 6294, description: 'งานบริหารจัดการและพัฒนาผลิตภัณฑ์' },
          { id: 6295, description: 'งานบริหารจัดการโปรแกรมและโปรเจกต์' },
          { id: 6296, description: 'งานขายไอที ' },
          { id: 6297, description: 'งานระบบรักษาความปลอดภัย' },
          { id: 6298, description: 'งานหัวหน้าทีม' },
          { id: 6299, description: 'งานเขียนทางเทคนิค' },
          { id: 6300, description: 'งานโทรคมนาคม' },
          { id: 6301, description: 'งานทดสอบและควบคุมคุณภาพ' },
          { id: 6302, description: 'งานออกแบบและพัฒนาเว็บไซต์' },
          { id: 6303, description: 'งานไอทีอื่น ๆ งานเทคโนโลยีสื่อสารอื่น ๆ' },
        ],
      },
      {
        id: 1214,
        description: 'งานประกันภัย งานกองทุนเกษียณ',
        subClassifications: [
          { id: 6275, description: 'งานนักสถิติการประกันภัย' },
          { id: 6276, description: 'งานประเมินสินไหม' },
          { id: 6277, description: 'งานตัวแทนประกันภัย' },
          { id: 1350, description: 'งานสินไหมทดแทน' },
          { id: 6278, description: 'งานบริหารกองทุน' },
          { id: 6279, description: 'งานบริหาร' },
          { id: 1499, description: 'งานที่ปรึกษาความเสี่ยง' },
          { id: 1524, description: 'งานกองทุนเกษียณอายุ' },
          { id: 1544, description: 'งานพิจารณารับประกันภัย' },
          { id: 1553, description: 'งานค่าตอบแทนทดแทนแรงงาน' },
          { id: 6280, description: 'งานประกันภัยอื่น ๆ งานกองทุนเกษียณอื่น ๆ' },
        ],
      },
      {
        id: 1216,
        description: 'งานกฎหมาย',
        subClassifications: [
          { id: 6188, description: 'งานกฎหมายการธนาคารและการเงิน' },
          { id: 6190, description: 'งานกฎหมายก่อสร้าง' },
          { id: 6191, description: 'งานกฎหมายองค์กรและการค้า' },
          { id: 6189, description: 'งานกฎหมายอาญา งานกฎหมายแพ่ง' },
          { id: 6192, description: 'งานกฎหมายสิ่งแวดล้อมและผังเมือง' },
          { id: 6193, description: 'งานกฎหมายครอบครัว' },
          { id: 6194, description: 'งานกฎหมายทั่วไป - องค์กร' },
          { id: 6195, description: 'งานกฎหมายทั่วไป - สำนักงานกฎหมาย' },
          { id: 6196, description: 'งานกฎหมายแรงงานสัมพันธ์และการจ้างงาน' },
          { id: 6197, description: 'งานกฎหมายประกันภัยและเงินบำนาญ' },
          { id: 6198, description: 'งานกฎหมายทรัพย์สินทางปัญญา' },
          { id: 1429, description: 'งานเสมียนกฎหมาย งานทนายความ' },
          { id: 6199, description: 'งานบริหารจัดการการปฏิบัติงานทางกฎหมาย' },
          { id: 1431, description: 'งานเลขานุการกฎหมาย' },
          { id: 6200, description: 'งานดำเนินคดีและระงับข้อพิพาท' },
          { id: 6201, description: 'งานกฎหมายกรณีบาดเจ็บเสียหายส่วนบุคคล' },
          { id: 6202, description: 'งานกฎหมายทรัพย์สิน' },
          { id: 6203, description: 'งานกฎหมายภาษี' },
          { id: 6204, description: 'งานกฎหมายอื่น ๆ' },
        ],
      },
      {
        id: 6092,
        description: 'งานการผลิต งานขนส่ง',
        subClassifications: [
          { id: 6095, description: 'งานวิเคราะห์และจัดทำรายงาน' },
          { id: 6093, description: 'งานประกอบชิ้นส่วนการผลิต' },
          { id: 6094, description: 'งานการบิน' },
          {
            id: 6096,
            description: 'งานบริการจัดส่ง งานพนักงานขับรถ งานบริการไปรษณีย์',
          },
          { id: 6097, description: 'งานบริหารและติดตามพิกัดตำแหน่งยานพาหนะ' },
          { id: 6098, description: 'งานขนส่งสินค้า' },
          { id: 6099, description: 'งานนำเข้า งานส่งออก งานศุลกากร' },
          { id: 6101, description: 'งานดูแลเครื่องจักร' },
          { id: 6102, description: 'งานบริหาร' },
          {
            id: 6104,
            description: 'งานช่างทำแพทเทิร์น/งานช่างตัดเย็บเสื้อผ้า',
          },
          { id: 6105, description: 'งานแพ็คสินค้า' },
          { id: 6103, description: 'งานผลิต งานวางแผน งานจัดตารางเวลา' },
          { id: 6100, description: 'งานบริการขนส่งสาธารณะและแท็กซี่' },
          { id: 6109, description: 'งานจัดซื้อ งานสินค้าคงคลัง' },
          { id: 6106, description: 'งานประกันคุณภาพ งานควบคุมคุณภาพ' },
          { id: 6110, description: 'งานขนส่งทางรถไฟ งานขนส่งทางทะเล' },
          { id: 6111, description: 'งานขนส่งทางบก' },
          { id: 6107, description: 'งานหัวหน้าทีม/งานหัวหน้างาน' },
          { id: 6108, description: 'งานคลังสินค้า งานจัดเก็บ งานจัดจำหน่าย' },
          { id: 6112, description: 'งานการผลิตอื่น ๆ งานขนส่งอื่น ๆ' },
        ],
      },
      {
        id: 6008,
        description: 'งานการตลาด งานสื่อสาร',
        subClassifications: [
          { id: 6009, description: 'งานบริหารแบรนด์สินค้า งานบริหารตราสินค้า' },
          { id: 6010, description: 'งานการตลาดดิจิทัล' },
          { id: 6011, description: 'งานการตลาดทางตรง งานการตลาด CRM' },
          { id: 6012, description: 'งานอีเวนต์' },
          { id: 6013, description: 'งานสื่อสารภายในองค์กร' },
          { id: 6017, description: 'งานบริหาร' },
          { id: 6014, description: 'งานวิจัยและวิเคราะห์การตลาด' },
          { id: 6015, description: 'งานผู้ช่วยการตลาด/งานผู้ประสานงานการตลาด' },
          { id: 6016, description: 'งานสื่อสารการตลาด' },
          { id: 6018, description: 'งานพัฒนาสินค้า' },
          { id: 6019, description: 'งานประชาสัมพันธ์และสื่อสารองค์กร' },
          { id: 6020, description: 'งาน Trade Marketing' },
          { id: 6021, description: 'งานการตลาดอื่น ๆ งานสื่อสารอื่น ๆ' },
        ],
      },
      {
        id: 6058,
        description: 'งานเหมืองแร่ งานทรัพยากรและพลังงาน',
        subClassifications: [
          { id: 6059, description: 'งานวิเคราะห์และจัดทำรายงาน' },
          { id: 6060, description: 'งานวิศวกรสิ่งแวดล้อม งาน จป' },
          { id: 6061, description: 'งานบริหาร' },
          { id: 6062, description: 'งานเหมืองแร่ - งานสว่านและระเบิด' },
          { id: 6063, description: 'งานเหมืองแร่ - งานวิศวกร งานบำรุงรักษา' },
          { id: 6064, description: 'งานเหมืองแร่ - งานสำรวจและธรณีศาสตร์' },
          { id: 6066, description: 'งานเหมืองแร่ - งานวางแผนและปฏิบัติการ' },
          { id: 6065, description: 'งานเหมืองแร่- การแปรรูป' },
          { id: 6073, description: 'งานทรัพยากรธรรมชาติและน้ำ' },
          { id: 6067, description: 'งานน้ำมันและก๊าซ - งานขุดเจาะ' },
          {
            id: 6068,
            description: 'งานน้ำมันและก๊าซ - งานวิศวกร งานบำรุงรักษา',
          },
          { id: 6069, description: 'งานน้ำมันและก๊าซ - งานสำรวจและธรณีศาสตร์' },
          {
            id: 6071,
            description: 'งานน้ำมันและก๊าซ - งานวางแผนและปฏิบัติการ',
          },
          { id: 6070, description: 'งานน้ำมันและก๊าซ - งานผลิต งานกลั่น' },
          { id: 6072, description: 'งานผลิตและจ่ายไฟฟ้า' },
          { id: 6074, description: 'งานสำรวจ' },
          {
            id: 6075,
            description: 'งานเหมืองแร่อื่น ๆ งานทรัพยากรและพลังงานอื่น ๆ',
          },
        ],
      },
      {
        id: 1220,
        description: 'งานอสังหาริมทรัพย์',
        subClassifications: [
          { id: 6371, description: 'งานธุรการ' },
          { id: 6001, description: 'งานนักวิเคราะห์' },
          { id: 6002, description: 'งานบริหารจัดการที่อยู่อาศัย' },
          {
            id: 6003,
            description: 'งานขายโครงการ ให้เช่า และบริหารจัดการอสังหาริมทรัพย์',
          },
          {
            id: 6004,
            description: 'งานบริหารจัดการที่อยู่อาศัยและอสังหาริมทรัพย์',
          },
          { id: 6005, description: 'งานขายที่อยู่อาศัย' },
          { id: 6006, description: 'งานพัฒนาอสังหาริมทรัพย์' },
          { id: 1546, description: 'งานประเมินมูลค่าทรัพย์สิน' },
          { id: 6007, description: 'งานอสังหาริมทรัพย์อื่น ๆ' },
        ],
      },
      {
        id: 6043,
        description: 'งานสินค้าขายปลีกและอุปโภคบริโภค',
        subClassifications: [
          { id: 6044, description: 'งานจัดซื้อ' },
          { id: 6045, description: 'งานบริหาร - พื้นที่' },
          { id: 6046, description: 'งานบริหาร - แผนก/ผู้ช่วย' },
          { id: 6047, description: 'งานบริหาร - สโตร์' },
          { id: 6048, description: 'งานออกแบบและจัดวางสินค้า' },
          { id: 6049, description: 'งานวางแผน' },
          { id: 6050, description: 'งานดูแลร้านค้า' },
          { id: 6051, description: 'งานสินค้าขายปลีกและอุปโภคบริโภคอื่น ๆ' },
        ],
      },
      {
        id: 6362,
        description: 'งานขาย',
        subClassifications: [
          { id: 6363, description: 'งานขาย/งานดูแลลูกค้า' },
          { id: 6364, description: 'งานวิเคราะห์และจัดทำรายงาน' },
          { id: 6366, description: 'งานบริหาร' },
          { id: 6365, description: 'งานพัฒนาธุรกิจใหม่' },
          { id: 6367, description: 'งานประสานงานขาย' },
          { id: 6368, description: 'งานขาย/งานที่ปรึกษาการขาย ' },
          { id: 6369, description: 'งานขายอื่น ๆ' },
        ],
      },
      {
        id: 1223,
        description: 'งานวิทยาศาสตร์ งานเทคโนโลยี',
        subClassifications: [
          { id: 6215, description: 'งานวิทยาศาสตร์ชีวภาพ งานชีวการแพทย์' },
          { id: 6216, description: 'งานเทคโนโลยีชีวภาพ งานพันธุศาสตร์' },
          { id: 6217, description: 'งานเคมี งานฟิสิกส์' },
          { id: 6218, description: 'งานสิ่งแวดล้อม งานธรณีศาสตร์' },
          { id: 6219, description: 'งานเทคโนโลยีอาหารและความปลอดภัย' },
          { id: 6220, description: 'งาน Lab งานบริการด้านเทคนิค' },
          { id: 6221, description: 'งานนักวัสดุศาสตร์' },
          { id: 6222, description: 'งานคณิตศาสตร์ งานสถิติ งานสารสนเทศศาสตร์' },
          { id: 6223, description: 'งานสร้างแบบจำลอง' },
          { id: 6224, description: 'งานตรวจสอบและควบคุมคุณภาพ' },
          { id: 6225, description: 'งานวิทยาศาสตร์อื่น ๆ งานเทคโนโลยีอื่น ๆ' },
        ],
      },
      {
        id: 6261,
        description: 'งานอาชีพอิสระ',
        subClassifications: [{ id: 6262, description: 'งานอาชีพอิสระ' }],
      },
      {
        id: 6246,
        description: 'งานการกีฬา งานสันทนาการ',
        subClassifications: [
          { id: 6247, description: 'งานโค้ช งานฝึกสอน' },
          { id: 6248, description: 'งานฟิตเนส งานผู้ฝึกสอนส่วนตัว' },
          { id: 6249, description: 'งานบริหาร' },
          { id: 6250, description: 'งานการกีฬาอื่น ๆ งานสันทนาการอื่น ๆ' },
        ],
      },
      {
        id: 1225,
        description: 'งาน Trade งานบริการ',
        subClassifications: [
          { id: 1313, description: 'งานเครื่องปรับอากาศและเครื่องทำความเย็น' },
          { id: 1328, description: 'งานบริการยานยนต์' },
          { id: 6226, description: 'งานทำเบเกอรี่ งานทำขนม' },
          { id: 6227, description: 'งานก่อสร้าง' },
          { id: 6228, description: 'งานชำแหละและตัดแต่งเนื้อสัตว์' },
          { id: 1345, description: 'งานช่างไม้' },
          { id: 6229, description: 'งานบริการทำความสะอาด' },
          { id: 6230, description: 'งานช่างไฟฟ้า' },
          { id: 6231, description: 'งานช่างฟิต งานช่างกลึง งานช่างเครื่อง' },
          { id: 6232, description: 'งานร้านดอกไม้' },
          { id: 1406, description: 'งานจัดสวน' },
          { id: 6233, description: 'งานบริการทำผมและความงาม' },
          { id: 6234, description: 'งานใช้แรงงาน' },
          { id: 6235, description: 'งานช่างทำกุญแจ' },
          { id: 6236, description: 'งานบำรุงรักษา งานช่างซ่อมบำรุง' },
          { id: 6237, description: 'งานพี่เลี้ยงเด็ก' },
          { id: 6238, description: 'งานจิตรกร งานเขียนป้าย' },
          { id: 6240, description: 'งานช่างประปา' },
          { id: 6239, description: 'งานพิมพ์ งานบริการจัดพิมพ์' },
          { id: 6241, description: 'งานบริการรักษาความปลอดภัย' },
          { id: 6242, description: 'งานช่างตัดเสื้อ' },
          { id: 6243, description: 'งานช่างเทคนิค' },
          { id: 6244, description: 'งานช่างเชื่อมและบอยเลอร์' },
          { id: 6245, description: 'งาน Trade อื่น ๆ งานบริการอื่น ๆ' },
        ],
      },
    ],
  },
  'asia-4': {
    en: [
      {
        id: 1200,
        description: 'Accounting',
        subClassifications: [
          { id: 6140, description: 'Accounts Officers/Clerks' },
          { id: 1303, description: 'Accounts Payable' },
          { id: 6141, description: 'Accounts Receivable/Credit Control' },
          { id: 6143, description: 'Analysis & Reporting' },
          { id: 6142, description: 'Assistant Accountants' },
          { id: 6144, description: 'Audit - External' },
          { id: 6145, description: 'Audit - Internal' },
          { id: 6146, description: 'Bookkeeping & Small Practice Accounting' },
          { id: 6147, description: 'Business Services & Corporate Advisory' },
          { id: 6148, description: 'Company Secretaries' },
          { id: 6149, description: 'Compliance & Risk' },
          { id: 6150, description: 'Cost Accounting' },
          { id: 6151, description: 'Financial Accounting & Reporting' },
          { id: 6152, description: 'Financial Managers & Controllers' },
          { id: 6153, description: 'Forensic Accounting & Investigation' },
          { id: 6154, description: 'Insolvency & Corporate Recovery' },
          { id: 6155, description: 'Inventory & Fixed Assets' },
          { id: 6156, description: 'Management' },
          { id: 6157, description: 'Management Accounting & Budgeting' },
          { id: 1468, description: 'Payroll' },
          { id: 6158, description: 'Strategy & Planning' },
          { id: 6159, description: 'Systems Accounting & IT Audit' },
          { id: 6160, description: 'Taxation' },
          { id: 6161, description: 'Treasury' },
          { id: 6162, description: 'Other' },
        ],
      },
      {
        id: 6251,
        description: 'Administration & Office Support',
        subClassifications: [
          { id: 6252, description: 'Administrative Assistants' },
          { id: 6253, description: 'Client & Sales Administration' },
          { id: 6254, description: 'Contracts Administration' },
          { id: 6255, description: 'Data Entry & Word Processing' },
          { id: 6256, description: 'Office Management' },
          { id: 6257, description: 'PA, EA & Secretarial' },
          { id: 6258, description: 'Receptionists' },
          { id: 6259, description: 'Records Management & Document Control' },
          { id: 6260, description: 'Other' },
        ],
      },
      {
        id: 6304,
        description: 'Advertising, Arts & Media',
        subClassifications: [
          { id: 6305, description: 'Agency Account Management' },
          { id: 6306, description: 'Art Direction' },
          { id: 6307, description: 'Editing & Publishing' },
          { id: 6308, description: 'Event Management' },
          { id: 6309, description: 'Journalism & Writing' },
          { id: 6310, description: 'Management' },
          { id: 6311, description: 'Media Strategy, Planning & Buying' },
          { id: 6312, description: 'Performing Arts' },
          { id: 6313, description: 'Photography' },
          { id: 6314, description: 'Programming & Production' },
          { id: 6315, description: 'Promotions' },
          { id: 6316, description: 'Other' },
        ],
      },
      {
        id: 1203,
        description: 'Banking & Financial Services',
        subClassifications: [
          { id: 6174, description: 'Account & Relationship Management' },
          { id: 6175, description: 'Analysis & Reporting' },
          { id: 6177, description: 'Banking - Business' },
          { id: 6178, description: 'Banking - Corporate & Institutional' },
          { id: 6176, description: 'Banking - Retail/Branch' },
          { id: 1352, description: 'Client Services' },
          { id: 6179, description: 'Compliance & Risk' },
          { id: 6180, description: 'Corporate Finance & Investment Banking' },
          { id: 6181, description: 'Credit' },
          { id: 1392, description: 'Financial Planning' },
          { id: 1404, description: 'Funds Management' },
          { id: 6182, description: 'Management' },
          { id: 6183, description: 'Mortgages' },
          { id: 6184, description: 'Settlements' },
          { id: 6185, description: 'Stockbroking & Trading' },
          { id: 6186, description: 'Treasury' },
          { id: 6187, description: 'Other' },
        ],
      },
      {
        id: 1204,
        description: 'Call Centre & Customer Service',
        subClassifications: [
          { id: 6084, description: 'Collections' },
          { id: 6085, description: 'Customer Service - Call Centre' },
          { id: 6086, description: 'Customer Service - Customer Facing' },
          { id: 6087, description: 'Management & Support' },
          { id: 6088, description: 'Sales - Inbound' },
          { id: 6089, description: 'Sales - Outbound' },
          { id: 6090, description: 'Supervisors/Team Leaders' },
          { id: 6091, description: 'Other' },
        ],
      },
      {
        id: 7019,
        description: 'CEO & General Management',
        subClassifications: [
          { id: 7020, description: 'Board Appointments' },
          { id: 7021, description: 'CEO' },
          { id: 7022, description: 'COO & MD' },
          { id: 7023, description: 'General/Business Unit Manager' },
          { id: 7024, description: 'Other' },
        ],
      },
      {
        id: 6163,
        description: 'Community Services & Development',
        subClassifications: [
          { id: 6164, description: 'Aged & Disability Support' },
          { id: 6165, description: 'Child Welfare, Youth & Family Services' },
          { id: 6166, description: 'Community Development' },
          { id: 6167, description: 'Employment Services' },
          { id: 6168, description: 'Fundraising' },
          { id: 6169, description: 'Housing & Homelessness Services' },
          { id: 6170, description: 'Indigenous & Multicultural Services' },
          { id: 6171, description: 'Management' },
          { id: 6172, description: 'Volunteer Coordination & Support' },
          { id: 6173, description: 'Other' },
        ],
      },
      {
        id: 1206,
        description: 'Construction',
        subClassifications: [
          { id: 6113, description: 'Contracts Management' },
          { id: 1387, description: 'Estimating' },
          { id: 6114, description: 'Foreperson/Supervisors' },
          { id: 6115, description: 'Health, Safety & Environment' },
          { id: 6116, description: 'Management' },
          { id: 6117, description: 'Planning & Scheduling' },
          { id: 6118, description: 'Plant & Machinery Operators' },
          { id: 6119, description: 'Project Management' },
          { id: 6120, description: 'Quality Assurance & Control' },
          { id: 6121, description: 'Surveying' },
          { id: 6122, description: 'Other' },
        ],
      },
      {
        id: 6076,
        description: 'Consulting & Strategy',
        subClassifications: [
          { id: 6077, description: 'Analysts' },
          { id: 6078, description: 'Corporate Development' },
          { id: 6079, description: 'Environment & Sustainability Consulting' },
          { id: 6080, description: 'Management & Change Consulting' },
          { id: 6081, description: 'Policy' },
          { id: 6082, description: 'Strategy & Planning' },
          { id: 6083, description: 'Other' },
        ],
      },
      {
        id: 6263,
        description: 'Design & Architecture',
        subClassifications: [
          { id: 6265, description: 'Architectural Drafting' },
          { id: 6264, description: 'Architecture' },
          { id: 6267, description: 'Fashion & Textile Design' },
          { id: 6268, description: 'Graphic Design' },
          { id: 6266, description: 'Illustration & Animation' },
          { id: 6269, description: 'Industrial Design' },
          { id: 6270, description: 'Interior Design' },
          { id: 6271, description: 'Landscape Architecture' },
          { id: 6272, description: 'Urban Design & Planning' },
          { id: 6273, description: 'Web & Interaction Design' },
          { id: 6274, description: 'Other' },
        ],
      },
      {
        id: 6123,
        description: 'Education & Training',
        subClassifications: [
          { id: 6124, description: 'Childcare & Outside School Hours Care' },
          {
            id: 6125,
            description: 'Library Services & Information Management',
          },
          { id: 6126, description: 'Management - Schools' },
          { id: 6127, description: 'Management - Universities' },
          { id: 6128, description: 'Management - Vocational' },
          { id: 6129, description: 'Research & Fellowships' },
          { id: 6130, description: 'Student Services' },
          { id: 6132, description: 'Teaching - Early Childhood' },
          { id: 6133, description: 'Teaching - Primary' },
          { id: 6134, description: 'Teaching - Secondary' },
          { id: 6135, description: 'Teaching - Tertiary' },
          { id: 6136, description: 'Teaching - Vocational' },
          { id: 6131, description: 'Teaching Aides & Special Needs' },
          { id: 6137, description: 'Tutoring' },
          { id: 6138, description: 'Workplace Training & Assessment' },
          { id: 6139, description: 'Other' },
        ],
      },
      {
        id: 1209,
        description: 'Engineering',
        subClassifications: [
          { id: 6022, description: 'Aerospace Engineering' },
          { id: 6023, description: 'Automotive Engineering' },
          { id: 6024, description: 'Building Services Engineering' },
          { id: 6026, description: 'Chemical Engineering' },
          { id: 6027, description: 'Civil/Structural Engineering' },
          { id: 6028, description: 'Electrical/Electronic Engineering' },
          { id: 6025, description: 'Engineering Drafting' },
          { id: 6029, description: 'Environmental Engineering' },
          { id: 6030, description: 'Field Engineering' },
          { id: 6031, description: 'Industrial Engineering' },
          { id: 6032, description: 'Maintenance' },
          { id: 6033, description: 'Management' },
          { id: 6034, description: 'Materials Handling Engineering' },
          { id: 6035, description: 'Mechanical Engineering' },
          { id: 6036, description: 'Process Engineering' },
          { id: 6038, description: 'Project Engineering' },
          { id: 6037, description: 'Project Management' },
          { id: 6039, description: 'Supervisors' },
          { id: 6040, description: 'Systems Engineering' },
          { id: 6041, description: 'Water & Waste Engineering' },
          { id: 6042, description: 'Other' },
        ],
      },
      {
        id: 6205,
        description: 'Farming, Animals & Conservation',
        subClassifications: [
          { id: 6206, description: 'Agronomy & Farm Services' },
          { id: 6207, description: 'Conservation, Parks & Wildlife' },
          { id: 6208, description: 'Farm Labour' },
          { id: 6209, description: 'Farm Management' },
          { id: 6210, description: 'Fishing & Aquaculture' },
          { id: 6211, description: 'Horticulture' },
          { id: 6212, description: 'Veterinary Services & Animal Welfare' },
          { id: 6213, description: 'Winery & Viticulture' },
          { id: 6214, description: 'Other' },
        ],
      },
      {
        id: 1210,
        description: 'Government & Defence',
        subClassifications: [
          { id: 1314, description: 'Air Force' },
          { id: 1322, description: 'Army' },
          { id: 1378, description: 'Emergency Services' },
          { id: 1409, description: 'Government - Federal' },
          { id: 1410, description: 'Government - Local' },
          { id: 1411, description: 'Government - State' },
          { id: 1450, description: 'Navy' },
          { id: 6359, description: 'Police & Corrections' },
          { id: 6360, description: 'Policy, Planning & Regulation' },
          { id: 6361, description: 'Other' },
        ],
      },
      {
        id: 1211,
        description: 'Healthcare & Medical',
        subClassifications: [
          { id: 6329, description: 'Ambulance/Paramedics' },
          { id: 6330, description: 'Chiropractic & Osteopathic' },
          { id: 1353, description: 'Clinical/Medical Research' },
          { id: 1372, description: 'Dental' },
          { id: 6331, description: 'Dieticians' },
          { id: 1386, description: 'Environmental Services' },
          { id: 6332, description: 'General Practitioners' },
          { id: 6333, description: 'Management' },
          { id: 6334, description: 'Medical Administration' },
          { id: 6335, description: 'Medical Imaging' },
          { id: 6336, description: 'Medical Specialists' },
          { id: 6337, description: 'Natural Therapies & Alternative Medicine' },
          { id: 6338, description: 'Nursing - A&E, Critical Care & ICU' },
          { id: 6339, description: 'Nursing - Aged Care' },
          {
            id: 6340,
            description: 'Nursing - Community, Maternal & Child Health',
          },
          { id: 6341, description: 'Nursing - Educators & Facilitators' },
          { id: 6342, description: 'Nursing - General Medical & Surgical' },
          { id: 6343, description: 'Nursing - High Acuity' },
          { id: 6344, description: 'Nursing - Management' },
          {
            id: 6345,
            description: 'Nursing - Midwifery, Neo-Natal, SCN & NICU',
          },
          { id: 6346, description: 'Nursing - Paediatric & PICU' },
          {
            id: 6347,
            description: 'Nursing - Psych, Forensic & Correctional Health',
          },
          { id: 6348, description: 'Nursing - Theatre & Recovery' },
          { id: 6349, description: 'Optical' },
          { id: 1467, description: 'Pathology' },
          { id: 6350, description: 'Pharmaceuticals & Medical Devices' },
          { id: 1470, description: 'Pharmacy' },
          { id: 6352, description: 'Physiotherapy, OT & Rehabilitation' },
          { id: 6351, description: 'Psychology, Counselling & Social Work' },
          { id: 6370, description: 'Residents & Registrars' },
          { id: 6353, description: 'Sales' },
          { id: 6354, description: 'Speech Therapy' },
          { id: 6355, description: 'Other' },
        ],
      },
      {
        id: 1212,
        description: 'Hospitality & Tourism',
        subClassifications: [
          { id: 1315, description: 'Airlines' },
          { id: 1332, description: 'Bar & Beverage Staff' },
          { id: 6052, description: 'Chefs/Cooks' },
          { id: 6053, description: 'Front Office & Guest Services' },
          { id: 1405, description: 'Gaming' },
          { id: 1415, description: 'Housekeeping' },
          { id: 6054, description: 'Kitchen & Sandwich Hands' },
          { id: 6055, description: 'Management' },
          { id: 6056, description: 'Reservations' },
          { id: 1537, description: 'Tour Guides' },
          { id: 1542, description: 'Travel Agents/Consultants' },
          { id: 1549, description: 'Waiting Staff' },
          { id: 6057, description: 'Other' },
        ],
      },
      {
        id: 6317,
        description: 'Human Resources & Recruitment',
        subClassifications: [
          { id: 6318, description: 'Consulting & Generalist HR' },
          { id: 6319, description: 'Industrial & Employee Relations' },
          { id: 6320, description: 'Management - Agency' },
          { id: 6321, description: 'Management - Internal' },
          { id: 6322, description: 'Occupational Health & Safety' },
          { id: 6323, description: 'Organisational Development' },
          { id: 6324, description: 'Recruitment - Agency' },
          { id: 6325, description: 'Recruitment - Internal' },
          { id: 6326, description: 'Remuneration & Benefits' },
          { id: 6327, description: 'Training & Development' },
          { id: 6328, description: 'Other' },
        ],
      },
      {
        id: 6281,
        description: 'Information & Communication Technology',
        subClassifications: [
          { id: 6282, description: 'Architects' },
          { id: 6283, description: 'Business/Systems Analysts' },
          { id: 6284, description: 'Computer Operators' },
          { id: 6285, description: 'Consultants' },
          { id: 6286, description: 'Database Development & Administration' },
          { id: 6287, description: 'Developers/Programmers' },
          { id: 6288, description: 'Engineering - Hardware' },
          { id: 6289, description: 'Engineering - Network' },
          { id: 6290, description: 'Engineering - Software' },
          { id: 6291, description: 'Help Desk & IT Support' },
          { id: 6292, description: 'Management' },
          { id: 6293, description: 'Networks & Systems Administration' },
          { id: 6294, description: 'Product Management & Development' },
          { id: 6295, description: 'Programme & Project Management' },
          { id: 6296, description: 'Sales - Pre & Post' },
          { id: 6297, description: 'Security' },
          { id: 6298, description: 'Team Leaders' },
          { id: 6299, description: 'Technical Writing' },
          { id: 6300, description: 'Telecommunications' },
          { id: 6301, description: 'Testing & Quality Assurance' },
          { id: 6302, description: 'Web Development & Production' },
          { id: 6303, description: 'Other' },
        ],
      },
      {
        id: 1214,
        description: 'Insurance & Superannuation',
        subClassifications: [
          { id: 6275, description: 'Actuarial' },
          { id: 6276, description: 'Assessment' },
          { id: 6277, description: 'Brokerage' },
          { id: 1350, description: 'Claims' },
          { id: 6278, description: 'Fund Administration' },
          { id: 6279, description: 'Management' },
          { id: 1499, description: 'Risk Consulting' },
          { id: 1524, description: 'Superannuation' },
          { id: 1544, description: 'Underwriting' },
          { id: 1553, description: "Workers' Compensation" },
          { id: 6280, description: 'Other' },
        ],
      },
      {
        id: 1216,
        description: 'Legal',
        subClassifications: [
          { id: 6188, description: 'Banking & Finance Law' },
          { id: 6190, description: 'Construction Law' },
          { id: 6191, description: 'Corporate & Commercial Law' },
          { id: 6189, description: 'Criminal & Civil Law' },
          { id: 6192, description: 'Environment & Planning Law' },
          { id: 6193, description: 'Family Law' },
          { id: 6194, description: 'Generalists - In-house' },
          { id: 6195, description: 'Generalists - Law Firm' },
          { id: 6196, description: 'Industrial Relations & Employment Law' },
          { id: 6197, description: 'Insurance & Superannuation Law' },
          { id: 6198, description: 'Intellectual Property Law' },
          { id: 1429, description: 'Law Clerks & Paralegals' },
          { id: 6199, description: 'Legal Practice Management' },
          { id: 1431, description: 'Legal Secretaries' },
          { id: 6200, description: 'Litigation & Dispute Resolution' },
          { id: 6201, description: 'Personal Injury Law' },
          { id: 6202, description: 'Property Law' },
          { id: 6203, description: 'Tax Law' },
          { id: 6204, description: 'Other' },
        ],
      },
      {
        id: 6092,
        description: 'Manufacturing, Transport & Logistics',
        subClassifications: [
          { id: 6095, description: 'Analysis & Reporting' },
          { id: 6093, description: 'Assembly & Process Work' },
          { id: 6094, description: 'Aviation Services' },
          { id: 6096, description: 'Couriers, Drivers & Postal Services' },
          { id: 6097, description: 'Fleet Management' },
          { id: 6098, description: 'Freight/Cargo Forwarding' },
          { id: 6099, description: 'Import/Export & Customs' },
          { id: 6101, description: 'Machine Operators' },
          { id: 6102, description: 'Management' },
          { id: 6104, description: 'Pattern Makers & Garment Technicians' },
          { id: 6105, description: 'Pickers & Packers' },
          { id: 6103, description: 'Production, Planning & Scheduling' },
          { id: 6100, description: 'Public Transport & Taxi Services' },
          { id: 6109, description: 'Purchasing, Procurement & Inventory' },
          { id: 6106, description: 'Quality Assurance & Control' },
          { id: 6110, description: 'Rail & Maritime Transport' },
          { id: 6111, description: 'Road Transport' },
          { id: 6107, description: 'Team Leaders/Supervisors' },
          { id: 6108, description: 'Warehousing, Storage & Distribution' },
          { id: 6112, description: 'Other' },
        ],
      },
      {
        id: 6008,
        description: 'Marketing & Communications',
        subClassifications: [
          { id: 6009, description: 'Brand Management' },
          { id: 6010, description: 'Digital & Search Marketing' },
          { id: 6011, description: 'Direct Marketing & CRM' },
          { id: 6012, description: 'Event Management' },
          { id: 6013, description: 'Internal Communications' },
          { id: 6017, description: 'Management' },
          { id: 6014, description: 'Market Research & Analysis' },
          { id: 6015, description: 'Marketing Assistants/Coordinators' },
          { id: 6016, description: 'Marketing Communications' },
          { id: 6018, description: 'Product Management & Development' },
          { id: 6019, description: 'Public Relations & Corporate Affairs' },
          { id: 6020, description: 'Trade Marketing' },
          { id: 6021, description: 'Other' },
        ],
      },
      {
        id: 6058,
        description: 'Mining, Resources & Energy',
        subClassifications: [
          { id: 6059, description: 'Analysis & Reporting' },
          { id: 6060, description: 'Health, Safety & Environment' },
          { id: 6061, description: 'Management' },
          { id: 6062, description: 'Mining - Drill & Blast' },
          { id: 6063, description: 'Mining - Engineering & Maintenance' },
          { id: 6064, description: 'Mining - Exploration & Geoscience' },
          { id: 6066, description: 'Mining - Operations' },
          { id: 6065, description: 'Mining - Processing' },
          { id: 6073, description: 'Natural Resources & Water' },
          { id: 6067, description: 'Oil & Gas - Drilling' },
          { id: 6068, description: 'Oil & Gas - Engineering & Maintenance' },
          { id: 6069, description: 'Oil & Gas - Exploration & Geoscience' },
          { id: 6071, description: 'Oil & Gas - Operations' },
          { id: 6070, description: 'Oil & Gas - Production & Refinement' },
          { id: 6072, description: 'Power Generation & Distribution' },
          { id: 6074, description: 'Surveying' },
          { id: 6075, description: 'Other' },
        ],
      },
      {
        id: 1220,
        description: 'Real Estate & Property',
        subClassifications: [
          { id: 6371, description: 'Administration' },
          { id: 6001, description: 'Analysts' },
          { id: 6002, description: 'Body Corporate & Facilities Management' },
          {
            id: 6003,
            description: 'Commercial Sales, Leasing & Property Mgmt',
          },
          {
            id: 6004,
            description: 'Residential Leasing & Property Management',
          },
          { id: 6005, description: 'Residential Sales' },
          { id: 6006, description: 'Retail & Property Development' },
          { id: 1546, description: 'Valuation' },
          { id: 6007, description: 'Other' },
        ],
      },
      {
        id: 6043,
        description: 'Retail & Consumer Products',
        subClassifications: [
          { id: 6044, description: 'Buying' },
          { id: 6045, description: 'Management - Area/Multi-site' },
          { id: 6046, description: 'Management - Department/Assistant' },
          { id: 6047, description: 'Management - Store' },
          { id: 6048, description: 'Merchandisers' },
          { id: 6049, description: 'Planning' },
          { id: 6050, description: 'Retail Assistants' },
          { id: 6051, description: 'Other' },
        ],
      },
      {
        id: 6362,
        description: 'Sales',
        subClassifications: [
          { id: 6363, description: 'Account & Relationship Management' },
          { id: 6364, description: 'Analysis & Reporting' },
          { id: 6366, description: 'Management' },
          { id: 6365, description: 'New Business Development' },
          { id: 6367, description: 'Sales Coordinators' },
          { id: 6368, description: 'Sales Representatives/Consultants' },
          { id: 6369, description: 'Other' },
        ],
      },
      {
        id: 1223,
        description: 'Science & Technology',
        subClassifications: [
          { id: 6215, description: 'Biological & Biomedical Sciences' },
          { id: 6216, description: 'Biotechnology & Genetics' },
          { id: 6217, description: 'Chemistry & Physics' },
          { id: 6218, description: 'Environmental, Earth & Geosciences' },
          { id: 6219, description: 'Food Technology & Safety' },
          { id: 6220, description: 'Laboratory & Technical Services' },
          { id: 6221, description: 'Materials Sciences' },
          {
            id: 6222,
            description: 'Mathematics, Statistics & Information Sciences',
          },
          { id: 6223, description: 'Modelling & Simulation' },
          { id: 6224, description: 'Quality Assurance & Control' },
          { id: 6225, description: 'Other' },
        ],
      },
      {
        id: 6261,
        description: 'Self Employment',
        subClassifications: [{ id: 6262, description: 'Self Employment' }],
      },
      {
        id: 6246,
        description: 'Sport & Recreation',
        subClassifications: [
          { id: 6247, description: 'Coaching & Instruction' },
          { id: 6248, description: 'Fitness & Personal Training' },
          { id: 6249, description: 'Management' },
          { id: 6250, description: 'Other' },
        ],
      },
      {
        id: 1225,
        description: 'Trades & Services',
        subClassifications: [
          { id: 1313, description: 'Air Conditioning & Refrigeration' },
          { id: 1328, description: 'Automotive Trades' },
          { id: 6226, description: 'Bakers & Pastry Chefs' },
          { id: 6227, description: 'Building Trades' },
          { id: 6228, description: 'Butchers' },
          { id: 1345, description: 'Carpentry & Cabinet Making' },
          { id: 6229, description: 'Cleaning Services' },
          { id: 6230, description: 'Electricians' },
          { id: 6231, description: 'Fitters, Turners & Machinists' },
          { id: 6232, description: 'Floristry' },
          { id: 1406, description: 'Gardening & Landscaping' },
          { id: 6233, description: 'Hair & Beauty Services' },
          { id: 6234, description: 'Labourers' },
          { id: 6235, description: 'Locksmiths' },
          { id: 6236, description: 'Maintenance & Handyperson Services' },
          { id: 6237, description: 'Nannies & Babysitters' },
          { id: 6238, description: 'Painters & Sign Writers' },
          { id: 6240, description: 'Plumbers' },
          { id: 6239, description: 'Printing & Publishing Services' },
          { id: 6241, description: 'Security Services' },
          { id: 6242, description: 'Tailors & Dressmakers' },
          { id: 6243, description: 'Technicians' },
          { id: 6244, description: 'Welders & Boilermakers' },
          { id: 6245, description: 'Other' },
        ],
      },
    ],
    id: [
      {
        id: 1200,
        description: 'Akuntansi',
        subClassifications: [
          { id: 6140, description: 'Pegawai/Staf Akuntansi' },
          { id: 1303, description: 'Hutang Usaha' },
          { id: 6141, description: 'Piutang Usaha/Pengendalian Kredit' },
          { id: 6143, description: 'Analisis & Pelaporan' },
          { id: 6142, description: 'Asisten Akuntan' },
          { id: 6144, description: 'Audit – Eksternal' },
          { id: 6145, description: 'Audit – Internal' },
          { id: 6146, description: 'Pembukuan & Akuntansi Praktik Kecil' },
          { id: 6147, description: 'Layanan Bisnis & Penasihat Perusahaan' },
          { id: 6148, description: 'Sekretaris Perusahaan' },
          { id: 6149, description: 'Kepatuhan & Resiko' },
          { id: 6150, description: 'Akuntansi Biaya' },
          { id: 6151, description: 'Akuntansi & Pelaporan Finansial' },
          { id: 6152, description: 'Manajer & Kontroler Finansial' },
          { id: 6153, description: 'Akuntansi & Investigasi Forensik' },
          { id: 6154, description: 'Insolvensi & Pemulihan Perusahaan' },
          { id: 6155, description: 'Inventaris & Aset Tidak Bergerak' },
          { id: 6156, description: 'Manajemen' },
          { id: 6157, description: 'Akuntansi Manajemen & Anggaran' },
          { id: 1468, description: 'Penggajian' },
          { id: 6158, description: 'Strategi & Perencanaan' },
          { id: 6159, description: 'Audit Sistem Akuntansi & IT' },
          { id: 6160, description: 'Perpajakan' },
          { id: 6161, description: 'Bendahara' },
          { id: 6162, description: 'Lainnya' },
        ],
      },
      {
        id: 6251,
        description: 'Administrasi & Dukungan Perkantoran',
        subClassifications: [
          { id: 6252, description: 'Asisten Administratif' },
          { id: 6253, description: 'Administrasi Klien & Penjualan' },
          { id: 6254, description: 'Administrasi Kontrak' },
          { id: 6255, description: 'Entri Data & Pengolahan Kata' },
          { id: 6256, description: 'Manajemen Perkantoran' },
          {
            id: 6257,
            description: 'Asisten Pribadi, Asisten Eksekutif & Sekretarial',
          },
          { id: 6258, description: 'Resepsionis' },
          { id: 6259, description: 'Manajemen Catatan & Kontrol Dokumen' },
          { id: 6260, description: 'Lainnya' },
        ],
      },
      {
        id: 6304,
        description: 'Periklanan, Seni & Media',
        subClassifications: [
          { id: 6305, description: 'Manajemen Akun Agensi' },
          { id: 6306, description: 'Pengarahan Seni' },
          { id: 6307, description: 'Penyuntingan & Penerbitan' },
          { id: 6308, description: 'Manajemen Acara' },
          { id: 6309, description: 'Jurnalisme & Penulisan' },
          { id: 6310, description: 'Manajemen' },
          { id: 6311, description: 'Strategi Media, Perencanaan & Pembelian' },
          { id: 6312, description: 'Seni Pertunjukan' },
          { id: 6313, description: 'Fotografi' },
          { id: 6314, description: 'Program & Produksi' },
          { id: 6315, description: 'Pemasaran' },
          { id: 6316, description: 'Lainnya' },
        ],
      },
      {
        id: 1203,
        description: 'Perbankan & Layanan Finansial',
        subClassifications: [
          { id: 6174, description: 'Manajemen Akun & Hubungan' },
          { id: 6175, description: 'Analisis & Pelaporan' },
          { id: 6177, description: 'Perbankan – Bisnis' },
          { id: 6178, description: 'Perbankan – Korporasi & Institusi' },
          { id: 6176, description: 'Perbankan – Ritel/Cabang' },
          { id: 1352, description: 'Layanan Klien' },
          { id: 6179, description: 'Kepatuhan & Resiko' },
          { id: 6180, description: 'Perbankan Finansial & Investasi Korporat' },
          { id: 6181, description: 'Kredit' },
          { id: 1392, description: 'Perencanaan Keuangan' },
          { id: 1404, description: 'Pengelolaan Dana' },
          { id: 6182, description: 'Manajemen' },
          { id: 6183, description: 'Cicilan' },
          { id: 6184, description: 'Penyelesaian Transaksi' },
          { id: 6185, description: 'Pialang & Jual Beli Saham' },
          { id: 6186, description: 'Perbendaharaan' },
          { id: 6187, description: 'Lainnya' },
        ],
      },
      {
        id: 1204,
        description: 'Call Center & Layanan Konsumen',
        subClassifications: [
          { id: 6084, description: 'Pengumpulan' },
          { id: 6085, description: 'Layanan Konsumen – Call Center' },
          {
            id: 6086,
            description: 'Layanan Konsumen – Berhadapan dengan Konsumen',
          },
          { id: 6087, description: 'Manajemen & Dukungan' },
          { id: 6088, description: 'Penjualan – Inbound' },
          { id: 6089, description: 'Penjualan – Outbound' },
          { id: 6090, description: 'Pemimpin Tim/Supervisor' },
          { id: 6091, description: 'Lainnya' },
        ],
      },
      {
        id: 7019,
        description: 'CEO & Manajemen Umum',
        subClassifications: [
          { id: 7020, description: 'Penunjukan Dewan' },
          { id: 7021, description: 'CEO' },
          { id: 7022, description: 'COO & Direktur Utama' },
          { id: 7023, description: 'Manajer Umum/Unit Bisnis' },
          { id: 7024, description: 'Lainnya' },
        ],
      },
      {
        id: 6163,
        description: 'Layanan & Pengembangan Masyarakat',
        subClassifications: [
          { id: 6164, description: 'Dukungan Lansia & Disabilitas' },
          {
            id: 6165,
            description: 'Layanan Kesejahteraan Anak, Pemuda & Keluarga',
          },
          { id: 6166, description: 'Pengembangan Masyarakat' },
          { id: 6167, description: 'Layanan Ketenagakerjaan' },
          { id: 6168, description: 'Penggalangan Dana' },
          { id: 6169, description: 'Layanan Perumahan & Tunawisma' },
          { id: 6170, description: 'Layanan Masyarakat Asli & Multibudaya' },
          { id: 6171, description: 'Manajemen' },
          { id: 6172, description: 'Koordinasi & Dukungan Sukarelawan' },
          { id: 6173, description: 'Lainnya' },
        ],
      },
      {
        id: 1206,
        description: 'Konstruksi',
        subClassifications: [
          { id: 6113, description: 'Manajemen Kontrak' },
          { id: 1387, description: 'Perkiraan' },
          { id: 6114, description: 'Mandor/Supervisor' },
          { id: 6115, description: 'Kesehatan, Keamanan & Lingkungan' },
          { id: 6116, description: 'Manajemen' },
          { id: 6117, description: 'Perencanaan & Penjadwalan' },
          { id: 6118, description: 'Operator Pabrik & Mesin' },
          { id: 6119, description: 'Manajemen Proyek' },
          { id: 6120, description: 'Penjaminan & Kontrol Mutu' },
          { id: 6121, description: 'Survey' },
          { id: 6122, description: 'Lainnya' },
        ],
      },
      {
        id: 6076,
        description: 'Konsultasi & Strategi',
        subClassifications: [
          { id: 6077, description: 'Analis' },
          { id: 6078, description: 'Pengembangan Korporat' },
          { id: 6079, description: 'Konsultasi Lingkungan & Keberlanjutan' },
          { id: 6080, description: 'Konsultasi Manajemen & Perubahan' },
          { id: 6081, description: 'Kebijakan' },
          { id: 6082, description: 'Strategi & Perencanaan' },
          { id: 6083, description: 'Lainnya' },
        ],
      },
      {
        id: 6263,
        description: 'Desain & Arsitektur',
        subClassifications: [
          { id: 6265, description: 'Drafting Arsitektur' },
          { id: 6264, description: 'Arsitektur' },
          { id: 6267, description: 'Desain Busana & Tekstil' },
          { id: 6268, description: 'Desain Graifs' },
          { id: 6266, description: 'Ilustrasi & Animasi' },
          { id: 6269, description: 'Desain Industri' },
          { id: 6270, description: 'Desain Interior' },
          { id: 6271, description: 'Arsitektur Lanskap' },
          { id: 6272, description: 'Desain & Perencanaan Kota' },
          { id: 6273, description: 'Desain Web & Interaksi' },
          { id: 6274, description: 'Lainnya' },
        ],
      },
      {
        id: 6123,
        description: 'Pendidikan & Pelatihan',
        subClassifications: [
          {
            id: 6124,
            description: 'Pengasuhan Anak & Perawatan di Luar Jam Sekolah',
          },
          { id: 6125, description: 'Jasa Perpustakaan & Manajemen Informasi' },
          { id: 6126, description: 'Manajemen – Sekolah' },
          { id: 6127, description: 'Manajemen – Universitas' },
          { id: 6128, description: 'Manajemen – Kejuruan' },
          { id: 6129, description: 'Penelitian & Beasiswa' },
          { id: 6130, description: 'Bimbingan Siswa' },
          { id: 6132, description: 'Pengajaran – Pendidikan Usia Dini' },
          { id: 6133, description: 'Pengajaran – Pendidikan Dasar' },
          { id: 6134, description: 'Pengajaran – Pendidikan Menengah' },
          { id: 6135, description: 'Pengajaran – Pendidikan Tinggi' },
          { id: 6136, description: 'Pengajaran -Kejuruan' },
          { id: 6131, description: 'Asisten Guru & Berkebutuhan Khusus' },
          { id: 6137, description: 'Tutor' },
          { id: 6138, description: 'Pelatihan & Penilaian Tempat Kerja' },
          { id: 6139, description: 'Lainnya' },
        ],
      },
      {
        id: 1209,
        description: 'Teknik',
        subClassifications: [
          { id: 6022, description: 'Teknik Antariksa' },
          { id: 6023, description: 'Teknik Otomotif' },
          { id: 6024, description: 'Teknik Fasilitas Bangunan' },
          { id: 6026, description: 'Teknik Kimia' },
          { id: 6027, description: 'Teknik Sipil/Struktural' },
          { id: 6028, description: 'Teknik Elektro/Elektronik' },
          { id: 6025, description: 'Drafting Teknik' },
          { id: 6029, description: 'Teknik Lingkungan' },
          { id: 6030, description: 'Teknik Lapangan' },
          { id: 6031, description: 'Teknik Industri' },
          { id: 6032, description: 'Pemeliharaan' },
          { id: 6033, description: 'Manajemen' },
          { id: 6034, description: 'Teknik Penanganan Material' },
          { id: 6035, description: 'Teknik Mesin' },
          { id: 6036, description: 'Rekayasa Proses' },
          { id: 6038, description: 'Teknik Manajemen Proyek' },
          { id: 6037, description: 'Manajemen Proyek' },
          { id: 6039, description: 'Supervisor' },
          { id: 6040, description: 'Teknik Sistem' },
          { id: 6041, description: 'Teknik Pengairan & Limbah' },
          { id: 6042, description: 'Lainnya' },
        ],
      },
      {
        id: 6205,
        description: 'Pertanian, Hewan & Konservasi',
        subClassifications: [
          { id: 6206, description: 'Agronomi & Jasa Pertanian' },
          { id: 6207, description: 'Konservasi, Pertamanan & Alam' },
          { id: 6208, description: 'Buruh Pertanian' },
          { id: 6209, description: 'Manajemen Pertanian' },
          { id: 6210, description: 'Perikanan & Akuakultur' },
          { id: 6211, description: 'Hortikultura' },
          {
            id: 6212,
            description: 'Jasa Kedokteran Hewan & Kesejahteraan Hewan',
          },
          { id: 6213, description: 'Pengilangan & Budidaya Anggur' },
          { id: 6214, description: 'Lainnya' },
        ],
      },
      {
        id: 1210,
        description: 'Pemerintahan & Pertahanan',
        subClassifications: [
          { id: 1314, description: 'Angkatan Udara' },
          { id: 1322, description: 'Angkatan Darat' },
          { id: 1378, description: 'Layanan Darurat' },
          { id: 1409, description: 'Pemerintahan – Negara' },
          { id: 1410, description: 'Pemerintahan – Daerah' },
          { id: 1411, description: 'Pemerintahan – Provinsi' },
          { id: 1450, description: 'Angkatan Laut' },
          { id: 6359, description: 'Polisi & Lembaga Kemasyarakatan' },
          { id: 6360, description: 'Kebijakan, Perencanaan & Regulasi' },
          { id: 6361, description: 'Lainnya' },
        ],
      },
      {
        id: 1211,
        description: 'Kesehatan & Medis',
        subClassifications: [
          { id: 6329, description: 'Ambulans/Paramedis' },
          { id: 6330, description: 'Kiropraktik & Osteopati' },
          { id: 1353, description: 'Penelitian Klinis/Medis' },
          { id: 1372, description: 'Dental' },
          { id: 6331, description: 'Ahli Gizi' },
          { id: 1386, description: 'Jasa Lingkungan' },
          { id: 6332, description: 'Dokter Umum' },
          { id: 6333, description: 'Manajemen' },
          { id: 6334, description: 'Administrasi Medis' },
          { id: 6335, description: 'Rekam Medis' },
          { id: 6336, description: 'Spesialis Medis' },
          { id: 6337, description: 'Terapi Alami & Pengobatan Tradisional' },
          {
            id: 6338,
            description: 'Keperawatan – Gawat Darurat, Perawatan Kritis & ICU',
          },
          { id: 6339, description: 'Keperawatan – Perawatan Lansia' },
          {
            id: 6340,
            description: 'Keperawatan – Kesehatan Masyarakat, Ibu & Anak',
          },
          { id: 6341, description: 'Keperawatan – Pendidik & Fasilitator' },
          { id: 6342, description: 'Keperawatan – Medis & Pembedahan Umum' },
          { id: 6343, description: 'Keperawatan – Akuitas Tinggi' },
          { id: 6344, description: 'Keperawatan – Manajemen' },
          {
            id: 6345,
            description: 'Keperawatan – Kebidanan, Neo-Natal, SCN & NICU',
          },
          { id: 6346, description: 'Keperawatan – Anak & PICU' },
          {
            id: 6347,
            description: 'Keperawatan – Psikologi, Forensik & Kesehatan Lapas',
          },
          { id: 6348, description: 'Keperawatan - Operasi & Pemulihan' },
          { id: 6349, description: 'Optikal' },
          { id: 1467, description: 'Patologi' },
          { id: 6350, description: 'Perangkat Farmasi & Medis' },
          { id: 1470, description: 'Farmasi' },
          {
            id: 6352,
            description: 'Fisioterapi, Terapi Okupasi & Rehabilitasi',
          },
          { id: 6351, description: 'Psikologi, Konseling & Kerja Sosial' },
          { id: 6370, description: 'Residen & Registrar' },
          { id: 6353, description: 'Penjualan' },
          { id: 6354, description: 'Terapi Wicara' },
          { id: 6355, description: 'Lainnya' },
        ],
      },
      {
        id: 1212,
        description: 'Hospitaliti & Pariwisata',
        subClassifications: [
          { id: 1315, description: 'Maskapai' },
          { id: 1332, description: 'Staf Bar & Minuman' },
          { id: 6052, description: 'Chef/Koki' },
          { id: 6053, description: 'Layanan Kantor Depan & Tamu' },
          { id: 1405, description: 'Perjudian' },
          { id: 1415, description: 'Tata Graha' },
          { id: 6054, description: 'Asisten & Staf Dapur' },
          { id: 6055, description: 'Manajemen' },
          { id: 6056, description: 'Reservasi' },
          { id: 1537, description: 'Pemandu Wisata' },
          { id: 1542, description: 'Agen/Konsultan Perjalanan' },
          { id: 1549, description: 'Pelayan' },
          { id: 6057, description: 'Lainnya' },
        ],
      },
      {
        id: 6317,
        description: 'Sumber Daya Manusia & Perekrutan',
        subClassifications: [
          { id: 6318, description: 'Konsultasi & HR Umum' },
          { id: 6319, description: 'Hubungan Industri & Pegawai' },
          { id: 6320, description: 'Manajemen – Agensi' },
          { id: 6321, description: 'Manajemen – Internal' },
          { id: 6322, description: 'Kesehatan & Keamanan Kerja' },
          { id: 6323, description: 'Pengembangan Organisasi' },
          { id: 6324, description: 'Perekrutan – Agen' },
          { id: 6325, description: 'Perekrutan – Internal' },
          { id: 6326, description: 'Remunerasi & Tunjangan' },
          { id: 6327, description: 'Pelatihan & Pengembangan' },
          { id: 6328, description: 'Lainnya' },
        ],
      },
      {
        id: 6281,
        description: 'Teknologi Informasi & Komunikasi',
        subClassifications: [
          { id: 6282, description: 'Arsitek' },
          { id: 6283, description: 'Analis Bisnis/Sistem' },
          { id: 6284, description: 'Operator Komputer' },
          { id: 6285, description: 'Konsultan' },
          { id: 6286, description: 'Pengembangan & Administrasi Database' },
          { id: 6287, description: 'Developer/Programmer' },
          { id: 6288, description: 'Teknik – Perangkat Keras' },
          { id: 6289, description: 'Teknik – Jaringan' },
          { id: 6290, description: 'Teknik – Perangkat Lunak' },
          { id: 6291, description: 'Meja Bantuan & Dukungan IT' },
          { id: 6292, description: 'Manajemen' },
          { id: 6293, description: 'Administrasi Jaringan & Sistem' },
          { id: 6294, description: 'Manajemen & Pengembangan Produk' },
          { id: 6295, description: 'Manajemen Program & Proyek' },
          { id: 6296, description: 'Penjualan – Pra & Pasca' },
          { id: 6297, description: 'Keamanan' },
          { id: 6298, description: 'Pemimpin Tim' },
          { id: 6299, description: 'Penulisan Teknis' },
          { id: 6300, description: 'Telekomunikasi' },
          { id: 6301, description: 'Uji Coba & Penjaminan Mutu' },
          { id: 6302, description: 'Pengembangan & Produksi Web' },
          { id: 6303, description: 'Lainnya' },
        ],
      },
      {
        id: 1214,
        description: 'Asuransi & Dana Pensiun',
        subClassifications: [
          { id: 6275, description: 'Aktuaria' },
          { id: 6276, description: 'Asesmen' },
          { id: 6277, description: 'Broker' },
          { id: 1350, description: 'Klaim' },
          { id: 6278, description: 'Administrasi Dana' },
          { id: 6279, description: 'Manajemen' },
          { id: 1499, description: 'Konsultasi Resiko' },
          { id: 1524, description: 'Dana Pensiun' },
          { id: 1544, description: 'Penjaminan Emisi' },
          { id: 1553, description: 'Kompensasi Pekerja' },
          { id: 6280, description: 'Lainnya' },
        ],
      },
      {
        id: 1216,
        description: 'Hukum',
        subClassifications: [
          { id: 6188, description: 'Hukum Perbankan & Finansial' },
          { id: 6190, description: 'Hukum Konstruksi' },
          { id: 6191, description: 'Hukum Korporasi & Komersil' },
          { id: 6189, description: 'Hukum Perdata & Pidana' },
          { id: 6192, description: 'Hukum Lingkungan & Perencanaan' },
          { id: 6193, description: 'Hukum Keluarga' },
          { id: 6194, description: 'Umum – In-house' },
          { id: 6195, description: 'Umum – Firma Hukum' },
          {
            id: 6196,
            description: 'Hukum Hubungan Industri & Ketenagakerjaan',
          },
          { id: 6197, description: 'Hukum Asuransi & Dana Pensiun' },
          { id: 6198, description: 'Hukum Kekayaan Intelektual' },
          { id: 1429, description: 'Staf Hukum & Paralegal' },
          { id: 6199, description: 'Manajemen Praktik Hukum' },
          { id: 1431, description: 'Sekretaris Hukum' },
          { id: 6200, description: 'Litigasi & Resolusi Konflik' },
          { id: 6201, description: 'Hukum Cedera Pribadi' },
          { id: 6202, description: 'Hukum Properti' },
          { id: 6203, description: 'Hukum Perpajakan' },
          { id: 6204, description: 'Lainnya' },
        ],
      },
      {
        id: 6092,
        description: 'Manufaktur, Transportasi & Logistik',
        subClassifications: [
          { id: 6095, description: 'Analisis & Pelaporan' },
          { id: 6093, description: 'Pekerjaan Perakitan & Pemrosesan' },
          { id: 6094, description: 'Jasa Penerbangan' },
          { id: 6096, description: 'Kurir, Sopir & Jasa Pos' },
          { id: 6097, description: 'Manajemen Armada' },
          { id: 6098, description: 'Pengapalan/Pengurusan Transportasi' },
          { id: 6099, description: 'Impor/Ekspor & Pabean' },
          { id: 6101, description: 'Operator Mesin' },
          { id: 6102, description: 'Manajemen' },
          { id: 6104, description: 'Pembuatan Pola & Teknisi Garmen' },
          { id: 6105, description: 'Pengambilan & Pengepakan' },
          { id: 6103, description: 'Produksi, Perencanaan & Penjadwalan' },
          { id: 6100, description: 'Transportasi Umum & Jasa Taksi' },
          { id: 6109, description: 'Pembelian, Pengadaan & Inventaris' },
          { id: 6106, description: 'Penjaminan & Kontrol Mutu' },
          { id: 6110, description: 'Transportasi Rel & Maritim' },
          { id: 6111, description: 'Transportasi Jalan' },
          { id: 6107, description: 'Pemimpin Tim/Supervisor' },
          { id: 6108, description: 'Pergudangan, Penyimpanan & Distribusi' },
          { id: 6112, description: 'Lainnya' },
        ],
      },
      {
        id: 6008,
        description: 'Pemasaran & Komunikasi',
        subClassifications: [
          { id: 6009, description: 'Manajemen Brand' },
          { id: 6010, description: 'Pemasaran Digital & Pencarian' },
          { id: 6011, description: 'Pemasaran Langsung & CRM' },
          { id: 6012, description: 'Manajemen Acara' },
          { id: 6013, description: 'Komunikasi Internal' },
          { id: 6017, description: 'Manajemen' },
          { id: 6014, description: 'Penelitian & Analisis Pasar' },
          { id: 6015, description: 'Asisten/Koordinator Pemasaran' },
          { id: 6016, description: 'Komunikasi Pemasaran' },
          { id: 6018, description: 'Manajemen & Pengembangan Produk' },
          { id: 6019, description: 'Relasi Publik & Urusan Perusahaan' },
          { id: 6020, description: 'Pemasaran Perdagangan' },
          { id: 6021, description: 'Lainnya' },
        ],
      },
      {
        id: 6058,
        description: 'Pertambangan, Sumber Daya Alam & Energi',
        subClassifications: [
          { id: 6059, description: 'Analisis & Pelaporan' },
          { id: 6060, description: 'Kesehatan, Keamanan & Lingkungan' },
          { id: 6061, description: 'Manajemen' },
          { id: 6062, description: 'Pertambangan - Pengeboran & Peledakan' },
          { id: 6063, description: 'Pertambangan – Teknik & Pemeliharaan' },
          { id: 6064, description: 'Pertambangan – Eksplorasi & Geosains' },
          { id: 6066, description: 'Pertambangan – Operasional' },
          { id: 6065, description: 'Pertambangan – Pemrosesan' },
          { id: 6073, description: 'Sumber Daya Alam & Air' },
          { id: 6067, description: 'Minyak & Gas – Pengeboran' },
          { id: 6068, description: 'Minyak & Gas – Teknik & Pemeliharaan' },
          { id: 6069, description: 'Minyak & Gas – Eksplorasi & Geosains' },
          { id: 6071, description: 'Minyak & Gas – Operasional' },
          { id: 6070, description: 'Minyak & Gas – Produksi & Pemurnian' },
          { id: 6072, description: 'Pembangkit & Distribusi Listrik' },
          { id: 6074, description: 'Survei' },
          { id: 6075, description: 'Lainnya' },
        ],
      },
      {
        id: 1220,
        description: 'Real Estat & Properti',
        subClassifications: [
          { id: 6371, description: 'Administrasi' },
          { id: 6001, description: 'Analis' },
          { id: 6002, description: 'Manajemen Badan Korporat & Fasilitas' },
          {
            id: 6003,
            description: 'Penjualan Komersil, Penyewaan & Manajemen Properti',
          },
          { id: 6004, description: 'Penyewaan Rumah & Manajemen Properti' },
          { id: 6005, description: 'Penjualan Rumah' },
          { id: 6006, description: 'Pengembangan Ritel & Properti' },
          { id: 1546, description: 'Valuasi' },
          { id: 6007, description: 'Lainnya' },
        ],
      },
      {
        id: 6043,
        description: 'Ritel & Produk Konsumen',
        subClassifications: [
          { id: 6044, description: 'Pembelian' },
          { id: 6045, description: 'Manajemen – Area/Multi-lokasi' },
          { id: 6046, description: 'Manajemen – Departemen/Asisten' },
          { id: 6047, description: 'Manajemen – Toko' },
          { id: 6048, description: 'Merchandiser' },
          { id: 6049, description: 'Perencanaan' },
          { id: 6050, description: 'Asisten Ritel' },
          { id: 6051, description: 'Lainnya' },
        ],
      },
      {
        id: 6362,
        description: 'Penjualan',
        subClassifications: [
          { id: 6363, description: 'Manajemen Akun & Relasi' },
          { id: 6364, description: 'Analisis & Pelaporan' },
          { id: 6366, description: 'Manajemen' },
          { id: 6365, description: 'Pengembangan Bisnis Baru' },
          { id: 6367, description: 'Koordinator Penjualan' },
          { id: 6368, description: 'Perwakilan/Konsultan Penjualan' },
          { id: 6369, description: 'Lainnya' },
        ],
      },
      {
        id: 1223,
        description: 'Sains & Teknologi',
        subClassifications: [
          { id: 6215, description: 'Ilmu Biologi & Biomedis' },
          { id: 6216, description: 'Bioteknologi & Genetika' },
          { id: 6217, description: 'Kimia & Fisika' },
          { id: 6218, description: 'Lingkungan, Bumi & Geosains' },
          { id: 6219, description: 'Teknologi & Keamanan Pangan' },
          { id: 6220, description: 'Jasa Laboratorium & Teknis' },
          { id: 6221, description: 'Ilmu Material' },
          { id: 6222, description: 'Matematika, Statistik & Teknik Informasi' },
          { id: 6223, description: 'Modeling & Simulasi' },
          { id: 6224, description: 'Penjaminan & Kontrol Mutu' },
          { id: 6225, description: 'Lainnya' },
        ],
      },
      {
        id: 6261,
        description: 'Pekerjaan Lepas',
        subClassifications: [{ id: 6262, description: 'Pekerjaan Lepas' }],
      },
      {
        id: 6246,
        description: 'Olahraga & Rekreasi',
        subClassifications: [
          { id: 6247, description: 'Pelatihan & Instruktur' },
          { id: 6248, description: 'Kebugaran & Pelatihan Pribadi' },
          { id: 6249, description: 'Manajemen' },
          { id: 6250, description: 'Lainnya' },
        ],
      },
      {
        id: 1225,
        description: 'Keterampilan & Jasa',
        subClassifications: [
          { id: 1313, description: 'Pendinginan Udara & Pembekuan' },
          { id: 1328, description: 'Jasa Otomotif' },
          { id: 6226, description: 'Baker & Chef Pastry' },
          { id: 6227, description: 'Jasa Bangunan' },
          { id: 6228, description: 'Tukang Potong Hewan' },
          { id: 1345, description: 'Pertukangan & Pembuatan Lemari' },
          { id: 6229, description: 'Jasa Kebersihan' },
          { id: 6230, description: 'Tukang Listrik' },
          { id: 6231, description: 'Fitter, Pembubutan & Ahli Mesin' },
          { id: 6232, description: 'Tukang Bunga' },
          { id: 1406, description: 'Perkebunan & Penataan Lanskap' },
          { id: 6233, description: 'Tata Rambut & Kecantikan' },
          { id: 6234, description: 'Buruh' },
          { id: 6235, description: 'Tukang Kunci' },
          { id: 6236, description: 'Jasa Perbaikan & Pertukangan' },
          { id: 6237, description: 'Pramusiwi & Pengasuh Bayi' },
          { id: 6238, description: 'Tukang Cat & Penulis Reklame' },
          { id: 6240, description: 'Tukang Ledeng' },
          { id: 6239, description: 'Jasa Percetakan & Penerbitan' },
          { id: 6241, description: 'Jasa Keamanan' },
          { id: 6242, description: 'Penjahit & Pembuatan Baju' },
          { id: 6243, description: 'Teknisi' },
          { id: 6244, description: 'Pengelas & Pembuat Ketel Uap' },
          { id: 6245, description: 'Lainnya' },
        ],
      },
    ],
  },
  'asia-5': {
    en: [
      {
        id: 1200,
        description: 'Accounting',
        subClassifications: [
          { id: 6140, description: 'Accounts Officers/Clerks' },
          { id: 1303, description: 'Accounts Payable' },
          { id: 6141, description: 'Accounts Receivable/Credit Control' },
          { id: 6143, description: 'Analysis & Reporting' },
          { id: 6142, description: 'Assistant Accountants' },
          { id: 6144, description: 'Audit - External' },
          { id: 6145, description: 'Audit - Internal' },
          { id: 6146, description: 'Bookkeeping & Small Practice Accounting' },
          { id: 6147, description: 'Business Services & Corporate Advisory' },
          { id: 6148, description: 'Company Secretaries' },
          { id: 6149, description: 'Compliance & Risk' },
          { id: 6150, description: 'Cost Accounting' },
          { id: 6151, description: 'Financial Accounting & Reporting' },
          { id: 6152, description: 'Financial Managers & Controllers' },
          { id: 6153, description: 'Forensic Accounting & Investigation' },
          { id: 6154, description: 'Insolvency & Corporate Recovery' },
          { id: 6155, description: 'Inventory & Fixed Assets' },
          { id: 6156, description: 'Management' },
          { id: 6157, description: 'Management Accounting & Budgeting' },
          { id: 1468, description: 'Payroll' },
          { id: 6158, description: 'Strategy & Planning' },
          { id: 6159, description: 'Systems Accounting & IT Audit' },
          { id: 6160, description: 'Taxation' },
          { id: 6161, description: 'Treasury' },
          { id: 6162, description: 'Other' },
        ],
      },
      {
        id: 6251,
        description: 'Administration & Office Support',
        subClassifications: [
          { id: 6252, description: 'Administrative Assistants' },
          { id: 6253, description: 'Client & Sales Administration' },
          { id: 6254, description: 'Contracts Administration' },
          { id: 6255, description: 'Data Entry & Word Processing' },
          { id: 6256, description: 'Office Management' },
          { id: 6257, description: 'PA, EA & Secretarial' },
          { id: 6258, description: 'Receptionists' },
          { id: 6259, description: 'Records Management & Document Control' },
          { id: 6260, description: 'Other' },
        ],
      },
      {
        id: 6304,
        description: 'Advertising, Arts & Media',
        subClassifications: [
          { id: 6305, description: 'Agency Account Management' },
          { id: 6306, description: 'Art Direction' },
          { id: 6307, description: 'Editing & Publishing' },
          { id: 6308, description: 'Event Management' },
          { id: 6309, description: 'Journalism & Writing' },
          { id: 6310, description: 'Management' },
          { id: 6311, description: 'Media Strategy, Planning & Buying' },
          { id: 6312, description: 'Performing Arts' },
          { id: 6313, description: 'Photography' },
          { id: 6314, description: 'Programming & Production' },
          { id: 6315, description: 'Promotions' },
          { id: 6316, description: 'Other' },
        ],
      },
      {
        id: 1203,
        description: 'Banking & Financial Services',
        subClassifications: [
          { id: 6174, description: 'Account & Relationship Management' },
          { id: 6175, description: 'Analysis & Reporting' },
          { id: 6177, description: 'Banking - Business' },
          { id: 6178, description: 'Banking - Corporate & Institutional' },
          { id: 6176, description: 'Banking - Retail/Branch' },
          { id: 1352, description: 'Client Services' },
          { id: 6179, description: 'Compliance & Risk' },
          { id: 6180, description: 'Corporate Finance & Investment Banking' },
          { id: 6181, description: 'Credit' },
          { id: 1392, description: 'Financial Planning' },
          { id: 1404, description: 'Funds Management' },
          { id: 6182, description: 'Management' },
          { id: 6183, description: 'Mortgages' },
          { id: 6184, description: 'Settlements' },
          { id: 6185, description: 'Stockbroking & Trading' },
          { id: 6186, description: 'Treasury' },
          { id: 6187, description: 'Other' },
        ],
      },
      {
        id: 1204,
        description: 'Call Centre & Customer Service',
        subClassifications: [
          { id: 6084, description: 'Collections' },
          { id: 6085, description: 'Customer Service - Call Centre' },
          { id: 6086, description: 'Customer Service - Customer Facing' },
          { id: 6087, description: 'Management & Support' },
          { id: 6088, description: 'Sales - Inbound' },
          { id: 6089, description: 'Sales - Outbound' },
          { id: 6090, description: 'Supervisors/Team Leaders' },
          { id: 6091, description: 'Other' },
        ],
      },
      {
        id: 7019,
        description: 'CEO & General Management',
        subClassifications: [
          { id: 7020, description: 'Board Appointments' },
          { id: 7021, description: 'CEO' },
          { id: 7022, description: 'COO & MD' },
          { id: 7023, description: 'General/Business Unit Manager' },
          { id: 7024, description: 'Other' },
        ],
      },
      {
        id: 6163,
        description: 'Community Services & Development',
        subClassifications: [
          { id: 6164, description: 'Aged & Disability Support' },
          { id: 6165, description: 'Child Welfare, Youth & Family Services' },
          { id: 6166, description: 'Community Development' },
          { id: 6167, description: 'Employment Services' },
          { id: 6168, description: 'Fundraising' },
          { id: 6169, description: 'Housing & Homelessness Services' },
          { id: 6170, description: 'Indigenous & Multicultural Services' },
          { id: 6171, description: 'Management' },
          { id: 6172, description: 'Volunteer Coordination & Support' },
          { id: 6173, description: 'Other' },
        ],
      },
      {
        id: 1206,
        description: 'Construction',
        subClassifications: [
          { id: 6113, description: 'Contracts Management' },
          { id: 1387, description: 'Estimating' },
          { id: 6114, description: 'Foreperson/Supervisors' },
          { id: 6115, description: 'Health, Safety & Environment' },
          { id: 6116, description: 'Management' },
          { id: 6117, description: 'Planning & Scheduling' },
          { id: 6118, description: 'Plant & Machinery Operators' },
          { id: 6119, description: 'Project Management' },
          { id: 6120, description: 'Quality Assurance & Control' },
          { id: 6121, description: 'Surveying' },
          { id: 6122, description: 'Other' },
        ],
      },
      {
        id: 6076,
        description: 'Consulting & Strategy',
        subClassifications: [
          { id: 6077, description: 'Analysts' },
          { id: 6078, description: 'Corporate Development' },
          { id: 6079, description: 'Environment & Sustainability Consulting' },
          { id: 6080, description: 'Management & Change Consulting' },
          { id: 6081, description: 'Policy' },
          { id: 6082, description: 'Strategy & Planning' },
          { id: 6083, description: 'Other' },
        ],
      },
      {
        id: 6263,
        description: 'Design & Architecture',
        subClassifications: [
          { id: 6265, description: 'Architectural Drafting' },
          { id: 6264, description: 'Architecture' },
          { id: 6267, description: 'Fashion & Textile Design' },
          { id: 6268, description: 'Graphic Design' },
          { id: 6266, description: 'Illustration & Animation' },
          { id: 6269, description: 'Industrial Design' },
          { id: 6270, description: 'Interior Design' },
          { id: 6271, description: 'Landscape Architecture' },
          { id: 6272, description: 'Urban Design & Planning' },
          { id: 6273, description: 'Web & Interaction Design' },
          { id: 6274, description: 'Other' },
        ],
      },
      {
        id: 6123,
        description: 'Education & Training',
        subClassifications: [
          { id: 6124, description: 'Childcare & Outside School Hours Care' },
          {
            id: 6125,
            description: 'Library Services & Information Management',
          },
          { id: 6126, description: 'Management - Schools' },
          { id: 6127, description: 'Management - Universities' },
          { id: 6128, description: 'Management - Vocational' },
          { id: 6129, description: 'Research & Fellowships' },
          { id: 6130, description: 'Student Services' },
          { id: 6132, description: 'Teaching - Early Childhood' },
          { id: 6133, description: 'Teaching - Primary' },
          { id: 6134, description: 'Teaching - Secondary' },
          { id: 6135, description: 'Teaching - Tertiary' },
          { id: 6136, description: 'Teaching - Vocational' },
          { id: 6131, description: 'Teaching Aides & Special Needs' },
          { id: 6137, description: 'Tutoring' },
          { id: 6138, description: 'Workplace Training & Assessment' },
          { id: 6139, description: 'Other' },
        ],
      },
      {
        id: 1209,
        description: 'Engineering',
        subClassifications: [
          { id: 6022, description: 'Aerospace Engineering' },
          { id: 6023, description: 'Automotive Engineering' },
          { id: 6024, description: 'Building Services Engineering' },
          { id: 6026, description: 'Chemical Engineering' },
          { id: 6027, description: 'Civil/Structural Engineering' },
          { id: 6028, description: 'Electrical/Electronic Engineering' },
          { id: 6025, description: 'Engineering Drafting' },
          { id: 6029, description: 'Environmental Engineering' },
          { id: 6030, description: 'Field Engineering' },
          { id: 6031, description: 'Industrial Engineering' },
          { id: 6032, description: 'Maintenance' },
          { id: 6033, description: 'Management' },
          { id: 6034, description: 'Materials Handling Engineering' },
          { id: 6035, description: 'Mechanical Engineering' },
          { id: 6036, description: 'Process Engineering' },
          { id: 6038, description: 'Project Engineering' },
          { id: 6037, description: 'Project Management' },
          { id: 6039, description: 'Supervisors' },
          { id: 6040, description: 'Systems Engineering' },
          { id: 6041, description: 'Water & Waste Engineering' },
          { id: 6042, description: 'Other' },
        ],
      },
      {
        id: 6205,
        description: 'Farming, Animals & Conservation',
        subClassifications: [
          { id: 6206, description: 'Agronomy & Farm Services' },
          { id: 6207, description: 'Conservation, Parks & Wildlife' },
          { id: 6208, description: 'Farm Labour' },
          { id: 6209, description: 'Farm Management' },
          { id: 6210, description: 'Fishing & Aquaculture' },
          { id: 6211, description: 'Horticulture' },
          { id: 6212, description: 'Veterinary Services & Animal Welfare' },
          { id: 6213, description: 'Winery & Viticulture' },
          { id: 6214, description: 'Other' },
        ],
      },
      {
        id: 1210,
        description: 'Government & Defence',
        subClassifications: [
          { id: 1314, description: 'Air Force' },
          { id: 1322, description: 'Army' },
          { id: 1378, description: 'Emergency Services' },
          { id: 1409, description: 'Government - Federal' },
          { id: 1410, description: 'Government - Local' },
          { id: 1411, description: 'Government - State' },
          { id: 1450, description: 'Navy' },
          { id: 6359, description: 'Police & Corrections' },
          { id: 6360, description: 'Policy, Planning & Regulation' },
          { id: 6361, description: 'Other' },
        ],
      },
      {
        id: 1211,
        description: 'Healthcare & Medical',
        subClassifications: [
          { id: 6329, description: 'Ambulance/Paramedics' },
          { id: 6330, description: 'Chiropractic & Osteopathic' },
          { id: 1353, description: 'Clinical/Medical Research' },
          { id: 1372, description: 'Dental' },
          { id: 6331, description: 'Dieticians' },
          { id: 1386, description: 'Environmental Services' },
          { id: 6332, description: 'General Practitioners' },
          { id: 6333, description: 'Management' },
          { id: 6334, description: 'Medical Administration' },
          { id: 6335, description: 'Medical Imaging' },
          { id: 6336, description: 'Medical Specialists' },
          { id: 6337, description: 'Natural Therapies & Alternative Medicine' },
          { id: 6338, description: 'Nursing - A&E, Critical Care & ICU' },
          { id: 6339, description: 'Nursing - Aged Care' },
          {
            id: 6340,
            description: 'Nursing - Community, Maternal & Child Health',
          },
          { id: 6341, description: 'Nursing - Educators & Facilitators' },
          { id: 6342, description: 'Nursing - General Medical & Surgical' },
          { id: 6343, description: 'Nursing - High Acuity' },
          { id: 6344, description: 'Nursing - Management' },
          {
            id: 6345,
            description: 'Nursing - Midwifery, Neo-Natal, SCN & NICU',
          },
          { id: 6346, description: 'Nursing - Paediatric & PICU' },
          {
            id: 6347,
            description: 'Nursing - Psych, Forensic & Correctional Health',
          },
          { id: 6348, description: 'Nursing - Theatre & Recovery' },
          { id: 6349, description: 'Optical' },
          { id: 1467, description: 'Pathology' },
          { id: 6350, description: 'Pharmaceuticals & Medical Devices' },
          { id: 1470, description: 'Pharmacy' },
          { id: 6352, description: 'Physiotherapy, OT & Rehabilitation' },
          { id: 6351, description: 'Psychology, Counselling & Social Work' },
          { id: 6370, description: 'Residents & Registrars' },
          { id: 6353, description: 'Sales' },
          { id: 6354, description: 'Speech Therapy' },
          { id: 6355, description: 'Other' },
        ],
      },
      {
        id: 1212,
        description: 'Hospitality & Tourism',
        subClassifications: [
          { id: 1315, description: 'Airlines' },
          { id: 1332, description: 'Bar & Beverage Staff' },
          { id: 6052, description: 'Chefs/Cooks' },
          { id: 6053, description: 'Front Office & Guest Services' },
          { id: 1405, description: 'Gaming' },
          { id: 1415, description: 'Housekeeping' },
          { id: 6054, description: 'Kitchen & Sandwich Hands' },
          { id: 6055, description: 'Management' },
          { id: 6056, description: 'Reservations' },
          { id: 1537, description: 'Tour Guides' },
          { id: 1542, description: 'Travel Agents/Consultants' },
          { id: 1549, description: 'Waiting Staff' },
          { id: 6057, description: 'Other' },
        ],
      },
      {
        id: 6317,
        description: 'Human Resources & Recruitment',
        subClassifications: [
          { id: 6318, description: 'Consulting & Generalist HR' },
          { id: 6319, description: 'Industrial & Employee Relations' },
          { id: 6320, description: 'Management - Agency' },
          { id: 6321, description: 'Management - Internal' },
          { id: 6322, description: 'Occupational Health & Safety' },
          { id: 6323, description: 'Organisational Development' },
          { id: 6324, description: 'Recruitment - Agency' },
          { id: 6325, description: 'Recruitment - Internal' },
          { id: 6326, description: 'Remuneration & Benefits' },
          { id: 6327, description: 'Training & Development' },
          { id: 6328, description: 'Other' },
        ],
      },
      {
        id: 6281,
        description: 'Information & Communication Technology',
        subClassifications: [
          { id: 6282, description: 'Architects' },
          { id: 6283, description: 'Business/Systems Analysts' },
          { id: 6284, description: 'Computer Operators' },
          { id: 6285, description: 'Consultants' },
          { id: 6286, description: 'Database Development & Administration' },
          { id: 6287, description: 'Developers/Programmers' },
          { id: 6288, description: 'Engineering - Hardware' },
          { id: 6289, description: 'Engineering - Network' },
          { id: 6290, description: 'Engineering - Software' },
          { id: 6291, description: 'Help Desk & IT Support' },
          { id: 6292, description: 'Management' },
          { id: 6293, description: 'Networks & Systems Administration' },
          { id: 6294, description: 'Product Management & Development' },
          { id: 6295, description: 'Programme & Project Management' },
          { id: 6296, description: 'Sales - Pre & Post' },
          { id: 6297, description: 'Security' },
          { id: 6298, description: 'Team Leaders' },
          { id: 6299, description: 'Technical Writing' },
          { id: 6300, description: 'Telecommunications' },
          { id: 6301, description: 'Testing & Quality Assurance' },
          { id: 6302, description: 'Web Development & Production' },
          { id: 6303, description: 'Other' },
        ],
      },
      {
        id: 1214,
        description: 'Insurance & Superannuation',
        subClassifications: [
          { id: 6275, description: 'Actuarial' },
          { id: 6276, description: 'Assessment' },
          { id: 6277, description: 'Brokerage' },
          { id: 1350, description: 'Claims' },
          { id: 6278, description: 'Fund Administration' },
          { id: 6279, description: 'Management' },
          { id: 1499, description: 'Risk Consulting' },
          { id: 1524, description: 'Superannuation' },
          { id: 1544, description: 'Underwriting' },
          { id: 1553, description: "Workers' Compensation" },
          { id: 6280, description: 'Other' },
        ],
      },
      {
        id: 1216,
        description: 'Legal',
        subClassifications: [
          { id: 6188, description: 'Banking & Finance Law' },
          { id: 6190, description: 'Construction Law' },
          { id: 6191, description: 'Corporate & Commercial Law' },
          { id: 6189, description: 'Criminal & Civil Law' },
          { id: 6192, description: 'Environment & Planning Law' },
          { id: 6193, description: 'Family Law' },
          { id: 6194, description: 'Generalists - In-house' },
          { id: 6195, description: 'Generalists - Law Firm' },
          { id: 6196, description: 'Industrial Relations & Employment Law' },
          { id: 6197, description: 'Insurance & Superannuation Law' },
          { id: 6198, description: 'Intellectual Property Law' },
          { id: 1429, description: 'Law Clerks & Paralegals' },
          { id: 6199, description: 'Legal Practice Management' },
          { id: 1431, description: 'Legal Secretaries' },
          { id: 6200, description: 'Litigation & Dispute Resolution' },
          { id: 6201, description: 'Personal Injury Law' },
          { id: 6202, description: 'Property Law' },
          { id: 6203, description: 'Tax Law' },
          { id: 6204, description: 'Other' },
        ],
      },
      {
        id: 6092,
        description: 'Manufacturing, Transport & Logistics',
        subClassifications: [
          { id: 6095, description: 'Analysis & Reporting' },
          { id: 6093, description: 'Assembly & Process Work' },
          { id: 6094, description: 'Aviation Services' },
          { id: 6096, description: 'Couriers, Drivers & Postal Services' },
          { id: 6097, description: 'Fleet Management' },
          { id: 6098, description: 'Freight/Cargo Forwarding' },
          { id: 6099, description: 'Import/Export & Customs' },
          { id: 6101, description: 'Machine Operators' },
          { id: 6102, description: 'Management' },
          { id: 6104, description: 'Pattern Makers & Garment Technicians' },
          { id: 6105, description: 'Pickers & Packers' },
          { id: 6103, description: 'Production, Planning & Scheduling' },
          { id: 6100, description: 'Public Transport & Taxi Services' },
          { id: 6109, description: 'Purchasing, Procurement & Inventory' },
          { id: 6106, description: 'Quality Assurance & Control' },
          { id: 6110, description: 'Rail & Maritime Transport' },
          { id: 6111, description: 'Road Transport' },
          { id: 6107, description: 'Team Leaders/Supervisors' },
          { id: 6108, description: 'Warehousing, Storage & Distribution' },
          { id: 6112, description: 'Other' },
        ],
      },
      {
        id: 6008,
        description: 'Marketing & Communications',
        subClassifications: [
          { id: 6009, description: 'Brand Management' },
          { id: 6010, description: 'Digital & Search Marketing' },
          { id: 6011, description: 'Direct Marketing & CRM' },
          { id: 6012, description: 'Event Management' },
          { id: 6013, description: 'Internal Communications' },
          { id: 6017, description: 'Management' },
          { id: 6014, description: 'Market Research & Analysis' },
          { id: 6015, description: 'Marketing Assistants/Coordinators' },
          { id: 6016, description: 'Marketing Communications' },
          { id: 6018, description: 'Product Management & Development' },
          { id: 6019, description: 'Public Relations & Corporate Affairs' },
          { id: 6020, description: 'Trade Marketing' },
          { id: 6021, description: 'Other' },
        ],
      },
      {
        id: 6058,
        description: 'Mining, Resources & Energy',
        subClassifications: [
          { id: 6059, description: 'Analysis & Reporting' },
          { id: 6060, description: 'Health, Safety & Environment' },
          { id: 6061, description: 'Management' },
          { id: 6062, description: 'Mining - Drill & Blast' },
          { id: 6063, description: 'Mining - Engineering & Maintenance' },
          { id: 6064, description: 'Mining - Exploration & Geoscience' },
          { id: 6066, description: 'Mining - Operations' },
          { id: 6065, description: 'Mining - Processing' },
          { id: 6073, description: 'Natural Resources & Water' },
          { id: 6067, description: 'Oil & Gas - Drilling' },
          { id: 6068, description: 'Oil & Gas - Engineering & Maintenance' },
          { id: 6069, description: 'Oil & Gas - Exploration & Geoscience' },
          { id: 6071, description: 'Oil & Gas - Operations' },
          { id: 6070, description: 'Oil & Gas - Production & Refinement' },
          { id: 6072, description: 'Power Generation & Distribution' },
          { id: 6074, description: 'Surveying' },
          { id: 6075, description: 'Other' },
        ],
      },
      {
        id: 1220,
        description: 'Real Estate & Property',
        subClassifications: [
          { id: 6371, description: 'Administration' },
          { id: 6001, description: 'Analysts' },
          { id: 6002, description: 'Body Corporate & Facilities Management' },
          {
            id: 6003,
            description: 'Commercial Sales, Leasing & Property Mgmt',
          },
          {
            id: 6004,
            description: 'Residential Leasing & Property Management',
          },
          { id: 6005, description: 'Residential Sales' },
          { id: 6006, description: 'Retail & Property Development' },
          { id: 1546, description: 'Valuation' },
          { id: 6007, description: 'Other' },
        ],
      },
      {
        id: 6043,
        description: 'Retail & Consumer Products',
        subClassifications: [
          { id: 6044, description: 'Buying' },
          { id: 6045, description: 'Management - Area/Multi-site' },
          { id: 6046, description: 'Management - Department/Assistant' },
          { id: 6047, description: 'Management - Store' },
          { id: 6048, description: 'Merchandisers' },
          { id: 6049, description: 'Planning' },
          { id: 6050, description: 'Retail Assistants' },
          { id: 6051, description: 'Other' },
        ],
      },
      {
        id: 6362,
        description: 'Sales',
        subClassifications: [
          { id: 6363, description: 'Account & Relationship Management' },
          { id: 6364, description: 'Analysis & Reporting' },
          { id: 6366, description: 'Management' },
          { id: 6365, description: 'New Business Development' },
          { id: 6367, description: 'Sales Coordinators' },
          { id: 6368, description: 'Sales Representatives/Consultants' },
          { id: 6369, description: 'Other' },
        ],
      },
      {
        id: 1223,
        description: 'Science & Technology',
        subClassifications: [
          { id: 6215, description: 'Biological & Biomedical Sciences' },
          { id: 6216, description: 'Biotechnology & Genetics' },
          { id: 6217, description: 'Chemistry & Physics' },
          { id: 6218, description: 'Environmental, Earth & Geosciences' },
          { id: 6219, description: 'Food Technology & Safety' },
          { id: 6220, description: 'Laboratory & Technical Services' },
          { id: 6221, description: 'Materials Sciences' },
          {
            id: 6222,
            description: 'Mathematics, Statistics & Information Sciences',
          },
          { id: 6223, description: 'Modelling & Simulation' },
          { id: 6224, description: 'Quality Assurance & Control' },
          { id: 6225, description: 'Other' },
        ],
      },
      {
        id: 6261,
        description: 'Self Employment',
        subClassifications: [{ id: 6262, description: 'Self Employment' }],
      },
      {
        id: 6246,
        description: 'Sport & Recreation',
        subClassifications: [
          { id: 6247, description: 'Coaching & Instruction' },
          { id: 6248, description: 'Fitness & Personal Training' },
          { id: 6249, description: 'Management' },
          { id: 6250, description: 'Other' },
        ],
      },
      {
        id: 1225,
        description: 'Trades & Services',
        subClassifications: [
          { id: 1313, description: 'Air Conditioning & Refrigeration' },
          { id: 1328, description: 'Automotive Trades' },
          { id: 6226, description: 'Bakers & Pastry Chefs' },
          { id: 6227, description: 'Building Trades' },
          { id: 6228, description: 'Butchers' },
          { id: 1345, description: 'Carpentry & Cabinet Making' },
          { id: 6229, description: 'Cleaning Services' },
          { id: 6230, description: 'Electricians' },
          { id: 6231, description: 'Fitters, Turners & Machinists' },
          { id: 6232, description: 'Floristry' },
          { id: 1406, description: 'Gardening & Landscaping' },
          { id: 6233, description: 'Hair & Beauty Services' },
          { id: 6234, description: 'Labourers' },
          { id: 6235, description: 'Locksmiths' },
          { id: 6236, description: 'Maintenance & Handyperson Services' },
          { id: 6237, description: 'Nannies & Babysitters' },
          { id: 6238, description: 'Painters & Sign Writers' },
          { id: 6240, description: 'Plumbers' },
          { id: 6239, description: 'Printing & Publishing Services' },
          { id: 6241, description: 'Security Services' },
          { id: 6242, description: 'Tailors & Dressmakers' },
          { id: 6243, description: 'Technicians' },
          { id: 6244, description: 'Welders & Boilermakers' },
          { id: 6245, description: 'Other' },
        ],
      },
    ],
  },
  'asia-6': {
    en: [
      {
        id: 1200,
        description: 'Accounting',
        subClassifications: [
          { id: 6140, description: 'Accounts Officers/Clerks' },
          { id: 1303, description: 'Accounts Payable' },
          { id: 6141, description: 'Accounts Receivable/Credit Control' },
          { id: 6143, description: 'Analysis & Reporting' },
          { id: 6142, description: 'Assistant Accountants' },
          { id: 6144, description: 'Audit - External' },
          { id: 6145, description: 'Audit - Internal' },
          { id: 6146, description: 'Bookkeeping & Small Practice Accounting' },
          { id: 6147, description: 'Business Services & Corporate Advisory' },
          { id: 6148, description: 'Company Secretaries' },
          { id: 6149, description: 'Compliance & Risk' },
          { id: 6150, description: 'Cost Accounting' },
          { id: 6151, description: 'Financial Accounting & Reporting' },
          { id: 6152, description: 'Financial Managers & Controllers' },
          { id: 6153, description: 'Forensic Accounting & Investigation' },
          { id: 6154, description: 'Insolvency & Corporate Recovery' },
          { id: 6155, description: 'Inventory & Fixed Assets' },
          { id: 6156, description: 'Management' },
          { id: 6157, description: 'Management Accounting & Budgeting' },
          { id: 1468, description: 'Payroll' },
          { id: 6158, description: 'Strategy & Planning' },
          { id: 6159, description: 'Systems Accounting & IT Audit' },
          { id: 6160, description: 'Taxation' },
          { id: 6161, description: 'Treasury' },
          { id: 6162, description: 'Other' },
        ],
      },
      {
        id: 6251,
        description: 'Administration & Office Support',
        subClassifications: [
          { id: 6252, description: 'Administrative Assistants' },
          { id: 6253, description: 'Client & Sales Administration' },
          { id: 6254, description: 'Contracts Administration' },
          { id: 6255, description: 'Data Entry & Word Processing' },
          { id: 6256, description: 'Office Management' },
          { id: 6257, description: 'PA, EA & Secretarial' },
          { id: 6258, description: 'Receptionists' },
          { id: 6259, description: 'Records Management & Document Control' },
          { id: 6260, description: 'Other' },
        ],
      },
      {
        id: 6304,
        description: 'Advertising, Arts & Media',
        subClassifications: [
          { id: 6305, description: 'Agency Account Management' },
          { id: 6306, description: 'Art Direction' },
          { id: 6307, description: 'Editing & Publishing' },
          { id: 6308, description: 'Event Management' },
          { id: 6309, description: 'Journalism & Writing' },
          { id: 6310, description: 'Management' },
          { id: 6311, description: 'Media Strategy, Planning & Buying' },
          { id: 6312, description: 'Performing Arts' },
          { id: 6313, description: 'Photography' },
          { id: 6314, description: 'Programming & Production' },
          { id: 6315, description: 'Promotions' },
          { id: 6316, description: 'Other' },
        ],
      },
      {
        id: 1203,
        description: 'Banking & Financial Services',
        subClassifications: [
          { id: 6174, description: 'Account & Relationship Management' },
          { id: 6175, description: 'Analysis & Reporting' },
          { id: 6177, description: 'Banking - Business' },
          { id: 6178, description: 'Banking - Corporate & Institutional' },
          { id: 6176, description: 'Banking - Retail/Branch' },
          { id: 1352, description: 'Client Services' },
          { id: 6179, description: 'Compliance & Risk' },
          { id: 6180, description: 'Corporate Finance & Investment Banking' },
          { id: 6181, description: 'Credit' },
          { id: 1392, description: 'Financial Planning' },
          { id: 1404, description: 'Funds Management' },
          { id: 6182, description: 'Management' },
          { id: 6183, description: 'Mortgages' },
          { id: 6184, description: 'Settlements' },
          { id: 6185, description: 'Stockbroking & Trading' },
          { id: 6186, description: 'Treasury' },
          { id: 6187, description: 'Other' },
        ],
      },
      {
        id: 1204,
        description: 'Call Centre & Customer Service',
        subClassifications: [
          { id: 6084, description: 'Collections' },
          { id: 6085, description: 'Customer Service - Call Centre' },
          { id: 6086, description: 'Customer Service - Customer Facing' },
          { id: 6087, description: 'Management & Support' },
          { id: 6088, description: 'Sales - Inbound' },
          { id: 6089, description: 'Sales - Outbound' },
          { id: 6090, description: 'Supervisors/Team Leaders' },
          { id: 6091, description: 'Other' },
        ],
      },
      {
        id: 7019,
        description: 'CEO & General Management',
        subClassifications: [
          { id: 7020, description: 'Board Appointments' },
          { id: 7021, description: 'CEO' },
          { id: 7022, description: 'COO & MD' },
          { id: 7023, description: 'General/Business Unit Manager' },
          { id: 7024, description: 'Other' },
        ],
      },
      {
        id: 6163,
        description: 'Community Services & Development',
        subClassifications: [
          { id: 6164, description: 'Aged & Disability Support' },
          { id: 6165, description: 'Child Welfare, Youth & Family Services' },
          { id: 6166, description: 'Community Development' },
          { id: 6167, description: 'Employment Services' },
          { id: 6168, description: 'Fundraising' },
          { id: 6169, description: 'Housing & Homelessness Services' },
          { id: 6170, description: 'Indigenous & Multicultural Services' },
          { id: 6171, description: 'Management' },
          { id: 6172, description: 'Volunteer Coordination & Support' },
          { id: 6173, description: 'Other' },
        ],
      },
      {
        id: 1206,
        description: 'Construction',
        subClassifications: [
          { id: 6113, description: 'Contracts Management' },
          { id: 1387, description: 'Estimating' },
          { id: 6114, description: 'Foreperson/Supervisors' },
          { id: 6115, description: 'Health, Safety & Environment' },
          { id: 6116, description: 'Management' },
          { id: 6117, description: 'Planning & Scheduling' },
          { id: 6118, description: 'Plant & Machinery Operators' },
          { id: 6119, description: 'Project Management' },
          { id: 6120, description: 'Quality Assurance & Control' },
          { id: 6121, description: 'Surveying' },
          { id: 6122, description: 'Other' },
        ],
      },
      {
        id: 6076,
        description: 'Consulting & Strategy',
        subClassifications: [
          { id: 6077, description: 'Analysts' },
          { id: 6078, description: 'Corporate Development' },
          { id: 6079, description: 'Environment & Sustainability Consulting' },
          { id: 6080, description: 'Management & Change Consulting' },
          { id: 6081, description: 'Policy' },
          { id: 6082, description: 'Strategy & Planning' },
          { id: 6083, description: 'Other' },
        ],
      },
      {
        id: 6263,
        description: 'Design & Architecture',
        subClassifications: [
          { id: 6265, description: 'Architectural Drafting' },
          { id: 6264, description: 'Architecture' },
          { id: 6267, description: 'Fashion & Textile Design' },
          { id: 6268, description: 'Graphic Design' },
          { id: 6266, description: 'Illustration & Animation' },
          { id: 6269, description: 'Industrial Design' },
          { id: 6270, description: 'Interior Design' },
          { id: 6271, description: 'Landscape Architecture' },
          { id: 6272, description: 'Urban Design & Planning' },
          { id: 6273, description: 'Web & Interaction Design' },
          { id: 6274, description: 'Other' },
        ],
      },
      {
        id: 6123,
        description: 'Education & Training',
        subClassifications: [
          { id: 6124, description: 'Childcare & Outside School Hours Care' },
          {
            id: 6125,
            description: 'Library Services & Information Management',
          },
          { id: 6126, description: 'Management - Schools' },
          { id: 6127, description: 'Management - Universities' },
          { id: 6128, description: 'Management - Vocational' },
          { id: 6129, description: 'Research & Fellowships' },
          { id: 6130, description: 'Student Services' },
          { id: 6132, description: 'Teaching - Early Childhood' },
          { id: 6133, description: 'Teaching - Primary' },
          { id: 6134, description: 'Teaching - Secondary' },
          { id: 6135, description: 'Teaching - Tertiary' },
          { id: 6136, description: 'Teaching - Vocational' },
          { id: 6131, description: 'Teaching Aides & Special Needs' },
          { id: 6137, description: 'Tutoring' },
          { id: 6138, description: 'Workplace Training & Assessment' },
          { id: 6139, description: 'Other' },
        ],
      },
      {
        id: 1209,
        description: 'Engineering',
        subClassifications: [
          { id: 6022, description: 'Aerospace Engineering' },
          { id: 6023, description: 'Automotive Engineering' },
          { id: 6024, description: 'Building Services Engineering' },
          { id: 6026, description: 'Chemical Engineering' },
          { id: 6027, description: 'Civil/Structural Engineering' },
          { id: 6028, description: 'Electrical/Electronic Engineering' },
          { id: 6025, description: 'Engineering Drafting' },
          { id: 6029, description: 'Environmental Engineering' },
          { id: 6030, description: 'Field Engineering' },
          { id: 6031, description: 'Industrial Engineering' },
          { id: 6032, description: 'Maintenance' },
          { id: 6033, description: 'Management' },
          { id: 6034, description: 'Materials Handling Engineering' },
          { id: 6035, description: 'Mechanical Engineering' },
          { id: 6036, description: 'Process Engineering' },
          { id: 6038, description: 'Project Engineering' },
          { id: 6037, description: 'Project Management' },
          { id: 6039, description: 'Supervisors' },
          { id: 6040, description: 'Systems Engineering' },
          { id: 6041, description: 'Water & Waste Engineering' },
          { id: 6042, description: 'Other' },
        ],
      },
      {
        id: 6205,
        description: 'Farming, Animals & Conservation',
        subClassifications: [
          { id: 6206, description: 'Agronomy & Farm Services' },
          { id: 6207, description: 'Conservation, Parks & Wildlife' },
          { id: 6208, description: 'Farm Labour' },
          { id: 6209, description: 'Farm Management' },
          { id: 6210, description: 'Fishing & Aquaculture' },
          { id: 6211, description: 'Horticulture' },
          { id: 6212, description: 'Veterinary Services & Animal Welfare' },
          { id: 6213, description: 'Winery & Viticulture' },
          { id: 6214, description: 'Other' },
        ],
      },
      {
        id: 1210,
        description: 'Government & Defence',
        subClassifications: [
          { id: 1314, description: 'Air Force' },
          { id: 1322, description: 'Army' },
          { id: 1378, description: 'Emergency Services' },
          { id: 1409, description: 'Government - Federal' },
          { id: 1410, description: 'Government - Local' },
          { id: 1411, description: 'Government - State' },
          { id: 1450, description: 'Navy' },
          { id: 6359, description: 'Police & Corrections' },
          { id: 6360, description: 'Policy, Planning & Regulation' },
          { id: 6361, description: 'Other' },
        ],
      },
      {
        id: 1211,
        description: 'Healthcare & Medical',
        subClassifications: [
          { id: 6329, description: 'Ambulance/Paramedics' },
          { id: 6330, description: 'Chiropractic & Osteopathic' },
          { id: 1353, description: 'Clinical/Medical Research' },
          { id: 1372, description: 'Dental' },
          { id: 6331, description: 'Dieticians' },
          { id: 1386, description: 'Environmental Services' },
          { id: 6332, description: 'General Practitioners' },
          { id: 6333, description: 'Management' },
          { id: 6334, description: 'Medical Administration' },
          { id: 6335, description: 'Medical Imaging' },
          { id: 6336, description: 'Medical Specialists' },
          { id: 6337, description: 'Natural Therapies & Alternative Medicine' },
          { id: 6338, description: 'Nursing - A&E, Critical Care & ICU' },
          { id: 6339, description: 'Nursing - Aged Care' },
          {
            id: 6340,
            description: 'Nursing - Community, Maternal & Child Health',
          },
          { id: 6341, description: 'Nursing - Educators & Facilitators' },
          { id: 6342, description: 'Nursing - General Medical & Surgical' },
          { id: 6343, description: 'Nursing - High Acuity' },
          { id: 6344, description: 'Nursing - Management' },
          {
            id: 6345,
            description: 'Nursing - Midwifery, Neo-Natal, SCN & NICU',
          },
          { id: 6346, description: 'Nursing - Paediatric & PICU' },
          {
            id: 6347,
            description: 'Nursing - Psych, Forensic & Correctional Health',
          },
          { id: 6348, description: 'Nursing - Theatre & Recovery' },
          { id: 6349, description: 'Optical' },
          { id: 1467, description: 'Pathology' },
          { id: 6350, description: 'Pharmaceuticals & Medical Devices' },
          { id: 1470, description: 'Pharmacy' },
          { id: 6352, description: 'Physiotherapy, OT & Rehabilitation' },
          { id: 6351, description: 'Psychology, Counselling & Social Work' },
          { id: 6370, description: 'Residents & Registrars' },
          { id: 6353, description: 'Sales' },
          { id: 6354, description: 'Speech Therapy' },
          { id: 6355, description: 'Other' },
        ],
      },
      {
        id: 1212,
        description: 'Hospitality & Tourism',
        subClassifications: [
          { id: 1315, description: 'Airlines' },
          { id: 1332, description: 'Bar & Beverage Staff' },
          { id: 6052, description: 'Chefs/Cooks' },
          { id: 6053, description: 'Front Office & Guest Services' },
          { id: 1405, description: 'Gaming' },
          { id: 1415, description: 'Housekeeping' },
          { id: 6054, description: 'Kitchen & Sandwich Hands' },
          { id: 6055, description: 'Management' },
          { id: 6056, description: 'Reservations' },
          { id: 1537, description: 'Tour Guides' },
          { id: 1542, description: 'Travel Agents/Consultants' },
          { id: 1549, description: 'Waiting Staff' },
          { id: 6057, description: 'Other' },
        ],
      },
      {
        id: 6317,
        description: 'Human Resources & Recruitment',
        subClassifications: [
          { id: 6318, description: 'Consulting & Generalist HR' },
          { id: 6319, description: 'Industrial & Employee Relations' },
          { id: 6320, description: 'Management - Agency' },
          { id: 6321, description: 'Management - Internal' },
          { id: 6322, description: 'Occupational Health & Safety' },
          { id: 6323, description: 'Organisational Development' },
          { id: 6324, description: 'Recruitment - Agency' },
          { id: 6325, description: 'Recruitment - Internal' },
          { id: 6326, description: 'Remuneration & Benefits' },
          { id: 6327, description: 'Training & Development' },
          { id: 6328, description: 'Other' },
        ],
      },
      {
        id: 6281,
        description: 'Information & Communication Technology',
        subClassifications: [
          { id: 6282, description: 'Architects' },
          { id: 6283, description: 'Business/Systems Analysts' },
          { id: 6284, description: 'Computer Operators' },
          { id: 6285, description: 'Consultants' },
          { id: 6286, description: 'Database Development & Administration' },
          { id: 6287, description: 'Developers/Programmers' },
          { id: 6288, description: 'Engineering - Hardware' },
          { id: 6289, description: 'Engineering - Network' },
          { id: 6290, description: 'Engineering - Software' },
          { id: 6291, description: 'Help Desk & IT Support' },
          { id: 6292, description: 'Management' },
          { id: 6293, description: 'Networks & Systems Administration' },
          { id: 6294, description: 'Product Management & Development' },
          { id: 6295, description: 'Programme & Project Management' },
          { id: 6296, description: 'Sales - Pre & Post' },
          { id: 6297, description: 'Security' },
          { id: 6298, description: 'Team Leaders' },
          { id: 6299, description: 'Technical Writing' },
          { id: 6300, description: 'Telecommunications' },
          { id: 6301, description: 'Testing & Quality Assurance' },
          { id: 6302, description: 'Web Development & Production' },
          { id: 6303, description: 'Other' },
        ],
      },
      {
        id: 1214,
        description: 'Insurance & Superannuation',
        subClassifications: [
          { id: 6275, description: 'Actuarial' },
          { id: 6276, description: 'Assessment' },
          { id: 6277, description: 'Brokerage' },
          { id: 1350, description: 'Claims' },
          { id: 6278, description: 'Fund Administration' },
          { id: 6279, description: 'Management' },
          { id: 1499, description: 'Risk Consulting' },
          { id: 1524, description: 'Superannuation' },
          { id: 1544, description: 'Underwriting' },
          { id: 1553, description: "Workers' Compensation" },
          { id: 6280, description: 'Other' },
        ],
      },
      {
        id: 1216,
        description: 'Legal',
        subClassifications: [
          { id: 6188, description: 'Banking & Finance Law' },
          { id: 6190, description: 'Construction Law' },
          { id: 6191, description: 'Corporate & Commercial Law' },
          { id: 6189, description: 'Criminal & Civil Law' },
          { id: 6192, description: 'Environment & Planning Law' },
          { id: 6193, description: 'Family Law' },
          { id: 6194, description: 'Generalists - In-house' },
          { id: 6195, description: 'Generalists - Law Firm' },
          { id: 6196, description: 'Industrial Relations & Employment Law' },
          { id: 6197, description: 'Insurance & Superannuation Law' },
          { id: 6198, description: 'Intellectual Property Law' },
          { id: 1429, description: 'Law Clerks & Paralegals' },
          { id: 6199, description: 'Legal Practice Management' },
          { id: 1431, description: 'Legal Secretaries' },
          { id: 6200, description: 'Litigation & Dispute Resolution' },
          { id: 6201, description: 'Personal Injury Law' },
          { id: 6202, description: 'Property Law' },
          { id: 6203, description: 'Tax Law' },
          { id: 6204, description: 'Other' },
        ],
      },
      {
        id: 6092,
        description: 'Manufacturing, Transport & Logistics',
        subClassifications: [
          { id: 6095, description: 'Analysis & Reporting' },
          { id: 6093, description: 'Assembly & Process Work' },
          { id: 6094, description: 'Aviation Services' },
          { id: 6096, description: 'Couriers, Drivers & Postal Services' },
          { id: 6097, description: 'Fleet Management' },
          { id: 6098, description: 'Freight/Cargo Forwarding' },
          { id: 6099, description: 'Import/Export & Customs' },
          { id: 6101, description: 'Machine Operators' },
          { id: 6102, description: 'Management' },
          { id: 6104, description: 'Pattern Makers & Garment Technicians' },
          { id: 6105, description: 'Pickers & Packers' },
          { id: 6103, description: 'Production, Planning & Scheduling' },
          { id: 6100, description: 'Public Transport & Taxi Services' },
          { id: 6109, description: 'Purchasing, Procurement & Inventory' },
          { id: 6106, description: 'Quality Assurance & Control' },
          { id: 6110, description: 'Rail & Maritime Transport' },
          { id: 6111, description: 'Road Transport' },
          { id: 6107, description: 'Team Leaders/Supervisors' },
          { id: 6108, description: 'Warehousing, Storage & Distribution' },
          { id: 6112, description: 'Other' },
        ],
      },
      {
        id: 6008,
        description: 'Marketing & Communications',
        subClassifications: [
          { id: 6009, description: 'Brand Management' },
          { id: 6010, description: 'Digital & Search Marketing' },
          { id: 6011, description: 'Direct Marketing & CRM' },
          { id: 6012, description: 'Event Management' },
          { id: 6013, description: 'Internal Communications' },
          { id: 6017, description: 'Management' },
          { id: 6014, description: 'Market Research & Analysis' },
          { id: 6015, description: 'Marketing Assistants/Coordinators' },
          { id: 6016, description: 'Marketing Communications' },
          { id: 6018, description: 'Product Management & Development' },
          { id: 6019, description: 'Public Relations & Corporate Affairs' },
          { id: 6020, description: 'Trade Marketing' },
          { id: 6021, description: 'Other' },
        ],
      },
      {
        id: 6058,
        description: 'Mining, Resources & Energy',
        subClassifications: [
          { id: 6059, description: 'Analysis & Reporting' },
          { id: 6060, description: 'Health, Safety & Environment' },
          { id: 6061, description: 'Management' },
          { id: 6062, description: 'Mining - Drill & Blast' },
          { id: 6063, description: 'Mining - Engineering & Maintenance' },
          { id: 6064, description: 'Mining - Exploration & Geoscience' },
          { id: 6066, description: 'Mining - Operations' },
          { id: 6065, description: 'Mining - Processing' },
          { id: 6073, description: 'Natural Resources & Water' },
          { id: 6067, description: 'Oil & Gas - Drilling' },
          { id: 6068, description: 'Oil & Gas - Engineering & Maintenance' },
          { id: 6069, description: 'Oil & Gas - Exploration & Geoscience' },
          { id: 6071, description: 'Oil & Gas - Operations' },
          { id: 6070, description: 'Oil & Gas - Production & Refinement' },
          { id: 6072, description: 'Power Generation & Distribution' },
          { id: 6074, description: 'Surveying' },
          { id: 6075, description: 'Other' },
        ],
      },
      {
        id: 1220,
        description: 'Real Estate & Property',
        subClassifications: [
          { id: 6371, description: 'Administration' },
          { id: 6001, description: 'Analysts' },
          { id: 6002, description: 'Body Corporate & Facilities Management' },
          {
            id: 6003,
            description: 'Commercial Sales, Leasing & Property Mgmt',
          },
          {
            id: 6004,
            description: 'Residential Leasing & Property Management',
          },
          { id: 6005, description: 'Residential Sales' },
          { id: 6006, description: 'Retail & Property Development' },
          { id: 1546, description: 'Valuation' },
          { id: 6007, description: 'Other' },
        ],
      },
      {
        id: 6043,
        description: 'Retail & Consumer Products',
        subClassifications: [
          { id: 6044, description: 'Buying' },
          { id: 6045, description: 'Management - Area/Multi-site' },
          { id: 6046, description: 'Management - Department/Assistant' },
          { id: 6047, description: 'Management - Store' },
          { id: 6048, description: 'Merchandisers' },
          { id: 6049, description: 'Planning' },
          { id: 6050, description: 'Retail Assistants' },
          { id: 6051, description: 'Other' },
        ],
      },
      {
        id: 6362,
        description: 'Sales',
        subClassifications: [
          { id: 6363, description: 'Account & Relationship Management' },
          { id: 6364, description: 'Analysis & Reporting' },
          { id: 6366, description: 'Management' },
          { id: 6365, description: 'New Business Development' },
          { id: 6367, description: 'Sales Coordinators' },
          { id: 6368, description: 'Sales Representatives/Consultants' },
          { id: 6369, description: 'Other' },
        ],
      },
      {
        id: 1223,
        description: 'Science & Technology',
        subClassifications: [
          { id: 6215, description: 'Biological & Biomedical Sciences' },
          { id: 6216, description: 'Biotechnology & Genetics' },
          { id: 6217, description: 'Chemistry & Physics' },
          { id: 6218, description: 'Environmental, Earth & Geosciences' },
          { id: 6219, description: 'Food Technology & Safety' },
          { id: 6220, description: 'Laboratory & Technical Services' },
          { id: 6221, description: 'Materials Sciences' },
          {
            id: 6222,
            description: 'Mathematics, Statistics & Information Sciences',
          },
          { id: 6223, description: 'Modelling & Simulation' },
          { id: 6224, description: 'Quality Assurance & Control' },
          { id: 6225, description: 'Other' },
        ],
      },
      {
        id: 6261,
        description: 'Self Employment',
        subClassifications: [{ id: 6262, description: 'Self Employment' }],
      },
      {
        id: 6246,
        description: 'Sport & Recreation',
        subClassifications: [
          { id: 6247, description: 'Coaching & Instruction' },
          { id: 6248, description: 'Fitness & Personal Training' },
          { id: 6249, description: 'Management' },
          { id: 6250, description: 'Other' },
        ],
      },
      {
        id: 1225,
        description: 'Trades & Services',
        subClassifications: [
          { id: 1313, description: 'Air Conditioning & Refrigeration' },
          { id: 1328, description: 'Automotive Trades' },
          { id: 6226, description: 'Bakers & Pastry Chefs' },
          { id: 6227, description: 'Building Trades' },
          { id: 6228, description: 'Butchers' },
          { id: 1345, description: 'Carpentry & Cabinet Making' },
          { id: 6229, description: 'Cleaning Services' },
          { id: 6230, description: 'Electricians' },
          { id: 6231, description: 'Fitters, Turners & Machinists' },
          { id: 6232, description: 'Floristry' },
          { id: 1406, description: 'Gardening & Landscaping' },
          { id: 6233, description: 'Hair & Beauty Services' },
          { id: 6234, description: 'Labourers' },
          { id: 6235, description: 'Locksmiths' },
          { id: 6236, description: 'Maintenance & Handyperson Services' },
          { id: 6237, description: 'Nannies & Babysitters' },
          { id: 6238, description: 'Painters & Sign Writers' },
          { id: 6240, description: 'Plumbers' },
          { id: 6239, description: 'Printing & Publishing Services' },
          { id: 6241, description: 'Security Services' },
          { id: 6242, description: 'Tailors & Dressmakers' },
          { id: 6243, description: 'Technicians' },
          { id: 6244, description: 'Welders & Boilermakers' },
          { id: 6245, description: 'Other' },
        ],
      },
    ],
  },
  'asia-7': {
    en: [
      {
        id: 1200,
        description: 'Accounting',
        subClassifications: [
          { id: 6140, description: 'Accounts Officers/Clerks' },
          { id: 1303, description: 'Accounts Payable' },
          { id: 6141, description: 'Accounts Receivable/Credit Control' },
          { id: 6143, description: 'Analysis & Reporting' },
          { id: 6142, description: 'Assistant Accountants' },
          { id: 6144, description: 'Audit - External' },
          { id: 6145, description: 'Audit - Internal' },
          { id: 6146, description: 'Bookkeeping & Small Practice Accounting' },
          { id: 6147, description: 'Business Services & Corporate Advisory' },
          { id: 6148, description: 'Company Secretaries' },
          { id: 6149, description: 'Compliance & Risk' },
          { id: 6150, description: 'Cost Accounting' },
          { id: 6151, description: 'Financial Accounting & Reporting' },
          { id: 6152, description: 'Financial Managers & Controllers' },
          { id: 6153, description: 'Forensic Accounting & Investigation' },
          { id: 6154, description: 'Insolvency & Corporate Recovery' },
          { id: 6155, description: 'Inventory & Fixed Assets' },
          { id: 6156, description: 'Management' },
          { id: 6157, description: 'Management Accounting & Budgeting' },
          { id: 1468, description: 'Payroll' },
          { id: 6158, description: 'Strategy & Planning' },
          { id: 6159, description: 'Systems Accounting & IT Audit' },
          { id: 6160, description: 'Taxation' },
          { id: 6161, description: 'Treasury' },
          { id: 6162, description: 'Other' },
        ],
      },
      {
        id: 6251,
        description: 'Administration & Office Support',
        subClassifications: [
          { id: 6252, description: 'Administrative Assistants' },
          { id: 6253, description: 'Client & Sales Administration' },
          { id: 6254, description: 'Contracts Administration' },
          { id: 6255, description: 'Data Entry & Word Processing' },
          { id: 6256, description: 'Office Management' },
          { id: 6257, description: 'PA, EA & Secretarial' },
          { id: 6258, description: 'Receptionists' },
          { id: 6259, description: 'Records Management & Document Control' },
          { id: 6260, description: 'Other' },
        ],
      },
      {
        id: 6304,
        description: 'Advertising, Arts & Media',
        subClassifications: [
          { id: 6305, description: 'Agency Account Management' },
          { id: 6306, description: 'Art Direction' },
          { id: 6307, description: 'Editing & Publishing' },
          { id: 6308, description: 'Event Management' },
          { id: 6309, description: 'Journalism & Writing' },
          { id: 6310, description: 'Management' },
          { id: 6311, description: 'Media Strategy, Planning & Buying' },
          { id: 6312, description: 'Performing Arts' },
          { id: 6313, description: 'Photography' },
          { id: 6314, description: 'Programming & Production' },
          { id: 6315, description: 'Promotions' },
          { id: 6316, description: 'Other' },
        ],
      },
      {
        id: 1203,
        description: 'Banking & Financial Services',
        subClassifications: [
          { id: 6174, description: 'Account & Relationship Management' },
          { id: 6175, description: 'Analysis & Reporting' },
          { id: 6177, description: 'Banking - Business' },
          { id: 6178, description: 'Banking - Corporate & Institutional' },
          { id: 6176, description: 'Banking - Retail/Branch' },
          { id: 1352, description: 'Client Services' },
          { id: 6179, description: 'Compliance & Risk' },
          { id: 6180, description: 'Corporate Finance & Investment Banking' },
          { id: 6181, description: 'Credit' },
          { id: 1392, description: 'Financial Planning' },
          { id: 1404, description: 'Funds Management' },
          { id: 6182, description: 'Management' },
          { id: 6183, description: 'Mortgages' },
          { id: 6184, description: 'Settlements' },
          { id: 6185, description: 'Stockbroking & Trading' },
          { id: 6186, description: 'Treasury' },
          { id: 6187, description: 'Other' },
        ],
      },
      {
        id: 1204,
        description: 'Call Centre & Customer Service',
        subClassifications: [
          { id: 6084, description: 'Collections' },
          { id: 6085, description: 'Customer Service - Call Centre' },
          { id: 6086, description: 'Customer Service - Customer Facing' },
          { id: 6087, description: 'Management & Support' },
          { id: 6088, description: 'Sales - Inbound' },
          { id: 6089, description: 'Sales - Outbound' },
          { id: 6090, description: 'Supervisors/Team Leaders' },
          { id: 6091, description: 'Other' },
        ],
      },
      {
        id: 7019,
        description: 'CEO & General Management',
        subClassifications: [
          { id: 7020, description: 'Board Appointments' },
          { id: 7021, description: 'CEO' },
          { id: 7022, description: 'COO & MD' },
          { id: 7023, description: 'General/Business Unit Manager' },
          { id: 7024, description: 'Other' },
        ],
      },
      {
        id: 6163,
        description: 'Community Services & Development',
        subClassifications: [
          { id: 6164, description: 'Aged & Disability Support' },
          { id: 6165, description: 'Child Welfare, Youth & Family Services' },
          { id: 6166, description: 'Community Development' },
          { id: 6167, description: 'Employment Services' },
          { id: 6168, description: 'Fundraising' },
          { id: 6169, description: 'Housing & Homelessness Services' },
          { id: 6170, description: 'Indigenous & Multicultural Services' },
          { id: 6171, description: 'Management' },
          { id: 6172, description: 'Volunteer Coordination & Support' },
          { id: 6173, description: 'Other' },
        ],
      },
      {
        id: 1206,
        description: 'Construction',
        subClassifications: [
          { id: 6113, description: 'Contracts Management' },
          { id: 1387, description: 'Estimating' },
          { id: 6114, description: 'Foreperson/Supervisors' },
          { id: 6115, description: 'Health, Safety & Environment' },
          { id: 6116, description: 'Management' },
          { id: 6117, description: 'Planning & Scheduling' },
          { id: 6118, description: 'Plant & Machinery Operators' },
          { id: 6119, description: 'Project Management' },
          { id: 6120, description: 'Quality Assurance & Control' },
          { id: 6121, description: 'Surveying' },
          { id: 6122, description: 'Other' },
        ],
      },
      {
        id: 6076,
        description: 'Consulting & Strategy',
        subClassifications: [
          { id: 6077, description: 'Analysts' },
          { id: 6078, description: 'Corporate Development' },
          { id: 6079, description: 'Environment & Sustainability Consulting' },
          { id: 6080, description: 'Management & Change Consulting' },
          { id: 6081, description: 'Policy' },
          { id: 6082, description: 'Strategy & Planning' },
          { id: 6083, description: 'Other' },
        ],
      },
      {
        id: 6263,
        description: 'Design & Architecture',
        subClassifications: [
          { id: 6265, description: 'Architectural Drafting' },
          { id: 6264, description: 'Architecture' },
          { id: 6267, description: 'Fashion & Textile Design' },
          { id: 6268, description: 'Graphic Design' },
          { id: 6266, description: 'Illustration & Animation' },
          { id: 6269, description: 'Industrial Design' },
          { id: 6270, description: 'Interior Design' },
          { id: 6271, description: 'Landscape Architecture' },
          { id: 6272, description: 'Urban Design & Planning' },
          { id: 6273, description: 'Web & Interaction Design' },
          { id: 6274, description: 'Other' },
        ],
      },
      {
        id: 6123,
        description: 'Education & Training',
        subClassifications: [
          { id: 6124, description: 'Childcare & Outside School Hours Care' },
          {
            id: 6125,
            description: 'Library Services & Information Management',
          },
          { id: 6126, description: 'Management - Schools' },
          { id: 6127, description: 'Management - Universities' },
          { id: 6128, description: 'Management - Vocational' },
          { id: 6129, description: 'Research & Fellowships' },
          { id: 6130, description: 'Student Services' },
          { id: 6132, description: 'Teaching - Early Childhood' },
          { id: 6133, description: 'Teaching - Primary' },
          { id: 6134, description: 'Teaching - Secondary' },
          { id: 6135, description: 'Teaching - Tertiary' },
          { id: 6136, description: 'Teaching - Vocational' },
          { id: 6131, description: 'Teaching Aides & Special Needs' },
          { id: 6137, description: 'Tutoring' },
          { id: 6138, description: 'Workplace Training & Assessment' },
          { id: 6139, description: 'Other' },
        ],
      },
      {
        id: 1209,
        description: 'Engineering',
        subClassifications: [
          { id: 6022, description: 'Aerospace Engineering' },
          { id: 6023, description: 'Automotive Engineering' },
          { id: 6024, description: 'Building Services Engineering' },
          { id: 6026, description: 'Chemical Engineering' },
          { id: 6027, description: 'Civil/Structural Engineering' },
          { id: 6028, description: 'Electrical/Electronic Engineering' },
          { id: 6025, description: 'Engineering Drafting' },
          { id: 6029, description: 'Environmental Engineering' },
          { id: 6030, description: 'Field Engineering' },
          { id: 6031, description: 'Industrial Engineering' },
          { id: 6032, description: 'Maintenance' },
          { id: 6033, description: 'Management' },
          { id: 6034, description: 'Materials Handling Engineering' },
          { id: 6035, description: 'Mechanical Engineering' },
          { id: 6036, description: 'Process Engineering' },
          { id: 6038, description: 'Project Engineering' },
          { id: 6037, description: 'Project Management' },
          { id: 6039, description: 'Supervisors' },
          { id: 6040, description: 'Systems Engineering' },
          { id: 6041, description: 'Water & Waste Engineering' },
          { id: 6042, description: 'Other' },
        ],
      },
      {
        id: 6205,
        description: 'Farming, Animals & Conservation',
        subClassifications: [
          { id: 6206, description: 'Agronomy & Farm Services' },
          { id: 6207, description: 'Conservation, Parks & Wildlife' },
          { id: 6208, description: 'Farm Labour' },
          { id: 6209, description: 'Farm Management' },
          { id: 6210, description: 'Fishing & Aquaculture' },
          { id: 6211, description: 'Horticulture' },
          { id: 6212, description: 'Veterinary Services & Animal Welfare' },
          { id: 6213, description: 'Winery & Viticulture' },
          { id: 6214, description: 'Other' },
        ],
      },
      {
        id: 1210,
        description: 'Government & Defence',
        subClassifications: [
          { id: 1314, description: 'Air Force' },
          { id: 1322, description: 'Army' },
          { id: 1378, description: 'Emergency Services' },
          { id: 1409, description: 'Government - Federal' },
          { id: 1410, description: 'Government - Local' },
          { id: 1411, description: 'Government - State' },
          { id: 1450, description: 'Navy' },
          { id: 6359, description: 'Police & Corrections' },
          { id: 6360, description: 'Policy, Planning & Regulation' },
          { id: 6361, description: 'Other' },
        ],
      },
      {
        id: 1211,
        description: 'Healthcare & Medical',
        subClassifications: [
          { id: 6329, description: 'Ambulance/Paramedics' },
          { id: 6330, description: 'Chiropractic & Osteopathic' },
          { id: 1353, description: 'Clinical/Medical Research' },
          { id: 1372, description: 'Dental' },
          { id: 6331, description: 'Dieticians' },
          { id: 1386, description: 'Environmental Services' },
          { id: 6332, description: 'General Practitioners' },
          { id: 6333, description: 'Management' },
          { id: 6334, description: 'Medical Administration' },
          { id: 6335, description: 'Medical Imaging' },
          { id: 6336, description: 'Medical Specialists' },
          { id: 6337, description: 'Natural Therapies & Alternative Medicine' },
          { id: 6338, description: 'Nursing - A&E, Critical Care & ICU' },
          { id: 6339, description: 'Nursing - Aged Care' },
          {
            id: 6340,
            description: 'Nursing - Community, Maternal & Child Health',
          },
          { id: 6341, description: 'Nursing - Educators & Facilitators' },
          { id: 6342, description: 'Nursing - General Medical & Surgical' },
          { id: 6343, description: 'Nursing - High Acuity' },
          { id: 6344, description: 'Nursing - Management' },
          {
            id: 6345,
            description: 'Nursing - Midwifery, Neo-Natal, SCN & NICU',
          },
          { id: 6346, description: 'Nursing - Paediatric & PICU' },
          {
            id: 6347,
            description: 'Nursing - Psych, Forensic & Correctional Health',
          },
          { id: 6348, description: 'Nursing - Theatre & Recovery' },
          { id: 6349, description: 'Optical' },
          { id: 1467, description: 'Pathology' },
          { id: 6350, description: 'Pharmaceuticals & Medical Devices' },
          { id: 1470, description: 'Pharmacy' },
          { id: 6352, description: 'Physiotherapy, OT & Rehabilitation' },
          { id: 6351, description: 'Psychology, Counselling & Social Work' },
          { id: 6370, description: 'Residents & Registrars' },
          { id: 6353, description: 'Sales' },
          { id: 6354, description: 'Speech Therapy' },
          { id: 6355, description: 'Other' },
        ],
      },
      {
        id: 1212,
        description: 'Hospitality & Tourism',
        subClassifications: [
          { id: 1315, description: 'Airlines' },
          { id: 1332, description: 'Bar & Beverage Staff' },
          { id: 6052, description: 'Chefs/Cooks' },
          { id: 6053, description: 'Front Office & Guest Services' },
          { id: 1405, description: 'Gaming' },
          { id: 1415, description: 'Housekeeping' },
          { id: 6054, description: 'Kitchen & Sandwich Hands' },
          { id: 6055, description: 'Management' },
          { id: 6056, description: 'Reservations' },
          { id: 1537, description: 'Tour Guides' },
          { id: 1542, description: 'Travel Agents/Consultants' },
          { id: 1549, description: 'Waiting Staff' },
          { id: 6057, description: 'Other' },
        ],
      },
      {
        id: 6317,
        description: 'Human Resources & Recruitment',
        subClassifications: [
          { id: 6318, description: 'Consulting & Generalist HR' },
          { id: 6319, description: 'Industrial & Employee Relations' },
          { id: 6320, description: 'Management - Agency' },
          { id: 6321, description: 'Management - Internal' },
          { id: 6322, description: 'Occupational Health & Safety' },
          { id: 6323, description: 'Organisational Development' },
          { id: 6324, description: 'Recruitment - Agency' },
          { id: 6325, description: 'Recruitment - Internal' },
          { id: 6326, description: 'Remuneration & Benefits' },
          { id: 6327, description: 'Training & Development' },
          { id: 6328, description: 'Other' },
        ],
      },
      {
        id: 6281,
        description: 'Information & Communication Technology',
        subClassifications: [
          { id: 6282, description: 'Architects' },
          { id: 6283, description: 'Business/Systems Analysts' },
          { id: 6284, description: 'Computer Operators' },
          { id: 6285, description: 'Consultants' },
          { id: 6286, description: 'Database Development & Administration' },
          { id: 6287, description: 'Developers/Programmers' },
          { id: 6288, description: 'Engineering - Hardware' },
          { id: 6289, description: 'Engineering - Network' },
          { id: 6290, description: 'Engineering - Software' },
          { id: 6291, description: 'Help Desk & IT Support' },
          { id: 6292, description: 'Management' },
          { id: 6293, description: 'Networks & Systems Administration' },
          { id: 6294, description: 'Product Management & Development' },
          { id: 6295, description: 'Programme & Project Management' },
          { id: 6296, description: 'Sales - Pre & Post' },
          { id: 6297, description: 'Security' },
          { id: 6298, description: 'Team Leaders' },
          { id: 6299, description: 'Technical Writing' },
          { id: 6300, description: 'Telecommunications' },
          { id: 6301, description: 'Testing & Quality Assurance' },
          { id: 6302, description: 'Web Development & Production' },
          { id: 6303, description: 'Other' },
        ],
      },
      {
        id: 1214,
        description: 'Insurance & Superannuation',
        subClassifications: [
          { id: 6275, description: 'Actuarial' },
          { id: 6276, description: 'Assessment' },
          { id: 6277, description: 'Brokerage' },
          { id: 1350, description: 'Claims' },
          { id: 6278, description: 'Fund Administration' },
          { id: 6279, description: 'Management' },
          { id: 1499, description: 'Risk Consulting' },
          { id: 1524, description: 'Superannuation' },
          { id: 1544, description: 'Underwriting' },
          { id: 1553, description: "Workers' Compensation" },
          { id: 6280, description: 'Other' },
        ],
      },
      {
        id: 1216,
        description: 'Legal',
        subClassifications: [
          { id: 6188, description: 'Banking & Finance Law' },
          { id: 6190, description: 'Construction Law' },
          { id: 6191, description: 'Corporate & Commercial Law' },
          { id: 6189, description: 'Criminal & Civil Law' },
          { id: 6192, description: 'Environment & Planning Law' },
          { id: 6193, description: 'Family Law' },
          { id: 6194, description: 'Generalists - In-house' },
          { id: 6195, description: 'Generalists - Law Firm' },
          { id: 6196, description: 'Industrial Relations & Employment Law' },
          { id: 6197, description: 'Insurance & Superannuation Law' },
          { id: 6198, description: 'Intellectual Property Law' },
          { id: 1429, description: 'Law Clerks & Paralegals' },
          { id: 6199, description: 'Legal Practice Management' },
          { id: 1431, description: 'Legal Secretaries' },
          { id: 6200, description: 'Litigation & Dispute Resolution' },
          { id: 6201, description: 'Personal Injury Law' },
          { id: 6202, description: 'Property Law' },
          { id: 6203, description: 'Tax Law' },
          { id: 6204, description: 'Other' },
        ],
      },
      {
        id: 6092,
        description: 'Manufacturing, Transport & Logistics',
        subClassifications: [
          { id: 6095, description: 'Analysis & Reporting' },
          { id: 6093, description: 'Assembly & Process Work' },
          { id: 6094, description: 'Aviation Services' },
          { id: 6096, description: 'Couriers, Drivers & Postal Services' },
          { id: 6097, description: 'Fleet Management' },
          { id: 6098, description: 'Freight/Cargo Forwarding' },
          { id: 6099, description: 'Import/Export & Customs' },
          { id: 6101, description: 'Machine Operators' },
          { id: 6102, description: 'Management' },
          { id: 6104, description: 'Pattern Makers & Garment Technicians' },
          { id: 6105, description: 'Pickers & Packers' },
          { id: 6103, description: 'Production, Planning & Scheduling' },
          { id: 6100, description: 'Public Transport & Taxi Services' },
          { id: 6109, description: 'Purchasing, Procurement & Inventory' },
          { id: 6106, description: 'Quality Assurance & Control' },
          { id: 6110, description: 'Rail & Maritime Transport' },
          { id: 6111, description: 'Road Transport' },
          { id: 6107, description: 'Team Leaders/Supervisors' },
          { id: 6108, description: 'Warehousing, Storage & Distribution' },
          { id: 6112, description: 'Other' },
        ],
      },
      {
        id: 6008,
        description: 'Marketing & Communications',
        subClassifications: [
          { id: 6009, description: 'Brand Management' },
          { id: 6010, description: 'Digital & Search Marketing' },
          { id: 6011, description: 'Direct Marketing & CRM' },
          { id: 6012, description: 'Event Management' },
          { id: 6013, description: 'Internal Communications' },
          { id: 6017, description: 'Management' },
          { id: 6014, description: 'Market Research & Analysis' },
          { id: 6015, description: 'Marketing Assistants/Coordinators' },
          { id: 6016, description: 'Marketing Communications' },
          { id: 6018, description: 'Product Management & Development' },
          { id: 6019, description: 'Public Relations & Corporate Affairs' },
          { id: 6020, description: 'Trade Marketing' },
          { id: 6021, description: 'Other' },
        ],
      },
      {
        id: 6058,
        description: 'Mining, Resources & Energy',
        subClassifications: [
          { id: 6059, description: 'Analysis & Reporting' },
          { id: 6060, description: 'Health, Safety & Environment' },
          { id: 6061, description: 'Management' },
          { id: 6062, description: 'Mining - Drill & Blast' },
          { id: 6063, description: 'Mining - Engineering & Maintenance' },
          { id: 6064, description: 'Mining - Exploration & Geoscience' },
          { id: 6066, description: 'Mining - Operations' },
          { id: 6065, description: 'Mining - Processing' },
          { id: 6073, description: 'Natural Resources & Water' },
          { id: 6067, description: 'Oil & Gas - Drilling' },
          { id: 6068, description: 'Oil & Gas - Engineering & Maintenance' },
          { id: 6069, description: 'Oil & Gas - Exploration & Geoscience' },
          { id: 6071, description: 'Oil & Gas - Operations' },
          { id: 6070, description: 'Oil & Gas - Production & Refinement' },
          { id: 6072, description: 'Power Generation & Distribution' },
          { id: 6074, description: 'Surveying' },
          { id: 6075, description: 'Other' },
        ],
      },
      {
        id: 1220,
        description: 'Real Estate & Property',
        subClassifications: [
          { id: 6371, description: 'Administration' },
          { id: 6001, description: 'Analysts' },
          { id: 6002, description: 'Body Corporate & Facilities Management' },
          {
            id: 6003,
            description: 'Commercial Sales, Leasing & Property Mgmt',
          },
          {
            id: 6004,
            description: 'Residential Leasing & Property Management',
          },
          { id: 6005, description: 'Residential Sales' },
          { id: 6006, description: 'Retail & Property Development' },
          { id: 1546, description: 'Valuation' },
          { id: 6007, description: 'Other' },
        ],
      },
      {
        id: 6043,
        description: 'Retail & Consumer Products',
        subClassifications: [
          { id: 6044, description: 'Buying' },
          { id: 6045, description: 'Management - Area/Multi-site' },
          { id: 6046, description: 'Management - Department/Assistant' },
          { id: 6047, description: 'Management - Store' },
          { id: 6048, description: 'Merchandisers' },
          { id: 6049, description: 'Planning' },
          { id: 6050, description: 'Retail Assistants' },
          { id: 6051, description: 'Other' },
        ],
      },
      {
        id: 6362,
        description: 'Sales',
        subClassifications: [
          { id: 6363, description: 'Account & Relationship Management' },
          { id: 6364, description: 'Analysis & Reporting' },
          { id: 6366, description: 'Management' },
          { id: 6365, description: 'New Business Development' },
          { id: 6367, description: 'Sales Coordinators' },
          { id: 6368, description: 'Sales Representatives/Consultants' },
          { id: 6369, description: 'Other' },
        ],
      },
      {
        id: 1223,
        description: 'Science & Technology',
        subClassifications: [
          { id: 6215, description: 'Biological & Biomedical Sciences' },
          { id: 6216, description: 'Biotechnology & Genetics' },
          { id: 6217, description: 'Chemistry & Physics' },
          { id: 6218, description: 'Environmental, Earth & Geosciences' },
          { id: 6219, description: 'Food Technology & Safety' },
          { id: 6220, description: 'Laboratory & Technical Services' },
          { id: 6221, description: 'Materials Sciences' },
          {
            id: 6222,
            description: 'Mathematics, Statistics & Information Sciences',
          },
          { id: 6223, description: 'Modelling & Simulation' },
          { id: 6224, description: 'Quality Assurance & Control' },
          { id: 6225, description: 'Other' },
        ],
      },
      {
        id: 6261,
        description: 'Self Employment',
        subClassifications: [{ id: 6262, description: 'Self Employment' }],
      },
      {
        id: 6246,
        description: 'Sport & Recreation',
        subClassifications: [
          { id: 6247, description: 'Coaching & Instruction' },
          { id: 6248, description: 'Fitness & Personal Training' },
          { id: 6249, description: 'Management' },
          { id: 6250, description: 'Other' },
        ],
      },
      {
        id: 1225,
        description: 'Trades & Services',
        subClassifications: [
          { id: 1313, description: 'Air Conditioning & Refrigeration' },
          { id: 1328, description: 'Automotive Trades' },
          { id: 6226, description: 'Bakers & Pastry Chefs' },
          { id: 6227, description: 'Building Trades' },
          { id: 6228, description: 'Butchers' },
          { id: 1345, description: 'Carpentry & Cabinet Making' },
          { id: 6229, description: 'Cleaning Services' },
          { id: 6230, description: 'Electricians' },
          { id: 6231, description: 'Fitters, Turners & Machinists' },
          { id: 6232, description: 'Floristry' },
          { id: 1406, description: 'Gardening & Landscaping' },
          { id: 6233, description: 'Hair & Beauty Services' },
          { id: 6234, description: 'Labourers' },
          { id: 6235, description: 'Locksmiths' },
          { id: 6236, description: 'Maintenance & Handyperson Services' },
          { id: 6237, description: 'Nannies & Babysitters' },
          { id: 6238, description: 'Painters & Sign Writers' },
          { id: 6240, description: 'Plumbers' },
          { id: 6239, description: 'Printing & Publishing Services' },
          { id: 6241, description: 'Security Services' },
          { id: 6242, description: 'Tailors & Dressmakers' },
          { id: 6243, description: 'Technicians' },
          { id: 6244, description: 'Welders & Boilermakers' },
          { id: 6245, description: 'Other' },
        ],
      },
    ],
  },
};
