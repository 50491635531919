import type { Country } from '@seek/melways-sites';
import { useTranslations } from '@vocab/react';
import { Box, Text } from 'braid-design-system';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';

import NavLink from 'src/components/NavLink/NavLink';
import { classificationList } from 'src/components/Search/StaticList';
import { useSERPHasExternalJobs } from 'src/modules/hooks/useSERPHasExternalJobs';
import type { RefinementClassification } from 'src/modules/refine-job-search/lib/types';
import { useMelwaysCountry } from 'src/utils/melwaysHelper';

import translations from './.vocab';

import * as styles from './RefineChecklist.css';

type FilterFn = () => boolean;

interface RenderChecklistProps {
  options: RefinementClassification[];
  path: string;
  filter?: FilterFn;
  depth?: number;
}

interface ChecklistItemProps {
  option: RefinementClassification;
  depth: number;
  path: string;
  filter: FilterFn;
}

export const shouldFollowClassification = (
  country: Country,
  value: string,
): boolean => {
  const countryClassifications = classificationList[country];
  return countryClassifications?.some((item) => item.value === value) || false;
};
const ChecklistItem = ({ option, depth, path, filter }: ChecklistItemProps) => {
  const { t } = useTranslations(translations);
  const hasExternalJobs = useSERPHasExternalJobs();
  const testId = `${option.type === 'all' ? 'all-' : ''}${option.value}`;

  const country = useMelwaysCountry();

  // Determine if the option should be followed based on its presence in the list for the selected country
  // It's a temporary solution to make it work for SSR as moving getCounts to SSR increase the latency by 200ms
  const shouldFollow = useMemo(
    () => shouldFollowClassification(country, option.value),
    [country, option.value],
  );

  const [isActive, setIsActive] = useState(option.isActive);

  useEffect(() => {
    setIsActive(option.isActive);
  }, [option.isActive]);

  return (
    <Box
      component="li"
      key={option.value}
      position="relative"
      className={{
        [styles.item_isActive]: isActive,
        [styles.item_isAll]: option.type === 'all',
      }}
      data-automation={`item-depth-${depth}`}
    >
      <NavLink
        data-automation={testId}
        data-testid={testId}
        location={{
          pathname: path,
          query: option.refineParams,
        }}
        role="checkbox"
        aria-checked={isActive}
        aria-label={`${option.label} ${
          option.displayCount ? t('jobs', { count: option.displayCount }) : ''
        }`}
        className={styles.link}
        rel={
          !shouldFollow && (!option.count || hasExternalJobs)
            ? 'nofollow'
            : undefined
        }
        onClick={() => {
          setIsActive(!isActive);
        }}
      >
        <Box component="span" display="flex" justifyContent="spaceBetween">
          <Text
            weight={isActive ? 'strong' : undefined}
            baseline={false}
            data={{ automation: 'item-text' }}
          >
            {option.type === 'all'
              ? t('All {classification}', { classification: option.label })
              : option.label}
          </Text>
          <Box
            component="span"
            paddingLeft={{ mobile: 'xsmall', tablet: 'small' }}
            className={styles.count}
          >
            {option.displayCount ? (
              <Text
                weight={isActive ? 'strong' : undefined}
                align="right"
                data={{ automation: 'item-count' }}
                baseline={false}
              >
                {option.displayCount}
              </Text>
            ) : null}
          </Box>
        </Box>
      </NavLink>
      {!option.children || isEmpty(option.children) ? null : (
        <RefineChecklist
          path={path}
          filter={filter}
          options={option.children}
          depth={depth + 1}
        />
      )}
    </Box>
  );
};

const RefineChecklist = ({
  options = [],
  depth = 0,
  filter = () => true,
  path,
}: RenderChecklistProps) => {
  const filteredOptions = options.filter(filter);

  return filteredOptions.length > 0 ? (
    <Box
      component="ul"
      background={depth === 0 ? 'surface' : 'neutralLight'}
      paddingY={depth === 0 ? 'xxsmall' : undefined}
      className={depth > 0 ? styles.root_isNested : undefined}
    >
      {filteredOptions.map((option, i) => (
        <ChecklistItem
          key={i}
          option={option}
          depth={depth}
          path={path}
          filter={filter}
        />
      ))}
    </Box>
  ) : null;
};

export default RefineChecklist;
