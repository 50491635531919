/**
 * Some files in this folder were brought over from @seek/seek-refine-job-search,
 * which eventually will be merged into Chalice, and that package will be archived.
 *
 * For the interim period (while both graph and old classifications are used)
 *  this module is responsible for classifications only.
 */
import type { Zone } from '@seek/audience-zones';
import type { Language, Locale } from '@seek/melways-sites';
import taxonomy from '@seek/taxonomy-seekanz';
import lowercaseKeys from 'lowercase-keys';

import { classifications } from 'src/config/classifications';
import { dateRanges } from 'src/config/dateRanges';
import { distances } from 'src/config/distances';
import { workTypes } from 'src/config/workTypes';
import type { Counts } from 'src/store/search/types';

import { workArrangements } from '../../config/workArrangements.ts';

import canSaveSearch from './lib/can-save-search';
import classificationCountsNeedUpdating from './lib/classification-counts-need-updating/classification-counts-need-updating';
import resetSearchParams from './lib/resetSearchParams';
import stepCounts from './lib/step-counts';
import clearClassification from './refinements/clear/classification';
import clearCompany from './refinements/clear/company';
import refineSortMode from './refinements/sortmode';
import jobViewModel from './view-models/job/job';
// @ts-expect-error: non-ts file
import searchResultsViewModel from './view-models/search-results/search-results';
import sortModeViewModel from './view-models/sort-mode/sort-mode';

export const getClassifications = (zone: Zone, languageCode: Language) =>
  classifications[zone][languageCode]!;

export const getClassificationName = (
  id: number,
  zone: Zone,
  languageCode: Language,
) => {
  const classificationRecords = getClassifications(zone, languageCode);
  for (const currClassification of classificationRecords) {
    if (currClassification.id === id) {
      return currClassification.description;
    }

    for (const currSubClassification of currClassification.subClassifications) {
      if (currSubClassification.id === id) {
        return currSubClassification.description;
      }
    }
  }

  return '';
};

export const getDateRanges = (zone: Zone, languageCode: Language) =>
  dateRanges[zone][languageCode]!;

export const getDistances = (zone: Zone, languageCode: Language) =>
  distances[zone][languageCode]!;

export const getWorkTypes = (zone: Zone, languageCode: Language) =>
  workTypes[zone][languageCode]!;

export const getWorkArrangements = (locales: Locale) =>
  workArrangements[locales]!;

export const getPriorityClassifications = (
  zone: Zone,
  languageCode: Language,
) =>
  getAllClassifications(zone, languageCode).filter(
    ({ searchQuery: { classification: classificationId } }) => {
      const priorityClassificationIdsRegex = new RegExp(
        /^(1200|6123|1210|1211|6362)$/,
      );
      return priorityClassificationIdsRegex.test(classificationId);
    },
  );

export const getAllClassifications = (zone: Zone, languageCode: Language) =>
  getClassifications(zone, languageCode).map(({ description, id }) => ({
    description,
    searchQuery: { classification: String(id) },
  }));

const refine = ({
  paramsUnsanitised = {},
  newCounts,
  lastParamsUnsanitised = {},
  lastCounts,
}: {
  paramsUnsanitised: any;
  newCounts?: Counts;
  lastParamsUnsanitised?: any;
  lastCounts?: Counts;
  zone: Zone;
  languageCode: Language;
}) => {
  const params = lowercaseKeys(paramsUnsanitised);
  const lastParams = lowercaseKeys(lastParamsUnsanitised);

  const counts = stepCounts(params, newCounts, lastParams, lastCounts);
  const newParams = resetSearchParams(params);

  const clearRefinements = {
    clear: {
      ...clearClassification(params),
      ...clearCompany(params),
    },
  };

  return {
    counts,
    ...refineSortMode(taxonomy.sortModes, newParams),
    ...clearRefinements,
  };
};

export default refine;

export {
  canSaveSearch,
  classificationCountsNeedUpdating,
  jobViewModel,
  searchResultsViewModel,
  sortModeViewModel,
};
