import type { SearchParams } from '@seek/chalice-types';
import {
  Box,
  Checkbox,
  CheckboxStandalone,
  Inline,
  Stack,
  Text,
  Tiles,
  useResponsiveValue,
} from 'braid-design-system';
import isEmpty from 'lodash/isEmpty';

import { Link } from 'src/components/NavLink/NavLink';
import type { RefinementClassification } from 'src/modules/refine-job-search/lib/types';

import { useSecondaryFilterContext } from '../../../context/SecondaryFilterContext';

import * as styles from './RefineChecklist.css';

interface ChecklistItemProps {
  option: RefinementClassification;
  path: string;
}

const classificationActiveLabel = (
  classification: ChecklistItemProps['option'],
) => {
  if (!classification.children || classification.children.length === 0) {
    return { label: classification.label, hasActiveLabel: false };
  }

  let classificationLabel = classification.label;

  const allTypeIsActive = classification.children.find(
    (c) => c.type === 'all',
  )?.isActive;

  if (allTypeIsActive) {
    classificationLabel = `${classificationLabel} • All`;
  }

  const subclassificationActive = classification.children.filter(
    (c) => c.type === 'subclassification' && c.isActive,
  );

  if (subclassificationActive.length === 1) {
    classificationLabel = `${classificationLabel} • ${subclassificationActive[0].label}`;
  }

  if (subclassificationActive.length > 1) {
    classificationLabel = `${classificationLabel} • ${subclassificationActive.length}`;
  }

  return {
    classificationLabel,
    hasActiveLabel: allTypeIsActive || subclassificationActive.length > 0,
  };
};

export const ChecklistItem = ({ option, path }: ChecklistItemProps) => {
  const {
    activeDropdownField,
    setActiveDropdownField,
    setFilterSelection,
    ROBOTS_TAG,
  } = useSecondaryFilterContext();
  const isMobile =
    useResponsiveValue()({
      mobile: true,
      tablet: false,
    }) || false;

  const { classificationLabel } = classificationActiveLabel(option);

  return (
    <Box
      key={option.value}
      position="relative"
      className={{
        [styles.item_isActive]: option.isActive,
        [styles.item_isAll]: option.type === 'all',
      }}
      data-automation={`${option.label}-classificationLabel`}
    >
      <Stack space="xxsmall">
        <Box display="flex">
          <CheckboxStandalone
            id={option.value}
            checked={option.isCheckboxActive || false}
            onChange={() => {
              if (activeDropdownField === option.label) {
                setActiveDropdownField(null);
              }

              if (activeDropdownField !== option.label) {
                setActiveDropdownField(option.label);
              }

              setFilterSelection(option.refineParams || {});
            }}
            aria-label={option.value}
          />
          <Box paddingLeft="small" flexGrow={1}>
            <Inline space="small" alignY="center">
              <Text
                weight={option.isCheckboxActive ? 'strong' : undefined}
                baseline={false}
              >
                <Link
                  rel={ROBOTS_TAG}
                  location={{ pathname: path, query: option.refineParams }}
                  disabledLinkNavigate
                  onClick={() => {
                    if (activeDropdownField === option.label) {
                      setActiveDropdownField(null);
                    }

                    if (activeDropdownField !== option.label) {
                      setActiveDropdownField(option.label);
                    }

                    if (!option.isCheckboxActive) {
                      setFilterSelection(option.refineParams || {});
                    }
                  }}
                >
                  {activeDropdownField === option.label
                    ? option.label
                    : classificationLabel || option.label}
                </Link>
              </Text>
            </Inline>
          </Box>
        </Box>

        {!option.children || isEmpty(option.children) ? null : (
          <ClassificationDropDown
            option={option}
            activeDropdownField={activeDropdownField}
            setFilterSelection={setFilterSelection}
            isMobile={isMobile}
          />
        )}
      </Stack>
    </Box>
  );
};

const ClassificationDropDown = ({
  option,
  activeDropdownField,
  setFilterSelection,
  isMobile,
}: {
  option: RefinementClassification;
  activeDropdownField: string | null;
  setFilterSelection: React.Dispatch<React.SetStateAction<SearchParams>>;
  isMobile: boolean;
}) => {
  const { ROBOTS_TAG } = useSecondaryFilterContext();

  return (
    <Box
      display={activeDropdownField === option.label ? 'flex' : 'none'}
      position="relative"
      zIndex="dropdown"
    >
      <Box
        position="absolute"
        background="surface"
        borderRadius="standard"
        boxShadow="medium"
        padding="xsmall"
        width="full"
        className={styles.dropDown}
      >
        <Tiles columns={1} space="large">
          {option.children?.map(
            ({ label, isActive, refineParams, type }: any) => (
              <Checkbox
                key={label}
                size={isMobile ? 'small' : 'standard'}
                id={label}
                checked={isActive || false}
                onChange={() => {
                  setFilterSelection(refineParams || {});
                }}
                label={
                  <Link
                    rel={ROBOTS_TAG}
                    location={{ pathname: '/jobs', query: refineParams }}
                  >
                    {type === 'all' ? 'All' : label}
                  </Link>
                }
              />
            ),
          )}
        </Tiles>
      </Box>
    </Box>
  );
};
