import type { Zone } from '@seek/audience-zones';

import type { TypedAction } from '../types';

import {
  UPDATE_AFTER_AUTH,
  type ExperimentsState,
  type TFeatureExperiments,
  type TSeekExperiments,
  type UpdateExperimentsAction,
} from './types';

const REMOTE_SEARCH_FILTER_GROUP_SALT = 'REMOTE_SEARCH_FILTER';
const SERP_JOB_CARD_INFO_DENSITY_1_GROUP_SALT = 'SERP_JOB_CARD_INFO_DENSITY_1';
const DYNAMIC_PILLS_AND_SECONDARY_FILTERS_GROUP_SALT =
  'DYNAMIC_PILLS_AND_SECONDARY_FILTERS_GROUP_SALT';
const MATCHED_QUALITIES_GROUP_SALT = 'MATCHED_QUALITIES';
const HOMEPAGE_MOBILE_ACTIVITY_REDESIGN_GROUP_SALT =
  'HOMEPAGE_MOBILE_ACTIVITY_REDESIGN';
const DYNAMIC_PILLS_V2_GROUP_SALT = 'DYNAMIC_PILLS_V2';

/**
 * This is the experiment will be added to `store: experiments` on the Server Side for all users.
 */
export const FEATURE_EXPERIMENTS: TFeatureExperiments = {
  remoteSearchFilter: {
    name: 'remote_search_filter',
    percentage: 0, // 0:0:0 => control/variant/outsider
    enableTrackingForZeroPercent: false,
    num: 4,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-1',
      'asia-3',
      'asia-4',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
    groupSalt: REMOTE_SEARCH_FILTER_GROUP_SALT,
  },
  behaviouralCuesFilters: {
    name: 'behavioural_cues_filters',
    percentage: 0, // 0:0:0 => control/variant/outsider
    enableTrackingForZeroPercent: false,
    num: 61,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-1',
      'asia-3',
      'asia-4',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
    groupSalt: REMOTE_SEARCH_FILTER_GROUP_SALT,
  },
  secondaryFilters: {
    name: 'secondary_filters',
    /*
      Note: The control split must be strictly lower than dynamic pills experiment to ensure dynamic pills is only
      enabled when secondary filters is enabled.
      See more information same group splitting here: https://myseek.atlassian.net/wiki/spaces/AJDT/pages/2669056503/AB+Test+-+Group+Splitting
      Also note that to ensure group splits are consistent across both experiment, the same salt must be used (DYNAMIC_PILLS_AND_SECONDARY_FILTERS_GROUP_SALT).
    */
    percentage: 0, // 0:0:0 => control/variant/outsider
    enableTrackingForZeroPercent: false,
    num: 5,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
    groupSalt: DYNAMIC_PILLS_AND_SECONDARY_FILTERS_GROUP_SALT,
  },
  dynamicPills: {
    name: 'dynamic_pills',
    /*
      Note: The control split must be strictly higher than dynamic pills experiment to ensure dynamic pills is only
      enabled when secondary filters is enabled.
      See more information same group splitting here: https://myseek.atlassian.net/wiki/spaces/AJDT/pages/2669056503/AB+Test+-+Group+Splitting
      Also note that to ensure group splits are consistent across both experiment, the same salt must be used (DYNAMIC_PILLS_AND_SECONDARY_FILTERS_GROUP_SALT).
    */
    percentage: 0, // 0:0:0 => control/variant/outsider
    enableTrackingForZeroPercent: false,
    num: 6,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
    groupSalt: DYNAMIC_PILLS_AND_SECONDARY_FILTERS_GROUP_SALT,
  },
  // This hide job card summary(teaser)
  serpJobCardInfoDensity1: {
    name: 'serp_job_card_info_density_1',
    percentage: 0, // 0:0:0 => control/variant/outsider
    enableTrackingForZeroPercent: false,
    num: 60,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-1',
      'asia-3',
      'asia-4',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
    groupSalt: SERP_JOB_CARD_INFO_DENSITY_1_GROUP_SALT,
  },
  serpGoogleOneTap: {
    name: 'serp_google_one_tap',
    percentage: 50,
    enableTrackingForZeroPercent: true,
    num: 6,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-1',
      'asia-3',
      'asia-4',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
  },
  matchedQualities: {
    name: 'matched_qualities',
    percentage: 50,
    enableTrackingForZeroPercent: false,
    num: 72,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-1',
      'asia-3',
      'asia-4',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
    groupSalt: MATCHED_QUALITIES_GROUP_SALT,
  },
};

/**
 * This is the experiment will be added to `store: experiments` after user authentication on Client Side.
 *
 * For logged out users, this experiment will not be available.
 */
export const FEATURE_EXPERIMENTS_AFTER_AUTH: TFeatureExperiments = {
  homepageJdvDrawer: {
    name: 'jdv_drawer',
    percentage: 50, // 50:50:0 => control/variant/outsider
    enableTrackingForZeroPercent: true,
    num: 4,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-1',
      'asia-3',
      'asia-4',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
  },
  homepageMobileWebRedesign: {
    name: 'homepage_mobile_web_redesign',
    percentage: 70, // 30:70:0 => control/variant/outsider
    enableTrackingForZeroPercent: true,
    num: 66,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-1',
      'asia-3',
      'asia-4',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
    groupSalt: HOMEPAGE_MOBILE_ACTIVITY_REDESIGN_GROUP_SALT,
  },
  homepageNoCompanyCarousel: {
    name: 'homepage_no_company_carousel',
    percentage: 35, // 35:35:30 => control/variant/outsider
    enableTrackingForZeroPercent: true,
    num: 67,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-1',
      'asia-3',
      'asia-4',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
    groupSalt: HOMEPAGE_MOBILE_ACTIVITY_REDESIGN_GROUP_SALT,
  },
  dynamicPillsV2: {
    name: 'dynamic_pills_v2',
    percentage: 0, // 0 for not till we start experiment
    enableTrackingForZeroPercent: false, // turn off for now until we start experiment
    num: 64,
    targetZones: new Set<Zone>([
      'anz-1',
      'anz-2',
      'asia-5',
      'asia-6',
      'asia-7',
    ]),
    groupSalt: DYNAMIC_PILLS_V2_GROUP_SALT,
  },
};

export const experimentsAfterAuthIntialised = (
  experiments: TSeekExperiments,
): UpdateExperimentsAction => ({
  type: UPDATE_AFTER_AUTH,
  payload: experiments,
});

export const initialState: ExperimentsState = {};

export const reducer = (state = {}, action: TypedAction) => {
  switch (action.type) {
    case UPDATE_AFTER_AUTH: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};
