import type { AxiosError } from 'axios';
import { cause, type VError } from 'verror';

export const findRootCause = <E = AxiosError | typeof VError | Error>(
  error: E,
): E => {
  let result = error;
  // @ts-expect-error fix me
  while (cause(result)) {
    // @ts-expect-error fix me
    result = cause(result);
  }

  return result;
};
