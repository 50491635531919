import type { SearchParams } from '@seek/chalice-types';

export const getRefineSearchQuery = (query: SearchParams): SearchParams => {
  // Clone a copy to avoid mutating the original query
  const refineSearchQuery = Object.assign({}, query);
  // Always default to first page when refining search
  // Hence we are removing the page query parameter when exist
  if (refineSearchQuery?.page) {
    delete refineSearchQuery.page;
  }
  return refineSearchQuery;
};
