export const salaryRangeParam = ({
  salaryRangeSelected,
  salaryType,
  value,
}: {
  salaryRangeSelected?: string;
  salaryType: string;
  value: string;
}): string => {
  const [salaryFrom, salaryTo] = salaryRangeSelected?.split('-') || [];
  return salaryType === 'salaryFrom'
    ? `${value}-${salaryTo || ''}`
    : `${salaryFrom || '0'}-${value}`;
};
