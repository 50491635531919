import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { isCancelled } from '@seek/ca-http-client';
import type { UrlLocation } from '@seek/chalice-types';
import { parse as parseCookies } from 'cookie';
import mapKeys from 'lodash/fp/mapKeys';
import get from 'lodash/get';
import type { RouteConfig } from 'react-router-config';
// @ts-expect-error: non-ts file
import { trigger } from 'redial';

import getCountryFromZone from 'src/config/utils/getCountryFromZone';
import type { AnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { removeUserCookie, userCookieExists } from 'src/modules/client-context';
import {
  isGraphQLRateLimitedError,
  isWAFForbiddenError,
} from 'src/modules/graphql';
import { logger } from 'src/modules/logger';
import {
  endTransaction,
  startTransaction,
  // @ts-expect-error: non-ts file
} from 'src/modules/redux-batched-subscribe-transaction';
import type createStore from 'src/store/createStore';
import { getTransaction } from 'src/store/transactions';
import type { TypedDispatch, TypedGetState } from 'src/store/types';
import type { RedialLocals } from 'src/types/RedialLocals';

// Used to calculate 'routeEnter' values for each hook:
let prevRouteHandlers: Array<RouteConfig['component']> = [];

export const fetchDataForRoutes = async ({
  analyticsFacade,
  matchedRoutes,
  location,
  store,
  dispatch,
  getState,
  apolloClient,
  waitForAuth,
  visitorId,
}: {
  analyticsFacade: AnalyticsFacade;
  matchedRoutes: RouteConfig[];
  location: UrlLocation;
  store: ReturnType<typeof createStore>;
  dispatch: TypedDispatch;
  getState: TypedGetState;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  waitForAuth: () => Promise<boolean>;
  visitorId: string;
}) => {
  const authenticated = await waitForAuth();

  // Keep a local copy of the previous route handlers array.
  // This is so the 'prevRouteHandlers' array can be safely updated mid-lifecycle.
  const prevRouteHandlersCopy = [...prevRouteHandlers];

  const lowercasedQuery = mapKeys((k) => k.toLowerCase(), location.query);

  // Restore data from state
  const {
    appConfig: { brand, language: languageCode, zone, locale },
  } = getState();
  const country = getCountryFromZone(zone);

  const getLocals = (routeHandler: RouteConfig['component']): RedialLocals => ({
    analyticsFacade,
    apolloClient,
    authenticated,
    brand,
    cookies: parseCookies(window.document.cookie),
    country,
    store,
    dispatch,
    getState,
    languageCode,
    locale,
    path: location.pathname,
    query: lowercasedQuery,
    referrer: window.document.referrer,
    routeEnter: prevRouteHandlersCopy.indexOf(routeHandler) === -1,
    state: get(location, 'state', {}),
    userAgent: window.navigator.userAgent,
    visitorId,
    zone,
  });

  const routeHandlers = matchedRoutes.map((route) =>
    get(route, 'component', undefined),
  );
  prevRouteHandlers = routeHandlers;

  const makeTrigger = (name: string) => () =>
    trigger(name, routeHandlers, getLocals);

  let triggerFetch = () => Promise.resolve();
  let triggerSeo = () => Promise.resolve();

  if (window.SEEK_REDUX_DATA) {
    delete window.SEEK_REDUX_DATA;
  } else {
    triggerFetch = makeTrigger('fetch');
    triggerSeo = makeTrigger('seo');
  }

  const triggerFirst = makeTrigger('first');
  const triggerAuthenticated = makeTrigger('authenticated');
  const triggerDefer = makeTrigger('defer');
  const triggerPageLoad = makeTrigger('pageLoad');

  const triggerAuthFlow = () =>
    triggerAuthenticated().catch((e: Error) => {
      logger.error(
        { err: e, input: { status: get(e, 'response.status') } },
        'Authenticated trigger failed with an error',
      );
    });

  const triggerServerFlow = () => triggerFetch().then(triggerSeo);

  const routeChangeTransaction = getTransaction('routeChange');
  dispatch(startTransaction(routeChangeTransaction));

  return Promise.all([
    triggerFirst(),
    authenticated && triggerAuthFlow(),
    triggerServerFlow(),
    triggerDefer(),
  ])
    .catch((err) => {
      if (isWAFForbiddenError(err)) {
        return logger.error(err, 'Error: Forbidden.');
      }
      if (isGraphQLRateLimitedError(err)) {
        return logger.error(err, 'Error: Too many requests.');
      }
      if (isCancelled(err)) {
        logger.warn(err, 'Cancelled AuthFlow');
      }
      logger.warn(err, 'Error AuthFlow');
      throw err;
    })
    .then(triggerPageLoad)
    .then(() => {
      if (!authenticated && userCookieExists()) {
        removeUserCookie();
      }
    })
    .then(() => dispatch(endTransaction(routeChangeTransaction)));
};
