import gql from 'graphql-tag';

export const GET_BANNER = gql`
  query GetBanner(
    $placement: BannerPlacement!
    $country: String!
    $visitorId: UUID
    $locale: Locale!
    $zone: Zone
    $loggedIn: String
    $candidateId: String
    $roleId: String
    $keywords: String
    $classification: String
    $subclassification: String
    $where: String
    $workType: String
    $salaryType: String
    $salaryRange: String
  ) {
    banner(
      placement: $placement
      country: $country
      visitorId: $visitorId
      locale: $locale
      zone: $zone
      loggedIn: $loggedIn
      candidateId: $candidateId
      roleId: $roleId
      keywords: $keywords
      classification: $classification
      subclassification: $subclassification
      where: $where
      workType: $workType
      salaryType: $salaryType
      salaryRange: $salaryRange
    ) {
      id
      analytics
      template {
        id
        image {
          url
          urlDark
        }
        link {
          text
          url
          style {
            color
            colorDark
          }
        }
        heading {
          text
          style {
            color
            colorDark
          }
        }
        style {
          color
          colorDark
        }
        items {
          id
          text
          href
        }
      }
    }
  }
`;
