import { Box, Text } from 'braid-design-system';

import * as styles from './Status.css';

export interface StatusProps {
  label: string;
  isActive: boolean;
  isSelected: boolean;
}

export const Status = ({ label, isActive, isSelected }: StatusProps) => (
  <Box
    className={styles.standardStatus}
    background={isActive || isSelected ? 'positiveLight' : 'brand'}
    borderRadius="standard"
  >
    <Text size="xsmall" weight="strong" tone="positive" baseline={false}>
      {label}
    </Text>
  </Box>
);
