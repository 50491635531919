export const END_TRANSACTION = 'END_TRANSACTION';
export const START_TRANSACTION = 'START_TRANSACTION';

export function startTransaction(transaction) {
  return {
    type: START_TRANSACTION,
    meta: {
      transaction,
    },
  };
}

export function endTransaction(transaction) {
  return {
    type: END_TRANSACTION,
    meta: {
      transaction,
    },
  };
}
