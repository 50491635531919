import { useTranslations } from '@vocab/react';
import { useSelector } from 'react-redux';

import { useAppConfig } from 'src/config/appConfig';
import { selectPoeaCountryPicker } from 'src/store/selectors';

import type {
  ReplaceableKeyValues,
  TitleTypeValues,
} from '../../../../server/utils/titleOverride/type';
import { useReplaceableKeyValues } from '../../replaceableKeyValues';
import { useSearchParams } from '../search-params';
import { generateTranslationTypeKey } from '../utils';

import translations from './.vocab';

export const useSearchResultsHeadTitle = (): string => {
  const { locale } = useAppConfig();
  const { t } = useTranslations(translations);
  const isPoeaCountryPicker = useSelector(selectPoeaCountryPicker);
  const {
    country,
    month,
    year,
    brand,
    classDescription,
    commaFormattedClassDescription,
    jobCount,
    keywords,
    locationDescription,
    shortLocationName,
    workArrangementDescription,
    workTypeDescription,
    pageNumberText,
    fallBackTitle,
  } = useReplaceableKeyValues({ mode: 'title' });
  const searchParams = useSearchParams();

  const replaceableKeyValues: ReplaceableKeyValues = {
    brand,
    country,
    classDescription,
    commaFormattedClassDescription,
    fallBackTitle,
    keywords,
    locationDescription,
    month,
    workTypeDescription,
    workArrangementDescription,
    year,
    pageNumberText,
    jobCount: jobCount ? jobCount.toString() : '',
    shortLocationName: shortLocationName || locationDescription, // Fallback to locationDescription if shortLocationName is not available
  };

  const titleTypes: TitleTypeValues = {
    classification:
      // AU have special title for subclassification
      locale === 'en-AU' && Boolean(searchParams?.subclassification)
        ? t('classification-subclassification-title', replaceableKeyValues)
        : t('classification-title', replaceableKeyValues),
    'classification-company': t(
      'classification-company-title',
      replaceableKeyValues,
    ),
    'classification-company-location-workarrangement': t(
      'classification-company-location-workarrangement-title',
      replaceableKeyValues,
    ),
    'classification-company-location-workarrangement-worktype': t(
      'classification-company-location-workarrangement-worktype-title',
      replaceableKeyValues,
    ),
    'classification-company-workarrangement': t(
      'classification-company-workarrangement-title',
      replaceableKeyValues,
    ),
    'classification-company-workarrangement-worktype': t(
      'classification-company-workarrangement-worktype-title',
      replaceableKeyValues,
    ),
    'classification-company-worktype': t(
      'classification-company-worktype-title',
      replaceableKeyValues,
    ),
    'classification-keywords':
      // NZ have special title for subclassification
      locale === 'en-NZ' && Boolean(searchParams?.subclassification)
        ? t(
            'classification-keywords-subclassification-title',
            replaceableKeyValues,
          )
        : t('classification-keywords-title', replaceableKeyValues),
    'classification-keywords-location': t(
      'classification-keywords-location-title',
      replaceableKeyValues,
    ),
    'classification-keywords-location-workarrangement': t(
      'classification-keywords-location-workarrangement-title',
      replaceableKeyValues,
    ),
    'classification-keywords-location-workarrangement-worktype': t(
      'classification-keywords-location-workarrangement-worktype-title',
      replaceableKeyValues,
    ),
    'classification-keywords-location-worktype': t(
      'classification-keywords-location-worktype-title',
      replaceableKeyValues,
    ),

    'classification-keywords-workarrangement': t(
      'classification-keywords-workarrangement-title',
      replaceableKeyValues,
    ),
    'classification-keywords-workarrangement-worktype': t(
      'classification-keywords-workarrangement-worktype-title',
      replaceableKeyValues,
    ),
    'classification-location': t(
      'classification-location-title',
      replaceableKeyValues,
    ),
    'classification-location-workarrangement': t(
      'classification-location-workarrangement-title',
      replaceableKeyValues,
    ),
    'classification-location-workarrangement-worktype': t(
      'classification-location-workarrangement-worktype-title',
      replaceableKeyValues,
    ),
    'classification-location-worktype': t(
      'classification-location-worktype-title',
      replaceableKeyValues,
    ),
    'classification-workarrangement': t(
      'classification-workarrangement-title',
      replaceableKeyValues,
    ),
    'classification-workarrangement-worktype': t(
      'classification-workarrangement-worktype-title',
      replaceableKeyValues,
    ),
    'classification-worktype':
      // NZ have special title for subclassification
      locale === 'en-NZ' && Boolean(searchParams?.subclassification)
        ? t(
            'classification-subclassification-worktype-title',
            replaceableKeyValues,
          )
        : t('classification-worktype-title', replaceableKeyValues),
    company: t('company-title', replaceableKeyValues),
    'company-location': t('company-location-title', replaceableKeyValues),
    'company-location-workarrangement': t(
      'company-location-workarrangement-title',
      replaceableKeyValues,
    ),
    'company-location-workarrangement-worktype': t(
      'company-location-workarrangement-worktype-title',
      replaceableKeyValues,
    ),
    'company-location-worktype': t(
      'company-location-worktype-title',
      replaceableKeyValues,
    ),
    'company-workarrangement': t(
      'company-workarrangement-title',
      replaceableKeyValues,
    ),
    'company-workarrangement-worktype': t(
      'company-workarrangement-worktype-title',
      replaceableKeyValues,
    ),
    'company-worktype': t('company-worktype-title', replaceableKeyValues),
    keywords: t('keywords-title', replaceableKeyValues),
    'keywords-location': t('keywords-location-title', replaceableKeyValues),
    'keywords-location-workarrangement': t(
      'keywords-location-workarrangement-title',
      replaceableKeyValues,
    ),
    'keywords-location-workarrangement-worktype': t(
      'keywords-location-workarrangement-worktype-title',
      replaceableKeyValues,
    ),
    'keywords-location-worktype': t(
      'keywords-location-worktype-title',
      replaceableKeyValues,
    ),
    'keywords-workarrangement': t(
      'keywords-workarrangement-title',
      replaceableKeyValues,
    ),
    'keywords-workarrangement-worktype': t(
      'keywords-workarrangement-worktype-title',
      replaceableKeyValues,
    ),
    'keywords-worktype': t('keywords-worktype-title', replaceableKeyValues),
    location: t('location-title', replaceableKeyValues),
    'location-workarrangement': t(
      'location-workarrangement-title',
      replaceableKeyValues,
    ),
    'location-workarrangement-worktype': t(
      'location-workarrangement-worktype-title',
      replaceableKeyValues,
    ),
    'location-worktype': t('location-worktype-title', replaceableKeyValues),
    workarrangement: t('workarrangement-title', replaceableKeyValues),
    'workarrangement-worktype': t(
      'workarrangement-worktype-title',
      replaceableKeyValues,
    ),
    worktype: t('worktype-title', replaceableKeyValues),
    'poea-keyword': t('poea-keyword-title', replaceableKeyValues),
    'poea-worktype': t('poea-worktype-title', replaceableKeyValues),
  };

  const FALLBACK_TITLE_KEY = 'fallBackTitle';

  const key: string = generateTranslationTypeKey({
    searchParams,
    classDescription,
    fallbackKey: FALLBACK_TITLE_KEY,
    isPoeaCountryPicker,
  });

  return key === FALLBACK_TITLE_KEY
    ? fallBackTitle
    : titleTypes[key as keyof typeof titleTypes] || fallBackTitle;
};
