import type { JobDetailsTrackingInput } from 'src/graphql/graphql';

export const mapToJobDetailsTrackingInput = ({
  jobDetailsViewedCorrelationId = '',
  sessionId,
}: {
  jobDetailsViewedCorrelationId?: string;
  sessionId: string;
}): JobDetailsTrackingInput => ({
  channel: 'WEB',
  jobDetailsViewedCorrelationId,
  sessionId,
});
