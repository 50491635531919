import { useTranslations } from '@vocab/react';
import { List, Stack, Text } from 'braid-design-system';

import { useTimedBanner } from 'src/config/appConfig';
import { isTimedComponentValid } from 'src/utils/timedComponent/timedComponentValidator';

import { GlobalNotificationBannerSkeleton } from '../GlobalNotificationBannerSkeleton/GlobalNotificationBannerSkeleton';

import translations from './.vocab';

export const CookieConsentBanner = () => {
  const { t } = useTranslations(translations);
  const cookieConsent = useTimedBanner('cookieConsent');

  return isTimedComponentValid(cookieConsent?.displayDates) ? (
    <GlobalNotificationBannerSkeleton
      dataAutomation="cookieConsent"
      tone="info"
      localStorageKey="pPB"
    >
      <Stack space="small">
        <>
          {t('By continuing to use our platform...', {
            Text: (children) =>
              children?.length !== 0 ? (
                <Text size="small">{children}</Text>
              ) : null,
            List: (children: any[]) =>
              children?.length !== 0 ? (
                <List space="xsmall">
                  {children.map((textList) => textList)}
                </List>
              ) : null,
            TextList: (children) => <Text size="small">{children}</Text>,
          })}
        </>
      </Stack>
    </GlobalNotificationBannerSkeleton>
  ) : null;
};
