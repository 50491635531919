import type { GetLoggedOutRecommendationsQuery } from 'src/graphql/graphql';

export const mockLoggedOutRecommendations: GetLoggedOutRecommendationsQuery = {
  loggedOutRecommendedJobs: {
    globalJobs: [
      {
        solMetadata: {
          token: '0~fa506d01-7c7d-48a7-9989-9b86b492a1f5',
          jobId: '76806359',
          tags: {
            'recs:experimentGroup': '1',
            'recs:flight': '1',
            'recs:product': 'logged_out_home',
            'recs:recSysChannel': '',
          },
        },
        job: {
          advertiser: {
            name: 'Intro Recruitment Solutions',
          },
          id: '76806359',
          title: 'Accounts Payable Officer',
          products: {
            branding: {
              logo: {
                url: 'https://image-service-cdn.seek.com.au/1df99fbb0feb6f5036e00b5c68ec0b6d368dc066/f3c5292cec0e05e4272d9bf9146f390d366481d0',
              },
            },
          },
          location: {
            label: 'Milton, Brisbane QLD',
          },
          listedAt: {
            shortLabel: '2d ago',
          },
        },
      },
      {
        solMetadata: {
          token: '0~fa506d01-7c7d-48a7-9989-9b86b492a1f5',
          jobId: '76806596',
          tags: {
            'recs:experimentGroup': '1',
            'recs:flight': '1',
            'recs:product': 'logged_out_home',
            'recs:recSysChannel': '',
          },
        },
        job: {
          advertiser: {
            name: 'GOUGH RECRUITMENT - REAL ESTATE | PROPERTY | DEVELOPMENT | CONSTRUCTION',
          },
          id: '76806596',
          title: 'Assistant Accountant',
          products: {
            branding: null,
          },
          location: {
            label: 'Sydney NSW',
          },
          listedAt: {
            shortLabel: '2d ago',
          },
        },
      },
      {
        solMetadata: {
          token: '0~fa506d01-7c7d-48a7-9989-9b86b492a1f5',
          jobId: '76830300',
          tags: {
            'recs:experimentGroup': '1',
            'recs:flight': '1',
            'recs:product': 'logged_out_home',
            'recs:recSysChannel': '',
          },
        },
        job: {
          advertiser: {
            name: 'Stenhouse Recruitment Services',
          },
          id: '76830300',
          title: 'Finance Officer - Accounts Payable',
          products: {
            branding: null,
          },
          location: {
            label: 'Brisbane QLD',
          },
          listedAt: {
            shortLabel: '1d ago',
          },
        },
      },
      {
        solMetadata: {
          token: '0~fa506d01-7c7d-48a7-9989-9b86b492a1f5',
          jobId: '76806358',
          tags: {
            'recs:experimentGroup': '1',
            'recs:flight': '1',
            'recs:product': 'logged_out_home',
            'recs:recSysChannel': '',
          },
        },
        job: {
          advertiser: {
            name: 'Cover Desk Philippines, Inc.',
          },
          id: '76806358',
          title: 'Business Analyst',
          products: {
            branding: {
              logo: {
                url: 'https://bx-branding-gateway.cloud.seek.com.au/2ce97134-d317-1165-9b50-38f87d260a25.1/serpLogo',
              },
            },
          },
          location: {
            label: 'Quezon City, Metro Manila',
          },
          listedAt: {
            shortLabel: '2d ago',
          },
        },
      },
      {
        solMetadata: {
          token: '0~fa506d01-7c7d-48a7-9989-9b86b492a1f5',
          jobId: '76806360',
          tags: {
            'recs:experimentGroup': '1',
            'recs:flight': '1',
            'recs:product': 'logged_out_home',
            'recs:recSysChannel': '',
          },
        },
        job: {
          advertiser: {
            name: 'A Company with a Really Long Name That Never Seems to End Inc.',
          },
          id: '76806360',
          title:
            'This is an example of a super long job title that goes on and on and on and on and on and on and on and on and on and on and on and on and on and on and on and on and on and on and on and on and on and on and on and on and on and',
          products: {
            branding: {
              logo: {
                url: 'https://bx-branding-gateway.cloud.seek.com.au/449e295d-8b89-4e0d-831c-714a8f9b2687.1/serpLogo',
              },
            },
          },
          location: {
            label:
              'A City That Has an Exceptionally Long Name Which Is Hard to Pronounce, State',
          },
          listedAt: {
            shortLabel: "A Few Weeks Ago, More or Less, I Can't Quite Remember",
          },
        },
      },
    ],
    solMetadata: {},
  },
};
