
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IkFubnVhbGx5IiwibWluaW11bSBzYWxhcnkiOiJtaW5pbXVtIHNhbGFyeSIsInBheWluZyI6InBheWluZyIsIk1pbmltdW0iOiJNaW5pbXVtIiwibWF4aW11bSBzYWxhcnkiOiJtYXhpbXVtIHNhbGFyeSIsInRvIjoidG8iLCJNYXhpbXVtIjoiTWF4aW11bSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IkFubnVhbGx5IiwibWluaW11bSBzYWxhcnkiOiJtaW5pbXVtIHNhbGFyeSIsInBheWluZyI6InBheWluZyIsIk1pbmltdW0iOiJNaW5pbXVtIiwibWF4aW11bSBzYWxhcnkiOiJtYXhpbXVtIHNhbGFyeSIsInRvIjoidG8iLCJNYXhpbXVtIjoiTWF4aW11bSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IkFubnVhbGx5IiwibWluaW11bSBzYWxhcnkiOiJtaW5pbXVtIHNhbGFyeSIsInBheWluZyI6InBheWluZyIsIk1pbmltdW0iOiJNaW5pbXVtIiwibWF4aW11bSBzYWxhcnkiOiJtYXhpbXVtIHNhbGFyeSIsInRvIjoidG8iLCJNYXhpbXVtIjoiTWF4aW11bSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IkFubnVhbGx5IiwibWluaW11bSBzYWxhcnkiOiJtaW5pbXVtIHNhbGFyeSIsInBheWluZyI6InBheWluZyIsIk1pbmltdW0iOiJNaW5pbXVtIiwibWF4aW11bSBzYWxhcnkiOiJtYXhpbXVtIHNhbGFyeSIsInRvIjoidG8iLCJNYXhpbXVtIjoiTWF4aW11bSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IkFubnVhbGx5IiwibWluaW11bSBzYWxhcnkiOiJtaW5pbXVtIHNhbGFyeSIsInBheWluZyI6InBheWluZyIsIk1pbmltdW0iOiJNaW5pbXVtIiwibWF4aW11bSBzYWxhcnkiOiJtYXhpbXVtIHNhbGFyeSIsInRvIjoidG8iLCJNYXhpbXVtIjoiTWF4aW11bSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IkFubnVhbGx5IiwibWluaW11bSBzYWxhcnkiOiJtaW5pbXVtIHNhbGFyeSIsInBheWluZyI6InBheWluZyIsIk1pbmltdW0iOiJNaW5pbXVtIiwibWF4aW11bSBzYWxhcnkiOiJtYXhpbXVtIHNhbGFyeSIsInRvIjoidG8iLCJNYXhpbXVtIjoiTWF4aW11bSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IkFubnVhbGx5IiwibWluaW11bSBzYWxhcnkiOiJtaW5pbXVtIHNhbGFyeSIsInBheWluZyI6InBheWluZyIsIk1pbmltdW0iOiJNaW5pbXVtIiwibWF4aW11bSBzYWxhcnkiOiJtYXhpbXVtIHNhbGFyeSIsInRvIjoidG8iLCJNYXhpbXVtIjoiTWF4aW11bSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IkFubnVhbGx5IiwibWluaW11bSBzYWxhcnkiOiJtaW5pbXVtIHNhbGFyeSIsInBheWluZyI6InBheWluZyIsIk1pbmltdW0iOiJNaW5pbXVtIiwibWF4aW11bSBzYWxhcnkiOiJtYXhpbXVtIHNhbGFyeSIsInRvIjoidG8iLCJNYXhpbXVtIjoiTWF4aW11bSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IkFubnVhbGx5IiwibWluaW11bSBzYWxhcnkiOiJtaW5pbXVtIHNhbGFyeSIsInBheWluZyI6InBheWluZyIsIk1pbmltdW0iOiJNaW5pbXVtIiwibWF4aW11bSBzYWxhcnkiOiJtYXhpbXVtIHNhbGFyeSIsInRvIjoidG8iLCJNYXhpbXVtIjoiTWF4aW11bSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IkFubnVhbGx5IiwibWluaW11bSBzYWxhcnkiOiJtaW5pbXVtIHNhbGFyeSIsInBheWluZyI6InBheWluZyIsIk1pbmltdW0iOiJNaW5pbXVtIiwibWF4aW11bSBzYWxhcnkiOiJtYXhpbXVtIHNhbGFyeSIsInRvIjoidG8iLCJNYXhpbXVtIjoiTWF4aW11bSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IkFubnVhbGx5IiwibWluaW11bSBzYWxhcnkiOiJtaW5pbXVtIHNhbGFyeSIsInBheWluZyI6InBheWluZyIsIk1pbmltdW0iOiJNaW5pbXVtIiwibWF4aW11bSBzYWxhcnkiOiJtYXhpbXVtIHNhbGFyeSIsInRvIjoidG8iLCJNYXhpbXVtIjoiTWF4aW11bSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IkFubnVhbGx5IiwibWluaW11bSBzYWxhcnkiOiJtaW5pbXVtIHNhbGFyeSIsInBheWluZyI6InBheWluZyIsIk1pbmltdW0iOiJNaW5pbXVtIiwibWF4aW11bSBzYWxhcnkiOiJtYXhpbXVtIHNhbGFyeSIsInRvIjoidG8iLCJNYXhpbXVtIjoiTWF4aW11bSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IkFubnVhbGx5IiwibWluaW11bSBzYWxhcnkiOiJtaW5pbXVtIHNhbGFyeSIsInBheWluZyI6InBheWluZyIsIk1pbmltdW0iOiJNaW5pbXVtIiwibWF4aW11bSBzYWxhcnkiOiJtYXhpbXVtIHNhbGFyeSIsInRvIjoidG8iLCJNYXhpbXVtIjoiTWF4aW11bSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IkFubnVhbGx5IiwibWluaW11bSBzYWxhcnkiOiJtaW5pbXVtIHNhbGFyeSIsInBheWluZyI6InBheWluZyIsIk1pbmltdW0iOiJNaW5pbXVtIiwibWF4aW11bSBzYWxhcnkiOiJtYXhpbXVtIHNhbGFyeSIsInRvIjoidG8iLCJNYXhpbXVtIjoiTWF4aW11bSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IkFubnVhbGx5IiwibWluaW11bSBzYWxhcnkiOiJtaW5pbXVtIHNhbGFyeSIsInBheWluZyI6InBheWluZyIsIk1pbmltdW0iOiJNaW5pbXVtIiwibWF4aW11bSBzYWxhcnkiOiJtYXhpbXVtIHNhbGFyeSIsInRvIjoidG8iLCJNYXhpbXVtIjoiTWF4aW11bSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IkFubnVhbGx5IiwibWluaW11bSBzYWxhcnkiOiJtaW5pbXVtIHNhbGFyeSIsInBheWluZyI6InBheWluZyIsIk1pbmltdW0iOiJNaW5pbXVtIiwibWF4aW11bSBzYWxhcnkiOiJtYXhpbXVtIHNhbGFyeSIsInRvIjoidG8iLCJNYXhpbXVtIjoiTWF4aW11bSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IkFubnVhbGx5IiwibWluaW11bSBzYWxhcnkiOiJtaW5pbXVtIHNhbGFyeSIsInBheWluZyI6InBheWluZyIsIk1pbmltdW0iOiJNaW5pbXVtIiwibWF4aW11bSBzYWxhcnkiOiJtYXhpbXVtIHNhbGFyeSIsInRvIjoidG8iLCJNYXhpbXVtIjoiTWF4aW11bSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IkFubnVhbGx5IiwibWluaW11bSBzYWxhcnkiOiJtaW5pbXVtIHNhbGFyeSIsInBheWluZyI6InBheWluZyIsIk1pbmltdW0iOiJNaW5pbXVtIiwibWF4aW11bSBzYWxhcnkiOiJtYXhpbXVtIHNhbGFyeSIsInRvIjoidG8iLCJNYXhpbXVtIjoiTWF4aW11bSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IlRhaHVuYW4iLCJtaW5pbXVtIHNhbGFyeSI6ImdhamkgbWluaW11bSIsInBheWluZyI6ImdhamkiLCJNaW5pbXVtIjoiTWluaW11bSIsIm1heGltdW0gc2FsYXJ5IjoiZ2FqaSBtYWtzaW11bSIsInRvIjoiaGluZ2dhIiwiTWF4aW11bSI6Ik1ha3NpbXVtIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IlRhaHVuYW4iLCJtaW5pbXVtIHNhbGFyeSI6ImdhamkgbWluaW11bSIsInBheWluZyI6ImdhamkiLCJNaW5pbXVtIjoiTWluaW11bSIsIm1heGltdW0gc2FsYXJ5IjoiZ2FqaSBtYWtzaW11bSIsInRvIjoiaGluZ2dhIiwiTWF4aW11bSI6Ik1ha3NpbXVtIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IuC4o%2BC4suC4ouC4m%2BC4tSIsIm1pbmltdW0gc2FsYXJ5Ijoi4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4LmA4Lij4Li04LmI4Lih4LiV4LmJ4LiZIiwicGF5aW5nIjoi4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZIiwiTWluaW11bSI6IuC4leC5iOC4s%2BC4quC4uOC4lCIsIm1heGltdW0gc2FsYXJ5Ijoi4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4Liq4Li54LiH4Liq4Li44LiUIiwidG8iOiLguJbguLbguIciLCJNYXhpbXVtIjoi4Liq4Li54LiH4Liq4Li44LiUIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IuC4o%2BC4suC4ouC4m%2BC4tSIsIm1pbmltdW0gc2FsYXJ5Ijoi4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4LmA4Lij4Li04LmI4Lih4LiV4LmJ4LiZIiwicGF5aW5nIjoi4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZIiwiTWluaW11bSI6IuC4leC5iOC4s%2BC4quC4uOC4lCIsIm1heGltdW0gc2FsYXJ5Ijoi4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4Liq4Li54LiH4Liq4Li44LiUIiwidG8iOiLguJbguLbguIciLCJNYXhpbXVtIjoi4Liq4Li54LiH4Liq4Li44LiUIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IlRhaHVuYW4iLCJtaW5pbXVtIHNhbGFyeSI6ImdhamkgbWluaW11bSIsInBheWluZyI6ImdhamkiLCJNaW5pbXVtIjoiTWluaW11bSIsIm1heGltdW0gc2FsYXJ5IjoiZ2FqaSBtYWtzaW11bSIsInRvIjoiaGluZ2dhIiwiTWF4aW11bSI6Ik1ha3NpbXVtIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IlRhaHVuYW4iLCJtaW5pbXVtIHNhbGFyeSI6ImdhamkgbWluaW11bSIsInBheWluZyI6ImdhamkiLCJNaW5pbXVtIjoiTWluaW11bSIsIm1heGltdW0gc2FsYXJ5IjoiZ2FqaSBtYWtzaW11bSIsInRvIjoiaGluZ2dhIiwiTWF4aW11bSI6Ik1ha3NpbXVtIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IuC4o%2BC4suC4ouC4m%2BC4tSIsIm1pbmltdW0gc2FsYXJ5Ijoi4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4LmA4Lij4Li04LmI4Lih4LiV4LmJ4LiZIiwicGF5aW5nIjoi4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZIiwiTWluaW11bSI6IuC4leC5iOC4s%2BC4quC4uOC4lCIsIm1heGltdW0gc2FsYXJ5Ijoi4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4Liq4Li54LiH4Liq4Li44LiUIiwidG8iOiLguJbguLbguIciLCJNYXhpbXVtIjoi4Liq4Li54LiH4Liq4Li44LiUIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IuC4o%2BC4suC4ouC4m%2BC4tSIsIm1pbmltdW0gc2FsYXJ5Ijoi4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4LmA4Lij4Li04LmI4Lih4LiV4LmJ4LiZIiwicGF5aW5nIjoi4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZIiwiTWluaW11bSI6IuC4leC5iOC4s%2BC4quC4uOC4lCIsIm1heGltdW0gc2FsYXJ5Ijoi4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZ4Liq4Li54LiH4Liq4Li44LiUIiwidG8iOiLguJbguLbguIciLCJNYXhpbXVtIjoi4Liq4Li54LiH4Liq4Li44LiUIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IlvhuqzguIHguLXguYnguIHguLXguYnHmseax5rEg8SDxIPGmsaaw73DvcO9XSIsIm1pbmltdW0gc2FsYXJ5IjoiW23MgsOsw6zguIHguLXguYnDrMOsbcyCx5rHmm3MgiDFocSDxIPGmsSDxIPFmcO9w71dIiwicGF5aW5nIjoiW8alxIPEg8O9w73DrMOs4LiB4Li14LmJxKNdIiwiTWluaW11bSI6IlvhuYLDrMOs4LiB4Li14LmJw6zDrG3Mgseax5ptzIJdIiwibWF4aW11bSBzYWxhcnkiOiJbbcyCxIPEg%2BqtlcOsw6xtzILHmseabcyCIMWhxIPEg8aaxIPEg8WZw73DvV0iLCJ0byI6Ilvhua%2FDtsO2XSIsIk1heGltdW0iOiJb4bmCxIPEg%2BqtlcOsw6xtzILHmseabcyCXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbm51YWxseSI6IlvhuqzguIHguLXguYnguIHguLXguYnHmseax5rEg8SDxIPGmsaaw73DvcO9XSIsIm1pbmltdW0gc2FsYXJ5IjoiW23MgsOsw6zguIHguLXguYnDrMOsbcyCx5rHmm3MgiDFocSDxIPGmsSDxIPFmcO9w71dIiwicGF5aW5nIjoiW8alxIPEg8O9w73DrMOs4LiB4Li14LmJxKNdIiwiTWluaW11bSI6IlvhuYLDrMOs4LiB4Li14LmJw6zDrG3Mgseax5ptzIJdIiwibWF4aW11bSBzYWxhcnkiOiJbbcyCxIPEg%2BqtlcOsw6xtzILHmseabcyCIMWhxIPEg8aaxIPEg8WZw73DvV0iLCJ0byI6Ilvhua%2FDtsO2XSIsIk1heGltdW0iOiJb4bmCxIPEg%2BqtlcOsw6xtzILHmseabcyCXSJ9!"
        )
      )
      });
  
      export { translations as default };
    