import type { SearchParams } from '@seek/chalice-types';
import isEqual from 'lodash/isEqual';
// @ts-expect-error non-ts file
import shallowDiff from 'shallow-diff';

import type { Diff } from '../types';

import onlyClassificationsChanged from './only-classifications-changed';

export default (lastSearchQuery: SearchParams, searchQuery: SearchParams) => {
  const diff: Diff = shallowDiff(lastSearchQuery, searchQuery);

  return (
    !onlyClassificationsChanged(diff) && !isEqual(lastSearchQuery, searchQuery)
  );
};
