import type { Diff } from '../types';

export default (diff: Diff) => {
  const isOneAdded =
    diff.added.length === 1 &&
    (diff.added.indexOf('classification') > -1 ||
      diff.added.indexOf('subclassification') > -1);
  const isOneUpdated =
    diff.updated.length === 1 &&
    (diff.updated.indexOf('classification') > -1 ||
      diff.updated.indexOf('subclassification') > -1);
  const isOneDeleted =
    diff.deleted.length === 1 &&
    (diff.deleted.indexOf('classification') > -1 ||
      diff.deleted.indexOf('subclassification') > -1);

  const isBothAdded =
    diff.added.length === 2 &&
    diff.added.indexOf('classification') > -1 &&
    diff.added.indexOf('subclassification') > -1;
  const isBothUpdated =
    diff.updated.length === 2 &&
    diff.updated.indexOf('classification') > -1 &&
    diff.updated.indexOf('subclassification') > -1;
  const isBothDeleted =
    diff.deleted.length === 2 &&
    diff.deleted.indexOf('classification') > -1 &&
    diff.deleted.indexOf('subclassification') > -1;

  return (
    isOneAdded ||
    isOneUpdated ||
    isOneDeleted ||
    isBothAdded ||
    isBothUpdated ||
    isBothDeleted
  );
};
