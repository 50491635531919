import { Box, Loader } from 'braid-design-system';

import * as styles from './PageLoader.css';

const PageLoader = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    className={styles.root}
    data-automation="pageLoader"
  >
    <Loader size="large" />
  </Box>
);

export default PageLoader;
