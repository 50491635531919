import type { Zone } from '@seek/audience-zones';
import type {
  Brand,
  CountrySiteName,
  Language,
  Locale,
} from '@seek/melways-sites';

import type { Country } from 'src/types/globals';

export const SMARTER_SEARCH_CLUSTERS = [
  'default',
  'ph-launch',
  'end-state-launch',
] as const;

export type SmarterSearchCluster = (typeof SMARTER_SEARCH_CLUSTERS)[number];

export interface DisplayDates {
  startDate?: string;
  endDate?: string;
}

export interface ZoneFeatures {
  LMIS_ENABLED?: boolean;
  NUDGE_ENABLED?: boolean;
  SEARCH_PAGE_SIZE?: number;
  BEHAVIOURAL_CUES_ENABLED?: boolean;
  BEHAVIOURAL_CUES_FILTERS_ENABLED?: boolean;
  PDPA_CONSENT_REQUIRED?: boolean;
  HOMEPAGE_BANNER_TYPE?: string;
  SHOW_MARKETING_AD_SPLIT_VIEW?: boolean;
  SHOW_FLOATING_SAVE_SEARCH?: boolean;
  SHOW_JOBCARD_TEASER_IN_SPLITVIEW?: boolean;
  AUTO_SELECT_SPLIT_VIEW_FIRST_JOB?: boolean;
  LOGGED_OUT_RECS?: boolean;
  REMOTE_SEARCH_FILTER?: boolean;
  REMOTE_SEARCH_FILTER_NEW_LABEL?: boolean;
  DYNAMIC_PILLS?: boolean;
  SECONDARY_FILTERS?: boolean;
  DYNAMIC_PILLS_V2?: boolean;
  KEYWORD_AUTOSUGGEST_V2?: boolean;
  NEW_JOB_CARD_DENSITY?: boolean;
  ENABLE_VERIFIED_HIRER_BADGE?: boolean;
  SERP_JOBCARD_INFO_DENSITY_1?: boolean;
  HOMEPAGE_JDV_DRAWER?: boolean;
  HOMEPAGE_LAYOUT_REFRESH?: boolean;
  MATCHED_QUALITIES?: boolean;
  ENTRY_LEVEL_BADGE?:boolean;
}

interface TimedBanner {
  displayDates: DisplayDates;
}

interface CookieConsentBanner extends TimedBanner {}
interface PrivacyPolicyBanner extends TimedBanner {}

export interface ZoneConfig {
  /**
   * 🟡 Going to remove this `zone` from the config in the future. You should not use this `zone` any more.
   *
   * @deprecated Use `useMelwaysZone()` from `src/utils/melwaysHelper` instead. **Be aware that the Letter Case is different.**
   */
  zone: Zone;
  /**
   * The value of this type will be uppercase country code.
   *
   * 🟡 Going to remove this `country` from the config in the future. You should not use this `country` any more.
   *
   * @deprecated Use `useMelwaysCountry()` from `src/utils/melwaysHelper` instead. **Be aware that the Letter Case is different.**
   */
  country: Country;
  /**
   * 🟡 Going to remove this `brand` from the config in the future. You should not use this `brand` any more.
   *
   * @deprecated Use `useMelwaysInfo().brand` from `@seek/melways-sites` instead.
   */
  brand: Brand;
  availableLocales: Locale[];
  defaultLocale: Locale;
  timedBanners: {
    cookieConsent?: CookieConsentBanner;
    privacyPolicy?: PrivacyPolicyBanner;
  };
  zoneFeatures: ZoneFeatures;
  BRANCH_IO_KEY: string;
  GPT_ACCOUNT_ID?: string;
}

export interface AppConfig extends ZoneConfig {
  /**
   * 🟡 Going to remove this `locale` from the config in the future. You should not use this `locale` any more.
   *
   * @deprecated Use `useMelwaysInfo().locale` from `@seek/melways-sites` instead.
   */
  locale: Locale;
  /**
   * 🟡 Going to remove this `language` from the config in the future. You should not use this `language` any more.
   *
   * @deprecated Use `useMelwaysInfo().language` from `@seek/melways-sites` instead.
   */
  language: Language;
  /**
   * 🟡 Going to remove this `site` from the config in the future. You should not use this `site` any more.
   *
   * @deprecated Use `useMelwaysInfo().site` from `@seek/melways-sites` instead.
   */
  site: CountrySiteName;
}

export interface SalaryRange_Frequency {
  label: string;
  type: string;
  isDefault: boolean;
}
export interface SalaryRange_Range {
  shortLabel: string;
  longLabel: string;
  value: string;
}
export interface SalaryRange_Currency {
  code: string;
}

export interface SalaryRange_SearchSalaryRange {
  frequency: SalaryRange_Frequency;
  range: SalaryRange_Range[];
}
export interface SalaryRange {
  defaultCurrency: SalaryRange_Currency;
  searchSalaryRanges: SalaryRange_SearchSalaryRange[];
}

export type AllSalaryRanges = {
  [key in Language]?: SalaryRange;
};

export interface ChildClassification {
  id: number;
  description: string;
}

export interface ParentClassification {
  id: number;
  description: string;
  subClassifications: ChildClassification[];
}

export type Classifications = ParentClassification[];

export type AllClassifications = {
  [key in Language]?: Classifications;
};

export type WorkTypes = {
  [key in Language]?: Array<{ label: string; value: string }>;
};

export type DateRanges = {
  [key in Language]?: Array<{ label: string; value: string }>;
};

export type Distances = {
  [key in Language]?: Array<{ label: string; value: string }>;
};

export type WorkArrangements = {
  [key in Locale]?: Array<{ id: string; label: string }>;
};
