import type { WorkTypes } from './types';

// TODO: Temporarily hardcoded workTypes values as these will be migrate to the candidate graph later on.
export const workTypes: Record<string, WorkTypes> = {
  'anz-1': {
    en: [
      {
        label: 'Full time',
        value: '242',
      },
      {
        label: 'Part time',
        value: '243',
      },
      {
        label: 'Contract/Temp',
        value: '244',
      },
      {
        label: 'Casual/Vacation',
        value: '245',
      },
    ],
  },
  'anz-2': {
    en: [
      {
        label: 'Full time',
        value: '242',
      },
      {
        label: 'Part time',
        value: '243',
      },
      {
        label: 'Contract/Temp',
        value: '244',
      },
      {
        label: 'Casual/Vacation',
        value: '245',
      },
    ],
  },
  'asia-1': {
    en: [
      {
        label: 'Full time',
        value: '242',
      },
      {
        label: 'Part time',
        value: '243',
      },
      {
        label: 'Contract/Temp',
        value: '244',
      },
      {
        label: 'Casual/Vacation',
        value: '245',
      },
    ],
  },
  'asia-3': {
    en: [
      {
        label: 'Full time',
        value: '242',
      },
      {
        label: 'Part time',
        value: '243',
      },
      {
        label: 'Contract/Temp',
        value: '244',
      },
      {
        label: 'Casual/Vacation',
        value: '245',
      },
    ],
    th: [
      {
        label: 'งานเต็มเวลา',
        value: '242',
      },
      {
        label: 'งานนอกเวลา',
        value: '243',
      },
      {
        label: 'งานสัญญาจ้าง',
        value: '244',
      },
      {
        label: 'งานชั่วคราว',
        value: '245',
      },
    ],
  },
  'asia-4': {
    en: [
      {
        label: 'Full time',
        value: '242',
      },
      {
        label: 'Part time',
        value: '243',
      },
      {
        label: 'Contract/Temp',
        value: '244',
      },
      {
        label: 'Casual/Vacation',
        value: '245',
      },
    ],
    id: [
      {
        label: 'Full time',
        value: '242',
      },
      {
        label: 'Paruh waktu',
        value: '243',
      },
      {
        label: 'Kontrak',
        value: '244',
      },
      {
        label: 'Kasual/Liburan',
        value: '245',
      },
    ],
  },
  'asia-5': {
    en: [
      {
        label: 'Full time',
        value: '242',
      },
      {
        label: 'Part time',
        value: '243',
      },
      {
        label: 'Contract/Temp',
        value: '244',
      },
      {
        label: 'Casual/Vacation',
        value: '245',
      },
    ],
  },
  'asia-6': {
    en: [
      {
        label: 'Full time',
        value: '242',
      },
      {
        label: 'Part time',
        value: '243',
      },
      {
        label: 'Contract/Temp',
        value: '244',
      },
      {
        label: 'Casual/Vacation',
        value: '245',
      },
    ],
  },
  'asia-7': {
    en: [
      {
        label: 'Full time',
        value: '242',
      },
      {
        label: 'Part time',
        value: '243',
      },
      {
        label: 'Contract/Temp',
        value: '244',
      },
      {
        label: 'Casual/Vacation',
        value: '245',
      },
    ],
  },
};
