import type { SearchSavedAndAppliedJobsQuery } from 'src/graphql/graphql';

interface JobStatusFlags {
  jobId: string;
  savedAndAppliedJobs: SearchSavedAndAppliedJobsQuery;
}

export const getJobStatusFlags = ({
  jobId,
  savedAndAppliedJobs,
}: JobStatusFlags) => ({
  isApplied:
    savedAndAppliedJobs?.viewer?.searchAppliedJobs
      .map((appliedJob) => appliedJob.jobId)
      .includes(jobId) || false,
  isSaved:
    savedAndAppliedJobs?.viewer?.searchSavedJobs
      .map((savedJob) => savedJob.jobId)
      .includes(jobId) || false,
});
