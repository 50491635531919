import type { UrlLocation } from '@seek/chalice-types';
import type { CountrySiteName } from '@seek/melways-sites';
import { isLocalHoustonLocation, isSeekLocation } from '@seek/seek-jobs-seo';

/**
 * Disable Linking to new tabs
 * Currently we prevent link events with event.preventDefault and attempt to open the links with JavaScript
 * This is registered as a 'pop-up' and is blocked by some browsers
 */
const DISABLE_LINK_TO_NEW_TAB = true;

export const getDefaultLinkTargetFromLocation = (
  location: UrlLocation,
): string | undefined => {
  // if this is a placeholder or this is a relative path
  if (!location.href || !location.hostname) {
    return undefined;
  }

  if (DISABLE_LINK_TO_NEW_TAB) {
    return '_self';
  }

  // if this is within the seek domain
  if (isSeekLocation(location)) {
    return '_self';
  }

  return '_blank';
};

export const shouldOpenLinkExternally = (
  location: UrlLocation,
  windowLocation: UrlLocation,
  site: CountrySiteName,
): boolean =>
  !isLocalHoustonLocation({ location, localLocation: windowLocation, site });
