export class JobDetailsGraphQLError extends Error {
  customData: Record<string, any>;
  constructor({
    message,
    customData,
  }: {
    message: string;
    customData: Record<string, any>;
  }) {
    super(message);
    this.customData = customData;
  }
}

export class PageNotFoundError extends Error {
  statusCode: number;
  constructor(message: string) {
    super(message);
    this.statusCode = 404;
  }
}
