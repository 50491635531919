import type { TestHeaders } from '../productionTesting/productionTesting';
import { getParam } from '../urlParams';

export type EventCaptureTestTags = Partial<{
  testRecord: boolean;
  testScope: string;
}>;

export const getTestTags = (
  testHeaders: TestHeaders,
): EventCaptureTestTags | undefined => {
  const testTags: EventCaptureTestTags = {};

  if (
    testHeaders['user-test-record'] === 'true' ||
    testHeaders['seek-test'] === 'true'
  ) {
    testTags.testRecord = true;
    testTags.testScope =
      testHeaders['user-test-scope'] || testHeaders['seek-test-scope'] || '';
  }

  return testTags.testRecord ? testTags : undefined;
};

export const getViewJobOriginRef = () => getParam(['ref', 'cid']);
export const getJobActionOrigin = () => getParam('origin');
