import type { UrlLocation } from '@seek/chalice-types';
import { stringify } from 'query-string';
import { useCallback } from 'react';

import { signInRegUrlPrefix, signInPath } from 'src/config';
import { useSelector } from 'src/store/react';
import { selectLocation } from 'src/store/selectors';

function getReturnUrl(location: UrlLocation) {
  const { pathname, query } = location;
  const queryString = stringify(query || {});
  return `${pathname || ''}${queryString ? `?${queryString}` : ''}`;
}

function useSignInRedirect(
  destination?: UrlLocation,
): [
  string,
  (evt?: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void,
] {
  const currentLocation = useSelector(selectLocation);
  const returnUrl = getReturnUrl(destination ?? currentLocation);
  const signInUrl = `${signInRegUrlPrefix}${signInPath}?returnUrl=${encodeURIComponent(
    returnUrl,
  )}`;
  const navigate = useCallback(
    (evt?: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
      if (evt) {
        evt.preventDefault();
      }
      window.location.href = signInUrl;
    },
    [signInUrl],
  );

  return [signInUrl, navigate];
}

export default useSignInRedirect;
