import { useTranslations } from '@vocab/react';
import {
  Box,
  Columns,
  ContentBlock,
  Heading,
  Stack,
  List,
  Column,
} from 'braid-design-system';

import translations from './.vocab';
import { CountryLink, generateColumnData } from './utils';

const popularCountries = [
  'Saudi Arabia',
  'Qatar',
  'Hong Kong SAR',
  'Macau SAR',
  'United Arab Emirates',
  'Kuwait',
  'Singapore',
  'Australia',
  'Germany',
  'Malaysia',
];

const PopularCountries = () => {
  const { t } = useTranslations(translations);

  const columnData = generateColumnData({
    items: popularCountries,
    columns: 3,
  });

  return (
    <Box paddingX="xxlarge" paddingY="xlarge">
      <ContentBlock width="large">
        <Box paddingTop="medium">
          <Heading level="3">{t('Popular countries')}</Heading>
          <Box paddingTop="xlarge">
            <Columns space="medium" collapseBelow="tablet">
              {columnData.map(({ key, start, items }) => (
                <Column key={key}>
                  <Stack space="medium">
                    <List type="number" start={start + 1} space="medium">
                      {items.map((country, index) => (
                        <CountryLink
                          key={index}
                          country={country}
                          withDivider={true}
                        />
                      ))}
                    </List>
                  </Stack>
                </Column>
              ))}
            </Columns>
          </Box>
        </Box>
      </ContentBlock>
    </Box>
  );
};

export default PopularCountries;
