import type { WorkTypeList } from 'src/components/Search/RefineBar/types';
import type { ClassificationList } from 'src/components/Search/SearchBar/types';

const jobStreetData = [
  { value: '1322' },
  { value: '1204' },
  { value: '1225' },
  { value: '1546' },
  { value: '1549' },
  { value: '6022' },
  { value: '6029' },
  { value: '6031' },
  { value: '6032' },
  { value: '6053' },
  { value: '6068' },
  { value: '6089' },
  { value: '6096' },
  { value: '6108' },
  { value: '6138' },
  { value: '6151' },
  { value: '6206' },
  { value: '6216' },
  { value: '6217' },
  { value: '6309' },
  { value: '6312' },
  { value: '6318' },
  { value: '6351' },
  { value: '6364' },
  { value: '6370' },
  { value: '7020' },
  { value: '1200' },
  { value: '1206' },
  { value: '1209' },
  { value: '1211' },
  { value: '1212' },
  { value: '1223' },
  { value: '6092' },
  { value: '6123' },
  { value: '6251' },
  { value: '6281' },
  { value: '6304' },
  { value: '6362' },
  { value: '1387' },
  { value: '1470' },
  { value: '6010' },
  { value: '6019' },
  { value: '6026' },
  { value: '6027' },
  { value: '6028' },
  { value: '6035' },
  { value: '6048' },
  { value: '6050' },
  { value: '6085' },
  { value: '6093' },
  { value: '6102' },
  { value: '6106' },
  { value: '6109' },
  { value: '6144' },
  { value: '6178' },
  { value: '6180' },
  { value: '6191' },
  { value: '6218' },
  { value: '6219' },
  { value: '6220' },
  { value: '6222' },
  { value: '6233' },
  { value: '6239' },
  { value: '6252' },
  { value: '6257' },
  { value: '6264' },
  { value: '6268' },
  { value: '6288' },
  { value: '6289' },
  { value: '6290' },
  { value: '6291' },
  { value: '6311' },
  { value: '6332' },
  { value: '6363' },
  { value: '6365' },
  { value: '6368' },
];
export const classificationList: ClassificationList = {
  id: jobStreetData,
  my: jobStreetData,
  ph: jobStreetData,
  sg: jobStreetData,
  hk: [
    { value: '1200' },
    { value: '1206' },
    { value: '1209' },
    { value: '1211' },
    { value: '1212' },
    { value: '1223' },
    { value: '6092' },
    { value: '6123' },
    { value: '6251' },
    { value: '6281' },
    { value: '6304' },
    { value: '6362' },
    { value: '1387' },
    { value: '1470' },
    { value: '6010' },
    { value: '6019' },
    { value: '6026' },
    { value: '6027' },
    { value: '6028' },
    { value: '6035' },
    { value: '6048' },
    { value: '6050' },
    { value: '6085' },
    { value: '6093' },
    { value: '6102' },
    { value: '6106' },
    { value: '6109' },
    { value: '6144' },
    { value: '6178' },
    { value: '6180' },
    { value: '6191' },
    { value: '6218' },
    { value: '6219' },
    { value: '6220' },
    { value: '6222' },
    { value: '6233' },
    { value: '6239' },
    { value: '6252' },
    { value: '6257' },
    { value: '6264' },
    { value: '6268' },
    { value: '6288' },
    { value: '6289' },
    { value: '6290' },
    { value: '6291' },
    { value: '6311' },
    { value: '6332' },
    { value: '6363' },
    { value: '6365' },
    { value: '6368' },
    { value: '1203' },
    { value: '1214' },
    { value: '1220' },
    { value: '6008' },
    { value: '6043' },
    { value: '6076' },
    { value: '6246' },
    { value: '6263' },
    { value: '7019' },
    { value: '1350' },
    { value: '1392' },
    { value: '1404' },
    { value: '1542' },
    { value: '1544' },
    { value: '6002' },
    { value: '6005' },
    { value: '6009' },
    { value: '6011' },
    { value: '6012' },
    { value: '6014' },
    { value: '6015' },
    { value: '6016' },
    { value: '6017' },
    { value: '6037' },
    { value: '6044' },
    { value: '6055' },
    { value: '6073' },
    { value: '6082' },
    { value: '6094' },
    { value: '6098' },
    { value: '6099' },
    { value: '6103' },
    { value: '6104' },
    { value: '6125' },
    { value: '6135' },
    { value: '6137' },
    { value: '6141' },
    { value: '6152' },
    { value: '6160' },
    { value: '6175' },
    { value: '6176' },
    { value: '6179' },
    { value: '6181' },
    { value: '6183' },
    { value: '6185' },
    { value: '6186' },
    { value: '6215' },
    { value: '6248' },
    { value: '6253' },
    { value: '6267' },
    { value: '6269' },
    { value: '6270' },
    { value: '6273' },
    { value: '6275' },
    { value: '6277' },
    { value: '6283' },
    { value: '6285' },
    { value: '6286' },
    { value: '6287' },
    { value: '6292' },
    { value: '6293' },
    { value: '6295' },
    { value: '6296' },
    { value: '6297' },
    { value: '6300' },
    { value: '6301' },
    { value: '6305' },
    { value: '6306' },
    { value: '6307' },
    { value: '6313' },
    { value: '6314' },
    { value: '6325' },
    { value: '6326' },
    { value: '6327' },
    { value: '6336' },
    { value: '6342' },
    { value: '6366' },
    { value: '7022' },
    { value: '7023' },
    { value: '6163' },
    { value: '6205' },
    { value: '1405' },
    { value: '1467' },
    { value: '6003' },
    { value: '6022' },
    { value: '6025' },
    { value: '6053' },
    { value: '6087' },
    { value: '6089' },
    { value: '6090' },
    { value: '6096' },
    { value: '6100' },
    { value: '6108' },
    { value: '6110' },
    { value: '6133' },
    { value: '6140' },
    { value: '6143' },
    { value: '6148' },
    { value: '6151' },
    { value: '6161' },
    { value: '6172' },
    { value: '6184' },
    { value: '6241' },
    { value: '6256' },
    { value: '6258' },
    { value: '6294' },
    { value: '6299' },
    { value: '6312' },
    { value: '6318' },
    { value: '6321' },
    { value: '6331' },
    { value: '6337' },
    { value: '6351' },
    { value: '6352' },
    { value: '6361' },
  ],
  th: [
    { value: '1200' },
    { value: '1206' },
    { value: '1209' },
    { value: '1211' },
    { value: '1212' },
    { value: '1223' },
    { value: '6092' },
    { value: '6123' },
    { value: '6251' },
    { value: '6281' },
    { value: '6304' },
    { value: '6362' },
    { value: '1387' },
    { value: '1470' },
    { value: '6010' },
    { value: '6019' },
    { value: '6026' },
    { value: '6027' },
    { value: '6028' },
    { value: '6035' },
    { value: '6048' },
    { value: '6050' },
    { value: '6085' },
    { value: '6093' },
    { value: '6102' },
    { value: '6106' },
    { value: '6109' },
    { value: '6144' },
    { value: '6178' },
    { value: '6180' },
    { value: '6191' },
    { value: '6218' },
    { value: '6219' },
    { value: '6220' },
    { value: '6222' },
    { value: '6233' },
    { value: '6239' },
    { value: '6252' },
    { value: '6257' },
    { value: '6264' },
    { value: '6268' },
    { value: '6288' },
    { value: '6289' },
    { value: '6290' },
    { value: '6291' },
    { value: '6311' },
    { value: '6332' },
    { value: '6363' },
    { value: '6365' },
    { value: '6368' },
    { value: '1203' },
    { value: '1214' },
    { value: '1220' },
    { value: '6008' },
    { value: '6043' },
    { value: '6076' },
    { value: '6246' },
    { value: '6263' },
    { value: '7019' },
    { value: '1350' },
    { value: '1392' },
    { value: '1404' },
    { value: '1542' },
    { value: '1544' },
    { value: '6002' },
    { value: '6005' },
    { value: '6009' },
    { value: '6011' },
    { value: '6012' },
    { value: '6014' },
    { value: '6015' },
    { value: '6016' },
    { value: '6017' },
    { value: '6037' },
    { value: '6044' },
    { value: '6055' },
    { value: '6073' },
    { value: '6082' },
    { value: '6094' },
    { value: '6098' },
    { value: '6099' },
    { value: '6103' },
    { value: '6104' },
    { value: '6125' },
    { value: '6135' },
    { value: '6137' },
    { value: '6141' },
    { value: '6152' },
    { value: '6160' },
    { value: '6175' },
    { value: '6176' },
    { value: '6179' },
    { value: '6181' },
    { value: '6183' },
    { value: '6185' },
    { value: '6186' },
    { value: '6215' },
    { value: '6248' },
    { value: '6253' },
    { value: '6267' },
    { value: '6269' },
    { value: '6270' },
    { value: '6273' },
    { value: '6275' },
    { value: '6277' },
    { value: '6283' },
    { value: '6285' },
    { value: '6286' },
    { value: '6287' },
    { value: '6292' },
    { value: '6293' },
    { value: '6295' },
    { value: '6296' },
    { value: '6297' },
    { value: '6300' },
    { value: '6301' },
    { value: '6305' },
    { value: '6306' },
    { value: '6307' },
    { value: '6313' },
    { value: '6314' },
    { value: '6325' },
    { value: '6326' },
    { value: '6327' },
    { value: '6336' },
    { value: '6342' },
    { value: '6366' },
    { value: '7022' },
    { value: '7023' },
    { value: '1314' },
    { value: '1322' },
    { value: '1411' },
    { value: '6004' },
    { value: '6029' },
    { value: '6031' },
    { value: '6032' },
    { value: '6040' },
    { value: '6064' },
    { value: '6086' },
    { value: '6088' },
    { value: '6111' },
    { value: '6134' },
    { value: '6157' },
    { value: '6165' },
    { value: '6212' },
    { value: '6216' },
    { value: '6309' },
    { value: '6319' },
    { value: '6335' },
  ],
};

export const workTypeList: WorkTypeList = {
  id: [{ value: '242' }, { value: '243' }, { value: '244' }],
  my: [{ value: '242' }, { value: '243' }, { value: '244' }],
  ph: [{ value: '242' }, { value: '243' }, { value: '244' }],
  sg: [{ value: '242' }, { value: '243' }, { value: '244' }],
  hk: [{ value: '242' }, { value: '243' }, { value: '244' }],
  th: [{ value: '242' }, { value: '243' }, { value: '244' }],
};
