
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0FsbCB0eXBlc30gb3RoZXIge3tjb3VudH0gdHlwZXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0FsbCB0eXBlc30gb3RoZXIge3tjb3VudH0gdHlwZXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0FsbCB0eXBlc30gb3RoZXIge3tjb3VudH0gdHlwZXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0FsbCB0eXBlc30gb3RoZXIge3tjb3VudH0gdHlwZXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0FsbCB0eXBlc30gb3RoZXIge3tjb3VudH0gdHlwZXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0FsbCB0eXBlc30gb3RoZXIge3tjb3VudH0gdHlwZXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0FsbCB0eXBlc30gb3RoZXIge3tjb3VudH0gdHlwZXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0FsbCB0eXBlc30gb3RoZXIge3tjb3VudH0gdHlwZXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0FsbCB0eXBlc30gb3RoZXIge3tjb3VudH0gdHlwZXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0FsbCB0eXBlc30gb3RoZXIge3tjb3VudH0gdHlwZXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0FsbCB0eXBlc30gb3RoZXIge3tjb3VudH0gdHlwZXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0FsbCB0eXBlc30gb3RoZXIge3tjb3VudH0gdHlwZXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0FsbCB0eXBlc30gb3RoZXIge3tjb3VudH0gdHlwZXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0FsbCB0eXBlc30gb3RoZXIge3tjb3VudH0gdHlwZXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0FsbCB0eXBlc30gb3RoZXIge3tjb3VudH0gdHlwZXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0FsbCB0eXBlc30gb3RoZXIge3tjb3VudH0gdHlwZXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0FsbCB0eXBlc30gb3RoZXIge3tjb3VudH0gdHlwZXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiQWxsIHdvcmsgdHlwZXMiLCJBbGwgdHlwZXMgKHRvdGFsKSI6IkFsbCB0eXBlcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtBbGwgd29yayB0eXBlc30gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0FsbCB0eXBlc30gb3RoZXIge3tjb3VudH0gdHlwZXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiU2VtdWEgamVuaXMgcGVrZXJqYWFuIiwiQWxsIHR5cGVzICh0b3RhbCkiOiJTZW11YSBqZW5pcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtTZW11YSBqZW5pcyBwZWtlcmphYW59IG90aGVyIHt7Y291bnR9IGplbmlzIHBla2VyamFhbn19IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0plbmlzIHBla2VyamFhbn0gb3RoZXIge3tjb3VudH0gamVuaXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiU2VtdWEgamVuaXMgcGVrZXJqYWFuIiwiQWxsIHR5cGVzICh0b3RhbCkiOiJTZW11YSBqZW5pcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtTZW11YSBqZW5pcyBwZWtlcmphYW59IG90aGVyIHt7Y291bnR9IGplbmlzIHBla2VyamFhbn19IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0plbmlzIHBla2VyamFhbn0gb3RoZXIge3tjb3VudH0gamVuaXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoi4Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiQWxsIHR5cGVzICh0b3RhbCkiOiLguJvguKPguLDguYDguKDguJfguIHguLLguKPguIjguYnguLLguIfguIfguLLguJnguJfguLHguYnguIfguKvguKHguJQiLCJBbGwgd29yayB0eXBlcyI6Intjb3VudCwgcGx1cmFsLCA9MCB74Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUfSBvdGhlciB7e2NvdW50fSDguJvguKPguLDguYDguKDguJfguIfguLLguJl9fSIsIkFsbCB3b3JrIHR5cGVzIGFsdGVybmF0aXZlIjoie2NvdW50LCBwbHVyYWwsID0wIHtXb3JrIHR5cGV9ID0xIHt7Y291bnR9IHdvcmsgdHlwZX0gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHvguJfguLjguIHguJvguKPguLDguYDguKDguJd9IG90aGVyIHt7Y291bnR9IOC4m%2BC4o%2BC4sOC5gOC4oOC4l319IiwiQWxsIHR5cGVzIGFsdGVybmF0aXZlIjoie2NvdW50LCBwbHVyYWwsID0wIHtUeXBlfSBvdGhlciB7e2NvdW50fSB0eXBlc319In0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoi4Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiQWxsIHR5cGVzICh0b3RhbCkiOiLguJvguKPguLDguYDguKDguJfguIHguLLguKPguIjguYnguLLguIfguIfguLLguJnguJfguLHguYnguIfguKvguKHguJQiLCJBbGwgd29yayB0eXBlcyI6Intjb3VudCwgcGx1cmFsLCA9MCB74Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUfSBvdGhlciB7e2NvdW50fSDguJvguKPguLDguYDguKDguJfguIfguLLguJl9fSIsIkFsbCB3b3JrIHR5cGVzIGFsdGVybmF0aXZlIjoie2NvdW50LCBwbHVyYWwsID0wIHtXb3JrIHR5cGV9ID0xIHt7Y291bnR9IHdvcmsgdHlwZX0gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHvguJfguLjguIHguJvguKPguLDguYDguKDguJd9IG90aGVyIHt7Y291bnR9IOC4m%2BC4o%2BC4sOC5gOC4oOC4l319IiwiQWxsIHR5cGVzIGFsdGVybmF0aXZlIjoie2NvdW50LCBwbHVyYWwsID0wIHtUeXBlfSBvdGhlciB7e2NvdW50fSB0eXBlc319In0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiU2VtdWEgamVuaXMgcGVrZXJqYWFuIiwiQWxsIHR5cGVzICh0b3RhbCkiOiJTZW11YSBqZW5pcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtTZW11YSBqZW5pcyBwZWtlcmphYW59IG90aGVyIHt7Y291bnR9IGplbmlzIHBla2VyamFhbn19IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0plbmlzIHBla2VyamFhbn0gb3RoZXIge3tjb3VudH0gamVuaXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiU2VtdWEgamVuaXMgcGVrZXJqYWFuIiwiQWxsIHR5cGVzICh0b3RhbCkiOiJTZW11YSBqZW5pcyIsIkFsbCB3b3JrIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHtTZW11YSBqZW5pcyBwZWtlcmphYW59IG90aGVyIHt7Y291bnR9IGplbmlzIHBla2VyamFhbn19IiwiQWxsIHdvcmsgdHlwZXMgYWx0ZXJuYXRpdmUiOiJ7Y291bnQsIHBsdXJhbCwgPTAge1dvcmsgdHlwZX0gPTEge3tjb3VudH0gd29yayB0eXBlfSBvdGhlciB7e2NvdW50fSB3b3JrIHR5cGVzfX0iLCJBbGwgdHlwZXMiOiJ7Y291bnQsIHBsdXJhbCwgPTAge0plbmlzIHBla2VyamFhbn0gb3RoZXIge3tjb3VudH0gamVuaXN9fSIsIkFsbCB0eXBlcyBhbHRlcm5hdGl2ZSI6Intjb3VudCwgcGx1cmFsLCA9MCB7VHlwZX0gb3RoZXIge3tjb3VudH0gdHlwZXN9fSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoi4Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiQWxsIHR5cGVzICh0b3RhbCkiOiLguJvguKPguLDguYDguKDguJfguIHguLLguKPguIjguYnguLLguIfguIfguLLguJnguJfguLHguYnguIfguKvguKHguJQiLCJBbGwgd29yayB0eXBlcyI6Intjb3VudCwgcGx1cmFsLCA9MCB74Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUfSBvdGhlciB7e2NvdW50fSDguJvguKPguLDguYDguKDguJfguIfguLLguJl9fSIsIkFsbCB3b3JrIHR5cGVzIGFsdGVybmF0aXZlIjoie2NvdW50LCBwbHVyYWwsID0wIHtXb3JrIHR5cGV9ID0xIHt7Y291bnR9IHdvcmsgdHlwZX0gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHvguJfguLjguIHguJvguKPguLDguYDguKDguJd9IG90aGVyIHt7Y291bnR9IOC4m%2BC4o%2BC4sOC5gOC4oOC4l319IiwiQWxsIHR5cGVzIGFsdGVybmF0aXZlIjoie2NvdW50LCBwbHVyYWwsID0wIHtUeXBlfSBvdGhlciB7e2NvdW50fSB0eXBlc319In0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoi4Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiQWxsIHR5cGVzICh0b3RhbCkiOiLguJvguKPguLDguYDguKDguJfguIHguLLguKPguIjguYnguLLguIfguIfguLLguJnguJfguLHguYnguIfguKvguKHguJQiLCJBbGwgd29yayB0eXBlcyI6Intjb3VudCwgcGx1cmFsLCA9MCB74Lib4Lij4Liw4LmA4Lig4LiX4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZ4LiX4Lix4LmJ4LiH4Lir4Lih4LiUfSBvdGhlciB7e2NvdW50fSDguJvguKPguLDguYDguKDguJfguIfguLLguJl9fSIsIkFsbCB3b3JrIHR5cGVzIGFsdGVybmF0aXZlIjoie2NvdW50LCBwbHVyYWwsID0wIHtXb3JrIHR5cGV9ID0xIHt7Y291bnR9IHdvcmsgdHlwZX0gb3RoZXIge3tjb3VudH0gd29yayB0eXBlc319IiwiQWxsIHR5cGVzIjoie2NvdW50LCBwbHVyYWwsID0wIHvguJfguLjguIHguJvguKPguLDguYDguKDguJd9IG90aGVyIHt7Y291bnR9IOC4m%2BC4o%2BC4sOC5gOC4oOC4l319IiwiQWxsIHR5cGVzIGFsdGVybmF0aXZlIjoie2NvdW50LCBwbHVyYWwsID0wIHtUeXBlfSBvdGhlciB7e2NvdW50fSB0eXBlc319In0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiW%2BG6rMaaxpogxbXDtsO2w7bFmcS3IOG5r8O9w73Dvcal4bq94bq94bq9xaFdIiwiQWxsIHR5cGVzICh0b3RhbCkiOiJb4bqsxprGmiDhua%2FDvcO9w73GpeG6veG6veG6vcWhXSIsIkFsbCB3b3JrIHR5cGVzIjoiW3tjb3VudCxwbHVyYWwsPTB74bqsxprGmiDFtcO2w7bDtsWZxLcg4bmvw73DvcO9xqXhur3hur3hur3FoX0gb3RoZXJ7e2NvdW50fSDFtcO2w7bDtsWZxLcg4bmvw73DvcO9xqXhur3hur3hur3FoX19XSIsIkFsbCB3b3JrIHR5cGVzIGFsdGVybmF0aXZlIjoiW3tjb3VudCxwbHVyYWwsPTB7xbTDtsO2w7bFmcS3IOG5r8O9w73Dvcal4bq94bq94bq9fSA9MXt7Y291bnR9IMW1w7bDtsO2xZnEtyDhua%2FDvcO9w73GpeG6veG6veG6vX0gb3RoZXJ7e2NvdW50fSDFtcO2w7bDtsWZxLcg4bmvw73DvcO9xqXhur3hur3hur3FoX19XSIsIkFsbCB0eXBlcyI6Ilt7Y291bnQscGx1cmFsLD0we%2BG6rMaaxpog4bmvw73DvcO9xqXhur3hur3hur3FoX0gb3RoZXJ7e2NvdW50fSDhua%2FDvcO9w73GpeG6veG6veG6vcWhfX1dIiwiQWxsIHR5cGVzIGFsdGVybmF0aXZlIjoiW3tjb3VudCxwbHVyYWwsPTB74bmuw73Dvcal4bq94bq9fSBvdGhlcnt7Y291bnR9IOG5r8O9w73Dvcal4bq94bq94bq9xaF9fV0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgd29yayB0eXBlcyAodG90YWwpIjoiW%2BG6rMaaxpogxbXDtsO2w7bFmcS3IOG5r8O9w73Dvcal4bq94bq94bq9xaFdIiwiQWxsIHR5cGVzICh0b3RhbCkiOiJb4bqsxprGmiDhua%2FDvcO9w73GpeG6veG6veG6vcWhXSIsIkFsbCB3b3JrIHR5cGVzIjoiW3tjb3VudCxwbHVyYWwsPTB74bqsxprGmiDFtcO2w7bDtsWZxLcg4bmvw73DvcO9xqXhur3hur3hur3FoX0gb3RoZXJ7e2NvdW50fSDFtcO2w7bDtsWZxLcg4bmvw73DvcO9xqXhur3hur3hur3FoX19XSIsIkFsbCB3b3JrIHR5cGVzIGFsdGVybmF0aXZlIjoiW3tjb3VudCxwbHVyYWwsPTB7xbTDtsO2w7bFmcS3IOG5r8O9w73Dvcal4bq94bq94bq9fSA9MXt7Y291bnR9IMW1w7bDtsO2xZnEtyDhua%2FDvcO9w73GpeG6veG6veG6vX0gb3RoZXJ7e2NvdW50fSDFtcO2w7bDtsWZxLcg4bmvw73DvcO9xqXhur3hur3hur3FoX19XSIsIkFsbCB0eXBlcyI6Ilt7Y291bnQscGx1cmFsLD0we%2BG6rMaaxpog4bmvw73DvcO9xqXhur3hur3hur3FoX0gb3RoZXJ7e2NvdW50fSDhua%2FDvcO9w73GpeG6veG6veG6vcWhfX1dIiwiQWxsIHR5cGVzIGFsdGVybmF0aXZlIjoiW3tjb3VudCxwbHVyYWwsPTB74bmuw73Dvcal4bq94bq9fSBvdGhlcnt7Y291bnR9IOG5r8O9w73Dvcal4bq94bq94bq9xaF9fV0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    