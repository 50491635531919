import gql from 'graphql-tag';

export const CREATE_SAVED_JOB = gql`
  mutation CreateSavedJob($input: CreateSavedJobInput!, $locale: Locale) {
    createSavedJob2(input: $input) {
      __typename
      ... on CreateSavedJobSuccess {
        __typename
        id
      }
      ... on CreateSavedJobFailure {
        __typename
        errors {
          __typename
          message(locale: $locale)
        }
      }
    }
  }
`;
