/*
  Code implementation is derived from 
  https://github.com/seek-oss/braid-design-system/blob/1c5da13ae84ae03ca2b36d03e7ade82c4181fd27/packages/braid-design-system/src/lib/components/Drawer/Drawer.tsx.

  Notes:
  - Some implementation of the original Drawer were intentionally
    not carried over to adapt to Chalice's use case, e.g. SecondaryFilters. 

  For reference:
  - Braid version: v32.21.0
*/

import { Modal, type ModalProps } from './components/Modal';

export interface SEODrawerProps
  extends Omit<
    ModalProps,
    'onClose' | 'scrollLock' | 'headingRef' | 'modalRef'
  > {
  open: boolean;
  onClose: (openState: false) => void | false;
}

const defaultWidth = 'medium';
const defaultPosition = 'right';
const modalStyle = {
  headingLevel: '2',
} as const;

export const SEODrawer = ({
  width = defaultWidth,
  position = defaultPosition,
  ...restProps
}: SEODrawerProps) => (
  <Modal width={width} position={position} {...restProps} {...modalStyle} />
);
