// Port over from srp-react
import { Box } from 'braid-design-system';
import { memo } from 'react';

import useAnimatedWrapper, {
  type UseAnimatedWrapperProps,
} from 'src/hooks/useAnimatedWrapper';

import * as styles from './AnimatedHeight.css';

const AnimatedHeight = ({
  children,
  changeKeys,
  changeDependsOn = 'child',
}: Pick<UseAnimatedWrapperProps, 'changeKeys' | 'changeDependsOn'> & {
  children: React.ReactNode;
}) => {
  const { style, parentRef, childRef, status } = useAnimatedWrapper({
    changeKeys,
    changeDependsOn,
    changeDimension: 'height',
  });

  const commonProps = {
    className: [
      styles.root,
      {
        [styles.active]: status !== 'idle',
      },
    ],
    style: { height: style },
  };

  return (
    <Box
      ref={parentRef}
      {...(changeDependsOn === 'child' ? commonProps : undefined)}
    >
      <Box
        ref={childRef}
        {...(changeDependsOn === 'parent' ? commonProps : undefined)}
      >
        {children}
      </Box>
    </Box>
  );
};

export default memo(AnimatedHeight);
