import type { Quality, MatchedSkills } from 'src/graphql/graphql';

const skills: Record<string, Quality> = {
  Recruitment: {
    ontologyId:
      'https://graph.cloud.seek.com/Spec-Req/0a0a569a-9519-4008-9817-4088125f97fe',
    displayLabel: 'Recruitment',
    __typename: 'Quality',
  },
  Mining_Industry: {
    ontologyId:
      'https://graph.cloud.seek.com/Spec-Req/6279a02e-8d2c-4893-9176-fd9be4235efc',
    displayLabel: 'Mining Industry',
    __typename: 'Quality',
  },
  Drilling: {
    ontologyId:
      'https://graph.cloud.seek.com/Spec-Req/3c89d92d-4eae-4b6c-8668-55a2b960d1ad',
    displayLabel: 'Drilling',
    __typename: 'Quality',
  },
  General_Operations: {
    ontologyId:
      'https://graph.cloud.seek.com/Spec-Req/fbb7d944-d72c-4f4f-b89d-112f3e242900',
    displayLabel: 'General Operations',
    __typename: 'Quality',
  },
  Cleaning: {
    ontologyId:
      'https://graph.cloud.seek.com/Spec-Req/f569824e-546f-4f86-9579-1546a4b868f9',
    displayLabel: 'Cleaning',
    __typename: 'Quality',
  },
  Providing_Support: {
    ontologyId:
      'https://graph.cloud.seek.com/Spec-Req/31abf263-8308-4ce4-ba82-8a1eb3a0e5c3',
    displayLabel: 'Providing Support',
    __typename: 'Quality',
  },
  Drilling_Operations: {
    ontologyId:
      'https://graph.cloud.seek.com/Spec-Req/fad75177-e976-4711-b1e0-eb00e2cee6ab',
    displayLabel: 'Drilling Operations',
    __typename: 'Quality',
  },
  Mobilisation: {
    ontologyId:
      'https://graph.cloud.seek.com/Spec-Req/0f76605e-fe51-4a6b-b417-ba0178b73fab',
    displayLabel: 'Mobilisation',
    __typename: 'Quality',
  },
  Australian_HR_Licence: {
    ontologyId:
      'https://graph.cloud.seek.com/Spec-Req/995eceac-da26-4dc3-9f98-1ed07eea7f2b',
    displayLabel: 'Australian HR Licence',
    __typename: 'Quality',
  },
  Criminal_History_Check: {
    ontologyId:
      'https://graph.cloud.seek.com/Spec-Req/1cb148f6-6622-46d3-a2ee-43d98b23413b',
    displayLabel: 'Criminal History Check',
    __typename: 'Quality',
  },
  DotNet: {
    displayLabel: '.NET',
    ontologyId:
      'https://graph.cloud.seek.com/Spec-Req/97662222-086e-4d7e-b95e-3f2413611d9a',
    __typename: 'Quality',
  },
  Web_Development: {
    displayLabel: 'Web Development',
    ontologyId:
      'https://graph.cloud.seek.com/Spec-Req/83e4c1fa-37a2-4ad2-8c8e-bc33765ba7ec',
    __typename: 'Quality',
  },
  CSharp: {
    displayLabel: 'C#',
    ontologyId:
      'https://graph.cloud.seek.com/Spec-Req/f5476482-dcee-4f3d-8703-0fb5032db2b5',
    __typename: 'Quality',
  },
  JavaScript: {
    displayLabel: 'JavaScript',
    ontologyId:
      'https://graph.cloud.seek.com/Spec-Req/88dc05ae-4673-41dd-a83e-570c999523e7',
    __typename: 'Quality',
  },
  AngularJS: {
    displayLabel: 'AngularJS',
    ontologyId:
      'https://graph.cloud.seek.com/Spec-Req/a0e455c7-c6b0-48f7-b288-e04c84a0a5ee',
    __typename: 'Quality',
  },
  TypeScript: {
    displayLabel: 'Typescript Programming',
    ontologyId:
      'https://graph.cloud.seek.com/Spec-Req/c7304e46-a3e7-45f1-a038-11f5af499cae',
    __typename: 'Quality',
  },
  React: {
    displayLabel: 'React',
    ontologyId:
      'https://graph.cloud.seek.com/Spec-Req/e0fc73b7-4149-4a90-8df5-965da8438fbb',
    __typename: 'Quality',
  },
  Front_end_SPAs: {
    displayLabel: 'Front-end SPAs',
    ontologyId:
      'https://graph.cloud.seek.com/Spec-Req/2ade1c90-bb67-4bab-9386-e44a3128a9c8',
    __typename: 'Quality',
  },
  GraphQL: {
    displayLabel: 'Graphql',
    ontologyId:
      'https://graph.cloud.seek.com/Spec-Req/c048fc05-13ba-4ada-a6a2-3bd2690535b6',
    __typename: 'Quality',
  },
};

const empty = {
  matched: [],
  inferred: [],
  unmatched: [],
};

const matched = [
    skills.DotNet,
    skills.Web_Development,
    skills.CSharp,
    skills.JavaScript,
    skills.AngularJS,
    skills.TypeScript,
  ],
  inferred = [skills.Mobilisation, skills.Drilling_Operations],
  unmatched = [skills.Australian_HR_Licence, skills.Criminal_History_Check];

const base = {
  matched,
  inferred,
  unmatched,
} as MatchedSkills;

const oneMatch = {
  ...base,
  matched: matched?.slice(0, 1),
} as MatchedSkills;

const twoMatches = {
  ...base,
  matched: matched?.slice(0, 2),
} as MatchedSkills;

const threeMatches = {
  ...base,
  matched: matched?.slice(0, 3),
} as MatchedSkills;

const fourMatches = {
  ...base,
  matched: matched?.slice(0, 4),
} as MatchedSkills;

const fiveMatches = {
  ...base,
  matched: matched?.slice(0, 5),
} as MatchedSkills;

const sixMatches = {
  ...base,
  matched: matched?.slice(0, 5),
} as MatchedSkills;

const noMatches = { ...empty, inferred, unmatched } as MatchedSkills,
  noInferred = { ...empty, matched, unmatched } as MatchedSkills,
  noUnmatched = { ...empty, matched, inferred } as MatchedSkills,
  onlyMatched = { ...empty, matched } as MatchedSkills,
  onlyInferred = { ...empty, inferred } as MatchedSkills,
  onlyUnmatched = {
    ...empty,
    unmatched: [
      ...inferred,
      ...unmatched,
      skills.Providing_Support,
      skills.General_Operations,
    ],
  } as MatchedSkills;

const matchedNotInProfile = {
  ...base,
  matched: [...matched, skills.Recruitment],
};

const inferredAlreadyInProfile = {
  matched,
  inferred: [...inferred, skills.Mining_Industry],
  unmatched: [...unmatched, skills.Drilling],
};

const tooFewMatches = {
  matched: [skills.Recruitment],
  inferred: [skills.Drilling],
  unmatched: [],
};

export const qualitiesData: Record<string, MatchedSkills> = {
  '29677777': oneMatch, // Prison Officer - Metropolitan Remand Centre
  '29752529': twoMatches, // Senior EA/Business Manager
  '29663971': threeMatches, // Front of House Waitstaff
  '29663948': fourMatches, // Pastry Chef & Chef de Partie
  '29800267': fiveMatches, // Textiles Designer
  '29800268': sixMatches, // Category Manager - Food Services (cat: Purchasing ..)
  '29800266': noMatches, // Category Manager - Food Services (cat: Marketing)
  '29800264': noInferred, // Category Manager - Food Services (cat: Product Management, 6h)
  '29800265': noUnmatched, // Category Manager - Food Services (cat: Product Management, 1 Jun)
  '29800262': onlyMatched, // Category Manager - Food Services (cat: Retail)
  '29800261': onlyInferred, // .NET Developer
  '34536098': onlyUnmatched, // Site Manager
  '29800259': matchedNotInProfile, // Personal Trainers Wanted - Goodlife Point Cook
  '29662612': inferredAlreadyInProfile, // MR & HR DRIVERS!
  '29800257': tooFewMatches, // Aged Carer Mock Job
};
