
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJTRUVLIC0gQXVzdHJhbGlhJ3Mgbm8uIDEgam9icywgZW1wbG95bWVudCwgY2FyZWVyIGFuZCByZWNydWl0bWVudCBzaXRlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJTRUVLIC0gQXVzdHJhbGlhJ3Mgbm8uIDEgam9icywgZW1wbG95bWVudCwgY2FyZWVyIGFuZCByZWNydWl0bWVudCBzaXRlIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJTRUVLIC0gQXVzdHJhbGlhJ3Mgbm8uIDEgam9icywgZW1wbG95bWVudCwgY2FyZWVyIGFuZCByZWNydWl0bWVudCBzaXRlIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJTRUVLIC0gQXVzdHJhbGlhJ3Mgbm8uIDEgam9icywgZW1wbG95bWVudCwgY2FyZWVyIGFuZCByZWNydWl0bWVudCBzaXRlIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJKb2JzIG9uIFNFRUsgLSBOZXcgWmVhbGFuZCdzIG5vLiAxIEVtcGxveW1lbnQsIENhcmVlciBhbmQgUmVjcnVpdG1lbnQgc2l0ZSJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJKb2JzIG9uIFNFRUsgLSBOZXcgWmVhbGFuZCdzIG5vLiAxIEVtcGxveW1lbnQsIENhcmVlciBhbmQgUmVjcnVpdG1lbnQgc2l0ZSJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJKb2JzZGIgLSBIb25nIEtvbmcncyBuby4gMSBqb2JzLCBlbXBsb3ltZW50LCBjYXJlZXIgYW5kIHJlY3J1aXRtZW50IHNpdGUifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJKb2JzZGIgLSBIb25nIEtvbmcncyBuby4gMSBqb2JzLCBlbXBsb3ltZW50LCBjYXJlZXIgYW5kIHJlY3J1aXRtZW50IHNpdGUifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJKb2JzZGIgLSBUaGFpbGFuZCdzIG5vLiAxIGpvYnMsIGVtcGxveW1lbnQsIGNhcmVlciBhbmQgcmVjcnVpdG1lbnQgc2l0ZSJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJKb2JzZGIgLSBUaGFpbGFuZCdzIG5vLiAxIGpvYnMsIGVtcGxveW1lbnQsIGNhcmVlciBhbmQgcmVjcnVpdG1lbnQgc2l0ZSJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJKb2JzIGluIEluZG9uZXNpYSAtIFNlYXJjaCBKb2IgVmFjYW5jaWVzIC0gQ2FyZWVyIHwgSm9ic3RyZWV0In0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJKb2JzIGluIEluZG9uZXNpYSAtIFNlYXJjaCBKb2IgVmFjYW5jaWVzIC0gQ2FyZWVyIHwgSm9ic3RyZWV0In0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJKb2JzIGluIFNpbmdhcG9yZSAtIFNlYXJjaCBKb2IgVmFjYW5jaWVzIC0gQ2FyZWVyIHwgSm9ic3RyZWV0In0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJKb2JzIGluIFNpbmdhcG9yZSAtIFNlYXJjaCBKb2IgVmFjYW5jaWVzIC0gQ2FyZWVyIHwgSm9ic3RyZWV0In0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJKb2JzIGluIE1hbGF5c2lhIC0gU2VhcmNoIEpvYiBWYWNhbmNpZXMgLSBDYXJlZXIgfCBKb2JzdHJlZXQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJKb2JzIGluIE1hbGF5c2lhIC0gU2VhcmNoIEpvYiBWYWNhbmNpZXMgLSBDYXJlZXIgfCBKb2JzdHJlZXQifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJKb2JzIGluIFBoaWxpcHBpbmVzIC0gU2VhcmNoIEpvYiBWYWNhbmNpZXMgLSBDYXJlZXIgfCBKb2JzdHJlZXQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJKb2JzIGluIFBoaWxpcHBpbmVzIC0gU2VhcmNoIEpvYiBWYWNhbmNpZXMgLSBDYXJlZXIgfCBKb2JzdHJlZXQifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJMb3dvbmdhbiBrZXJqYSBkaSBJbmRvbmVzaWEgLSBDYXJpIExvd29uZ2FuIEtlcmphIC0gS2FyaXIgfCBKb2JzdHJlZXQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJMb3dvbmdhbiBrZXJqYSBkaSBJbmRvbmVzaWEgLSBDYXJpIExvd29uZ2FuIEtlcmphIC0gS2FyaXIgfCBKb2JzdHJlZXQifQ%3D%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJKb2JzZGIgLSDguYDguKfguYfguJrguYTguIvguJXguYzguKvguLLguIfguLLguJkg4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZIOC5geC4peC4sOC4geC4suC4o%2BC4quC4o%2BC4o%2BC4q%2BC4suC4reC4seC4meC4lOC4seC4miAxIOC5g%2BC4meC4m%2BC4o%2BC4sOC5gOC4l%2BC4qOC5hOC4l%2BC4oiJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJKb2JzZGIgLSDguYDguKfguYfguJrguYTguIvguJXguYzguKvguLLguIfguLLguJkg4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZIOC5geC4peC4sOC4geC4suC4o%2BC4quC4o%2BC4o%2BC4q%2BC4suC4reC4seC4meC4lOC4seC4miAxIOC5g%2BC4meC4m%2BC4o%2BC4sOC5gOC4l%2BC4qOC5hOC4l%2BC4oiJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJMb3dvbmdhbiBrZXJqYSBkaSBJbmRvbmVzaWEgLSBDYXJpIExvd29uZ2FuIEtlcmphIC0gS2FyaXIgfCBKb2JzdHJlZXQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJMb3dvbmdhbiBrZXJqYSBkaSBJbmRvbmVzaWEgLSBDYXJpIExvd29uZ2FuIEtlcmphIC0gS2FyaXIgfCBKb2JzdHJlZXQifQ%3D%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJKb2JzZGIgLSDguYDguKfguYfguJrguYTguIvguJXguYzguKvguLLguIfguLLguJkg4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZIOC5geC4peC4sOC4geC4suC4o%2BC4quC4o%2BC4o%2BC4q%2BC4suC4reC4seC4meC4lOC4seC4miAxIOC5g%2BC4meC4m%2BC4o%2BC4sOC5gOC4l%2BC4qOC5hOC4l%2BC4oiJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJKb2JzZGIgLSDguYDguKfguYfguJrguYTguIvguJXguYzguKvguLLguIfguLLguJkg4LiB4Liy4Lij4LiI4LmJ4Liy4LiH4LiH4Liy4LiZIOC5geC4peC4sOC4geC4suC4o%2BC4quC4o%2BC4o%2BC4q%2BC4suC4reC4seC4meC4lOC4seC4miAxIOC5g%2BC4meC4m%2BC4o%2BC4sOC5gOC4l%2BC4qOC5hOC4l%2BC4oiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJbxaDDi8OL4biwIC0g4bqsx5rHmseaxaHhua%2FFmcSDxIPEg8aaw6zDrMOsxIPEg8SDJ8WhIOC4geC4teC5icO2w7bDti4gMSDEtcO2w7bDtsOfxaEsIOG6veG6veG6vW3MgsalxprDtsO2w7bDvcO9w71tzILhur3hur3hur3guIHguLXguYnhua8sIMOnxIPEg8SDxZnhur3hur3hur3hur3hur3hur3FmSDEg8SDxIPguIHguLXguYnGjCDFmeG6veG6veG6vcOnxZnHmseax5rDrMOsw6zhua9tzILhur3hur3hur3guIHguLXguYnhua8gxaHDrMOsw6zhua%2Fhur3hur3hur1dIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJzaGFyZWQtdGl0bGUiOiJbxaDDi8OL4biwIC0g4bqsx5rHmseaxaHhua%2FFmcSDxIPEg8aaw6zDrMOsxIPEg8SDJ8WhIOC4geC4teC5icO2w7bDti4gMSDEtcO2w7bDtsOfxaEsIOG6veG6veG6vW3MgsalxprDtsO2w7bDvcO9w71tzILhur3hur3hur3guIHguLXguYnhua8sIMOnxIPEg8SDxZnhur3hur3hur3hur3hur3hur3FmSDEg8SDxIPguIHguLXguYnGjCDFmeG6veG6veG6vcOnxZnHmseax5rDrMOsw6zhua9tzILhur3hur3hur3guIHguLXguYnhua8gxaHDrMOsw6zhua%2Fhur3hur3hur1dIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    