import { END_TRANSACTION, START_TRANSACTION } from './actions';
import {
  endBlocking,
  getTransactionInfo,
  shouldNotify,
  startBlocking,
  warnBecauseNotAllowedInCurrentTransaction,
} from './transaction';

export default function batchHandler(notify, action) {
  const { transaction, type } = getTransactionInfo(action);

  if (type === START_TRANSACTION) {
    startBlocking(transaction);
  }

  if (
    type === END_TRANSACTION ||
    warnBecauseNotAllowedInCurrentTransaction(type)
  ) {
    endBlocking();
  }

  if (shouldNotify()) {
    notify();
  }
}
