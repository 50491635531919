import { useTranslations } from '@vocab/react';
import { Box } from 'braid-design-system';
import filter from 'lodash/filter';
import size from 'lodash/size';

import type { CopyProps } from '../WorkTypeSummary/WorkTypeSummary.tsx';

import translations from './.vocab';

const AllArrangementsCopy = ({ count, total, display }: CopyProps) => {
  const { t } = useTranslations(translations);
  return (
    <Box
      display={display}
      component="span"
      data-automation={`allArrangements-${count}-${total}`}
    >
      {count === total ? t('All options (total)') : t('Remote', { count })}
    </Box>
  );
};
const AllWorkArrangementsCopy = ({ count, total, display }: CopyProps) => {
  const { t } = useTranslations(translations);
  return (
    <Box
      display={display}
      component="span"
      data-automation={`allWorkArrangements-${count}-${total}`}
    >
      {count === total
        ? t('All remote options (total)')
        : t('All remote options', { count })}
    </Box>
  );
};

interface WorkArrangement {
  label: string;
}

interface WorkArrangementSummaryProps {
  workArrangements: WorkArrangement[];
}

const WorkArrangementSummary = ({
  workArrangements,
}: WorkArrangementSummaryProps) => {
  const selectedWorkArrangements = filter(workArrangements, 'isActive');
  const total = size(workArrangements);
  const count = size(selectedWorkArrangements);

  if (count === 1) {
    const label = selectedWorkArrangements.shift()?.label.split('/')[0];
    return (
      <Box component="span" data-automation={label}>
        {label}
      </Box>
    );
  }
  return (
    <>
      <AllArrangementsCopy
        count={count}
        total={total}
        display={{ mobile: 'inline', tablet: 'none' }}
      />
      <AllWorkArrangementsCopy
        count={count}
        total={total}
        display={{ mobile: 'none', tablet: 'inline' }}
      />
    </>
  );
};

export default WorkArrangementSummary;
