import { Checkbox, useResponsiveValue } from 'braid-design-system';

import { Link } from 'src/components/NavLink/NavLink';
import type { Option } from 'src/types/globals';

import { useSecondaryFilterContext } from '../../context/SecondaryFilterContext';

interface CheckboxFilter {
  option: Option;
  handleSelection: (value: string) => void;
  path: string;
}

export const CheckboxFilter = ({
  option,
  handleSelection,
  path,
}: CheckboxFilter) => {
  const { ROBOTS_TAG } = useSecondaryFilterContext();
  const isMobile = useResponsiveValue()({
    mobile: true,
    tablet: false,
  });

  return (
    <Checkbox
      size={isMobile ? 'small' : 'standard'}
      id={option.label}
      checked={option.isActive || false}
      onChange={() => handleSelection(option.value)}
      label={
        <Link
          rel={ROBOTS_TAG}
          location={{ pathname: path, query: option.refineParams }}
          disabledLinkNavigate
        >
          {option.label}
        </Link>
      }
    />
  );
};
