import type { Zone } from '@seek/audience-zones';
import type {
  Brand,
  CountrySiteName,
  Language,
  Locale,
} from '@seek/melways-sites';

import type { ZoneFeatures } from 'src/config/types';
import type { Country } from 'src/types/globals';

export const SET_LANGUAGE = 'SET_LANGUAGE';

export interface AppConfigState {
  brand: Brand;
  site: CountrySiteName;
  language: Language;
  locale: Locale;
  /**
   * The value of this type will be uppercase country code.
   */
  country: Country;
  zoneFeatures: ZoneFeatures;
  zone: Zone;
}

export type AppConfigAction = SetLanguageAction;

export interface SetLanguageAction {
  type: typeof SET_LANGUAGE;
  payload: {
    language: Language;
  };
}
