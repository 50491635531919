import type { SearchParams } from '@seek/chalice-types';
import omit from 'lodash/omit';

export default (params: SearchParams) => {
  if (params.classification || params.subclassification) {
    return {
      classification: omit(params, ['classification', 'subclassification']),
    };
  }

  return {};
};
