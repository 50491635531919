import { useState, useEffect } from 'react';

import { useSelector } from 'src/store/react';
import {
  selectFeatureFlag,
  selectPathname,
  selectPrevPathname,
  selectPageLoadedCount,
} from 'src/store/selectors';

import { canShowAppBanner } from '../app-install-banner';

import useBranchBanner, { type BranchBannerProps } from './useBranchBanner';

/**
 * Assumes branch.io script is loaded
 */
export const BranchBannerView = (props: BranchBannerProps) => {
  const { metadata } = useBranchBanner(props);
  return (
    <>
      {metadata.map(({ name, content }) => (
        <meta
          data-automation={`deeplink-metadata-${name}`}
          key={name}
          name={`branch:deeplink:${name}`}
          content={content}
        />
      ))}
    </>
  );
};

const BranchBanner = () => {
  const isBranchEnabledFlag = useSelector(
    selectFeatureFlag('isBranchEnabledFlag'),
  );
  const branchBannerPreview = useSelector(
    selectFeatureFlag('branchBannerPreview'),
  );
  const pathname = useSelector(selectPathname);
  const prevPathname = useSelector(selectPrevPathname);
  const pageLoadedCount = useSelector(selectPageLoadedCount);

  const { shouldShowBranchInterstitial: newShouldShowBranchInterstitial } =
    canShowAppBanner({
      isBranchEnabledFlag,
    });
  const [shouldShowBranchInterstitial, setShouldShowBranchInterstital] =
    useState(newShouldShowBranchInterstitial);

  useEffect(() => {
    const {
      shouldShowBranchInterstitial: afterRenderShouldShowBranchInterstitial,
    } = canShowAppBanner({
      isBranchEnabledFlag,
    });

    setShouldShowBranchInterstital(afterRenderShouldShowBranchInterstitial);
  }, [
    isBranchEnabledFlag,
    pageLoadedCount,
    pathname,
    prevPathname,
    shouldShowBranchInterstitial,
  ]);

  return (
    <BranchBannerView
      preview={branchBannerPreview}
      shouldShowBranchInterstitial={Boolean(shouldShowBranchInterstitial)}
    />
  );
};

export default BranchBanner;
