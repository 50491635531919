import type { ErrorAndMetadata } from './client';

type Log = (errorAndMetadata: ErrorAndMetadata, message?: string) => void;

const noop: Log = () => {};

interface Logger {
  trace: Log;
  debug: Log;
  info: Log;
  warn: Log;
  error: Log;
}

export let logger: Logger = {
  trace: noop,
  debug: noop,
  info: noop,
  warn: noop,
  error: noop,
};

export const setupLogger = (logProvider: Logger) => {
  logger = logProvider;
};
