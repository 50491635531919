import { log, type BrowserLogExtras } from './browser-logs';

export type ErrorAndMetadata = {
  err?: Record<string, any>;
} & BrowserLogExtras;

const sendLog = (
  level: 'info' | 'warn' | 'error',
  errorAndMetadata: ErrorAndMetadata,
  message?: string,
) => {
  /* eslint-disable no-console */
  console[level](message);
  const { err } = errorAndMetadata;
  if (err) {
    err.message = message || '';
  }
  log('info', err as Error, { kind: 'clientError', ...errorAndMetadata });
};

const logger = {
  /* eslint-disable no-console */
  trace() {},
  debug() {},
  info(errorAndMetadata: ErrorAndMetadata, message?: string) {
    sendLog('info', errorAndMetadata, message);
  },
  warn(errorAndMetadata: ErrorAndMetadata, message?: string) {
    sendLog('warn', errorAndMetadata, message);
  },
  error(errorAndMetadata: ErrorAndMetadata, message?: string) {
    sendLog('error', errorAndMetadata, message);
  },
  /* eslint-enable no-console*/
};

export default logger;
