import type { SearchResultJobV5 } from '@seek/chalice-types';

import useAuthenticated from 'src/hooks/useAuthenticated';
import { usePrevious } from 'src/hooks/usePrevious';

/**
 *  This is going to tell you when to fetch job details based on the following conditions:
    1. the job ad has been changed, so it will not call for the same job multiple times
    2. Auth is ready (no matter logged in or logged out), so that we can call `fetchUnifiedJobPersonalised()` when needed. And the auth state change will also no trigger a fetch to job detail
 * @param job
 * @returns
 */
const useShouldFetchJobDetails = (
  job?: SearchResultJobV5 | string,
): boolean => {
  const authenticatedState = useAuthenticated();

  const jobId = typeof job === 'string' ? job : job?.id;
  const prevJobId = usePrevious(jobId);

  const prevAuthenticatedState = usePrevious(authenticatedState);

  const authNotPending =
    authenticatedState === 'authenticated' ||
    authenticatedState === 'unauthenticated';

  const isJobChanged = Boolean(jobId && prevJobId !== jobId);

  const isReadyToFetch =
    (isJobChanged && authNotPending) ||
    (authNotPending && prevAuthenticatedState === 'pending');

  return isReadyToFetch;
};

export default useShouldFetchJobDetails;
