import type { SearchParams } from '@seek/chalice-types';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

function clean(query: SearchParams) {
  return omit(query, ['page', 'sortmode']);
}

export default (
  lastQuery: SearchParams,
  nextQuery: SearchParams,
  hasSavedSearch: boolean,
) => {
  if (nextQuery.companysearch || nextQuery.advertiserid) {
    return false;
  }
  if (!hasSavedSearch) {
    return true;
  }
  return !isEqual(clean(lastQuery), clean(nextQuery));
};
