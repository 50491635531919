import { ApolloLink } from '@apollo/client';

import type createStore from '../store/createStore';

import { isTestAgent } from './productionTesting/productionTesting';

export default (
  store: ReturnType<typeof createStore>,
): ApolloLink | undefined =>
  store
    ? new ApolloLink((operation, forward) => {
        const { testHeaders } = store.getState().user;
        if (isTestAgent(testHeaders)) {
          operation.setContext({
            headers: testHeaders,
          });
        }
        return forward(operation);
      })
    : undefined;
