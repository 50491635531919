import { useTranslations } from '@vocab/react';
import type { Store } from 'redux';

import { useSelector } from 'src/store/react';
import { selectPageNumber } from 'src/store/selectors';
import type { ChaliceStore } from 'src/store/types';

import { appendSpacePrefix } from '../search-results-page/utils';

import translations from './.vocab';
import type { VocabLocale } from './types';

export const useLocationStateKeyValues = () => {
  const { t } = useTranslations(translations);
  const page = useSelector(selectPageNumber)?.toString() || '1';
  const pageNumberText = appendSpacePrefix(
    parseInt(page, 10) > 1 ? t('page-number-text', { page }) : '',
  );

  return { pageNumberText };
};

export const getLocationStateKeyValues = async ({
  store,
}: {
  store: Store<ChaliceStore>;
}) => {
  const state = store.getState();
  const { locale } = state.appConfig;
  const messages = await translations.getMessages(locale as VocabLocale);
  const pageNumber = state.location.pageNumber ?? 1;
  const pageNumberText = appendSpacePrefix(
    pageNumber > 1
      ? messages['page-number-text'].format({
          page: pageNumber.toString(),
        })
      : '',
  );

  return { pageNumberText };
};
