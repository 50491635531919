import gql from 'graphql-tag';

export const TRACK_SEARCH_RESULT_SELECTED = gql`
  mutation TrackSearchResultSelected(
    $input: TrackSearchResultSelectedInput!
    $locale: Locale = "en-AU"
  ) {
    trackSearchResultSelected(input: $input) {
      __typename
      ... on TrackSearchResultSelectedSuccess {
        __typename
        eventId
      }
      ... on TrackSearchResultSelectedFailure {
        error {
          __typename
          message(locale: $locale)
        }
      }
    }
  }
`;
