import type { DisplayDates } from 'src/config/types';

import { parseDate } from './parseDates';

export const isTimedComponentValid = (
  timedBannerDates?: DisplayDates,
): boolean => {
  if (!timedBannerDates) {
    return false;
  }
  const currentDateMilliseconds = new Date().getTime();
  const startDateMilliseconds = parseDate(
    timedBannerDates?.startDate,
  ).getTime();
  // if no endDate, only validate against startDate, used for privacy cookie consent
  if (!timedBannerDates?.endDate) {
    return startDateMilliseconds <= currentDateMilliseconds;
  }
  const endDateMilliseconds = parseDate(timedBannerDates.endDate).getTime();
  if (startDateMilliseconds < endDateMilliseconds) {
    return (
      currentDateMilliseconds <= endDateMilliseconds &&
      currentDateMilliseconds >= startDateMilliseconds
    );
  }
  return false;
};
