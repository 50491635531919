import { getMetaDescription } from '@seek/seek-jobs-seo';

import { useSelector } from 'src/store/react';
import {
  selectPathname,
  selectQuery,
  selectResultsCount,
  selectResultsLocation,
} from 'src/store/selectors';
import { useMelwaysCountry } from 'src/utils/melwaysHelper';

export const useDescriptionContent = () => {
  const country = useMelwaysCountry();
  const path = useSelector(selectPathname);
  const query = useSelector(selectQuery);
  const location = useSelector(selectResultsLocation);
  const totalCount = useSelector(selectResultsCount);

  return getMetaDescription({
    country,
    path,
    query,
    location,
    totalCount: totalCount || 0,
  });
};
