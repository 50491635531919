import { useTranslations } from '@vocab/react';
import { Autosuggest, Box } from 'braid-design-system';
import { useCallback, useEffect, useState } from 'react';

import useLocationSuggestions from 'src/modules/hooks/useLocationSuggestions';
import { useDispatch, useSelector } from 'src/store/react';
import { clearResults } from 'src/store/results';
import {
  updateWhereField as _updateWhereField,
  whereFieldFocused,
  type whereSuggestionSelected,
} from 'src/store/search';
import { selectWhereField } from 'src/store/selectors';
import { useMelwaysCountry } from 'src/utils/melwaysHelper';

import translations from './.vocab';

export default function WhereField({
  onSuggestionSelected,
  onInputBlur,
  onClear: onExternalClear,
}: {
  onSuggestionSelected: (
    payload: Parameters<typeof whereSuggestionSelected>[0],
  ) => void;
  onInputBlur: () => void;
  onClear: () => void;
}) {
  const { t } = useTranslations(translations);
  const dispatch = useDispatch();
  const whereField = useSelector(selectWhereField);
  const country = useMelwaysCountry();
  const [whereFieldLocalState, setKeywordFieldLocalState] =
    useState(whereField);

  useEffect(() => {
    if (whereField !== whereFieldLocalState) {
      setKeywordFieldLocalState(whereField);
    }
  }, [whereField, whereFieldLocalState]);

  const updateWhereField = (text: string) => {
    setKeywordFieldLocalState(text);
    dispatch(_updateWhereField(text));
  };

  const { getSuggestions, suggestions } = useLocationSuggestions();

  const onClear = () => {
    updateWhereField('');
    dispatch(clearResults());
    getSuggestions({ text: '' });
    onExternalClear();
  };

  const onFocus = useCallback(() => {
    dispatch(whereFieldFocused());
    getSuggestions({ text: whereFieldLocalState });
  }, [dispatch, getSuggestions, whereFieldLocalState]);

  const onChange = (params: any) => {
    // if params.value is defined then a value was selected
    // from Autosuggest dropdown, otherwise value is taken from text field.
    if (params.value) {
      setKeywordFieldLocalState(params.text);
      onSuggestionSelected({
        suggestion: params.value,
        suggestionValue: params.text,
      });
    } else if (params.text !== whereFieldLocalState) {
      updateWhereField(params.text);
      getSuggestions({ text: params.text });
    }
  };

  return (
    <Box
      role="region"
      aria-label={t('Enter a location')}
      data-automation="whereFieldOptions"
    >
      <Autosuggest
        id="SearchBar__Where"
        name="where"
        label={t('Where')}
        value={{ text: whereFieldLocalState }}
        type="search"
        onBlur={onInputBlur}
        onClear={onClear}
        onChange={onChange}
        onFocus={onFocus}
        showMobileBackdrop
        scrollToTopOnMobile
        suggestions={suggestions}
        placeholder={
          country === 'nz'
            ? t('Enter suburb, town or city')
            : t('Enter suburb, city, or region')
        }
        data={{ automation: 'SearchBar__Where' }}
      />
    </Box>
  );
}
