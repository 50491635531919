import type { SearchResultJobV5 } from '@seek/chalice-types';
import { JOB_DISPLAY_TYPE_QUERY_KEY } from '@seek/job-details-view';

import { SELECTED_JOB_ID_SEARCH_QUERY_KEY } from 'src/store/results';
import { pushParams, removeParams } from 'src/utils/urlParams';

export const persistJobUrl = ({
  job: { id, displayType },
}: {
  job: Pick<SearchResultJobV5, 'id' | 'displayType'>;
}) =>
  pushParams({
    [SELECTED_JOB_ID_SEARCH_QUERY_KEY]: String(id),
    [JOB_DISPLAY_TYPE_QUERY_KEY]: displayType,
  });

export const removePersistedJobUrl = () =>
  removeParams([SELECTED_JOB_ID_SEARCH_QUERY_KEY, JOB_DISPLAY_TYPE_QUERY_KEY]);

export const compareJobIds = (
  currentJobId?: string,
  selectedJobId?: string,
) => {
  if (!currentJobId || !selectedJobId) {
    return false;
  }

  return currentJobId === selectedJobId;
};
