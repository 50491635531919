import { useTranslations } from '@vocab/react';
import { Box, HiddenVisually } from 'braid-design-system';
import type { ComponentProps } from 'react';

import RefineRadio from '../RefineRadio/RefineRadio';

import translations from './.vocab';

interface WorkArrangementProps {
  handleSelection: (value: string) => any;
  workArrangements: ComponentProps<typeof RefineRadio>['options'];
}

const WorkArrangements = (props: WorkArrangementProps) => {
  const { t } = useTranslations(translations);
  const { handleSelection, workArrangements } = props;
  return (
    <Box
      component="nav"
      role="navigation"
      aria-labelledby="RefineWorkArrangement"
    >
      <HiddenVisually>
        <h1 id="RefineWorkArrangement">{t('Choose a remote option')}</h1>
      </HiddenVisually>
      <RefineRadio
        multiSelect={true}
        handleSelection={handleSelection}
        labelledBy="RefineWorkArrangement"
        options={workArrangements}
        path="/jobs"
        data-automation="refineWorkArrangement"
      />
    </Box>
  );
};

export default WorkArrangements;
