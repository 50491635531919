
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJob21lIHBhZ2UiLCJzZWFyY2ggcmVzdWx0cyI6InNlYXJjaCByZXN1bHRzIiwiam9iIGRldGFpbHMiOiJqb2IgZGV0YWlscyIsImVtYWlsIHRvIGEgZnJpZW5kIjoiZW1haWwgdG8gYSBmcmllbmQiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiZW1haWwgdG8gYSBmcmllbmQgc3VjY2VzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJob21lIHBhZ2UiLCJzZWFyY2ggcmVzdWx0cyI6InNlYXJjaCByZXN1bHRzIiwiam9iIGRldGFpbHMiOiJqb2IgZGV0YWlscyIsImVtYWlsIHRvIGEgZnJpZW5kIjoiZW1haWwgdG8gYSBmcmllbmQiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiZW1haWwgdG8gYSBmcmllbmQgc3VjY2VzcyJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJob21lIHBhZ2UiLCJzZWFyY2ggcmVzdWx0cyI6InNlYXJjaCByZXN1bHRzIiwiam9iIGRldGFpbHMiOiJqb2IgZGV0YWlscyIsImVtYWlsIHRvIGEgZnJpZW5kIjoiZW1haWwgdG8gYSBmcmllbmQiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiZW1haWwgdG8gYSBmcmllbmQgc3VjY2VzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJob21lIHBhZ2UiLCJzZWFyY2ggcmVzdWx0cyI6InNlYXJjaCByZXN1bHRzIiwiam9iIGRldGFpbHMiOiJqb2IgZGV0YWlscyIsImVtYWlsIHRvIGEgZnJpZW5kIjoiZW1haWwgdG8gYSBmcmllbmQiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiZW1haWwgdG8gYSBmcmllbmQgc3VjY2VzcyJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJob21lIHBhZ2UiLCJzZWFyY2ggcmVzdWx0cyI6InNlYXJjaCByZXN1bHRzIiwiam9iIGRldGFpbHMiOiJqb2IgZGV0YWlscyIsImVtYWlsIHRvIGEgZnJpZW5kIjoiZW1haWwgdG8gYSBmcmllbmQiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiZW1haWwgdG8gYSBmcmllbmQgc3VjY2VzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJob21lIHBhZ2UiLCJzZWFyY2ggcmVzdWx0cyI6InNlYXJjaCByZXN1bHRzIiwiam9iIGRldGFpbHMiOiJqb2IgZGV0YWlscyIsImVtYWlsIHRvIGEgZnJpZW5kIjoiZW1haWwgdG8gYSBmcmllbmQiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiZW1haWwgdG8gYSBmcmllbmQgc3VjY2VzcyJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJob21lIHBhZ2UiLCJzZWFyY2ggcmVzdWx0cyI6InNlYXJjaCByZXN1bHRzIiwiam9iIGRldGFpbHMiOiJqb2IgZGV0YWlscyIsImVtYWlsIHRvIGEgZnJpZW5kIjoiZW1haWwgdG8gYSBmcmllbmQiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiZW1haWwgdG8gYSBmcmllbmQgc3VjY2VzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJob21lIHBhZ2UiLCJzZWFyY2ggcmVzdWx0cyI6InNlYXJjaCByZXN1bHRzIiwiam9iIGRldGFpbHMiOiJqb2IgZGV0YWlscyIsImVtYWlsIHRvIGEgZnJpZW5kIjoiZW1haWwgdG8gYSBmcmllbmQiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiZW1haWwgdG8gYSBmcmllbmQgc3VjY2VzcyJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJob21lIHBhZ2UiLCJzZWFyY2ggcmVzdWx0cyI6InNlYXJjaCByZXN1bHRzIiwiam9iIGRldGFpbHMiOiJqb2IgZGV0YWlscyIsImVtYWlsIHRvIGEgZnJpZW5kIjoiZW1haWwgdG8gYSBmcmllbmQiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiZW1haWwgdG8gYSBmcmllbmQgc3VjY2VzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJob21lIHBhZ2UiLCJzZWFyY2ggcmVzdWx0cyI6InNlYXJjaCByZXN1bHRzIiwiam9iIGRldGFpbHMiOiJqb2IgZGV0YWlscyIsImVtYWlsIHRvIGEgZnJpZW5kIjoiZW1haWwgdG8gYSBmcmllbmQiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiZW1haWwgdG8gYSBmcmllbmQgc3VjY2VzcyJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJob21lIHBhZ2UiLCJzZWFyY2ggcmVzdWx0cyI6InNlYXJjaCByZXN1bHRzIiwiam9iIGRldGFpbHMiOiJqb2IgZGV0YWlscyIsImVtYWlsIHRvIGEgZnJpZW5kIjoiZW1haWwgdG8gYSBmcmllbmQiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiZW1haWwgdG8gYSBmcmllbmQgc3VjY2VzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJob21lIHBhZ2UiLCJzZWFyY2ggcmVzdWx0cyI6InNlYXJjaCByZXN1bHRzIiwiam9iIGRldGFpbHMiOiJqb2IgZGV0YWlscyIsImVtYWlsIHRvIGEgZnJpZW5kIjoiZW1haWwgdG8gYSBmcmllbmQiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiZW1haWwgdG8gYSBmcmllbmQgc3VjY2VzcyJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJob21lIHBhZ2UiLCJzZWFyY2ggcmVzdWx0cyI6InNlYXJjaCByZXN1bHRzIiwiam9iIGRldGFpbHMiOiJqb2IgZGV0YWlscyIsImVtYWlsIHRvIGEgZnJpZW5kIjoiZW1haWwgdG8gYSBmcmllbmQiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiZW1haWwgdG8gYSBmcmllbmQgc3VjY2VzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJob21lIHBhZ2UiLCJzZWFyY2ggcmVzdWx0cyI6InNlYXJjaCByZXN1bHRzIiwiam9iIGRldGFpbHMiOiJqb2IgZGV0YWlscyIsImVtYWlsIHRvIGEgZnJpZW5kIjoiZW1haWwgdG8gYSBmcmllbmQiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiZW1haWwgdG8gYSBmcmllbmQgc3VjY2VzcyJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJob21lIHBhZ2UiLCJzZWFyY2ggcmVzdWx0cyI6InNlYXJjaCByZXN1bHRzIiwiam9iIGRldGFpbHMiOiJqb2IgZGV0YWlscyIsImVtYWlsIHRvIGEgZnJpZW5kIjoiZW1haWwgdG8gYSBmcmllbmQiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiZW1haWwgdG8gYSBmcmllbmQgc3VjY2VzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJob21lIHBhZ2UiLCJzZWFyY2ggcmVzdWx0cyI6InNlYXJjaCByZXN1bHRzIiwiam9iIGRldGFpbHMiOiJqb2IgZGV0YWlscyIsImVtYWlsIHRvIGEgZnJpZW5kIjoiZW1haWwgdG8gYSBmcmllbmQiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiZW1haWwgdG8gYSBmcmllbmQgc3VjY2VzcyJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJob21lIHBhZ2UiLCJzZWFyY2ggcmVzdWx0cyI6InNlYXJjaCByZXN1bHRzIiwiam9iIGRldGFpbHMiOiJqb2IgZGV0YWlscyIsImVtYWlsIHRvIGEgZnJpZW5kIjoiZW1haWwgdG8gYSBmcmllbmQiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiZW1haWwgdG8gYSBmcmllbmQgc3VjY2VzcyJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJob21lIHBhZ2UiLCJzZWFyY2ggcmVzdWx0cyI6InNlYXJjaCByZXN1bHRzIiwiam9iIGRldGFpbHMiOiJqb2IgZGV0YWlscyIsImVtYWlsIHRvIGEgZnJpZW5kIjoiZW1haWwgdG8gYSBmcmllbmQiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiZW1haWwgdG8gYSBmcmllbmQgc3VjY2VzcyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJIYWxhbWFuIHV0YW1hIiwic2VhcmNoIHJlc3VsdHMiOiJoYXNpbCBwZW5jYXJpYW4iLCJqb2IgZGV0YWlscyI6ImRldGFpbCBwZWtlcmphYW4iLCJlbWFpbCB0byBhIGZyaWVuZCI6ImtpcmltIHZpYSBlbWFpbCBrZSB0ZW1hbiIsImVtYWlsIHRvIGEgZnJpZW5kIHN1Y2Nlc3MiOiJlbWFpbCBiZXJoYXNpbCBkaWtpcmltIGtlIHRlbWFuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJIYWxhbWFuIHV0YW1hIiwic2VhcmNoIHJlc3VsdHMiOiJoYXNpbCBwZW5jYXJpYW4iLCJqb2IgZGV0YWlscyI6ImRldGFpbCBwZWtlcmphYW4iLCJlbWFpbCB0byBhIGZyaWVuZCI6ImtpcmltIHZpYSBlbWFpbCBrZSB0ZW1hbiIsImVtYWlsIHRvIGEgZnJpZW5kIHN1Y2Nlc3MiOiJlbWFpbCBiZXJoYXNpbCBkaWtpcmltIGtlIHRlbWFuIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiLguKvguJnguYnguLLguKvguKXguLHguIEiLCJzZWFyY2ggcmVzdWx0cyI6IuC4nOC4peC4peC4seC4nuC4mOC5jOC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4siIsImpvYiBkZXRhaWxzIjoi4Lij4Liy4Lii4Lil4Liw4LmA4Lit4Li14Lii4LiU4LiH4Liy4LiZIiwiZW1haWwgdG8gYSBmcmllbmQiOiLguKrguYjguIfguK3guLXguYDguKHguKXguJbguLbguIfguYDguJ7guLfguYjguK3guJkiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoi4Liq4LmI4LiH4Lit4Li14LmA4Lih4Lil4LiW4Li24LiH4LmA4Lie4Li34LmI4Lit4LiZ4LmB4Lil4LmJ4LinIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiLguKvguJnguYnguLLguKvguKXguLHguIEiLCJzZWFyY2ggcmVzdWx0cyI6IuC4nOC4peC4peC4seC4nuC4mOC5jOC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4siIsImpvYiBkZXRhaWxzIjoi4Lij4Liy4Lii4Lil4Liw4LmA4Lit4Li14Lii4LiU4LiH4Liy4LiZIiwiZW1haWwgdG8gYSBmcmllbmQiOiLguKrguYjguIfguK3guLXguYDguKHguKXguJbguLbguIfguYDguJ7guLfguYjguK3guJkiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoi4Liq4LmI4LiH4Lit4Li14LmA4Lih4Lil4LiW4Li24LiH4LmA4Lie4Li34LmI4Lit4LiZ4LmB4Lil4LmJ4LinIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJIYWxhbWFuIHV0YW1hIiwic2VhcmNoIHJlc3VsdHMiOiJoYXNpbCBwZW5jYXJpYW4iLCJqb2IgZGV0YWlscyI6ImRldGFpbCBwZWtlcmphYW4iLCJlbWFpbCB0byBhIGZyaWVuZCI6ImtpcmltIHZpYSBlbWFpbCBrZSB0ZW1hbiIsImVtYWlsIHRvIGEgZnJpZW5kIHN1Y2Nlc3MiOiJlbWFpbCBiZXJoYXNpbCBkaWtpcmltIGtlIHRlbWFuIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJIYWxhbWFuIHV0YW1hIiwic2VhcmNoIHJlc3VsdHMiOiJoYXNpbCBwZW5jYXJpYW4iLCJqb2IgZGV0YWlscyI6ImRldGFpbCBwZWtlcmphYW4iLCJlbWFpbCB0byBhIGZyaWVuZCI6ImtpcmltIHZpYSBlbWFpbCBrZSB0ZW1hbiIsImVtYWlsIHRvIGEgZnJpZW5kIHN1Y2Nlc3MiOiJlbWFpbCBiZXJoYXNpbCBkaWtpcmltIGtlIHRlbWFuIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiLguKvguJnguYnguLLguKvguKXguLHguIEiLCJzZWFyY2ggcmVzdWx0cyI6IuC4nOC4peC4peC4seC4nuC4mOC5jOC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4siIsImpvYiBkZXRhaWxzIjoi4Lij4Liy4Lii4Lil4Liw4LmA4Lit4Li14Lii4LiU4LiH4Liy4LiZIiwiZW1haWwgdG8gYSBmcmllbmQiOiLguKrguYjguIfguK3guLXguYDguKHguKXguJbguLbguIfguYDguJ7guLfguYjguK3guJkiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoi4Liq4LmI4LiH4Lit4Li14LmA4Lih4Lil4LiW4Li24LiH4LmA4Lie4Li34LmI4Lit4LiZ4LmB4Lil4LmJ4LinIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiLguKvguJnguYnguLLguKvguKXguLHguIEiLCJzZWFyY2ggcmVzdWx0cyI6IuC4nOC4peC4peC4seC4nuC4mOC5jOC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4siIsImpvYiBkZXRhaWxzIjoi4Lij4Liy4Lii4Lil4Liw4LmA4Lit4Li14Lii4LiU4LiH4Liy4LiZIiwiZW1haWwgdG8gYSBmcmllbmQiOiLguKrguYjguIfguK3guLXguYDguKHguKXguJbguLbguIfguYDguJ7guLfguYjguK3guJkiLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoi4Liq4LmI4LiH4Lit4Li14LmA4Lih4Lil4LiW4Li24LiH4LmA4Lie4Li34LmI4Lit4LiZ4LmB4Lil4LmJ4LinIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJb4bipw7bDtm3MguG6veG6vSDGpcSDxIPEo%2BG6veG6vV0iLCJzZWFyY2ggcmVzdWx0cyI6IlvFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkgxZnhur3hur3hur3Foceax5rHmsaa4bmvxaFdIiwiam9iIGRldGFpbHMiOiJbxLXDtsO2w7bDnyDGjOG6veG6veG6veG5r8SDxIPEg8Osw6zDrMaaxaFdIiwiZW1haWwgdG8gYSBmcmllbmQiOiJb4bq94bq9bcyCxIPEg8Osw6zGmiDhua%2FDtsO2IMSDxIMgxpLFmcOsw6zhur3hur3guIHguLXguYnGjF0iLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiW%2BG6veG6veG6vW3MgsSDxIPEg8Osw6zDrMaaIOG5r8O2w7bDtiDEg8SDxIMgxpLFmcOsw6zDrOG6veG6veG6veC4geC4teC5icaMIMWhx5rHmseaw6fDp%2BG6veG6veG6vcWhxaFdIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJob21lIHBhZ2UiOiJb4bipw7bDtm3MguG6veG6vSDGpcSDxIPEo%2BG6veG6vV0iLCJzZWFyY2ggcmVzdWx0cyI6IlvFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkgxZnhur3hur3hur3Foceax5rHmsaa4bmvxaFdIiwiam9iIGRldGFpbHMiOiJbxLXDtsO2w7bDnyDGjOG6veG6veG6veG5r8SDxIPEg8Osw6zDrMaaxaFdIiwiZW1haWwgdG8gYSBmcmllbmQiOiJb4bq94bq9bcyCxIPEg8Osw6zGmiDhua%2FDtsO2IMSDxIMgxpLFmcOsw6zhur3hur3guIHguLXguYnGjF0iLCJlbWFpbCB0byBhIGZyaWVuZCBzdWNjZXNzIjoiW%2BG6veG6veG6vW3MgsSDxIPEg8Osw6zDrMaaIOG5r8O2w7bDtiDEg8SDxIMgxpLFmcOsw6zDrOG6veG6veG6veC4geC4teC5icaMIMWhx5rHmseaw6fDp%2BG6veG6veG6vcWhxaFdIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    