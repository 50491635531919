
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlNvcnJ5LCB0aGVyZSBkb2VzbuKAmXQgc2VlbSB0byBiZSBhbnl0aGluZyBoZXJlLi4uIiwiQ2hlY2sgdGhlIFVSTCwgdGhlcmUgbWF5IGJlIGEgdHlwby4iOiJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiIsIklmIGFsbCBlbHNlIGZhaWxzLCB0cnkgZ29pbmcgYmFjay4iOiJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlNvcnJ5LCB0aGVyZSBkb2VzbuKAmXQgc2VlbSB0byBiZSBhbnl0aGluZyBoZXJlLi4uIiwiQ2hlY2sgdGhlIFVSTCwgdGhlcmUgbWF5IGJlIGEgdHlwby4iOiJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiIsIklmIGFsbCBlbHNlIGZhaWxzLCB0cnkgZ29pbmcgYmFjay4iOiJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlNvcnJ5LCB0aGVyZSBkb2VzbuKAmXQgc2VlbSB0byBiZSBhbnl0aGluZyBoZXJlLi4uIiwiQ2hlY2sgdGhlIFVSTCwgdGhlcmUgbWF5IGJlIGEgdHlwby4iOiJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiIsIklmIGFsbCBlbHNlIGZhaWxzLCB0cnkgZ29pbmcgYmFjay4iOiJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlNvcnJ5LCB0aGVyZSBkb2VzbuKAmXQgc2VlbSB0byBiZSBhbnl0aGluZyBoZXJlLi4uIiwiQ2hlY2sgdGhlIFVSTCwgdGhlcmUgbWF5IGJlIGEgdHlwby4iOiJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiIsIklmIGFsbCBlbHNlIGZhaWxzLCB0cnkgZ29pbmcgYmFjay4iOiJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlNvcnJ5LCB0aGVyZSBkb2VzbuKAmXQgc2VlbSB0byBiZSBhbnl0aGluZyBoZXJlLi4uIiwiQ2hlY2sgdGhlIFVSTCwgdGhlcmUgbWF5IGJlIGEgdHlwby4iOiJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiIsIklmIGFsbCBlbHNlIGZhaWxzLCB0cnkgZ29pbmcgYmFjay4iOiJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlNvcnJ5LCB0aGVyZSBkb2VzbuKAmXQgc2VlbSB0byBiZSBhbnl0aGluZyBoZXJlLi4uIiwiQ2hlY2sgdGhlIFVSTCwgdGhlcmUgbWF5IGJlIGEgdHlwby4iOiJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiIsIklmIGFsbCBlbHNlIGZhaWxzLCB0cnkgZ29pbmcgYmFjay4iOiJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlNvcnJ5LCB0aGVyZSBkb2VzbuKAmXQgc2VlbSB0byBiZSBhbnl0aGluZyBoZXJlLi4uIiwiQ2hlY2sgdGhlIFVSTCwgdGhlcmUgbWF5IGJlIGEgdHlwby4iOiJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiIsIklmIGFsbCBlbHNlIGZhaWxzLCB0cnkgZ29pbmcgYmFjay4iOiJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlNvcnJ5LCB0aGVyZSBkb2VzbuKAmXQgc2VlbSB0byBiZSBhbnl0aGluZyBoZXJlLi4uIiwiQ2hlY2sgdGhlIFVSTCwgdGhlcmUgbWF5IGJlIGEgdHlwby4iOiJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiIsIklmIGFsbCBlbHNlIGZhaWxzLCB0cnkgZ29pbmcgYmFjay4iOiJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlNvcnJ5LCB0aGVyZSBkb2VzbuKAmXQgc2VlbSB0byBiZSBhbnl0aGluZyBoZXJlLi4uIiwiQ2hlY2sgdGhlIFVSTCwgdGhlcmUgbWF5IGJlIGEgdHlwby4iOiJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiIsIklmIGFsbCBlbHNlIGZhaWxzLCB0cnkgZ29pbmcgYmFjay4iOiJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlNvcnJ5LCB0aGVyZSBkb2VzbuKAmXQgc2VlbSB0byBiZSBhbnl0aGluZyBoZXJlLi4uIiwiQ2hlY2sgdGhlIFVSTCwgdGhlcmUgbWF5IGJlIGEgdHlwby4iOiJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiIsIklmIGFsbCBlbHNlIGZhaWxzLCB0cnkgZ29pbmcgYmFjay4iOiJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlNvcnJ5LCB0aGVyZSBkb2VzbuKAmXQgc2VlbSB0byBiZSBhbnl0aGluZyBoZXJlLi4uIiwiQ2hlY2sgdGhlIFVSTCwgdGhlcmUgbWF5IGJlIGEgdHlwby4iOiJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiIsIklmIGFsbCBlbHNlIGZhaWxzLCB0cnkgZ29pbmcgYmFjay4iOiJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlNvcnJ5LCB0aGVyZSBkb2VzbuKAmXQgc2VlbSB0byBiZSBhbnl0aGluZyBoZXJlLi4uIiwiQ2hlY2sgdGhlIFVSTCwgdGhlcmUgbWF5IGJlIGEgdHlwby4iOiJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiIsIklmIGFsbCBlbHNlIGZhaWxzLCB0cnkgZ29pbmcgYmFjay4iOiJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlNvcnJ5LCB0aGVyZSBkb2VzbuKAmXQgc2VlbSB0byBiZSBhbnl0aGluZyBoZXJlLi4uIiwiQ2hlY2sgdGhlIFVSTCwgdGhlcmUgbWF5IGJlIGEgdHlwby4iOiJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiIsIklmIGFsbCBlbHNlIGZhaWxzLCB0cnkgZ29pbmcgYmFjay4iOiJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlNvcnJ5LCB0aGVyZSBkb2VzbuKAmXQgc2VlbSB0byBiZSBhbnl0aGluZyBoZXJlLi4uIiwiQ2hlY2sgdGhlIFVSTCwgdGhlcmUgbWF5IGJlIGEgdHlwby4iOiJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiIsIklmIGFsbCBlbHNlIGZhaWxzLCB0cnkgZ29pbmcgYmFjay4iOiJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlNvcnJ5LCB0aGVyZSBkb2VzbuKAmXQgc2VlbSB0byBiZSBhbnl0aGluZyBoZXJlLi4uIiwiQ2hlY2sgdGhlIFVSTCwgdGhlcmUgbWF5IGJlIGEgdHlwby4iOiJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiIsIklmIGFsbCBlbHNlIGZhaWxzLCB0cnkgZ29pbmcgYmFjay4iOiJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlNvcnJ5LCB0aGVyZSBkb2VzbuKAmXQgc2VlbSB0byBiZSBhbnl0aGluZyBoZXJlLi4uIiwiQ2hlY2sgdGhlIFVSTCwgdGhlcmUgbWF5IGJlIGEgdHlwby4iOiJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiIsIklmIGFsbCBlbHNlIGZhaWxzLCB0cnkgZ29pbmcgYmFjay4iOiJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlNvcnJ5LCB0aGVyZSBkb2VzbuKAmXQgc2VlbSB0byBiZSBhbnl0aGluZyBoZXJlLi4uIiwiQ2hlY2sgdGhlIFVSTCwgdGhlcmUgbWF5IGJlIGEgdHlwby4iOiJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiIsIklmIGFsbCBlbHNlIGZhaWxzLCB0cnkgZ29pbmcgYmFjay4iOiJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlNvcnJ5LCB0aGVyZSBkb2VzbuKAmXQgc2VlbSB0byBiZSBhbnl0aGluZyBoZXJlLi4uIiwiQ2hlY2sgdGhlIFVSTCwgdGhlcmUgbWF5IGJlIGEgdHlwby4iOiJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiIsIklmIGFsbCBlbHNlIGZhaWxzLCB0cnkgZ29pbmcgYmFjay4iOiJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6Ik1hYWYsIHRhbXBha255YSB0aWRhayBhZGEgaGFzaWwgeWFuZyBzZXN1YWkuLi4iLCJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiI6IlBlcmlrc2EgVVJMLCBtdW5na2luIHRlcmRhcGF0IGtlc2FsYWhhbiBwZW51bGlzYW4uIiwiSWYgYWxsIGVsc2UgZmFpbHMsIHRyeSBnb2luZyBiYWNrLiI6Ikppa2Egc2VtdWEgZ2FnYWwsIHNpbGFrYW4gY29iYSBsYWdpLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6Ik1hYWYsIHRhbXBha255YSB0aWRhayBhZGEgaGFzaWwgeWFuZyBzZXN1YWkuLi4iLCJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiI6IlBlcmlrc2EgVVJMLCBtdW5na2luIHRlcmRhcGF0IGtlc2FsYWhhbiBwZW51bGlzYW4uIiwiSWYgYWxsIGVsc2UgZmFpbHMsIHRyeSBnb2luZyBiYWNrLiI6Ikppa2Egc2VtdWEgZ2FnYWwsIHNpbGFrYW4gY29iYSBsYWdpLiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IuC4guC4reC4reC4oOC4seC4oiDguJTguLnguYDguKvguKHguLfguK3guJnguKvguJnguYnguLLguJnguLXguYnguYTguKHguYjguKHguLXguILguYnguK3guKHguLnguKXguJfguLXguYjguIjguLDguYHguKrguJTguIciLCJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiI6IuC4leC4o%2BC4p%2BC4iOC4quC4reC4miBVUkwg4Lit4Liy4LiI4Lih4Li14LiB4Liy4Lij4Lie4Li04Lih4Lie4LmM4Lic4Li04LiUIiwiSWYgYWxsIGVsc2UgZmFpbHMsIHRyeSBnb2luZyBiYWNrLiI6IuC4q%2BC4suC4geC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC5hOC4oeC5iOC4quC4s%2BC5gOC4o%2BC5h%2BC4iCDguIHguKPguLjguJPguLLguKLguYnguK3guJnguIHguKXguLHguJrguYTguJsifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IuC4guC4reC4reC4oOC4seC4oiDguJTguLnguYDguKvguKHguLfguK3guJnguKvguJnguYnguLLguJnguLXguYnguYTguKHguYjguKHguLXguILguYnguK3guKHguLnguKXguJfguLXguYjguIjguLDguYHguKrguJTguIciLCJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiI6IuC4leC4o%2BC4p%2BC4iOC4quC4reC4miBVUkwg4Lit4Liy4LiI4Lih4Li14LiB4Liy4Lij4Lie4Li04Lih4Lie4LmM4Lic4Li04LiUIiwiSWYgYWxsIGVsc2UgZmFpbHMsIHRyeSBnb2luZyBiYWNrLiI6IuC4q%2BC4suC4geC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC5hOC4oeC5iOC4quC4s%2BC5gOC4o%2BC5h%2BC4iCDguIHguKPguLjguJPguLLguKLguYnguK3guJnguIHguKXguLHguJrguYTguJsifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6Ik1hYWYsIHRhbXBha255YSB0aWRhayBhZGEgaGFzaWwgeWFuZyBzZXN1YWkuLi4iLCJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiI6IlBlcmlrc2EgVVJMLCBtdW5na2luIHRlcmRhcGF0IGtlc2FsYWhhbiBwZW51bGlzYW4uIiwiSWYgYWxsIGVsc2UgZmFpbHMsIHRyeSBnb2luZyBiYWNrLiI6Ikppa2Egc2VtdWEgZ2FnYWwsIHNpbGFrYW4gY29iYSBsYWdpLiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6Ik1hYWYsIHRhbXBha255YSB0aWRhayBhZGEgaGFzaWwgeWFuZyBzZXN1YWkuLi4iLCJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiI6IlBlcmlrc2EgVVJMLCBtdW5na2luIHRlcmRhcGF0IGtlc2FsYWhhbiBwZW51bGlzYW4uIiwiSWYgYWxsIGVsc2UgZmFpbHMsIHRyeSBnb2luZyBiYWNrLiI6Ikppa2Egc2VtdWEgZ2FnYWwsIHNpbGFrYW4gY29iYSBsYWdpLiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IuC4guC4reC4reC4oOC4seC4oiDguJTguLnguYDguKvguKHguLfguK3guJnguKvguJnguYnguLLguJnguLXguYnguYTguKHguYjguKHguLXguILguYnguK3guKHguLnguKXguJfguLXguYjguIjguLDguYHguKrguJTguIciLCJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiI6IuC4leC4o%2BC4p%2BC4iOC4quC4reC4miBVUkwg4Lit4Liy4LiI4Lih4Li14LiB4Liy4Lij4Lie4Li04Lih4Lie4LmM4Lic4Li04LiUIiwiSWYgYWxsIGVsc2UgZmFpbHMsIHRyeSBnb2luZyBiYWNrLiI6IuC4q%2BC4suC4geC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC5hOC4oeC5iOC4quC4s%2BC5gOC4o%2BC5h%2BC4iCDguIHguKPguLjguJPguLLguKLguYnguK3guJnguIHguKXguLHguJrguYTguJsifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IuC4guC4reC4reC4oOC4seC4oiDguJTguLnguYDguKvguKHguLfguK3guJnguKvguJnguYnguLLguJnguLXguYnguYTguKHguYjguKHguLXguILguYnguK3guKHguLnguKXguJfguLXguYjguIjguLDguYHguKrguJTguIciLCJDaGVjayB0aGUgVVJMLCB0aGVyZSBtYXkgYmUgYSB0eXBvLiI6IuC4leC4o%2BC4p%2BC4iOC4quC4reC4miBVUkwg4Lit4Liy4LiI4Lih4Li14LiB4Liy4Lij4Lie4Li04Lih4Lie4LmM4Lic4Li04LiUIiwiSWYgYWxsIGVsc2UgZmFpbHMsIHRyeSBnb2luZyBiYWNrLiI6IuC4q%2BC4suC4geC4lOC4s%2BC5gOC4meC4tOC4meC4geC4suC4o%2BC5hOC4oeC5iOC4quC4s%2BC5gOC4o%2BC5h%2BC4iCDguIHguKPguLjguJPguLLguKLguYnguK3guJnguIHguKXguLHguJrguYTguJsifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlvFoMO2w7bDtsWZxZnDvcO9w70sIOG5r%2BG4qeG6veG6veG6vcWZ4bq94bq94bq9IMaMw7bDtsO24bq94bq94bq9xaHguIHguLXguYnigJnhua8gxaHhur3hur3hur3hur3hur3hur1tzIIg4bmvw7bDtsO2IMOf4bq94bq94bq9IMSDxIPEg%2BC4geC4teC5icO9w73DveG5r%2BG4qcOsw6zDrOC4geC4teC5icSjIOG4qeG6veG6veG6vcWZ4bq94bq94bq9Li4uXSIsIkNoZWNrIHRoZSBVUkwsIHRoZXJlIG1heSBiZSBhIHR5cG8uIjoiW8OH4bip4bq94bq94bq9w6fEtyDhua%2FhuKnhur3hur3hur0gx5nFmMWBLCDhua%2FhuKnhur3hur3hur3FmeG6veG6veG6vSBtzILEg8SDxIPDvcO9w70gw5%2Fhur3hur3hur0gxIPEg8SDIOG5r8O9w73Dvcalw7bDtsO2Ll0iLCJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIjoiW8OPxpIgxIPEg8SDxprGmiDhur3hur3hur3GmsWh4bq94bq94bq9IMaSxIPEg8SDw6zDrMOsxprFoSwg4bmvxZnDvcO9w70gxKPDtsO2w7bDrMOsw6zguIHguLXguYnEoyDDn8SDxIPEg8OnxLcuXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJTb3JyeSwgdGhlcmUgZG9lc27igJl0IHNlZW0gdG8gYmUgYW55dGhpbmcgaGVyZS4uLiI6IlvFoMO2w7bDtsWZxZnDvcO9w70sIOG5r%2BG4qeG6veG6veG6vcWZ4bq94bq94bq9IMaMw7bDtsO24bq94bq94bq9xaHguIHguLXguYnigJnhua8gxaHhur3hur3hur3hur3hur3hur1tzIIg4bmvw7bDtsO2IMOf4bq94bq94bq9IMSDxIPEg%2BC4geC4teC5icO9w73DveG5r%2BG4qcOsw6zDrOC4geC4teC5icSjIOG4qeG6veG6veG6vcWZ4bq94bq94bq9Li4uXSIsIkNoZWNrIHRoZSBVUkwsIHRoZXJlIG1heSBiZSBhIHR5cG8uIjoiW8OH4bip4bq94bq94bq9w6fEtyDhua%2FhuKnhur3hur3hur0gx5nFmMWBLCDhua%2FhuKnhur3hur3hur3FmeG6veG6veG6vSBtzILEg8SDxIPDvcO9w70gw5%2Fhur3hur3hur0gxIPEg8SDIOG5r8O9w73Dvcalw7bDtsO2Ll0iLCJJZiBhbGwgZWxzZSBmYWlscywgdHJ5IGdvaW5nIGJhY2suIjoiW8OPxpIgxIPEg8SDxprGmiDhur3hur3hur3GmsWh4bq94bq94bq9IMaSxIPEg8SDw6zDrMOsxprFoSwg4bmvxZnDvcO9w70gxKPDtsO2w7bDrMOsw6zguIHguLXguYnEoyDDn8SDxIPEg8OnxLcuXSJ9!"
        )
      )
      });
  
      export { translations as default };
    