import { useTranslations } from '@vocab/react';
import { HiddenVisually } from 'braid-design-system';
import { type ComponentProps, type KeyboardEvent, useCallback } from 'react';

import translations from './.vocab';
import RefineChecklist from './RefineChecklist/RefineChecklist';

interface RefineChecklistProps extends ComponentProps<typeof RefineChecklist> {}

interface Props {
  path: RefineChecklistProps['path'];
  classifications: RefineChecklistProps['options'];
  handleClose: (event: React.KeyboardEvent<HTMLElement>) => void;
  open: boolean;
}

export default ({ path, classifications, handleClose }: Props) => {
  const { t } = useTranslations(translations);
  const closeHandler = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      const ESCAPE_KEYCODE = 27;
      if ((event.code || event.keyCode) === ESCAPE_KEYCODE) {
        handleClose(event);
      }
    },
    [handleClose],
  );

  return (
    <nav
      onKeyUp={closeHandler}
      role="navigation"
      data-automation="searchClassification"
    >
      <HiddenVisually>
        <h1>{t('Classifications')}</h1>
      </HiddenVisually>
      <RefineChecklist options={classifications} path={path} />
    </nav>
  );
};
