import type { TypedDispatch } from 'src/store/types';

// Hook can be either
//  1. something dispatchable
//    - an action
//    - a thunk
//    - a promise
//  2. or a function that returns something dispatchable
export default (dispatch: TypedDispatch, hooks: any[]) => {
  const isActionCreator = (func: any) => typeof func === 'function';
  const isPromise = (prom: any) => prom && typeof prom.then === 'function';

  return Promise.all(
    hooks.map((actionCreator) => {
      const dispatchableOrPromise = isActionCreator(actionCreator)
        ? actionCreator()
        : actionCreator;

      // if it is a promise convert to dispatchable
      const dispatchable = isPromise(dispatchableOrPromise)
        ? () => dispatchableOrPromise
        : dispatchableOrPromise;

      return dispatchable && dispatch(dispatchable);
    }),
  );
};
