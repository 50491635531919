import type { SalaryType } from '@seek/chalice-types';
import {
  Box,
  RadioItem,
  RadioGroup,
  useResponsiveValue,
} from 'braid-design-system';
import type { ChangeEvent } from 'react';

import { Link } from 'src/components/NavLink/NavLink';
import { useSecondaryFilterContext } from 'src/components/Search/SecondaryFilter/context/SecondaryFilterContext';

import type { SalaryFields, SalaryRange } from '../../useSalaryRange';

import { salaryRangeParam } from './helper/salaryRangeParam';

import * as styles from '../../SalaryRange.css';

export const SalaryFieldDropdown = ({
  salary,
  activeDropdownField,
  salarySelected,
  setSalarySelected,
  salaryType,
}: {
  activeDropdownField: string | null;
  salary: SalaryRange;
  salarySelected: SalaryFields;
  setSalarySelected: React.Dispatch<React.SetStateAction<SalaryFields>>;
  salaryType: SalaryType;
}) => {
  const { setFilterSelection, setActiveDropdownField, ROBOTS_TAG } =
    useSecondaryFilterContext();
  const isMobile =
    useResponsiveValue()({
      mobile: true,
      tablet: false,
    }) || false;

  // As salary range, maximum salary labels (e.g. $350k+, $30k+, $175+) has a value of an empty string,
  // this serves as a placeholder value due to Braid's RadioItem's value property unable to
  // take an empty string or undefined value.
  const DEFAULT_MAXIMUM_RANGE = '999';

  const getInitialSalaryValueSelected = (id: string) => {
    if (id === 'salaryFrom') {
      return salarySelected.salaryFromValue || salary.ranges[0].value;
    }

    return salarySelected.salaryToValue || DEFAULT_MAXIMUM_RANGE;
  };

  return (
    <Box
      data={{ automation: 'salaryFieldDropdown' }}
      display={activeDropdownField === salary.id ? 'flex' : 'none'}
      position="relative"
      zIndex="dropdown"
    >
      <Box
        position="absolute"
        background="surface"
        borderRadius="standard"
        padding="xsmall"
        width="full"
        boxShadow="medium"
        className={styles.dropDown}
      >
        <RadioGroup
          id={salary.id}
          onChange={(e) => {
            const valueSelected = (e as ChangeEvent<HTMLInputElement>).target
              .value;

            setSalarySelected((currentSalarySelected) => ({
              ...currentSalarySelected,
              [salary.id === 'salaryFrom'
                ? `salaryFromValue`
                : `salaryToValue`]:
                valueSelected === DEFAULT_MAXIMUM_RANGE ? '' : valueSelected,
            }));

            setFilterSelection((currentFilterSelection) => ({
              ...currentFilterSelection,
              salarytype: salaryType,
              salaryrange: salaryRangeParam({
                salaryRangeSelected: currentFilterSelection?.salaryrange || '',
                salaryType: salary.id,
                value:
                  valueSelected === DEFAULT_MAXIMUM_RANGE ? '' : valueSelected,
              }),
            }));

            return activeDropdownField === salary.id
              ? setActiveDropdownField(null)
              : setActiveDropdownField(salary.id);
          }}
          value={getInitialSalaryValueSelected(salary.id)}
          size={isMobile ? 'small' : 'standard'}
          aria-labelledby="salaryRange"
        >
          {salary.ranges.map((option) => (
            <RadioItem
              disabled={!option.refineParams && !option.isActive}
              key={option.label}
              label={
                <Link
                  location={{
                    pathname: '/jobs',
                    query: option.refineParams,
                  }}
                  rel={ROBOTS_TAG}
                  disabledLinkNavigate
                >
                  {option.label}
                </Link>
              }
              value={option.value || DEFAULT_MAXIMUM_RANGE}
            />
          ))}
        </RadioGroup>
      </Box>
    </Box>
  );
};
