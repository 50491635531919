import { useTranslations } from '@vocab/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppConfig } from 'src/config/appConfig';
import { getWorkTypes } from 'src/modules/refine-job-search';
import { selectSearchQuery } from 'src/store/selectors';
import type { Option } from 'src/types/globals';

import { getRefineSearchQuery } from '../../utils/utils';
import type { RefineField } from '../RefineBar';
import WorkTypeSummary from '../WorkTypeSummary/WorkTypeSummary';

import translations from './.vocab';
import WorkTypes from './WorkTypes';

export const useWorkTypesField = (
  handleSelection: (fieldId: string) => void,
): RefineField => {
  const { t } = useTranslations(translations);

  const { zone, language } = useAppConfig();
  const query = getRefineSearchQuery(useSelector(selectSearchQuery));
  const workTypes = query?.worktype || '';

  const workTypeList = getWorkTypes(zone, language);

  const [activeValue, setActiveValue] = useState<string[]>(
    workTypes ? workTypes.split(',') : [],
  );

  useEffect(() => {
    const currWorkTypes = workTypes ? workTypes.split(',') : [];

    const uniqCurrWorkTypes = new Set(currWorkTypes);
    const uniqActiveValues = new Set(activeValue);

    const setsAreEqual =
      uniqCurrWorkTypes.size === uniqActiveValues.size &&
      [...uniqCurrWorkTypes].every((value) => uniqActiveValues.has(value));

    if (!setsAreEqual) {
      setActiveValue(currWorkTypes);
    }

    // Only the Redux state (workTypes) should trigger a re-run not local state (activeValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workTypes]);

  const getOptionItem = useCallback(
    (label: string, value: string) => {
      const isActive = activeValue.includes(value);
      const workTypeRefineParam = isActive
        ? activeValue.filter((v) => v !== value)
        : [...activeValue, value];
      return {
        label,
        value,
        isActive,
        refineParams: {
          ...query,
          worktype: workTypeRefineParam.join(','),
        },
      };
    },
    [activeValue, query],
  );

  const workTypeOptions: Option[] = useMemo(
    () =>
      workTypeList
        ? workTypeList.map(({ label, value }) => getOptionItem(label, value))
        : [],
    [workTypeList, getOptionItem],
  );

  const handleWorkTypeSelection = (value: string) => {
    setActiveValue(() => {
      if (activeValue.includes(value)) {
        return activeValue.filter((v) => v !== value);
      }
      return [...activeValue, value];
    });
    handleSelection('WorkType');
  };

  const isSelected = activeValue.length > 0;

  return {
    id: 'WorkType',
    ariaLabel: t('work type'),
    label: <WorkTypeSummary workTypes={workTypeOptions} />,
    filterComponent: () => (
      <WorkTypes
        handleSelection={handleWorkTypeSelection}
        workTypes={workTypeOptions}
      />
    ),
    isSelected,
  };
};
