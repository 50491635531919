import { useTranslations } from '@vocab/react';

import translations from './.vocab';
import type { VocabLocale } from './types';

export const useCountryTitle = () => {
  const { t } = useTranslations(translations);
  return t('country-title');
};

export const getCountryTitle = async (locale: VocabLocale) => {
  const messages = await translations.getMessages(locale);
  return messages['country-title'].format();
};
