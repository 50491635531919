import type { Language } from '@seek/melways-sites';

import { toLocale } from 'src/modules/locale-utils';

import {
  SET_LANGUAGE,
  type AppConfigAction,
  type AppConfigState,
  type SetLanguageAction,
} from './types';

export const initialState: AppConfigState = {
  brand: 'seek',
  site: 'candidate-seek-au',
  language: 'en',
  country: 'AU',
  zone: 'anz-1',
  zoneFeatures: {},
  locale: 'en-AU',
};

export const setLanguage = (language: Language): SetLanguageAction => ({
  type: SET_LANGUAGE,
  payload: { language },
});

export default function reducer(
  state: AppConfigState = initialState,
  action: AppConfigAction,
): AppConfigState {
  switch (action.type) {
    case SET_LANGUAGE: {
      const { language } = action.payload;
      const { country } = state;

      return {
        ...state,
        language,
        locale: toLocale(language, country),
      };
    }

    default:
      return state;
  }
}
