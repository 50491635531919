
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMvS3VwdW1hdHVhIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMvS3VwdW1hdHVhIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiV2hhdCIsIkVudGVyIEtleXdvcmRzIjoiRW50ZXIgS2V5d29yZHMifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiQXBhIiwiRW50ZXIgS2V5d29yZHMiOiJNYXN1a2thbiBrYXRhIGt1bmNpIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiQXBhIiwiRW50ZXIgS2V5d29yZHMiOiJNYXN1a2thbiBrYXRhIGt1bmNpIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0Ijoi4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwiRW50ZXIgS2V5d29yZHMiOiLguKPguLDguJrguLjguITguLXguKLguYzguYDguKfguLTguKPguYzguJQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0Ijoi4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwiRW50ZXIgS2V5d29yZHMiOiLguKPguLDguJrguLjguITguLXguKLguYzguYDguKfguLTguKPguYzguJQifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiQXBhIiwiRW50ZXIgS2V5d29yZHMiOiJNYXN1a2thbiBrYXRhIGt1bmNpIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiQXBhIiwiRW50ZXIgS2V5d29yZHMiOiJNYXN1a2thbiBrYXRhIGt1bmNpIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0Ijoi4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwiRW50ZXIgS2V5d29yZHMiOiLguKPguLDguJrguLjguITguLXguKLguYzguYDguKfguLTguKPguYzguJQifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0Ijoi4LiE4LmJ4LiZ4Lir4Liy4LiH4Liy4LiZIiwiRW50ZXIgS2V5d29yZHMiOiLguKPguLDguJrguLjguITguLXguKLguYzguYDguKfguLTguKPguYzguJQifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiW8W04bipxIPEg8SD4bmvXSIsIkVudGVyIEtleXdvcmRzIjoiW8OL4LiB4Li14LmJ4bmv4bq94bq94bq9xZkg4biw4bq94bq94bq9w73DvcO9xbXDtsO2w7bFmcaMxaFdIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXaGF0IjoiW8W04bipxIPEg8SD4bmvXSIsIkVudGVyIEtleXdvcmRzIjoiW8OL4LiB4Li14LmJ4bmv4bq94bq94bq9xZkg4biw4bq94bq94bq9w73DvcO9xbXDtsO2w7bFmcaMxaFdIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    