import { type StoreEnhancer, configureStore } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import { batchedSubscribe, type BatchFunction } from 'redux-batched-subscribe';

import { isProduction } from 'src/config';
// @ts-expect-error: non-ts file
import transactionHandler from 'src/modules/redux-batched-subscribe-transaction';
import reducer from 'src/store/reducer';

import defaultInitialState from './initialState';
import type { ChaliceStore } from './types';

function getDevToolsEnhancer() {
  const noop = (next: any) => next;
  return typeof window !== 'undefined' &&
    !isProduction &&
    window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : noop;
}

type PartialStore = RecursivePartial<ChaliceStore>;

export default (initialState: PartialStore = {}) => {
  const mergedInitialState = merge(
    {},
    defaultInitialState,
    initialState,
  ) as ChaliceStore;

  const batchingEnhancer = batchedSubscribe(
    transactionHandler as BatchFunction,
  );

  const devTools = getDevToolsEnhancer();

  return configureStore<ChaliceStore>({
    reducer,
    enhancers: [batchingEnhancer as StoreEnhancer],
    preloadedState: mergedInitialState,
    devTools,
  });
};
