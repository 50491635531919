import { Alert } from 'braid-design-system';
import {
  type ComponentProps,
  type ReactNode,
  useEffect,
  useState,
} from 'react';

import AnimatedHeight from 'src/components/AnimatedHeight/AnimatedHeight';

import { useGlobalNotificationBannerLocalStorage } from './useGlobalNotificationBannerLocalStorage';

interface GlobalNotificationBannerSkeletonProps {
  dataAutomation?: string;
  tone?: ComponentProps<typeof Alert>['tone'];
  children: ReactNode;
  localStorageKey: string;
}

const onCloseAction = ({
  setShowBanner,
  setBannerHasBeenSeen,
}: {
  setShowBanner: React.Dispatch<React.SetStateAction<boolean>>;
  setBannerHasBeenSeen: () => void;
}) => {
  setShowBanner(false);
  setBannerHasBeenSeen();
};

export const GlobalNotificationBannerSkeleton = ({
  dataAutomation,
  tone,
  children,
  localStorageKey,
}: GlobalNotificationBannerSkeletonProps) => {
  const [showBanner, setShowBanner] = useState(false);
  const { isBannerHasBeenSeen, setBannerHasBeenSeen } =
    useGlobalNotificationBannerLocalStorage(localStorageKey);

  useEffect(() => {
    const _isBannerHasBeenSeen = isBannerHasBeenSeen();

    if (!showBanner && !_isBannerHasBeenSeen) {
      setShowBanner(true);
    }
  }, [showBanner, isBannerHasBeenSeen]);

  return (
    <AnimatedHeight changeKeys={[showBanner]}>
      {showBanner ? (
        <Alert
          data={{ automation: `${dataAutomation}` }}
          tone={tone}
          closeLabel="closeAction"
          onClose={() =>
            onCloseAction({
              setShowBanner,
              setBannerHasBeenSeen,
            })
          }
        >
          {children}
        </Alert>
      ) : null}
    </AnimatedHeight>
  );
};
