import type { SearchResultJobV5 } from '@seek/chalice-types';
import type { Country } from '@seek/melways-sites';
import { useMemo } from 'react';

import { useSelector } from 'src/store/react';
import { selectResults } from 'src/store/selectors';
import { useMelwaysCountry } from 'src/utils/melwaysHelper';

export const hasExternalJobs = (
  jobs: SearchResultJobV5[] = [],
  currentCountryCode: Country,
): boolean => {
  if (!Array.isArray(jobs)) {
    return false;
  }

  return (
    jobs.findIndex((job) => {
      /**
       * only check jobs that have the locations (V5) prop,
       * make it backward compatible for jobs without these props
       */
      if (job.locations && job.locations.length) {
        // V5 supports an array of locations but we only need to worry about the first one for now
        return (
          job.locations[0].countryCode.toLowerCase() !== currentCountryCode
        );
      }

      return false;
    }) > -1
  );
};

/**
 * `useSERPHasExternalJobs` checks if the Search Results page has any external
 * jobs listed.
 *
 * @returns boolean
 */
export const useSERPHasExternalJobs = () => {
  const jobs = useSelector(selectResults)?.jobs;
  const country = useMelwaysCountry();
  return useMemo(() => hasExternalJobs(jobs ?? [], country), [country, jobs]);
};
