import { FeatureFlagProvider } from '@seek/candidate-feature-flag';
import { useMelwaysInfo } from '@seek/melways-react';
import { useTranslations, VocabProvider } from '@vocab/react';
import {
  Box,
  BraidProvider,
  Text,
  TextLinkButton,
  ToastProvider,
} from 'braid-design-system';
import seekJobsTheme from 'braid-design-system/themes/seekJobs';
import { renderRoutes } from 'react-router-config';
// @ts-expect-error: non-ts file
import { provideHooks } from 'redial';

import Footer from 'src/components/Footer/Footer';
import { GlobalNotificationBanner } from 'src/components/GlobalNotificationBanner';
import { GoogleOneTapWrapper } from 'src/components/GoogleOneTapWrapper/GoogleOneTapWrapper';
import Header from 'src/components/Header/Header';
import { PreviewBranchName } from 'src/components/PreviewBranchName/PreviewBranchName';
import { ScreenReaderAnnouncer } from 'src/components/ScreenReaderAnnouncer/ScreenReaderAnnouncer';
import { SecondaryFilterContextProvider } from 'src/components/Search/SecondaryFilter/context/SecondaryFilterContext';
import SharedHead from 'src/components/SharedHead/SharedHead';
import BranchBanner from 'src/modules/BranchBanner/BranchBanner';
import { scrollTo } from 'src/modules/scroll-with-callback';
import {
  isAuthenticated,
  updateUserTestData,
} from 'src/modules/seek-jobs-api-client/apis/candidate';
import {
  experimentsAfterAuthIntialised,
  FEATURE_EXPERIMENTS_AFTER_AUTH,
} from 'src/store/experiments';
import { mapToSeekExperiments } from 'src/store/experiments/experimentHelpers';
import { updateFeatureFlagsAfterAuth } from 'src/store/featureFlags';
import { selectHostname } from 'src/store/selectors';
import { setAlternateLinks, setCanonicalUrl } from 'src/store/seo';
import { getCandidateAccount, updateSession } from 'src/store/user';
import type { RedialLocals } from 'src/types/RedialLocals';

import { CustomLinkForBraid } from '../components/NavLink/NavLink';

import translations from './.vocab';
import {
  InitialiseExperiments,
  useFeatureFlagProviderProps,
} from './featureFlagProviderUtils';

import * as styles from './App.css';

const hooks = {
  async first({ getState, routeEnter, store }: RedialLocals) {
    const state = getState();
    const hostname = selectHostname(state);

    if (routeEnter) {
      const resolvedAuthState = await isAuthenticated();
      await updateUserTestData(store, resolvedAuthState, hostname);
    }
  },

  seo({ dispatch, country, path, query }: RedialLocals) {
    return Promise.all([
      dispatch(setCanonicalUrl({ country, path: path ?? '', query })),
      dispatch(setAlternateLinks({ country, path: path ?? '', query })),
    ]);
  },

  defer({ routeEnter, dispatch, cookies }: RedialLocals) {
    if (routeEnter) {
      return dispatch(
        updateSession({
          userClientId: cookies.JobseekerVisitorId || '',
          sessionId: cookies.JobseekerSessionId || '',
        }),
      );
    }
    return null;
  },

  authenticated: ({
    analyticsFacade,
    dispatch,
    getState,
    routeEnter,
    apolloClient,
    zone,
    cookies,
  }: RedialLocals) => {
    if (routeEnter) {
      return dispatch(getCandidateAccount(apolloClient, analyticsFacade))
        .then(() => {
          const state = getState();

          // The following will push the new user group to `experiments` state.
          const seekExperimentsAfterAuth = mapToSeekExperiments({
            userId: String(state.user.seekerId),
            featureExperiments: FEATURE_EXPERIMENTS_AFTER_AUTH,
            shouldExcludeFromExperiment: false,
            zone,
          });
          dispatch(experimentsAfterAuthIntialised(seekExperimentsAfterAuth));

          // Update feature flags after user details are fetched and updated the `experiments` state.
          dispatch(
            updateFeatureFlagsAfterAuth(
              cookies,
              seekExperimentsAfterAuth,
              state.appConfig.zoneFeatures,
            ),
          );
        })
        .then(() => {
          const { experiments } = getState();
          analyticsFacade.registerExperiments(experiments);
        })
        .then(() => {
          const state = getState();

          analyticsFacade.userDetailsUpdated({
            authenticated: true,
            trackingId: state.user.trackingId,
            loginId: String(state.user.seekerId),
          });
        });
    }

    return null;
  },
};

interface Props {
  route: any;
}

const UseScreenReaderSkipLink = () => {
  const { t } = useTranslations(translations);
  const noop = () => {};

  const scrollToStartOfContent = ENV.CLIENT
    ? () => {
        const startOfContent = document.getElementById('start-of-content');
        scrollTo({
          top: startOfContent?.getBoundingClientRect().top ?? 0,
          behavior: 'smooth',
        });
        startOfContent?.focus();
      }
    : noop;

  return (
    <Text>
      <Box className={styles.screenReaderSkipLink}>
        <TextLinkButton
          onClick={(e) => {
            e.preventDefault();
            scrollToStartOfContent();
          }}
        >
          {t('Skip to content')}
        </TextLinkButton>
      </Box>
    </Text>
  );
};

const App = ({ route }: Props) => {
  const { locale } = useMelwaysInfo();

  return (
    <VocabProvider language={locale}>
      <FeatureFlagProvider {...useFeatureFlagProviderProps()}>
        <BraidProvider theme={seekJobsTheme} linkComponent={CustomLinkForBraid}>
          <InitialiseExperiments />
          <GoogleOneTapWrapper />
          <PreviewBranchName />
          <GlobalNotificationBanner />
          <ToastProvider>
            <BranchBanner />
            <SharedHead />
            <UseScreenReaderSkipLink />
            <ScreenReaderAnnouncer />
            <Header />
            <SecondaryFilterContextProvider>
              <div role="main">{renderRoutes(route.routes)}</div>
            </SecondaryFilterContextProvider>
            <Box paddingTop="xxlarge">
              <Footer />
            </Box>
          </ToastProvider>
        </BraidProvider>
      </FeatureFlagProvider>
    </VocabProvider>
  );
};

export default provideHooks(hooks)(App);

export const hooksForTests = hooks;
