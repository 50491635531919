/*
  Code implementation is derived from 
  https://github.com/seek-oss/braid-design-system/blob/1c5da13ae84ae03ca2b36d03e7ade82c4181fd27/packages/braid-design-system/src/lib/components/private/Modal/Modal.tsx.

  For reference:
  - Braid version: v32.21.0
*/

import { Box } from 'braid-design-system';
import { type Reducer, useEffect, useReducer, useRef } from 'react';
import FocusLock from 'react-focus-lock';

import { ModelContent, type ModelContentProps } from './ModalContent';
import { ariaHideOthers } from './ariaHideOthers';

import * as styles from './Modal.css';

export interface ModalProps
  extends Omit<
    ModelContentProps,
    'onClose' | 'scrollLock' | 'headingRef' | 'modalRef'
  > {
  open: boolean;
  onClose: (openState: false) => void | false;
}

// Actions
const OPEN_MODAL = 1;
const CLOSE_MODAL = 2;
const ANIMATION_COMPLETE = 3;

// States
const INITIAL = 1;
const OPEN = 2;
const OPENING = 3;
const CLOSED = 4;
const CLOSING = 5;

type Action =
  | typeof OPEN_MODAL
  | typeof CLOSE_MODAL
  | typeof ANIMATION_COMPLETE;
type State =
  | typeof INITIAL
  | typeof OPEN
  | typeof OPENING
  | typeof CLOSED
  | typeof CLOSING;

const reducer: Reducer<State, Action> = (prevState, action) => {
  switch (action) {
    case OPEN_MODAL: {
      switch (prevState) {
        case INITIAL:
        case CLOSING:
        case CLOSED: {
          return OPENING;
        }
      }
      return prevState;
    }

    case CLOSE_MODAL: {
      switch (prevState) {
        case OPEN:
        case OPENING: {
          return CLOSING;
        }
      }
      return prevState;
    }

    case ANIMATION_COMPLETE: {
      switch (prevState) {
        case CLOSING: {
          return CLOSED;
        }

        case OPENING: {
          return OPEN;
        }
      }

      return prevState;
    }

    default: {
      return prevState;
    }
  }
};

const ANIMATION_DURATION = 300;

export const Modal = ({
  id,
  open,
  children,
  onClose,
  width,
  closeLabel,
  position,
  title,
  headingLevel,
  ...restProps
}: ModalProps) => {
  const [state, dispatch] = useReducer(reducer, INITIAL);

  const shouldFocus =
    typeof document !== 'undefined' &&
    typeof document.hasFocus === 'function' &&
    document.hasFocus();

  const openRef = useRef<boolean>(open);
  const modalRef = useRef<HTMLElement>(null);
  const headingRef = useRef<HTMLElement>(null);
  const closeHandlerRef = useRef<ModalProps['onClose']>(onClose);

  const initiateClose = () => {
    const result = closeHandlerRef.current(false);

    if (result === false) {
      return result;
    }

    if (openRef.current) {
      dispatch(CLOSE_MODAL);
    }
  };

  const shouldAriaHideOthers = state === OPEN || state === CLOSING;
  useEffect(() => {
    if (shouldAriaHideOthers && modalRef.current) {
      return ariaHideOthers(modalRef.current, { delay: ANIMATION_DURATION });
    }
  }, [shouldAriaHideOthers]);

  useEffect(() => {
    if (typeof onClose === 'function') {
      closeHandlerRef.current = onClose;
    }
  }, [onClose]);

  useEffect(() => {
    openRef.current = open;
    dispatch(open ? OPEN_MODAL : CLOSE_MODAL);
  }, [open]);

  const opening = state === OPENING;
  useEffect(() => {
    if (opening) {
      if (headingRef.current && shouldFocus) {
        headingRef.current.focus();
      }
      dispatch(ANIMATION_COMPLETE);
    }
  }, [opening, shouldFocus]);

  const closing = state === CLOSING;
  useEffect(() => {
    if (closing) {
      const timer = setTimeout(() => {
        dispatch(ANIMATION_COMPLETE);
      }, ANIMATION_DURATION);

      return () => clearTimeout(timer);
    }
  }, [closing]);

  return (
    /*
        The following Box component is used in place of the
        use of ModalPortal component which in turn relies on 
        React.Portal in the original implementation.

        React.Portal requires access to the document object
        which does not exist server side and this hinders
        the component to not being able to render server
        side.

        As such, for SEO reasons, content within the Drawer 
        component needs to render server side or exists in 
        the initial HTML load. 

        For reference:
        - https://github.com/seek-oss/braid-design-system/blob/1c5da13ae84ae03ca2b36d03e7ade82c4181fd27/packages/braid-design-system/src/lib/components/private/Modal/Modal.tsx#L206
        - https://github.com/seek-oss/braid-design-system/blob/1c5da13ae84ae03ca2b36d03e7ade82c4181fd27/packages/braid-design-system/src/lib/components/private/Modal/Modal.tsx#L33-L56
    */
    <Box
      id={id}
      className={styles.fixedStackingContext}
      /*
          Display property is used conditionally to hide contents 
          from the screen but remains intact in the DOM as opposed 
          to conditionally show the Modal.

          For reference:
          - https://github.com/seek-oss/braid-design-system/blob/1c5da13ae84ae03ca2b36d03e7ade82c4181fd27/packages/braid-design-system/src/lib/components/private/Modal/Modal.tsx#L205
      */
      display={
        state === OPENING || state === OPEN || state === CLOSING
          ? undefined
          : 'none'
      }
    >
      <FocusLock
        className={styles.resetStackingContext}
        disabled={!(state === OPEN)}
        autoFocus={false}
        onActivation={() => {
          if (state === OPEN) {
            return;
          }

          if (headingRef.current && shouldFocus) {
            headingRef.current.focus();
          }

          dispatch(ANIMATION_COMPLETE);
        }}
        returnFocus
      >
        <Box
          onClick={state === OPEN ? initiateClose : undefined}
          position="fixed"
          inset={0}
          zIndex="modalBackdrop"
          opacity={state !== OPEN ? 0 : undefined}
          pointerEvents={state === CLOSING ? 'none' : undefined}
          className={[styles.backdrop, styles.transition[position]]}
        />

        <Box
          position="fixed"
          inset={0}
          zIndex="modal"
          pointerEvents="none"
          opacity={state !== OPEN ? 0 : undefined}
          paddingLeft={position === 'right' ? ['none', 'xlarge'] : undefined}
          paddingRight={position === 'left' ? ['none', 'xlarge'] : undefined}
          className={[
            styles.modalContainer,
            styles.transition[position],
            state === OPENING && styles.entrance[position],
            state === CLOSING && styles.exit[position],
          ]}
          /* ==================================== */
          /* Added to adapt to Chalice's use case */
          /* ==================================== */
          top={0}
          right={0}
          height="full"
          /* ==================================== */
        >
          <ModelContent
            id={id}
            onClose={initiateClose}
            width={width}
            closeLabel={closeLabel}
            title={title}
            headingLevel={headingLevel}
            headingRef={headingRef}
            modalRef={modalRef}
            position={position}
            scrollLock={!(state === CLOSING)}
            open={open}
            {...restProps}
          >
            {children}
          </ModelContent>
        </Box>
      </FocusLock>
    </Box>
  );
};
