import { getLanguageFromLocale } from '@seek/melways-sites';

import { useAppConfig } from 'src/config/appConfig';
import { salaryRanges } from 'src/config/salaryRanges';
import type { SalaryRange } from 'src/config/types';
import { logger } from 'src/modules/logger';

export const useSearchSalaryRanges = (): SalaryRange => {
  const { zone, defaultLocale, language } = useAppConfig();
  const fallbackLanguageCode = getLanguageFromLocale(defaultLocale);

  if (
    !zone ||
    !language ||
    typeof salaryRanges[zone] === 'undefined' ||
    typeof salaryRanges[zone][language] === 'undefined' ||
    typeof salaryRanges[zone][fallbackLanguageCode] === 'undefined'
  ) {
    logger.error(
      {
        input: { zone, language, fallbackLanguageCode },
      },
      'Error occurred when using salaryRanges',
    );
  }

  if (typeof salaryRanges[zone][language] !== 'undefined') {
    // update cache by running `pnpm generate-salaryranges`
    return salaryRanges[zone][language]!;
  }

  return salaryRanges[zone][fallbackLanguageCode]!;
};
