
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ%2BIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ%2BIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ%2BIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ%2BIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD5CeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0sIHlvdTo8L1RleHQ%2BPExpc3Q%2BPFRleHRMaXN0PmNvbnNlbnQgdG8gdGhlIHVzZSBvZiBjb29raWVzIGFzIG91dGxpbmVkIHdpdGhpbiBvdXIgUHJpdmFjeSBQb2xpY3k7IGFuZDwvVGV4dExpc3Q%2BPFRleHRMaXN0PmNvbmZpcm0gdGhhdCB5b3XigJlyZSBhdCBsZWFzdCAyMCB5ZWFycyBvbGQgb3IgaGF2ZSBwYXJlbnRhbCBjb25zZW50IGZvciBKb2JzZGIgYW5kIGFmZmlsaWF0ZXMgdG8gcHJvY2VzcyB5b3VyIHBlcnNvbmFsIGRhdGE8L1RleHRMaXN0PjwvTGlzdD4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD5CeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0sIHlvdTo8L1RleHQ%2BPExpc3Q%2BPFRleHRMaXN0PmNvbnNlbnQgdG8gdGhlIHVzZSBvZiBjb29raWVzIGFzIG91dGxpbmVkIHdpdGhpbiBvdXIgUHJpdmFjeSBQb2xpY3k7IGFuZDwvVGV4dExpc3Q%2BPFRleHRMaXN0PmNvbmZpcm0gdGhhdCB5b3XigJlyZSBhdCBsZWFzdCAyMCB5ZWFycyBvbGQgb3IgaGF2ZSBwYXJlbnRhbCBjb25zZW50IGZvciBKb2JzZGIgYW5kIGFmZmlsaWF0ZXMgdG8gcHJvY2VzcyB5b3VyIHBlcnNvbmFsIGRhdGE8L1RleHRMaXN0PjwvTGlzdD4ifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ%2BIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD5CeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0sIHlvdSBjb25zZW50IHRvIHRoZSB1c2Ugb2YgY29va2llcyBhcyBvdXRsaW5lZCB3aXRoaW4gb3VyIFByaXZhY3kgUG9saWN5LjwvVGV4dD4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD5CeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0sIHlvdSBjb25zZW50IHRvIHRoZSB1c2Ugb2YgY29va2llcyBhcyBvdXRsaW5lZCB3aXRoaW4gb3VyIFByaXZhY3kgUG9saWN5LjwvVGV4dD4ifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ%2BIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ%2BIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ%2BIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD7guKvguLLguIHguJfguYjguLLguJnguJTguLPguYDguJnguLTguJnguIHguLLguKPguYPguIrguYnguYHguJ7guKXguJXguJ%2FguK3guKPguYzguKHguILguK3guIfguYDguKPguLIg4LiW4Li34Lit4Lin4LmI4Liy4LiX4LmI4Liy4LiZ4LmE4LiU4LmJ4LiV4LiB4Lil4LiH4LiU4Lix4LiH4LiV4LmI4Lit4LmE4Lib4LiZ4Li14LmJPC9UZXh0PjxMaXN0PjxUZXh0TGlzdD7guKLguLTguJnguKLguK3guKHguYPguKvguYnguYPguIrguYnguITguLjguIHguIHguLXguYnguJXguLLguKHguJfguLXguYjguIHguLPguKvguJnguJTguYTguKfguYnguYPguJnguJnguYLguKLguJrguLLguKLguITguKfguLLguKHguYDguJvguYfguJnguKrguYjguKfguJnguJXguLHguKfguILguK3guIfguYDguKPguLI8L1RleHRMaXN0PjxUZXh0TGlzdD7guKLguLfguJnguKLguLHguJnguKfguYjguLLguJfguYjguLLguJnguKHguLXguK3guLLguKLguLjguJXguLHguYnguIfguYHguJXguYggMjAg4Lib4Li14LiC4Li24LmJ4LiZ4LmE4LibIOC4q%2BC4o%2BC4t%2BC4reC4l%2BC5iOC4suC4meC5hOC4lOC5ieC4o%2BC4seC4muC4hOC4p%2BC4suC4oeC4ouC4tOC4meC4ouC4reC4oeC4iOC4suC4geC4nOC4ueC5ieC4m%2BC4geC4hOC4o%2BC4reC4hyDguYDguJ7guLfguYjguK3guKLguLTguJnguKLguK3guKHguYPguKvguYkgSm9ic2RiIOC5geC4peC4sOC4muC4o%2BC4tOC4qeC4seC4l%2BC5g%2BC4meC5gOC4hOC4o%2BC4t%2BC4reC4m%2BC4o%2BC4sOC4oeC4p%2BC4peC4nOC4peC4guC5ieC4reC4oeC4ueC4peC4quC5iOC4p%2BC4meC4muC4uOC4hOC4hOC4peC4guC4reC4h%2BC4l%2BC5iOC4suC4mTwvVGV4dExpc3Q%2BPC9MaXN0PiJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD7guKvguLLguIHguJfguYjguLLguJnguJTguLPguYDguJnguLTguJnguIHguLLguKPguYPguIrguYnguYHguJ7guKXguJXguJ%2FguK3guKPguYzguKHguILguK3guIfguYDguKPguLIg4LiW4Li34Lit4Lin4LmI4Liy4LiX4LmI4Liy4LiZ4LmE4LiU4LmJ4LiV4LiB4Lil4LiH4LiU4Lix4LiH4LiV4LmI4Lit4LmE4Lib4LiZ4Li14LmJPC9UZXh0PjxMaXN0PjxUZXh0TGlzdD7guKLguLTguJnguKLguK3guKHguYPguKvguYnguYPguIrguYnguITguLjguIHguIHguLXguYnguJXguLLguKHguJfguLXguYjguIHguLPguKvguJnguJTguYTguKfguYnguYPguJnguJnguYLguKLguJrguLLguKLguITguKfguLLguKHguYDguJvguYfguJnguKrguYjguKfguJnguJXguLHguKfguILguK3guIfguYDguKPguLI8L1RleHRMaXN0PjxUZXh0TGlzdD7guKLguLfguJnguKLguLHguJnguKfguYjguLLguJfguYjguLLguJnguKHguLXguK3guLLguKLguLjguJXguLHguYnguIfguYHguJXguYggMjAg4Lib4Li14LiC4Li24LmJ4LiZ4LmE4LibIOC4q%2BC4o%2BC4t%2BC4reC4l%2BC5iOC4suC4meC5hOC4lOC5ieC4o%2BC4seC4muC4hOC4p%2BC4suC4oeC4ouC4tOC4meC4ouC4reC4oeC4iOC4suC4geC4nOC4ueC5ieC4m%2BC4geC4hOC4o%2BC4reC4hyDguYDguJ7guLfguYjguK3guKLguLTguJnguKLguK3guKHguYPguKvguYkgSm9ic2RiIOC5geC4peC4sOC4muC4o%2BC4tOC4qeC4seC4l%2BC5g%2BC4meC5gOC4hOC4o%2BC4t%2BC4reC4m%2BC4o%2BC4sOC4oeC4p%2BC4peC4nOC4peC4guC5ieC4reC4oeC4ueC4peC4quC5iOC4p%2BC4meC4muC4uOC4hOC4hOC4peC4guC4reC4h%2BC4l%2BC5iOC4suC4mTwvVGV4dExpc3Q%2BPC9MaXN0PiJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD48L1RleHQ%2BIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD7guKvguLLguIHguJfguYjguLLguJnguJTguLPguYDguJnguLTguJnguIHguLLguKPguYPguIrguYnguYHguJ7guKXguJXguJ%2FguK3guKPguYzguKHguILguK3guIfguYDguKPguLIg4LiW4Li34Lit4Lin4LmI4Liy4LiX4LmI4Liy4LiZ4LmE4LiU4LmJ4LiV4LiB4Lil4LiH4LiU4Lix4LiH4LiV4LmI4Lit4LmE4Lib4LiZ4Li14LmJPC9UZXh0PjxMaXN0PjxUZXh0TGlzdD7guKLguLTguJnguKLguK3guKHguYPguKvguYnguYPguIrguYnguITguLjguIHguIHguLXguYnguJXguLLguKHguJfguLXguYjguIHguLPguKvguJnguJTguYTguKfguYnguYPguJnguJnguYLguKLguJrguLLguKLguITguKfguLLguKHguYDguJvguYfguJnguKrguYjguKfguJnguJXguLHguKfguILguK3guIfguYDguKPguLI8L1RleHRMaXN0PjxUZXh0TGlzdD7guKLguLfguJnguKLguLHguJnguKfguYjguLLguJfguYjguLLguJnguKHguLXguK3guLLguKLguLjguJXguLHguYnguIfguYHguJXguYggMjAg4Lib4Li14LiC4Li24LmJ4LiZ4LmE4LibIOC4q%2BC4o%2BC4t%2BC4reC4l%2BC5iOC4suC4meC5hOC4lOC5ieC4o%2BC4seC4muC4hOC4p%2BC4suC4oeC4ouC4tOC4meC4ouC4reC4oeC4iOC4suC4geC4nOC4ueC5ieC4m%2BC4geC4hOC4o%2BC4reC4hyDguYDguJ7guLfguYjguK3guKLguLTguJnguKLguK3guKHguYPguKvguYkgSm9ic2RiIOC5geC4peC4sOC4muC4o%2BC4tOC4qeC4seC4l%2BC5g%2BC4meC5gOC4hOC4o%2BC4t%2BC4reC4m%2BC4o%2BC4sOC4oeC4p%2BC4peC4nOC4peC4guC5ieC4reC4oeC4ueC4peC4quC5iOC4p%2BC4meC4muC4uOC4hOC4hOC4peC4guC4reC4h%2BC4l%2BC5iOC4suC4mTwvVGV4dExpc3Q%2BPC9MaXN0PiJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiI8VGV4dD7guKvguLLguIHguJfguYjguLLguJnguJTguLPguYDguJnguLTguJnguIHguLLguKPguYPguIrguYnguYHguJ7guKXguJXguJ%2FguK3guKPguYzguKHguILguK3guIfguYDguKPguLIg4LiW4Li34Lit4Lin4LmI4Liy4LiX4LmI4Liy4LiZ4LmE4LiU4LmJ4LiV4LiB4Lil4LiH4LiU4Lix4LiH4LiV4LmI4Lit4LmE4Lib4LiZ4Li14LmJPC9UZXh0PjxMaXN0PjxUZXh0TGlzdD7guKLguLTguJnguKLguK3guKHguYPguKvguYnguYPguIrguYnguITguLjguIHguIHguLXguYnguJXguLLguKHguJfguLXguYjguIHguLPguKvguJnguJTguYTguKfguYnguYPguJnguJnguYLguKLguJrguLLguKLguITguKfguLLguKHguYDguJvguYfguJnguKrguYjguKfguJnguJXguLHguKfguILguK3guIfguYDguKPguLI8L1RleHRMaXN0PjxUZXh0TGlzdD7guKLguLfguJnguKLguLHguJnguKfguYjguLLguJfguYjguLLguJnguKHguLXguK3guLLguKLguLjguJXguLHguYnguIfguYHguJXguYggMjAg4Lib4Li14LiC4Li24LmJ4LiZ4LmE4LibIOC4q%2BC4o%2BC4t%2BC4reC4l%2BC5iOC4suC4meC5hOC4lOC5ieC4o%2BC4seC4muC4hOC4p%2BC4suC4oeC4ouC4tOC4meC4ouC4reC4oeC4iOC4suC4geC4nOC4ueC5ieC4m%2BC4geC4hOC4o%2BC4reC4hyDguYDguJ7guLfguYjguK3guKLguLTguJnguKLguK3guKHguYPguKvguYkgSm9ic2RiIOC5geC4peC4sOC4muC4o%2BC4tOC4qeC4seC4l%2BC5g%2BC4meC5gOC4hOC4o%2BC4t%2BC4reC4m%2BC4o%2BC4sOC4oeC4p%2BC4peC4nOC4peC4guC5ieC4reC4oeC4ueC4peC4quC5iOC4p%2BC4meC4muC4uOC4hOC4hOC4peC4guC4reC4h%2BC4l%2BC5iOC4suC4mTwvVGV4dExpc3Q%2BPC9MaXN0PiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiJbPFRleHQ%2BPC9UZXh0Pl0ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCeSBjb250aW51aW5nIHRvIHVzZSBvdXIgcGxhdGZvcm0uLi4iOiJbPFRleHQ%2BPC9UZXh0Pl0ifQ%3D%3D!"
        )
      )
      });
  
      export { translations as default };
    