
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiQWxsIG90aGVyIGNvdW50cmllcyIsIlBvcHVsYXIgY291bnRyaWVzIjoiUG9wdWxhciBjb3VudHJpZXMiLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiLCJGaW5kIHdvcmxkLXdpZGUiOiJGaW5kIHdvcmxkLXdpZGUiLCJqb2JzIjoiam9icyIsIlBPRUEgam9icyI6IlBPRUEgam9icyJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiW%2BG6rMaaxpogw7bDtsO24bmv4bip4bq94bq94bq9xZkgw6fDtsO2w7bHmseax5rguIHguLXguYnhua%2FFmcOsw6zDrOG6veG6veG6vcWhXSIsIlBvcHVsYXIgY291bnRyaWVzIjoiW8akw7bDtsalx5rHmsaaxIPEg8WZIMOnw7bDtseax5rguIHguLXguYnhua%2FFmcOsw6zhur3hur3FoV0iLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJbxpHDrMOsw6zguIHguLXguYnGjCDEtcO2w7bDtsOfxaEg4bm94bq94bq94bq9xZnDrMOsw6zGksOsw6zDrOG6veG6veG6vcaMIMOfw73DvcO9IMW0w7bDtsO2xZnEt%2BG6rMOfxZnDtsO2w7bEg8SDxIPGjCDEg8SDxIPFoSDhuIrhuYLFtCAoxpLDtsO2w7bFmW3MguG6veG6veG6vcWZxprDvcO9w70gxqTDlsOL4bqsKS3Eg8SDxIPGpcalxZnDtsO2w7bhub3hur3hur3hur3GjCDDtsO2w7bhub3hur3hur3hur3FmcWh4bq94bq94bq9xIPEg8SDxaEgw7bDtsO2xqXGpcO2w7bDtsWZ4bmvx5rHmsea4LiB4Li14LmJw6zDrMOs4bmvw6zDrMOs4bq94bq94bq9xaFdIiwiRmluZCB3b3JsZC13aWRlIjoiW8aRw6zDrMOs4LiB4Li14LmJxowgxbXDtsO2w7bFmcaaxowtxbXDrMOsw6zGjOG6veG6veG6vV0iLCJqb2JzIjoiW8S1w7bDtsO2w5%2FFoV0iLCJQT0VBIGpvYnMiOiJbxqTDlsOL4bqsIMS1w7bDtsO2w7bDtsOfxaFdIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJBbGwgb3RoZXIgY291bnRyaWVzIjoiW%2BG6rMaaxpogw7bDtsO24bmv4bip4bq94bq94bq9xZkgw6fDtsO2w7bHmseax5rguIHguLXguYnhua%2FFmcOsw6zDrOG6veG6veG6vcWhXSIsIlBvcHVsYXIgY291bnRyaWVzIjoiW8akw7bDtsalx5rHmsaaxIPEg8WZIMOnw7bDtseax5rguIHguLXguYnhua%2FFmcOsw6zhur3hur3FoV0iLCJGaW5kIGpvYnMgdmVyaWZpZWQgYnkgV29ya0Ficm9hZCBhcyBETVcgKGZvcm1lcmx5IFBPRUEpLWFwcHJvdmVkIG92ZXJzZWFzIG9wcG9ydHVuaXRpZXMiOiJbxpHDrMOsw6zguIHguLXguYnGjCDEtcO2w7bDtsOfxaEg4bm94bq94bq94bq9xZnDrMOsw6zGksOsw6zDrOG6veG6veG6vcaMIMOfw73DvcO9IMW0w7bDtsO2xZnEt%2BG6rMOfxZnDtsO2w7bEg8SDxIPGjCDEg8SDxIPFoSDhuIrhuYLFtCAoxpLDtsO2w7bFmW3MguG6veG6veG6vcWZxprDvcO9w70gxqTDlsOL4bqsKS3Eg8SDxIPGpcalxZnDtsO2w7bhub3hur3hur3hur3GjCDDtsO2w7bhub3hur3hur3hur3FmcWh4bq94bq94bq9xIPEg8SDxaEgw7bDtsO2xqXGpcO2w7bDtsWZ4bmvx5rHmsea4LiB4Li14LmJw6zDrMOs4bmvw6zDrMOs4bq94bq94bq9xaFdIiwiRmluZCB3b3JsZC13aWRlIjoiW8aRw6zDrMOs4LiB4Li14LmJxowgxbXDtsO2w7bFmcaaxowtxbXDrMOsw6zGjOG6veG6veG6vV0iLCJqb2JzIjoiW8S1w7bDtsO2w5%2FFoV0iLCJQT0VBIGpvYnMiOiJbxqTDlsOL4bqsIMS1w7bDtsO2w7bDtsOfxaFdIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    