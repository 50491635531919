import type { Banner } from 'src/graphql/graphql';

export const mockedBanners: Banner = {
  __typename: 'Banner',
  analytics: '',
  id: '123',
  template: {
    items: [
      {
        __typename: 'BannerItems',
        href: 'https://www.volunteer.com.au',
        id: 'abc',
        text: 'Volunteering',
      },
      {
        __typename: 'BannerItems',
        href: 'https://www.seekbusiness.com.au',
        id: 'xyz',
        text: 'Businesses for Sale',
      },
    ],
    __typename: 'BannerTemplate',
    heading: {
      __typename: 'BannerHeading',
      text: 'Banners',
    },
  },
};
