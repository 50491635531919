import type { Pills as PillsType } from '@seek/chalice-types';
import { Box, Text, useResponsiveValue } from 'braid-design-system';
import { forwardRef } from 'react';

import { getPillText } from './helper/getPillText';

import * as styles from './Pills.css';

interface PillsProps {
  handleOnPillClick: (
    label: string,
    keyword: string,
    lastPill: boolean,
    position: number,
    isActive: boolean,
  ) => void;
  handleOnScroll: () => void;
  isAtEnd: boolean;
  pills: PillsType;
}

export const Pills = forwardRef<HTMLElement, PillsProps>(
  ({ handleOnPillClick, handleOnScroll, isAtEnd, pills }, ref) => {
    const isMobile =
      useResponsiveValue()({
        mobile: true,
        tablet: false,
      }) || false;

    return (
      <Box
        className={styles.hideScrollBar}
        onScroll={handleOnScroll}
        display="flex"
        overflow="scroll"
        ref={ref}
      >
        {pills.map(({ label, keywords, isActive }, index) => (
          <Box
            key={index}
            display="flex"
            paddingRight={{
              mobile:
                isAtEnd && pills.length - 1 === index ? 'none' : 'xxsmall',
              tablet: 'small',
            }}
          >
            <Box
              background={isActive ? 'customLight' : 'customDark'}
              data-selected={isActive ? 'true' : 'false'}
              className={styles.pill}
              onClick={() =>
                handleOnPillClick(
                  label,
                  keywords,
                  pills.length - 1 === index,
                  index,
                  isActive,
                )
              }
            >
              <Box minWidth={0} className={styles.innerTextPill}>
                <Text
                  baseline={false}
                  maxLines={1}
                  size={isMobile ? 'small' : undefined}
                >
                  {getPillText({ text: label, isActive, isMobile })}
                </Text>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    );
  },
);
