import type { SearchParams } from '@seek/chalice-types';

export const RECENT_SEARCHES_UPDATED = 'RECENT_SEARCHES_UPDATED';

export interface RecentSearchesState {
  searches?: SearchQuerySummary[];
}

export interface SearchQuerySummary {
  query: SearchParams;
  summary: string;
  date: Date;
  where: string;
}

export interface RecentSearchesUpdatedAction {
  type: typeof RECENT_SEARCHES_UPDATED;
  payload?: SearchQuerySummary[];
}

export type RecentSearchesAction = RecentSearchesUpdatedAction;
