import {
  SMARTER_SEARCH_CLUSTERS,
  type SmarterSearchCluster,
  type ZoneFeatures,
} from 'src/config/types';
import { isATargetDevice } from 'src/modules/app-install-banner';
import type { CookieType } from 'src/types/cookie';

import { toggleExperiment } from '../experiments/experimentHelpers';
import type { TSeekExperiments } from '../experiments/types';
import type { TypedAction, TypedThunkAction } from '../types';

import { UPDATE_FEATURE_FLAGS, type FeatureFlagsState } from './types';

export const initialState: FeatureFlagsState = {
  behaviouralCues: false,
  behaviouralCuesFilters: false,
  branchBannerPreview: false,
  isBranchEnabledFlag: false,
  smarterSearchLaunchCluster: SMARTER_SEARCH_CLUSTERS[0],
  showHomepageBanner: false,
  showFloatingSaveSearch: true,
  autoSelectSplitViewFirstJob: true,
  showMarketingAdSplitView: false,
  loggedOutRecs: false,
  remoteSearchFilter: false,
  remoteSearchFilterNewLabel: false,
  dynamicPills: false,
  secondaryFilters: false,
  dynamicPillsV2: false,
  keywordAutosuggestV2: false,
  hirerVerifiedBadge: false,
  serpJobCardInfoDensity1: false,
  newJobCardDensity: false,
  hideCompanyLogo: false,
  homepageJdvDrawer: false,
  matchedQualities: false,
  serpGoogleOneTap: false,
  homepageMobileWebRedesign: undefined,
  homepageNoCompanyCarousel: undefined,
  hideApplyButtonOnPrivateAdvertiser: true, // todo cleanup after
  entryLevelBadge: false,
};

export const initialiseFeatureFlags = ({
  cookies = {},
  experiments,
  zoneFeatures: {
    SHOW_FLOATING_SAVE_SEARCH,
    AUTO_SELECT_SPLIT_VIEW_FIRST_JOB,
    SHOW_MARKETING_AD_SPLIT_VIEW,
    BEHAVIOURAL_CUES_ENABLED,
    BEHAVIOURAL_CUES_FILTERS_ENABLED,
    LOGGED_OUT_RECS,
    REMOTE_SEARCH_FILTER,
    REMOTE_SEARCH_FILTER_NEW_LABEL,
    DYNAMIC_PILLS,
    SECONDARY_FILTERS,
    DYNAMIC_PILLS_V2,
    KEYWORD_AUTOSUGGEST_V2,
    NEW_JOB_CARD_DENSITY,
    ENABLE_VERIFIED_HIRER_BADGE,
    SERP_JOBCARD_INFO_DENSITY_1,
    MATCHED_QUALITIES,
    ENTRY_LEVEL_BADGE,
  },
}: {
  cookies: CookieType;
  experiments: TSeekExperiments;
  zoneFeatures: ZoneFeatures;
}): FeatureFlagsState => {
  const secondaryFilters =
    Boolean(cookies.SECONDARY_FILTERS || SECONDARY_FILTERS) ||
    toggleExperiment({
      experimentId: 'secondaryFilters',
      experiments,
      cookieValue: cookies.SECONDARY_FILTERS,
    });

  // Extra safe guard to ensure that dynamic pills can only be enabled if secondary filters is enabled.
  // You cannot enable dynamic pills if secondary filters is disabled.
  const dynamicPills =
    secondaryFilters &&
    (Boolean(cookies.DYNAMIC_PILLS || DYNAMIC_PILLS) ||
      toggleExperiment({
        experimentId: 'dynamicPills',
        experiments,
        cookieValue: cookies.DYNAMIC_PILLS,
      }));

  return {
    ...initialState,
    behaviouralCues: Boolean(
      cookies.BEHAVIOURAL_CUES_ENABLED || BEHAVIOURAL_CUES_ENABLED,
    ),
    behaviouralCuesFilters:
      Boolean(
        cookies.BEHAVIOURAL_CUES_FILTERS_ENABLED ||
          BEHAVIOURAL_CUES_FILTERS_ENABLED,
      ) ||
      toggleExperiment({
        experimentId: 'behaviouralCuesFilters',
        experiments,
        cookieValue: cookies.BEHAVIOURAL_CUES_FILTERS_ENABLED,
      }),
    branchBannerPreview: Boolean(cookies.BRANCH_BANNER_PREVIEW),
    isBranchEnabledFlag:
      Boolean(cookies.ENABLE_BRANCH_BANNER) || isATargetDevice(),
    smarterSearchLaunchCluster:
      cookies.SMARTER_SEARCH_LAUNCH_CLUSTER as SmarterSearchCluster,
    showFloatingSaveSearch: Boolean(
      cookies.SHOW_FLOATING_SAVE_SEARCH || SHOW_FLOATING_SAVE_SEARCH,
    ),
    autoSelectSplitViewFirstJob:
      Boolean(cookies.AUTO_SELECT_SPLIT_VIEW_FIRST_JOB) ||
      Boolean(AUTO_SELECT_SPLIT_VIEW_FIRST_JOB),
    showMarketingAdSplitView: Boolean(
      cookies.SHOW_MARKETING_AD_SPLIT_VIEW || SHOW_MARKETING_AD_SPLIT_VIEW,
    ),
    loggedOutRecs: Boolean(cookies.LOGGED_OUT_RECS || LOGGED_OUT_RECS),
    remoteSearchFilter:
      Boolean(cookies.REMOTE_SEARCH_FILTER || REMOTE_SEARCH_FILTER) ||
      toggleExperiment({
        experimentId: 'remoteSearchFilter',
        experiments,
        cookieValue: cookies.REMOTE_SEARCH_FILTER,
      }),
    remoteSearchFilterNewLabel: Boolean(REMOTE_SEARCH_FILTER_NEW_LABEL),
    dynamicPills,
    secondaryFilters,
    keywordAutosuggestV2: Boolean(
      cookies.KEYWORD_AUTOSUGGEST_V2 || KEYWORD_AUTOSUGGEST_V2,
    ),
    hirerVerifiedBadge: Boolean(
      cookies.ENABLE_VERIFIED_HIRER_BADGE || ENABLE_VERIFIED_HIRER_BADGE,
    ),
    serpJobCardInfoDensity1: Boolean(
      cookies.SERP_JOBCARD_INFO_DENSITY_1 ||
        SERP_JOBCARD_INFO_DENSITY_1 ||
        toggleExperiment({
          experimentId: 'serpJobCardInfoDensity1',
          experiments,
          cookieValue: cookies.SERP_JOBCARD_INFO_DENSITY_1,
        }),
    ),
    newJobCardDensity: Boolean(
      cookies.NEW_JOB_CARD_DENSITY || NEW_JOB_CARD_DENSITY,
    ),
    hideCompanyLogo: Boolean(cookies.HIDE_COMPANY_LOGO),
    serpGoogleOneTap: Boolean(
      cookies.SERP_GOOGLE_ONE_TAP ||
        toggleExperiment({
          experimentId: 'serpGoogleOneTap',
          experiments,
          cookieValue: cookies.SERP_GOOGLE_ONE_TAP,
        }),
    ),
    matchedQualities: Boolean(
      cookies.MATCHED_QUALITIES ||
        MATCHED_QUALITIES ||
        toggleExperiment({
          experimentId: 'matchedQualities',
          experiments,
          cookieValue: cookies.MATCHED_QUALITIES,
        }),
    ),
    hideApplyButtonOnPrivateAdvertiser: true, // todo cleanup after
    entryLevelBadge: Boolean(cookies.ENTRY_LEVEL_BADGE || ENTRY_LEVEL_BADGE),
    dynamicPillsV2: Boolean(
      cookies.DYNAMIC_PILLS_V2 ||
        DYNAMIC_PILLS_V2 ||
        toggleExperiment({
          experimentId: 'dynamicPillsV2',
          experiments,
          cookieValue: cookies.DYNAMIC_PILLS_V2,
        }),
    ),
  };
};

export const updateFeatureFlagsAfterAuth =
  (
    cookies: CookieType = {},
    experiments: TSeekExperiments,
    _zoneFeatures: ZoneFeatures,
  ): TypedThunkAction =>
  (dispatch) => {
    dispatch({
      type: UPDATE_FEATURE_FLAGS,
      payload: {
        dynamicFlags: {
          homepageJdvDrawer: Boolean(
            cookies.HOMEPAGE_JDV_DRAWER ||
              toggleExperiment({
                experimentId: 'homepageJdvDrawer',
                experiments,
                cookieValue: cookies.HOMEPAGE_JDV_DRAWER,
              }),
          ),
          homepageMobileWebRedesign: Boolean(
            cookies.HOMEPAGE_MOBILE_WEB_REDESIGN ||
              toggleExperiment({
                experimentId: 'homepageMobileWebRedesign',
                experiments,
                cookieValue: cookies.HOMEPAGE_MOBILE_WEB_REDESIGN,
              }),
          ),
          homepageNoCompanyCarousel: Boolean(
            cookies.HOMEPAGE_NO_COMPANY_CAROUSEL ||
              toggleExperiment({
                experimentId: 'homepageNoCompanyCarousel',
                experiments,
                cookieValue: cookies.HOMEPAGE_NO_COMPANY_CAROUSEL,
              }),
          ),
        },
      },
    });
  };

export default function reducer(
  state = initialState,
  action: TypedAction,
): FeatureFlagsState {
  switch (action.type) {
    case UPDATE_FEATURE_FLAGS: {
      const { dynamicFlags } = action.payload;
      return {
        ...state,
        ...dynamicFlags,
      };
    }

    default:
      return state;
  }
}
