import type { CandidateSalaryNudge } from '@seek/ca-graphql-schema/types';

export type NudgesAction =
  | {
      type: typeof GET_NUDGES;
      payload: {
        nudgeId?: string;
        position?: number;
        seekerId?: number;
        nudges: CandidateSalaryNudge[];
      };
    }
  | NoPayloadAction;

export const GET_NUDGES = 'GET_NUDGES';
export const DISMISS_NUDGE = 'DISMISS_NUDGE';
export const ADD_NUDGE_FEEDBACK_SUCCESS = 'ADD_NUDGE_FEEDBACK_SUCCESS';
export const ADD_NUDGE_FEEDBACK_ERROR = 'POST_NUDGE_ANSWER_ERROR';
export const CLEAR_NUDGE_ERROR = 'CLEAR_NUDGE_ERROR';

export interface NoPayloadAction {
  type:
    | typeof DISMISS_NUDGE
    | typeof ADD_NUDGE_FEEDBACK_SUCCESS
    | typeof ADD_NUDGE_FEEDBACK_ERROR
    | typeof CLEAR_NUDGE_ERROR;
}
