export const savedSearches = [
  {
    id: '76c5a37c-b155-11eb-b286-4fbc8d3e46c8',
    name: 'driver in Melbourne CBD & Inner Suburbs Melbourne VIC',
    countLabel: '99+',
    countryCode: 'au',
    createdDate: {
      shortLabel: '23h ago',
      __typename: 'SeekDateTime',
    },
    subscribeToNewJobs: true,
    query: {
      searchQueryString:
        'classification=6251&daterange=14&keywords=driver&salaryrange=60000-120000&salarytype=annual&sitekey=AU-Main&subclassification=6254%2C6257&where=Melbourne%20CBD%20%26%20Inner%20Suburbs%20Melbourne%20VIC&worktype=242%2C243&workarrangement=2',
      parameters: [
        {
          type: 'locationId',
          value: ['CBD & Inner Suburbs'],
          __typename: 'SavedSearchParameters',
        },
        {
          type: 'filter.category',
          value: [
            'Contracts Administration, PA, EA & Secretarial (Administration & Office Support)',
          ],
          __typename: 'SavedSearchParameters',
        },
        {
          type: 'salary',
          value: ['$60k to $120k Annually'],
          __typename: 'SavedSearchParameters',
        },
        {
          type: 'filter.workType',
          value: ['Full time, Part time'],
          __typename: 'SavedSearchParameters',
        },
        {
          type: 'filter.workArrangement',
          value: ['Hybrid'],
          __typename: 'SavedSearchParameters',
        },
      ],
      __typename: 'SavedSearchApacQuery',
    },
    __typename: 'ApacSavedSearch',
  },
  {
    // Saved search with all custom search params
    id: 'a990390c-aca5-11eb-ad6c-a3e34ffa605a',
    name: 'chef in Bayside & South Eastern Suburbs Melbourne VIC',
    countLabel: '5',
    countryCode: 'au',
    createdDate: {
      shortLabel: 'just now',
      __typename: 'SeekDateTime',
    },
    subscribeToNewJobs: true,
    query: {
      searchQueryString:
        'classification=1212&daterange=14&keywords=chef&salaryrange=35-50&salarytype=hourly&sitekey=AU-Main&subclassification=6052&where=Bayside%20%26%20South%20Eastern%20Suburbs%20Melbourne%20VIC&worktype=242&workarrangement=2',
      parameters: [
        {
          type: 'locationId',
          value: ['Bayside & South Eastern Suburbs'],
          __typename: 'SavedSearchParameters',
        },
        {
          type: 'filter.category',
          value: ['Chefs/Cooks (Hospitality & Tourism)'],
          __typename: 'SavedSearchParameters',
        },
        {
          type: 'salary',
          value: ['$35 to $50 Hourly'],
          __typename: 'SavedSearchParameters',
        },
        {
          type: 'filter.workType',
          value: ['Full time'],
          __typename: 'SavedSearchParameters',
        },
        {
          type: 'filter.workArrangement',
          value: ['Hybrid'],
          __typename: 'SavedSearchParameters',
        },
      ],
      __typename: 'SavedSearchApacQuery',
    },
    __typename: 'ApacSavedSearch',
  },
  {
    // Saved search with multiple worktypes
    id: 'a990390c-aca5-11eb-ad6c-a3e34ffa605d',
    name: 'chef in Bayside & South Eastern Suburbs Melbourne VIC',
    countLabel: '5',
    countryCode: 'au',
    createdDate: {
      shortLabel: 'just now',
      __typename: 'SeekDateTime',
    },
    subscribeToNewJobs: true,
    query: {
      searchQueryString:
        'classification=1212&daterange=14&keywords=chef&salaryrange=35-50&salarytype=hourly&sitekey=AU-Main&subclassification=6052&where=Bayside%20%26%20South%20Eastern%20Suburbs%20Melbourne%20VIC&worktype=243%2C242%2C244&workarrangement=2',
      parameters: [
        {
          type: 'locationId',
          value: ['Bayside & South Eastern Suburbs'],
          __typename: 'SavedSearchParameters',
        },
        {
          type: 'filter.category',
          value: ['Chefs/Cooks (Hospitality & Tourism)'],
          __typename: 'SavedSearchParameters',
        },
        {
          type: 'salary',
          value: ['$35 to $50 Hourly'],
          __typename: 'SavedSearchParameters',
        },
        {
          type: 'filter.workType',
          value: ['Full time'],
          __typename: 'SavedSearchParameters',
        },
        {
          type: 'filter.workArrangement',
          value: ['Hybrid'],
          __typename: 'SavedSearchParameters',
        },
      ],
      __typename: 'SavedSearchApacQuery',
    },
    __typename: 'ApacSavedSearch',
  },
  {
    // Saved search without where search param
    id: 'a990390c-aca5-11eb-ad6c-a3e34ffa605b',
    name: 'chef in All Australia',
    countLabel: '0',
    countryCode: 'au',
    createdDate: {
      shortLabel: 'just now',
      __typename: 'SeekDateTime',
    },
    subscribeToNewJobs: true,
    query: {
      searchQueryString:
        'classification=1212&daterange=14&keywords=chef&salaryrange=35-50&salarytype=hourly&sitekey=AU-Main&subclassification=6052&worktype=242&workarrangement=2',
      parameters: [
        {
          type: 'filter.category',
          value: ['Chefs/Cooks (Hospitality & Tourism)'],
          __typename: 'SavedSearchParameters',
        },
        {
          type: 'salary',
          value: ['$35 to $50 Hourly'],
          __typename: 'SavedSearchParameters',
        },
        {
          type: 'filter.workType',
          value: ['Full time'],
          __typename: 'SavedSearchParameters',
        },
        {
          type: 'filter.workArrangement',
          value: ['Hybrid'],
          __typename: 'SavedSearchParameters',
        },
        {
          type: 'locationId',
          value: ['Bayside & South Eastern Suburbs'],
          __typename: 'SavedSearchParameters',
        },
      ],
      __typename: 'SavedSearchApacQuery',
    },
    __typename: 'ApacSavedSearch',
  },
  {
    // Saved search with default search params
    id: 'a990390c-aca5-11eb-ad6c-a3e34ffa605c',
    name: 'chef in All Australia',
    countLabel: '0',
    countryCode: 'au',
    createdDate: {
      shortLabel: 'just now',
      __typename: 'SeekDateTime',
    },
    subscribeToNewJobs: true,
    query: {
      searchQueryString:
        'classification=1212&keywords=chef&sitekey=AU-Main&subclassification=6052&where=Bayside%20%26%20South%20Eastern%20Suburbs%20Melbourne%20VIC&worktype=242&workarrangement=2',
      parameters: [
        {
          type: 'filter.category',
          value: ['Chefs/Cooks (Hospitality & Tourism)'],
          __typename: 'SavedSearchParameters',
        },
        {
          type: 'filter.workType',
          value: ['Full time'],
          __typename: 'SavedSearchParameters',
        },
        {
          type: 'filter.workArrangement',
          value: ['Hybrid'],
          __typename: 'SavedSearchParameters',
        },
        {
          type: 'filter.category',
          value: ['Chefs/Cooks (Hospitality & Tourism)'],
          __typename: 'SavedSearchParameters',
        },
      ],
      __typename: 'SavedSearchApacQuery',
    },
    __typename: 'ApacSavedSearch',
  },
];
