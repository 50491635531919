// Using style-guide @responsive-breakpoint: 740px;
// `@media @mobile { @rules }` { max-width: (@responsive-breakpoint - 1)) }
// Usage of these function should be avoided as much as possible
// as it's causing server/client render comparison checksum errors.
// Consider looking at CSS-only solution,
// potentially using style-guide <Hidden> component
import get from 'lodash/get';
import max from 'lodash/max';
const isClient = ENV.CLIENT;

// gets viewport width, taking into account cross-browser quirks and scrollbar visibility
// https://stackoverflow.com/a/8876069/2415971
export const getViewportWidth = () =>
  max([
    get(document, 'documentElement.clientWidth'),
    get(window, 'innerWidth'),
  ]) || 0;

export const getViewportHeight = () =>
  max([
    get(document, 'documentElement.clientHeight'),
    get(window, 'innerHeight'),
  ]) || 0;

const isMobileWidth = (): boolean => getViewportWidth() < 740;
export const isClientDesktopWidth = (): boolean => isClient && !isMobileWidth();
export const isClientMobileWidth = (): boolean => isClient && isMobileWidth();
export const isClientWidthAbove = (threshold: number): boolean =>
  isClient && getViewportWidth() >= threshold;

export const isShortDevice = (): boolean =>
  isClient && getViewportHeight() < 568;
