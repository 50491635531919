import type { ApacCandidateHeader } from '@seek/apac-candidate-header-footer';
import { AUTHENTICATION_TYPES } from '@seek/apac-candidate-header-footer/types';
import type { Brand } from '@seek/melways-sites';
import type { ComponentProps } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import useAuthenticated from 'src/hooks/useAuthenticated';
import { useSelector } from 'src/store/react';
import {
  selectEmailAddress,
  selectFirstName,
  selectHostname,
  selectIsJDP,
  selectUrl,
} from 'src/store/selectors';
import { useMelwaysCountry } from 'src/utils/melwaysHelper';

import { useLanguageSwitcher } from './useLanguageSwitcher';

const resolveHeaderFooterAuthState = (
  authenticateState: ReturnType<typeof useAuthenticated>,
): ComponentProps<typeof ApacCandidateHeader>['authenticationStatus'] => {
  switch (authenticateState) {
    case 'authenticated':
      return AUTHENTICATION_TYPES.AUTHENTICATED;
    case 'unauthenticated':
      return AUTHENTICATION_TYPES.UNAUTHENTICATED;
    case 'pending':
      return AUTHENTICATION_TYPES.AUTH_PENDING;
  }
};

const useCommonProps = () => {
  const { brand, language } = useAppConfig();
  const country = useMelwaysCountry();
  const authenticationStatus = useAuthenticated();
  const hostname = useSelector(selectHostname);

  return {
    // Common props
    authenticationStatus: resolveHeaderFooterAuthState(authenticationStatus),
    brand: brand as Brand,
    country,
    // Keeping the host for ANZ unified page
    hostname,
    // Assuming for non-seek site, we are displaying Asia market as unified version
    isUnified: brand !== 'seek' ? true : undefined,
    language: language || 'en',
  };
};

export const useHeaderProps = () => {
  const commonProps = useCommonProps();

  const emailAddress = useSelector(selectEmailAddress);
  const isJDP = useSelector(selectIsJDP);
  const returnUrlForPostAuth = useSelector(selectUrl);
  const userName = useSelector(selectFirstName);
  const { setLanguage } = useAppConfig();

  const userProfile = {
    firstName: userName,
    lastName: '',
    email: emailAddress,
  };
  const onLanguageSwitch: ComponentProps<
    typeof ApacCandidateHeader
  >['onLanguageSwitch'] = (lang) => setLanguage(lang);

  const languageSwitcher = useLanguageSwitcher();

  return {
    ...commonProps,
    isEnableDivider: isJDP,
    languageSwitcher,
    onLanguageSwitch,
    returnUrlForPostAuth: returnUrlForPostAuth || '',
    userProfile,
  };
};

export const useFooterProps = () => {
  const commonProps = useCommonProps();

  return { ...commonProps, widthMode: 'large' as const };
};
