import { createContext, useContext, useState } from 'react';
import { v4 as uuid } from 'uuid';

type SelectedJobInHomePage = {
  id: string;
  analytics: {
    position: number;
    origin: 'saved-homepage' | 'recom-homepage';
    solHash?: string;
  };
};

const SignedInDashboardContext = createContext<
  ReturnType<typeof useSignedInDashboard>
>({} as ReturnType<typeof useSignedInDashboard>);

export const SignedInDashboardContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const signedInDashboardContextValue = useSignedInDashboard();

  return (
    <SignedInDashboardContext.Provider value={signedInDashboardContextValue}>
      {children}
    </SignedInDashboardContext.Provider>
  );
};

export const useSignedInDashboardContext = () =>
  useContext(SignedInDashboardContext);

const useSignedInDashboard = () => {
  const [selectedJob, setSelectedJob] =
    useState<SelectedJobInHomePage | null>();
  const jobDetailsViewedCorrelationId = uuid();

  return {
    selectedJob,
    setSelectedJob,
    jobDetailsViewedCorrelationId,
  };
};
