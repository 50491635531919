import { isSearchResultsPath } from '@seek/seek-jobs-seo';

import { canSaveSearch } from 'src/modules/refine-job-search';
import { LOCATION_CHANGED } from 'src/store/location/types';
import { UPDATE_CRITERIA } from 'src/store/search/types';

import type { TypedAction } from '../types';

import {
  JOB_MAIL_BEGIN,
  JOB_MAIL_ERROR,
  JOB_MAIL_SUCCESS,
  RESET_SAVE_SEARCH,
  SAVE_SEARCH_BEGIN,
  SAVE_SEARCH_DUPLICATE,
  SAVE_SEARCH_ERROR,
  SAVE_SEARCH_EXCEEDED,
  SAVE_SEARCH_INVALID_LOCATION,
  SAVE_SEARCH_SUCCESS,
  SavedSearchStatus,
  type JobMailErrorAction,
  type JobMailErrorStatus,
  type ResetSaveSearchAction,
  type SaveSearchErrorAction,
  type SaveSearchErrorActionType,
  type SaveSearchState,
  type SaveSearchSuccessAction,
} from './types';

export const initialState: SaveSearchState = {
  emailAddress: '',
  errorMessage: '',
  saveable: true,
  status: SavedSearchStatus.UNSAVED,
};

export default function reducer(
  state = initialState,
  action: TypedAction,
): SaveSearchState {
  switch (action.type) {
    case UPDATE_CRITERIA: {
      const { lastQuery, query } = action.payload;
      const { status } = state;

      const hasSavedSearch = status === SavedSearchStatus.SAVED;
      const saveable = canSaveSearch(lastQuery, query, hasSavedSearch);

      return {
        ...state,
        status: saveable ? SavedSearchStatus.UNSAVED : status,
        saveable,
        errorMessage: '',
      };
    }

    case SAVE_SEARCH_BEGIN: {
      return {
        ...state,
        status: SavedSearchStatus.SAVING,
      };
    }

    case SAVE_SEARCH_SUCCESS: {
      return {
        ...state,
        status: SavedSearchStatus.SAVED,
      };
    }

    case SAVE_SEARCH_ERROR: {
      const { errorMessage } = action.payload || {};

      return {
        ...state,
        errorMessage,
        status: SavedSearchStatus.ERROR,
      };
    }

    case SAVE_SEARCH_EXCEEDED: {
      const { errorMessage } = action.payload || {};

      return {
        ...state,
        errorMessage,
        status: SavedSearchStatus.EXCEEDED,
      };
    }

    case SAVE_SEARCH_INVALID_LOCATION: {
      const { errorMessage } = action.payload || {};

      return {
        ...state,
        errorMessage,
        status: SavedSearchStatus.INVALID_LOCATION,
      };
    }

    case SAVE_SEARCH_DUPLICATE: {
      const { errorMessage } = action.payload || {};

      return {
        ...state,
        errorMessage,
        status: SavedSearchStatus.DUPLICATE,
      };
    }

    case JOB_MAIL_BEGIN: {
      return {
        ...state,
        status: SavedSearchStatus.SAVING,
      };
    }

    case JOB_MAIL_SUCCESS: {
      const { emailAddress } = action.payload;
      return {
        ...state,
        status: SavedSearchStatus.SAVED,
        emailAddress,
      };
    }

    case JOB_MAIL_ERROR: {
      const { status, errorMessage } = action || {};
      return {
        ...state,
        errorMessage,
        status,
      };
    }

    // Resetting status when location changes to non SRP path to ensure ..
    // .. toasts will not keep showing when navigating between pages
    case LOCATION_CHANGED: {
      const {
        location: { pathname },
      } = action.payload;
      const isSRPPath = pathname && isSearchResultsPath(pathname);

      if (!isSRPPath) {
        return {
          ...state,
          status: SavedSearchStatus.UNSAVED,
        };
      }
      return state;
    }

    case RESET_SAVE_SEARCH: {
      return {
        ...state,
        status: SavedSearchStatus.UNSAVED,
        errorMessage: '',
      };
    }

    default: {
      return state;
    }
  }
}

export const createJobMailErrorAction = (
  status: JobMailErrorStatus,
  errorMessage: string,
): JobMailErrorAction => ({
  type: JOB_MAIL_ERROR,
  status,
  errorMessage,
  meta: {
    hotjar: 'Job Mail Error',
  },
});

export const createSavedSearchErrorAction = (
  type: SaveSearchErrorActionType,
  errorMessage: string,
): SaveSearchErrorAction => ({
  type,
  error: type === SAVE_SEARCH_ERROR,
  payload: { errorMessage },
});

export const saveSearchSuccess = (): SaveSearchSuccessAction => ({
  type: SAVE_SEARCH_SUCCESS,
});

export const resetSaveSearch = (): ResetSaveSearchAction => ({
  type: RESET_SAVE_SEARCH,
});
