
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IkRhdGUgTGlzdGVkIiwiZGF0ZSBsaXN0ZWQiOiJkYXRlIGxpc3RlZCIsIkFueSB0aW1lIjoiQW55IHRpbWUiLCJsaXN0ZWQiOiJsaXN0ZWQiLCJsaXN0ZWQgaW4iOiJsaXN0ZWQgaW4iLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IkRhdGUgTGlzdGVkIiwiZGF0ZSBsaXN0ZWQiOiJkYXRlIGxpc3RlZCIsIkFueSB0aW1lIjoiQW55IHRpbWUiLCJsaXN0ZWQiOiJsaXN0ZWQiLCJsaXN0ZWQgaW4iOiJsaXN0ZWQgaW4iLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IkRhdGUgTGlzdGVkIiwiZGF0ZSBsaXN0ZWQiOiJkYXRlIGxpc3RlZCIsIkFueSB0aW1lIjoiQW55IHRpbWUiLCJsaXN0ZWQiOiJsaXN0ZWQiLCJsaXN0ZWQgaW4iOiJsaXN0ZWQgaW4iLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IkRhdGUgTGlzdGVkIiwiZGF0ZSBsaXN0ZWQiOiJkYXRlIGxpc3RlZCIsIkFueSB0aW1lIjoiQW55IHRpbWUiLCJsaXN0ZWQiOiJsaXN0ZWQiLCJsaXN0ZWQgaW4iOiJsaXN0ZWQgaW4iLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IkRhdGUgTGlzdGVkIiwiZGF0ZSBsaXN0ZWQiOiJkYXRlIGxpc3RlZCIsIkFueSB0aW1lIjoiQW55IHRpbWUiLCJsaXN0ZWQiOiJsaXN0ZWQiLCJsaXN0ZWQgaW4iOiJsaXN0ZWQgaW4iLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IkRhdGUgTGlzdGVkIiwiZGF0ZSBsaXN0ZWQiOiJkYXRlIGxpc3RlZCIsIkFueSB0aW1lIjoiQW55IHRpbWUiLCJsaXN0ZWQiOiJsaXN0ZWQiLCJsaXN0ZWQgaW4iOiJsaXN0ZWQgaW4iLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IkRhdGUgTGlzdGVkIiwiZGF0ZSBsaXN0ZWQiOiJkYXRlIGxpc3RlZCIsIkFueSB0aW1lIjoiQW55IHRpbWUiLCJsaXN0ZWQiOiJsaXN0ZWQiLCJsaXN0ZWQgaW4iOiJsaXN0ZWQgaW4iLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IkRhdGUgTGlzdGVkIiwiZGF0ZSBsaXN0ZWQiOiJkYXRlIGxpc3RlZCIsIkFueSB0aW1lIjoiQW55IHRpbWUiLCJsaXN0ZWQiOiJsaXN0ZWQiLCJsaXN0ZWQgaW4iOiJsaXN0ZWQgaW4iLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IkRhdGUgTGlzdGVkIiwiZGF0ZSBsaXN0ZWQiOiJkYXRlIGxpc3RlZCIsIkFueSB0aW1lIjoiQW55IHRpbWUiLCJsaXN0ZWQiOiJsaXN0ZWQiLCJsaXN0ZWQgaW4iOiJsaXN0ZWQgaW4iLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IkRhdGUgTGlzdGVkIiwiZGF0ZSBsaXN0ZWQiOiJkYXRlIGxpc3RlZCIsIkFueSB0aW1lIjoiQW55IHRpbWUiLCJsaXN0ZWQiOiJsaXN0ZWQiLCJsaXN0ZWQgaW4iOiJsaXN0ZWQgaW4iLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IkRhdGUgTGlzdGVkIiwiZGF0ZSBsaXN0ZWQiOiJkYXRlIGxpc3RlZCIsIkFueSB0aW1lIjoiQW55IHRpbWUiLCJsaXN0ZWQiOiJsaXN0ZWQiLCJsaXN0ZWQgaW4iOiJsaXN0ZWQgaW4iLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IkRhdGUgTGlzdGVkIiwiZGF0ZSBsaXN0ZWQiOiJkYXRlIGxpc3RlZCIsIkFueSB0aW1lIjoiQW55IHRpbWUiLCJsaXN0ZWQiOiJsaXN0ZWQiLCJsaXN0ZWQgaW4iOiJsaXN0ZWQgaW4iLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IkRhdGUgTGlzdGVkIiwiZGF0ZSBsaXN0ZWQiOiJkYXRlIGxpc3RlZCIsIkFueSB0aW1lIjoiQW55IHRpbWUiLCJsaXN0ZWQiOiJsaXN0ZWQiLCJsaXN0ZWQgaW4iOiJsaXN0ZWQgaW4iLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IkRhdGUgTGlzdGVkIiwiZGF0ZSBsaXN0ZWQiOiJkYXRlIGxpc3RlZCIsIkFueSB0aW1lIjoiQW55IHRpbWUiLCJsaXN0ZWQiOiJsaXN0ZWQiLCJsaXN0ZWQgaW4iOiJsaXN0ZWQgaW4iLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IkRhdGUgTGlzdGVkIiwiZGF0ZSBsaXN0ZWQiOiJkYXRlIGxpc3RlZCIsIkFueSB0aW1lIjoiQW55IHRpbWUiLCJsaXN0ZWQiOiJsaXN0ZWQiLCJsaXN0ZWQgaW4iOiJsaXN0ZWQgaW4iLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IkRhdGUgTGlzdGVkIiwiZGF0ZSBsaXN0ZWQiOiJkYXRlIGxpc3RlZCIsIkFueSB0aW1lIjoiQW55IHRpbWUiLCJsaXN0ZWQiOiJsaXN0ZWQiLCJsaXN0ZWQgaW4iOiJsaXN0ZWQgaW4iLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IkRhdGUgTGlzdGVkIiwiZGF0ZSBsaXN0ZWQiOiJkYXRlIGxpc3RlZCIsIkFueSB0aW1lIjoiQW55IHRpbWUiLCJsaXN0ZWQiOiJsaXN0ZWQiLCJsaXN0ZWQgaW4iOiJsaXN0ZWQgaW4iLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IkRhdGUgTGlzdGVkIiwiZGF0ZSBsaXN0ZWQiOiJkYXRlIGxpc3RlZCIsIkFueSB0aW1lIjoiQW55IHRpbWUiLCJsaXN0ZWQiOiJsaXN0ZWQiLCJsaXN0ZWQgaW4iOiJsaXN0ZWQgaW4iLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IlRhbmdnYWwgVGVyZGFmdGFyIiwiZGF0ZSBsaXN0ZWQiOiJ0YW5nZ2FsIHRlcmRhZnRhciIsIkFueSB0aW1lIjoiS2FwYW4gc2FqYSIsImxpc3RlZCI6InRlcmRhZnRhciIsImxpc3RlZCBpbiI6InRlcmRhZnRhciBkaSIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IlRhbmdnYWwgVGVyZGFmdGFyIiwiZGF0ZSBsaXN0ZWQiOiJ0YW5nZ2FsIHRlcmRhZnRhciIsIkFueSB0aW1lIjoiS2FwYW4gc2FqYSIsImxpc3RlZCI6InRlcmRhZnRhciIsImxpc3RlZCBpbiI6InRlcmRhZnRhciBkaSIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IuC4p%2BC4seC4meC4l%2BC4teC5iOC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsImRhdGUgbGlzdGVkIjoi4Lin4Lix4LiZ4LiX4Li14LmI4Lil4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiQW55IHRpbWUiOiLguJfguLjguIHguIrguYjguKfguIfguYDguKfguKXguLIiLCJsaXN0ZWQiOiLguKfguLHguJnguJfguLXguYjguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJsaXN0ZWQgaW4iOiLguKfguLHguJnguJfguLXguYjguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IuC4p%2BC4seC4meC4l%2BC4teC5iOC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsImRhdGUgbGlzdGVkIjoi4Lin4Lix4LiZ4LiX4Li14LmI4Lil4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiQW55IHRpbWUiOiLguJfguLjguIHguIrguYjguKfguIfguYDguKfguKXguLIiLCJsaXN0ZWQiOiLguKfguLHguJnguJfguLXguYjguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJsaXN0ZWQgaW4iOiLguKfguLHguJnguJfguLXguYjguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IlRhbmdnYWwgVGVyZGFmdGFyIiwiZGF0ZSBsaXN0ZWQiOiJ0YW5nZ2FsIHRlcmRhZnRhciIsIkFueSB0aW1lIjoiS2FwYW4gc2FqYSIsImxpc3RlZCI6InRlcmRhZnRhciIsImxpc3RlZCBpbiI6InRlcmRhZnRhciBkaSIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IlRhbmdnYWwgVGVyZGFmdGFyIiwiZGF0ZSBsaXN0ZWQiOiJ0YW5nZ2FsIHRlcmRhZnRhciIsIkFueSB0aW1lIjoiS2FwYW4gc2FqYSIsImxpc3RlZCI6InRlcmRhZnRhciIsImxpc3RlZCBpbiI6InRlcmRhZnRhciBkaSIsIkxpc3RpbmcgdGltZSI6Ikxpc3RpbmcgdGltZSJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IuC4p%2BC4seC4meC4l%2BC4teC5iOC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsImRhdGUgbGlzdGVkIjoi4Lin4Lix4LiZ4LiX4Li14LmI4Lil4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiQW55IHRpbWUiOiLguJfguLjguIHguIrguYjguKfguIfguYDguKfguKXguLIiLCJsaXN0ZWQiOiLguKfguLHguJnguJfguLXguYjguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJsaXN0ZWQgaW4iOiLguKfguLHguJnguJfguLXguYjguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IuC4p%2BC4seC4meC4l%2BC4teC5iOC4peC4h%2BC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSIsImRhdGUgbGlzdGVkIjoi4Lin4Lix4LiZ4LiX4Li14LmI4Lil4LiH4Lib4Lij4Liw4LiB4Liy4Lio4LiH4Liy4LiZIiwiQW55IHRpbWUiOiLguJfguLjguIHguIrguYjguKfguIfguYDguKfguKXguLIiLCJsaXN0ZWQiOiLguKfguLHguJnguJfguLXguYjguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJsaXN0ZWQgaW4iOiLguKfguLHguJnguJfguLXguYjguKXguIfguJvguKPguLDguIHguLLguKjguIfguLLguJkiLCJMaXN0aW5nIHRpbWUiOiJMaXN0aW5nIHRpbWUifQ%3D%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IlvhuIrEg8SDxIPhua%2Fhur3hur3hur0gxYHDrMOsw6zFoeG5r%2BG6veG6veG6vcaMXSIsImRhdGUgbGlzdGVkIjoiW8aMxIPEg8SD4bmv4bq94bq94bq9IMaaw6zDrMOsxaHhua%2Fhur3hur3hur3GjF0iLCJBbnkgdGltZSI6IlvhuqzguIHguLXguYnDvcO9w70g4bmvw6zDrMOsbcyC4bq94bq94bq9XSIsImxpc3RlZCI6IlvGmsOsw6zDrMWh4bmv4bq94bq94bq9xoxdIiwibGlzdGVkIGluIjoiW8aaw6zDrMOsxaHhua%2Fhur3hur3hur3GjCDDrMOsw6zguIHguLXguYldIiwiTGlzdGluZyB0aW1lIjoiW8WBw6zDrMOsxaHhua%2FDrMOsw6zguIHguLXguYnEoyDhua%2FDrMOsw6xtzILhur3hur3hur1dIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJEYXRlIExpc3RlZCI6IlvhuIrEg8SDxIPhua%2Fhur3hur3hur0gxYHDrMOsw6zFoeG5r%2BG6veG6veG6vcaMXSIsImRhdGUgbGlzdGVkIjoiW8aMxIPEg8SD4bmv4bq94bq94bq9IMaaw6zDrMOsxaHhua%2Fhur3hur3hur3GjF0iLCJBbnkgdGltZSI6IlvhuqzguIHguLXguYnDvcO9w70g4bmvw6zDrMOsbcyC4bq94bq94bq9XSIsImxpc3RlZCI6IlvGmsOsw6zDrMWh4bmv4bq94bq94bq9xoxdIiwibGlzdGVkIGluIjoiW8aaw6zDrMOsxaHhua%2Fhur3hur3hur3GjCDDrMOsw6zguIHguLXguYldIiwiTGlzdGluZyB0aW1lIjoiW8WBw6zDrMOsxaHhua%2FDrMOsw6zguIHguLXguYnEoyDhua%2FDrMOsw6xtzILhur3hur3hur1dIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    