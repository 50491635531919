import type { ApacSavedSearch } from 'src/graphql/graphql';

export const SAVE_SEARCH_SUCCESS = 'SAVE_SEARCH_SUCCESS';

export const SAVE_SEARCH_BEGIN = 'SAVE_SEARCH_BEGIN';
export const SAVE_SEARCH_ERROR = 'SAVE_SEARCH_ERROR';
export const SAVE_SEARCH_EXCEEDED = 'SAVE_SEARCH_EXCEEDED';
export const SAVE_SEARCH_INVALID_LOCATION = 'SAVE_SEARCH_INVALID_LOCATION';
export const SAVE_SEARCH_DUPLICATE = 'SAVE_SEARCH_DUPLICATE';
export const RESET_SAVE_SEARCH = 'RESET_SAVE_SEARCH';

export const JOB_MAIL_BEGIN = 'JOB_MAIL_BEGIN';
export const JOB_MAIL_SUCCESS = 'JOB_MAIL_SUCCESS';
export const JOB_MAIL_ERROR = 'JOB_MAIL_ERROR';

export enum SavedSearchStatus {
  SAVING = 'SAVING',
  SAVED = 'SAVED',
  UNSAVED = 'UNSAVED',
  ERROR = 'ERROR',
  EXCEEDED = 'EXCEEDED',
  INVALID_LOCATION = 'INVALID_LOCATION',
  DUPLICATE = 'DUPLICATE',
  INVALID_EMAIL = 'INVALID_EMAIL',
}

export type SavedSearch = Pick<
  ApacSavedSearch,
  'name' | 'countLabel' | 'query' | 'id'
>;

export interface SaveSearchState {
  emailAddress: string;
  errorMessage: string;
  saveable: boolean;
  status: SavedSearchStatus;
}

export type JobMailPosition = 'top' | 'bottom';
export type JobMailPositionAnalytics = 'above-results' | 'below-results';
export type JobMailType =
  | 'job-mail-after-page-1'
  | 'job-mail'
  | 'job-mail-top-page-1';

export type SaveSearchErrorActionType =
  | typeof SAVE_SEARCH_EXCEEDED
  | typeof SAVE_SEARCH_INVALID_LOCATION
  | typeof SAVE_SEARCH_DUPLICATE
  | typeof SAVE_SEARCH_ERROR;

export interface SaveSearchErrorAction {
  type: SaveSearchErrorActionType;
  error: boolean;
  payload: {
    errorMessage: string;
  };
}

export interface SaveSearchSuccessAction {
  type: typeof SAVE_SEARCH_SUCCESS;
}

export interface ResetSaveSearchAction {
  type: typeof RESET_SAVE_SEARCH;
}

export type JobMailErrorStatus =
  | SavedSearchStatus.DUPLICATE
  | SavedSearchStatus.ERROR
  | SavedSearchStatus.EXCEEDED
  | SavedSearchStatus.INVALID_EMAIL
  | SavedSearchStatus.INVALID_LOCATION;

export interface JobMailErrorAction {
  type: typeof JOB_MAIL_ERROR;
  status: JobMailErrorStatus;
  errorMessage: string;
  meta: {
    hotjar: string;
  };
}

export interface JobMailSuccessAction {
  type: typeof JOB_MAIL_SUCCESS;
  payload: {
    emailAddress: string;
    jobMailPositionName: JobMailPositionAnalytics;
  };
  meta: {
    hotjar: string;
    jobMailType: JobMailType;
  };
}

export type SaveSearchAction =
  | SaveSearchErrorAction
  | SaveSearchSuccessAction
  | JobMailSuccessAction
  | ResetSaveSearchAction
  | JobMailErrorAction
  | {
      type:
        | typeof SAVE_SEARCH_BEGIN
        | typeof SAVE_SEARCH_ERROR
        | typeof JOB_MAIL_BEGIN;
      payload?: any;
      meta?: Record<string, any>;
    };
