import { type Hubble, useHubble } from '@seek/hubble';
import { useTranslations } from '@vocab/react';

import useIsomorphicLayoutEffect from 'src/modules/useIsomorphicLayoutEffect';
import type createStore from 'src/store/createStore';
import { selectLoginId } from 'src/store/selectors';
import { getLoginIdFromStore } from 'src/store/user/utils';

import {
  getTestScope,
  isTestAgent,
  type TestHeaders,
} from '../../utils/productionTesting/productionTesting';

import translations from './.vocab';

export enum PageTitle {
  homePage = 'home page',
  searchResults = 'search results',
  jobDetails = 'job details',
  emailToAFriend = 'email to a friend',
  emailToAFriendSuccess = 'email to a friend success',
}

export const useSetHubblePage = (pageName: PageTitle) => {
  const { t } = useTranslations(translations);
  const hubble = useHubble();
  useIsomorphicLayoutEffect(() => {
    hubble.updateOptions({ pageName: t(pageName) });
  }, [hubble, pageName]);
};

export const setHubbleLoginId = (
  hubble: Hubble,
  store: ReturnType<typeof createStore>,
) => {
  const state = store.getState();
  const loginId = selectLoginId(state);
  hubble.updateOptions({
    loginId:
      loginId ||
      getLoginIdFromStore(store).then((maybeValue) => maybeValue ?? ''),
  });
};
export const useSetHubbleTags = (testHeaders: TestHeaders) => {
  const hubble = useHubble();
  useIsomorphicLayoutEffect(() => {
    const isTestUser = isTestAgent(testHeaders);
    hubble.updateOptions({
      tags: {
        testRecord: isTestUser ? 'true' : 'false',
        testScope: getTestScope(testHeaders),
      },
    });
  }, [hubble, testHeaders]);
};
