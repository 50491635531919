import { useTranslations } from '@vocab/react';
import { Text, TextLink, useResponsiveValue } from 'braid-design-system';

import { useTimedBanner } from 'src/config/appConfig';
import { isTimedComponentValid } from 'src/utils/timedComponent/timedComponentValidator';

import { GlobalNotificationBannerSkeleton } from '../GlobalNotificationBannerSkeleton/GlobalNotificationBannerSkeleton';

import translations from './.vocab';

export const PrivacyPolicyBanner = () => {
  const { t } = useTranslations(translations);
  const privacyPolicy = useTimedBanner('privacyPolicy');
  const isMobile = useResponsiveValue()({
    mobile: true,
    tablet: false,
  });

  return isTimedComponentValid(privacyPolicy?.displayDates) ? (
    <GlobalNotificationBannerSkeleton
      dataAutomation="privacyPolicyBanner"
      tone="info"
      // We need to add the _ suffix to the localStorageKey to avoid conflicts with the existing CookieConsent Banner ..
      // .. which's historically been using the same localStorageKey
      localStorageKey="pPB_"
    >
      <Text size="small" data={{ automation: 'privacyPolicyUpdate' }}>
        {isMobile
          ? t('We have updated our Privacy Policy.', {
              TextLink: (children) => (
                <TextLink href="/privacy">{children}</TextLink>
              ),
            })
          : t('We have updated our Privacy Policy across...', {
              TextLink: (children) => (
                <TextLink href="/privacy">{children}</TextLink>
              ),
            })}
      </Text>
    </GlobalNotificationBannerSkeleton>
  ) : null;
};
