import type { RequestHeaders } from 'src/server/server-render';

const testHeaderKeys = [
  'user-test-record',
  'seek-test',
  'seek-test-scope',
  'user-test-scope',
] as const;
export type TestHeaderKeys = (typeof testHeaderKeys)[number];

export type TestHeaders = Partial<{
  [key in TestHeaderKeys]: string | undefined;
}>;

export const extractTestHeaders = (
  requestHeaders: RequestHeaders,
): TestHeaders =>
  Object.entries(requestHeaders).reduce((acc, [headerKey, headerValue]) => {
    if (testHeaderKeys.includes(headerKey as any)) {
      return {
        ...acc,
        [headerKey]: headerValue,
      };
    }
    return acc;
  }, {} as TestHeaders);

export const isTestAgent = (testHeaders: TestHeaders) =>
  testHeaders['seek-test'] === 'true' ||
  testHeaders['user-test-record'] === 'true';

export const getTestScope = (testHeaders: TestHeaders) =>
  testHeaders['user-test-scope'] || testHeaders['seek-test-scope'] || '';
