import type { TypedAction } from '../types';

import {
  GET_LMIS_ERROR,
  GET_LMIS_SUCCESS,
  pageKeys,
  RESET_JDP_LMIS,
  type LmisState,
} from './types';

const initialItemState = {
  content: '',
  lmisSnippet: undefined,
};

export const initialState = {
  [pageKeys.SRP]: initialItemState,
  [pageKeys.JDP]: initialItemState,
};

export default function reducer(
  state: LmisState = initialState,
  action: TypedAction,
): LmisState {
  switch (action.type) {
    case RESET_JDP_LMIS: {
      return {
        ...state,
        [pageKeys.JDP]: initialItemState,
      };
    }
    case GET_LMIS_SUCCESS: {
      const { key } = action.payload;
      return {
        ...state,
        [key]: action.payload,
      };
    }

    case GET_LMIS_ERROR: {
      const { key } = action.payload;
      return {
        ...state,
        [key]: initialItemState,
      };
    }

    default:
      return state;
  }
}
